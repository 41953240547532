import React from "react";
import { matchPath } from "react-router-dom";

import SidebarNavListItem from "components/sidebar/SidebarNavListItem";
import SidebarNavList from "components/sidebar/SidebarNavList";
import SidebarNavSeparator from "components/sidebar/SidebarNavSeparator";

import { SIDEBAR_NAV_SEPARATOR } from "../../constants";

const reduceChildRoutes = ({ items, page, depth, pathname, index, length }) => {
  // const key = `${page.title}-${page.id}`;
  const position =
    length === 1
      ? "both"
      : index === 0
      ? "first"
      : index === length - 1
      ? "last"
      : "middle";

  if (page === SIDEBAR_NAV_SEPARATOR) {
    items.push(<SidebarNavSeparator key={index} />);
  } else if (page.children) {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: page.type === "popup",
          },
          pathname
        )
      : false;

    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={index}
        badge={page.badge}
        open={open}
        title={page.title}
        href={page.href}
        position={position}
        state={page.state}
        type={page.type}
      >
        <SidebarNavList depth={depth + 1} pages={page.children} />
      </SidebarNavListItem>
    );
  } else {
    const open = page.href ? !!matchPath(page.href, pathname) : false;

    items.push(
      <SidebarNavListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={index}
        badge={page.badge}
        title={page.title}
        position={position}
        open={open}
      />
    );
  }

  return items;
};

export default reduceChildRoutes;
