import { useEffect, useState, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Button, Tab } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import FilterChips from "components/FilterChips";
import {
  common_fields,
  ad_group_name_field,
  ad_group_state_field,
  campaign_name_field,
  selection_box,
} from "pages/opasity/opasityApi/ag_grid_fields";

import {
  get_adgroups_data,
  get_adgroups_data_summary,
  get_adgroups_data_pages,
} from "pages/opasity/opasityApi/campaignAdgroupsData";

import {
  updateAdGroup,
  deleteAdGroup,
} from "pages/opasity/opasityApi/updateAdGroup";

import {
  pickerToNorm,
  loadDate,
  saveToLocal,
  loadFromLocal,
} from "pages/opasity/opasityApi/utils";

import {
  agGridOptions,
  restoreColumnsState,
  restoreSortState,
  restoreFilters,
  onDragStarted,
  onDragStopped,
  onColumnMoved as onColumnMovedCommon,
  onSortChanged as onSortChangedCommon,
  onFilterChanged as onFilterChangedCommon,
  onColumnVisible as onColumnVisibleCommon,
  onRowDataUpdated as onRowDataUpdatedCommon,
  collapseFilterGroups,
  toggleColumnsPanelShow,
  exportToCSV,
  getTotals,
  DEFAULT_FILTER,
  customizeToolPanel,
  filterObjToArray,
} from "utils/agGridCommon";
import { ReactComponent as FilterIcon } from "assets/img/icons/light-filter.svg";
import { ReactComponent as FilterLeftArrowIcon } from "assets/img/icons/filter-left-arrow.svg";
import { ReactComponent as FilterRightArrowIcon } from "assets/img/icons/filter-right-arrow.svg";
import DateModal from "components/opasity/DateModal";
import Pagination from "components/opasity/Pagination";
import FilterComponent from "components/opasity/FilterComponent";
import ExportButton from "components/opasity/ExportButton";
import ColumnsButton from "components/opasity/ColumnsButton";
import TabNav from "components/opasity/TabNav";
import Filters from "components/Filters";
import useNavbar from "hooks/useNavbar";
import useFooter from "hooks/useFooter";
import { useSearchDebounce } from "../utils";

const Campaign = () => {
  const { profileId, campaignId } = useParams();
  const [openFilters, setOpenFilters] = useState(false);
  const gridRef = useRef();
  const [gridReady, setGridReady] = useState(false);

  const keySuffix = `adgroups${campaignId ? "-campaign" : ""}`;
  const columnStateKey = `last-column-state-${keySuffix}`;
  const columnVisibleKey = `last-column-visible-${keySuffix}`;
  const sortStateKey = `last-sort-state-${keySuffix}`;
  const lastFilterKey = `last-filter-${keySuffix}`;
  const infoKey = `last-info-${keySuffix}`;

  const lastInfo = loadFromLocal(infoKey);

  const columnDefs = [
    {
      headerName: "AdGroup Info",
      suppressColumnsToolPanel: true,
      children: campaignId
        ? [selection_box, ad_group_state_field, ad_group_name_field]
        : [
            selection_box,
            ad_group_state_field,
            campaign_name_field,
            ad_group_name_field,
          ],
    },
    ...common_fields,
  ];

  selection_box.pinned = "left";
  ad_group_state_field.pinned = "left";
  campaign_name_field.pinned = "left";
  ad_group_name_field.pinned = "left";

  const [rawColumns, setRawColumns] = useState([]);
  const [parentInfo, setParentInfo] = useState([]);

  const [rawTableData, setRawTableData] = useState([{}]);
  const [summaryData, setSummaryData] = useState([]);

  const [dateState, setDateState] = useState(loadDate());

  const [selectedAdGroups, setSelectedAdGroups] = useState([]);

  const [pageSize, setPageSize] = useState(
    lastInfo?.pageSize ? lastInfo.pageSize : 50
  );
  const [page, setPage] = useState(1);
  const [noPages, setNoPages] = useState(0);
  const [count, setCount] = useState(0);

  const defaultFilter = campaignId ? {} : DEFAULT_FILTER;

  const [orderBy, setOrderBy] = useState([]);
  const [filterBy, setFilterBy] = useState(
    restoreFilters(lastFilterKey, defaultFilter) || {}
  );
  const [debFilter, setDebFilter] = useSearchDebounce(300);
  const { setBreadcrumb } = useNavbar();
  const { setPagination } = useFooter();

  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState("");

  const updateTable = () => {
    if (!gridRef.current.api || !gridReady) {
      return;
    }

    const { selection, compare } = dateState;

    const selStartDate = pickerToNorm(selection.startDate);
    const selEndDate = pickerToNorm(selection.endDate);
    const comStartDate = pickerToNorm(compare.startDate);
    const comEndDate = pickerToNorm(compare.endDate);

    gridRef.current.api.showLoadingOverlay();

    get_adgroups_data(
      profileId,
      campaignId,
      selStartDate,
      selEndDate,
      comStartDate,
      comEndDate,
      pageSize,
      page,
      orderBy,
      filterObjToArray(filterBy),
      search
    )
      .then((res) => {
        setRawTableData(!res.data || res.data.length === 0 ? [{}] : res.data);

        get_adgroups_data_summary(
          profileId,
          campaignId,
          selStartDate,
          selEndDate,
          comStartDate,
          comEndDate,
          filterObjToArray(filterBy),
          search
        ).then((res) => {
          setSummaryData(getTotals(res.data));
        });

        get_adgroups_data_pages(
          profileId,
          campaignId,
          selStartDate,
          selEndDate,
          comStartDate,
          comEndDate,
          pageSize,
          page,
          orderBy,
          filterObjToArray(filterBy),
          search
        ).then((res) => {
          setNoPages(res.pages);
          setCount(res.count);
        });
      })
      .catch(() => {
        gridRef.current.api.hideOverlay();
      });
  };

  const onGridReady = () => {
    restoreSortState(gridRef, sortStateKey, setOrderBy);

    collapseFilterGroups(gridRef);

    setGridReady(true);
  };

  const onPageSizeChanged = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onSortChanged = (event) => {
    onSortChangedCommon(event, sortStateKey, orderBy, setOrderBy);
  };

  const onFilterChanged = (event) => {
    onFilterChangedCommon(event, lastFilterKey, filterBy, setFilterBy);
  };

  const onColumnMoved = (event) => {
    onColumnMovedCommon(event, parentInfo, columnDefs, columnStateKey);
  };

  const onColumnVisible = (event) => {
    onColumnVisibleCommon(event, columnVisibleKey);
  };

  const onRowDataUpdated = () => {
    onRowDataUpdatedCommon(gridRef, rawTableData);
  };

  const onSelectionChanged = (event) => {
    setSelectedAdGroups(event.api.getSelectedNodes());
  };

  const isRowSelectable = (row) => {
    return row.data.adGroupState && row.data.adGroupState !== "archived";
  };

  const SelectedAdGroups = () => {
    const [saving, setSaving] = useState(false);

    const updateAdGroupsState = (newState) => {
      setSaving(true);

      const adgroups = [];
      const nodes = gridRef.current.api.getSelectedNodes();
      for (let node_index in nodes) {
        adgroups.push({
          adGroupId: nodes[node_index].data.adGroupId,
          state: newState,
        });
      }

      updateAdGroup(Number(profileId), adgroups)
        .then((res) => {
          setSaving(false);

          for (let i in res.success) {
            for (let j in nodes) {
              if (Number(res.success[i].adGroupId) == nodes[j].data.adGroupId) {
                nodes[j].updateData({
                  ...nodes[j].data,
                  adGroupState: res.success[i].state,
                });
                gridRef.current.api.flashCells({
                  rowNodes: [nodes[i]],
                  columns: ["adGroupState"],
                });
              }
            }
          }
        })
        .catch(() => {
          setSaving(false);
        });
    };

    const pauseAdGroups = () => {
      updateAdGroupsState("PAUSED");
    };

    const enableAdGroups = () => {
      updateAdGroupsState("ENABLED");
    };

    const archiveAdGroups = () => {
      setSaving(true);

      const adgroups = [];
      const nodes = gridRef.current.api.getSelectedNodes();

      for (let node_index in nodes) {
        adgroups.push({
          adGroupId: nodes[node_index].data.adGroupId,
        });
      }

      if (
        window.confirm(
          `You are about to delete ${adgroups.length} adgroups. Are you sure?`
        )
      ) {
        deleteAdGroup(Number(profileId), adgroups)
          .then((res) => {
            setSaving(false);

            for (let i in res.success) {
              for (let j in nodes) {
                if (
                  Number(res.success[i].adGroupId) == nodes[j].data.adGroupId
                ) {
                  nodes[j].updateData({
                    ...nodes[j].data,
                    adGroupState: res.success[i].state,
                  });
                  gridRef.current.api.flashCells({
                    rowNodes: [nodes[i]],
                    columns: ["adGroupState"],
                  });
                }
              }
            }
          })
          .catch(() => {
            setSaving(false);
          });
      } else {
        setSaving(false);
        return;
      }
    };

    if (selectedAdGroups.length === 0) {
      return null;
    }

    if (selectedAdGroups.length > 0) {
      return (
        <div className="mb-1">
          <Button
            style={{ marginRight: "6px" }}
            variant="btn btn-outline-warning"
            title="edit"
            onClick={() => pauseAdGroups()}
            disabled={saving}
          >
            Pause AdGroups ({selectedAdGroups.length})
          </Button>
          <Button
            style={{ marginRight: "6px" }}
            variant="btn btn-outline-success"
            title="edit"
            onClick={() => enableAdGroups()}
            disabled={saving}
          >
            Enable AdGroups ({selectedAdGroups.length})
          </Button>
          <Button
            style={{ marginRight: "6px" }}
            variant="btn btn-outline-danger"
            title="edit"
            onClick={() => archiveAdGroups()}
            disabled={saving}
          >
            Archive AdGroups ({selectedAdGroups.length})
          </Button>
        </div>
      );
    }
  };

  useEffect(() => {
    updateTable();
  }, [gridReady, search, dateState, campaignId, pageSize, page, orderBy]);
  useEffect(() => {
    setDebFilter(filterBy);
  }, [filterBy]);
  useEffect(() => {
    restoreColumnsState(
      columnStateKey,
      columnVisibleKey,
      columnDefs,
      setParentInfo,
      setRawColumns
    );
  }, []);

  useEffect(() => {
    setSearch(searchParams.get("search"));
  }, [searchParams]);

  useEffect(() => {
    setBreadcrumb({ campaignId, profileId });
  }, [campaignId, profileId]);

  useEffect(() => {
    setPagination({
      pageSize,
      onPageSizeChanged,
      setPage,
      noPages,
      page,
      count,
    });
  }, [pageSize, noPages, page, count]);

  useEffect(() => {
    let currentInfo = loadFromLocal(infoKey);

    if (!currentInfo) {
      currentInfo = {};
    }

    currentInfo.pageSize = pageSize;
    saveToLocal(infoKey, currentInfo);
  }, [pageSize]);

  useEffect(() => customizeToolPanel(gridRef), [gridRef]);
  useEffect(() => {
    onFilterChangedCommon(lastFilterKey, filterBy);
  }, [filterBy]);
  return (
    <>
      <Helmet title="AdGroups" />
      <Container fluid className="p-0" style={{ height: "100%" }}>
        {/* <Row>
          <Col md="6">
            <BreadCrumb
              campaignId={campaignId}
              profileId={profileId}
            ></BreadCrumb>
          </Col>
          <Col className="text-end">
            {campaignId && (
              <Button
                onClick={() =>
                  (window.location.href = `/profiles/${profileId}/campaigns/${campaignId}/settings`)
                }
              >
                Campaign Settings
              </Button>
            )}
          </Col>
        </Row> */}
        <Row style={{ height: "100%" }}>
          <Col>
            <div
              className="tab"
              style={{
                height: "100%",
                marginBottom: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div
                  className="d-flex align-items-end"
                  style={{
                    marginTop: 18,
                    marginRight: 16,
                    paddingRight: 0,
                  }}
                >
                  <TabNav
                    profileId={profileId}
                    campaignId={campaignId}
                    page="adgroups"
                  />
                  <div style={{ flexGrow: 1 }} />
                  <div style={{ marginBottom: "0.5em" }}>
                    <DateModal
                      gridRef={gridRef}
                      dateState={dateState}
                      setDateState={setDateState}
                    />
                  </div>
                </div>
                <Tab.Content style={{ padding: 0, flexGrow: 1 }}>
                  <Tab.Pane
                    eventKey="first"
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        background: "white",
                        padding: 0,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Row className="d-flex justify-content-between mb-1">
                        <Col
                          className="d-flex align-items-end"
                          style={{ marginLeft: 0 }}
                        >
                          {selectedAdGroups.length === 0 ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <button
                                className="filters-button"
                                onClick={() => {
                                  setOpenFilters(!openFilters);
                                }}
                              >
                                {openFilters ? (
                                  <span style={{ marginRight: 6 }}>
                                    Filters
                                  </span>
                                ) : null}
                                <FilterIcon style={{ marginRight: 6 }} />
                                {openFilters ? (
                                  <FilterLeftArrowIcon />
                                ) : (
                                  <FilterRightArrowIcon />
                                )}
                              </button>

                              <FilterChips
                                filterBy={filterBy}
                                setFilterBy={setFilterBy}
                              />
                            </div>
                          ) : (
                            <div style={{ marginLeft: "10px" }}>
                              <SelectedAdGroups />
                            </div>
                          )}

                          {/* {selectedAdGroups.length === 0 ? (
                            <FilterComponent
                              gridRef={gridRef}
                              filterBy={filterBy}
                              defaultFilter={defaultFilter}
                              page="campaign"
                            />
                          ) : (
                            <SelectedAdGroups />
                          )} */}
                          <div style={{ flexGrow: 1 }} />
                          <ExportButton
                            onClick={() => {
                              exportToCSV(gridRef, "adgroups.csv");
                            }}
                          />
                          <ColumnsButton
                            onClick={() => {
                              toggleColumnsPanelShow(gridRef);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ flexGrow: 1 }}>
                        <Filters
                          columnsArray={rawColumns}
                          openFilters={openFilters}
                          setOpenFilters={setOpenFilters}
                          filterBy={filterBy}
                          setFilterBy={setFilterBy}
                        />
                        <Col>
                          <div className="ag-theme-alpine grid-wrapper">
                            <AgGridReact
                              ref={gridRef}
                              onGridReady={onGridReady}
                              columnDefs={rawColumns}
                              rowData={rawTableData}
                              gridOptions={agGridOptions}
                              pinnedBottomRowData={summaryData}
                              rowSelection="multiple"
                              isRowSelectable={isRowSelectable}
                              onSelectionChanged={onSelectionChanged}
                              onDragStarted={onDragStarted}
                              onDragStopped={onDragStopped}
                              onColumnMoved={onColumnMoved}
                              onSortChanged={onSortChanged}
                              //onFilterChanged={onFilterChanged}
                              onColumnVisible={onColumnVisible}
                              onRowDataUpdated={onRowDataUpdated}
                              rowModelType={"clientSide"}
                              pivotMode={false}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Campaign;
