import api from "./Ax";
import {
  saveToLocalSession,
  loadFromLocalSession,
  loadFromLocal,
  saveToLocal,
} from "./utils";

export async function get_ad_group_search_term(
  profileId,
  adGroupId,
  campaignId,
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  pageSize,
  page,
  orderBy,
  filterBy
) {
  const query = {
    action: "get_search_term_data",
    profileId: Number(profileId),
    adgroup_id: Number(adGroupId),
    campaign_id: Number(campaignId),
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    pageSize,
    page,
    orderBy,
    filterBy,
  };
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  return result.data;
}

export async function get_ad_group_search_term_summary(
  profileId,
  adGroupId,
  campaignId,
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  filterBy
) {
  const query = {
    action: "get_search_term_data_summary",
    profileId: Number(profileId),
    adgroup_id: Number(adGroupId),
    campaign_id: Number(campaignId),
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    filterBy,
  };
  const cached = loadFromLocalSession(JSON.stringify(query));
  if (cached && cached.data) {
    return cached.data;
  }
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  saveToLocalSession(JSON.stringify(query), result);
  return result.data;
}
export async function get_ad_group_search_term_count(
  profileId,
  adGroupId,
  campaignId,
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  filterBy
) {
  const query = {
    action: "get_search_term_data",
    profileId: Number(profileId),
    adgroup_id: Number(adGroupId),
    campaign_id: Number(campaignId),
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    filterBy,
    getCount: true,
  };
  const cached = loadFromLocalSession(JSON.stringify(query));
  if (cached && cached.data) {
    return cached.data;
  }
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  saveToLocalSession(JSON.stringify(query), result);
  return result.data;
}
