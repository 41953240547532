/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Badge, Collapse } from "react-bootstrap";

const SidebarNavListItem = ({
  title,
  href,
  depth = 0,
  children,
  icon: Icon,
  badge,
  open,
  position,
  state = null,
  type,
}) => {
  const navigate = useNavigate();
  const [openState, setOpenState] = useState(open);
  const [show, setShow] = useState(false);

  const handleToggle = (href) => {
    setOpenState(!(openState && open));
    navigate(href);
  };

  if (children) {
    const isOpen = openState && open;

    if (type === "popup") {
      return (
        <li
          className={`sidebar-item-popup`}
          onMouseEnter={() => {
            setShow(true);
          }}
          onMouseLeave={() => {
            setShow(false);
          }}
        >
          <a
            className={`sidebar-link d-flex align-items-center${
              open ? " active" : ""
            }`}
            depth={depth}
            onClick={() => {
              navigate(href);
            }}
          >
            {Icon ? (
              <Icon
                className="feather align-middle"
                style={{ marginRight: 18 }}
              />
            ) : null}
            <span className="align-middle" depth={depth}>
              {title}
            </span>
          </a>
          <ul className={`sidebar-popup list-unstyled${show ? " show" : ""}`}>
            {children}
          </ul>
        </li>
      );
    }

    return (
      <li
        className={`sidebar-item${type ? `-${type}` : ""}${
          isOpen ? " active" : ""
        }${position === "middle" ? "" : ` sidebar-item-${position}`}${
          state ? ` sidebar-item-${state}` : ""
        }${state && depth === 0 ? " sidebar-item-campaign" : ""}`}
      >
        <a
          className={`sidebar-link${isOpen ? "" : " collapsed"}${
            position === "middle" ? "" : ` sidebar-link-${position}`
          }${state ? ` sidebar-link-${state}` : ""} d-flex align-items-center`}
          data-bs-toggle="collapse"
          aria-expanded={isOpen ? "true" : "false"}
          depth={depth}
          onClick={() => handleToggle(href)}
        >
          {Icon ? (
            <div className="icon-wrapper">
              <Icon
                className="feather align-middle"
                style={{ marginRight: 18 }}
              />
            </div>
          ) : null}
          <span className="align-middle" depth={depth}>
            {title}
          </span>
          {/* {badge && (
            <Badge className="badge-sidebar-primary" bg="" size={18}>
              {badge}
            </Badge>
          )} */}
        </a>
        <Collapse in={isOpen}>
          <ul className="sidebar-dropdown list-unstyled">{children}</ul>
        </Collapse>
      </li>
    );
  }

  return (
    <li
      className={`sidebar-item${
        depth > 0
          ? position === "middle"
            ? ""
            : ` sidebar-item-${position}`
          : ""
      }`}
    >
      <Link
        depth={depth}
        to={href}
        activeclassname="active"
        className={`sidebar-link${open ? " active" : ""}${
          depth > 0
            ? position === "middle"
              ? ""
              : ` sidebar-link-${position}`
            : ""
        } d-flex align-items-center`}
      >
        {Icon ? (
          <div className="icon-wrapper">
            <Icon
              className="feather align-middle"
              style={{ marginRight: 18 }}
            />
          </div>
        ) : null}
        <span className="align-middle" depth={depth}>
          {title}
        </span>
        {/* {badge && (
          <Badge className="badge-sidebar-primary" bg="" size={18}>
            {badge}
          </Badge>
        )} */}
      </Link>
    </li>
  );
};

export default SidebarNavListItem;
