import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { selection_box } from "./catalogTableColumns";
import { columns as columnDefs } from "./catalogTableColumns";
import { updateCatalogItems } from "../opasityApi/catalogManager";
import { list_catalog } from "pages/opasity/opasityApi/catalog";
import Filters from "components/Filters";
import FilterChips from "components/FilterChips";
import {
  agGridOptions,
  restoreSortState,
  restoreFilters,
  onColumnMoved as onColumnMovedCommon,
  onSortChanged as onSortChangedCommon,
  onFilterChanged as onFilterChangedCommon,
  onColumnVisible as onColumnVisibleCommon,
  onRowDataUpdated as onRowDataUpdatedCommon,
  collapseFilterGroups,
  toggleColumnsPanelShow,
  exportToCSV,
  agOrderToNorm,
  customizeToolPanel,
  restoreColumnsState,
  parseFilter,
  onDragStarted,
  onDragStopped,
} from "utils/agGridCommon";
import { ReactComponent as FilterIcon } from "assets/img/icons/light-filter.svg";
import { ReactComponent as FilterLeftArrowIcon } from "assets/img/icons/filter-left-arrow.svg";
import { ReactComponent as FilterRightArrowIcon } from "assets/img/icons/filter-right-arrow.svg";
import { saveToLocal, loadFromLocal } from "pages/opasity/opasityApi/utils";

import Pagination from "components/opasity/Pagination";
import FilterComponent from "components/opasity/FilterComponent";
import ExportButton from "components/opasity/ExportButton";
import ColumnsButton from "components/opasity/ColumnsButton";

import { Search, X } from "react-feather";

import useNavbar from "hooks/useNavbar";
import useFooter from "hooks/useFooter";
import { GroupedToggle2 } from "components/opasity/GroupedToggle";
import { useSearchParams } from "react-router-dom";
import { useMemo } from "react";

export const CatalogManager = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const onSelectionChanged = (event) => {
    setSelectedRows(event.api.getSelectedNodes());
    console.log("rows are", selectedRows);
  };
  const [renderGrid, setRenderGrid] = useState(false);
  const keySuffix = "";
  const columnStateKey = `last-column-state-${keySuffix}`;
  const columnVisibleKey = `last-column-visible-${keySuffix}`;
  const sortStateKey = `last-sort-state-${keySuffix}`;
  const lastFilterKey = `last-filter-${keySuffix}`;
  const infoKey = `last-info-${keySuffix}`;
  const lastInfo = loadFromLocal(infoKey);
  const { profileId } = useParams();
  const MEMO = useRef({});
  const gridRef = useRef();
  const [rawColumns, setRawColumns] = useState(columnDefs);
  const [search, setSearch] = useState("");
  const [grouped, setGrouped] = useState(true);
  const [parentInfo, setParentInfo] = useState({});
  const [pageSize, setPageSize] = useState(
    lastInfo?.pageSize ? lastInfo.pageSize : 50
  );
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [noPages, setNoPages] = useState(0);
  const [orderBy, setOrderBy] = useState([]);
  const [filterBy, setFilterBy] = useState(
    restoreFilters(lastFilterKey, {}) || {}
  );
  const [gridReady, setGridReady] = useState(false);
  const { setBreadcrumb } = useNavbar();
  const { setPagination } = useFooter();
  const [openFilters, setOpenFilters] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  // I added this function to refresh the selection whenever i change the grouped state :D
  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      const selectedNodes = api.getSelectedNodes();

      if (selectedNodes && selectedNodes.length > 0) {
        selectedNodes.forEach((node) => {
          node.setSelected(false);
        });
      }
    }
  }, [grouped]);

  const onGridReady = () => {
    restoreSortState(gridRef, sortStateKey, () => {});

    return;
    collapseFilterGroups(gridRef);
    setGridReady(true);
  };

  const onPageSizeChanged = (event) => {
    setPageSize(Number(event.target.value));
    setPage(1);
  };

  const onSortChanged = (event) => {
    onSortChangedCommon(event, sortStateKey, orderBy || [], () => {});
    setPage(1);
  };

  const onFilterChanged = (event) => {
    onFilterChangedCommon(event, lastFilterKey, filterBy, setFilterBy);
  };

  const onColumnMoved = (event) => {
    onColumnMovedCommon(event, parentInfo, columnDefs, columnStateKey);
  };

  const onColumnVisible = (event) => {
    onColumnVisibleCommon(event, columnVisibleKey);
  };

  useEffect(() => {
    restoreColumnsState(
      columnStateKey,
      columnVisibleKey,
      columnDefs,
      setParentInfo,
      setRawColumns
    );
  }, []);

  useEffect(() => {
    setSearch(searchParams.get("search"));
  }, [searchParams]);

  useEffect(() => {
    setBreadcrumb({
      page: "Catalog Manager",
      profileId,
    });
  }, [profileId]);

  useEffect(() => {
    setPagination({
      pageSize,
      onPageSizeChanged,
      setPage,
      noPages,
      page,
      count,
    });
  }, [pageSize, noPages, page, count]);

  useEffect(() => {
    let currentInfo = loadFromLocal(infoKey);
    if (!currentInfo) {
      currentInfo = {};
    }
    currentInfo.pageSize = pageSize;
    saveToLocal(infoKey, currentInfo);
  }, [pageSize]);

  useEffect(() => customizeToolPanel(gridRef), [gridRef]);
  const datasource = {
    getRows(params) {
      const queryParams = [
        profileId,
        pageSize,
        page,
        agOrderToNorm(params.request.sortModel),
        parseFilter(params.request.filterModel),
        search,
        grouped,
        params.request.groupKeys.length > 0 ? params.request.groupKeys[0] : "",
      ];
      const cache_key = JSON.stringify(queryParams);
      if (MEMO.current[cache_key]) {
        params.successCallback(
          MEMO.current[cache_key].map((row) => {
            return {
              ...row,
              profile_id: profileId,
              grouped,
            };
          })
        );
        return;
      } else {
        list_catalog(...queryParams)
          .then((res) => {
            console.log("RES IS", res.data);
            //setRawTableData(!res.data || res.data.length === 0 ? [{}] : res.data);
            MEMO.current = { ...MEMO.current, [cache_key]: res.data };
            if (params.request.groupKeys.length == 0) {
              setNoPages(res.pages);
              setCount(res.count);
            }

            params.successCallback(
              res.data.map((row) => {
                return {
                  ...row,
                  profile_id: profileId,
                  grouped,
                };
              })
            );
          })
          .catch((error) => {
            console.error(error);
            //gridRef.current.api.hideOverlay();
            params.failCallback();
          });
      }
    },
  };
  const groupedExclude = [];
  const groupFields = ["parent_asin"];
  function updateColDef(rawColumns, isChild = false) {
    const newCols = rawColumns.map((col) => {
      const newCol = { ...col };
      if (newCol.field === "asin" && !grouped) {
        newCol.checkboxSelection = true;
        
      }
      if (grouped) {
        if (groupedExclude.includes(newCol.field)) {
          newCol.hide = true;
        }
        if (groupFields.includes(newCol.field)) {
          newCol.hide = true;
          newCol.rowGroup = true;
        }
      } else {
        if (groupedExclude.includes(newCol.field)) {
          newCol.hide = false;
        }
        if (groupFields.includes(newCol.field)) {
          newCol.hide = false;
          newCol.rowGroup = false;
        }
      }
      if (newCol.children) {
        newCol.children = updateColDef([...newCol.children], true);
      }
      return newCol;
    });
    if (gridRef && gridRef.current && gridRef.current.api && !isChild) {
      gridRef.current.api.setColumnDefs(newCols);
    }
    return newCols;
  }

  const tableCols = useMemo(
    () => updateColDef(rawColumns),
    [rawColumns, grouped]
  );

  useEffect(() => {
    onFilterChangedCommon(lastFilterKey, filterBy);
  }, [filterBy]);

  const catalogGridOptions = {
    ...agGridOptions,
    autoGroupColumnDef: {
      pinned: "left",
      headerName: "Parent Asin",
      minWidth: 220,
      wrapText: true,

      cellRendererParams: {
        suppressCount: true,
        checkbox: true,
        innerRenderer: (p) => <h5 style={{ marginTop: "13px" }}>{p.value}</h5>,
      },
    },
  };

  const gridOnMemo = useMemo(() => {
    return (
      <AgGridReact
        ref={gridRef}
        onGridReady={onGridReady}
        columnDefs={tableCols}
        gridOptions={catalogGridOptions}
        onDragStarted={onDragStarted}
        onDragStopped={onDragStopped}
        onColumnMoved={onColumnMoved}
        onSortChanged={onSortChanged}
        //onFilterChanged={onFilterChanged}
        onColumnVisible={onColumnVisible}
        //onRowDataUpdated={onRowDataUpdated}
        onSelectionChanged={onSelectionChanged}
        rowSelection="multiple"
        rowModelType="serverSide"
        serverSideDatasource={datasource}
        groupDisplayType="multipleColumns"
        pinnedBottomRowData={null}
        pivotMode={false}
      />
    );
  }, [tableCols, page, pageSize, filterBy, renderGrid]); // Add renderGrid as dependency to rerender tjhe grid after bulk changing,

  function SelectedItems() {
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [goalTarget, setGoalTarget] = useState("");
    const [cost, setCost] = useState("");
    const [goalValue, setGoalValue] = useState("");

    function handleGoalValue(e) {
      setGoalValue(e.target.value);
    }
    function handleSubmitGoalValue() {
      console.log("submit", gridRef.current.api.getSelectedRows());
      const items = gridRef.current.api.getSelectedRows().map((item) => {
        if (!grouped) {
          return {
            profile_id: Number(item.profile_id),
            asin: item.asin,
            sku: item.sku,
            data: { goal_value: parseFloat(goalValue) },
          };
        } else {
          return {
            profile_id: Number(item.profile_id),
            parent_asin: item.parent_asin,
            sku: item.sku,
            data: { goal_value: parseFloat(goalValue) },
          };
        }
      });
      setSaving(true);
      updateCatalogItems(items[0].profile_id, items).then((res) => {
        console.log("got res: ", res);
        setSaving(false);
        setEditing(!editing);
        setRenderGrid(!renderGrid);
      });
    }

    function handleCost(e) {
      setCost(e.target.value);
    }
    function handleSubmitCost() {
      const items = gridRef.current.api.getSelectedRows().map((item) => {
        if (!grouped) {
          return {
            profile_id: Number(item.profile_id),
            asin: item.asin,
            sku: item.sku,
            data: { cost: parseFloat(cost) },
          };
        } else {
          return {
            profile_id: Number(item.profile_id),
            parent_asin: item.parent_asin,
            sku: item.sku,
            data: { cost: parseFloat(cost) },
          };
        }
      });
      setSaving(true);
      updateCatalogItems(items[0].profile_id, items).then((res) => {
        console.log("got res: ", res);
        setSaving(false);
        setEditing(!editing);
        setRenderGrid(!renderGrid);
      });
    }

    function handleGoalTarget(e) {
      setGoalTarget(e.target.value);
    }
    function handleSubmitGoalTarget() {
      const items = gridRef.current.api.getSelectedRows().map((item) => {
        if (!grouped) {
          return {
            profile_id: Number(item.profile_id),
            asin: item.asin,
            sku: item.sku,
            data: { goal_target: goalTarget },
          };
        } else {
          return {
            profile_id: Number(item.profile_id),
            parent_asin: item.parent_asin,
            sku: item.sku,
            data: { goal_target: goalTarget },
          };
        }
      });
      console.log("items dsds", items);
      setSaving(true);
      updateCatalogItems(items[0].profile_id, items).then((res) => {
        console.log("got res: ", res);
        setSaving(false);
        setEditing(!editing);
        setRenderGrid(!renderGrid);
      });
    }

    if (
      gridRef &&
      gridRef.current &&
      gridRef.current.api &&
      !editing &&
      gridRef.current.api.getSelectedRows().length > 0
    ) {
      return (
        <div className="mb-2">
          <Button
            style={{ marginRight: "6px" }}
            variant="btn btn-outline-success"
            title="edit"
            onClick={() => setEditing("cost")}
          >
            Change cost for {gridRef.current.api.getSelectedRows().length}{" "}
            product(s)
          </Button>
          <Button
            style={{ marginRight: "6px" }}
            variant="btn btn-outline-warning"
            title="edit"
            onClick={() => setEditing("goal-target")}
          >
            Change goal target for{" "}
            {gridRef.current.api.getSelectedRows().length} product(s)
          </Button>
          <Button
            style={{ marginRight: "6px" }}
            variant="btn btn-outline-danger"
            title="edit"
            onClick={() => setEditing("goal-value")}
          >
            Change goal value for {gridRef.current.api.getSelectedRows().length}{" "}
            product(s)
          </Button>
        </div>
      );
    }
    if (editing === "cost") {
      return (
        <div className="row mb-1">
          <div className="col-2">
            <div className="row">
              <div className="col-6">
                <Form.Label className="mr-0 mt-1">Set a cost :</Form.Label>
              </div>
              <div className="col-6">
                <Form.Control
                  value={cost}
                  inputMode="numeric"
                  type="number"
                  style={{ border: 0 }}
                  onChange={(e) => {
                    setCost(e.target.value);
                  }}
                  min="0"
                />
              </div>
            </div>
          </div>
          <div className="col-2">
            <div className="row">
              <div className="col-3">
                <Button
                  variant="btn btn-outline-primary"
                  onClick={handleSubmitCost}
                  title="save"
                  disabled={saving}
                >
                  Save
                </Button>
              </div>
              <div className="col-3 ">
                <Button
                  variant="btn btn-outline-warning"
                  title="cancel"
                  onClick={() => setEditing(false)}
                  disabled={saving}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (editing === "goal-target") {
      return (
        <div className="row mb-1">
          <div className="col-3">
            <div className="row">
              <div className="col-6 mt-1">
                <Form.Label>Select a goal target :</Form.Label>
              </div>
              <div className="col-6">
                {" "}
                <Form.Select
                  value={goalTarget}
                  id="goalTargetSelect"
                  name="goalTargetSelect"
                  onChange={handleGoalTarget}
                >
                  <option value={null}></option>
                  <option value="clicks">Clicks</option>
                  <option value="cost">Ad Cost</option>
                  <option value="orders">Orders</option>
                  <option value="sales">Sales</option>
                  <option value="acos">ACOS</option>
                  <option value="tacos">TACOS</option>
                </Form.Select>
              </div>
            </div>
          </div>

          <div className="col-2">
            <div className="row">
              <div className="col-3">
                <Button
                  variant="btn btn-outline-primary"
                  onClick={handleSubmitGoalTarget}
                  title="save"
                  disabled={saving}
                >
                  Save
                </Button>
              </div>
              <div className="col-3 ">
                <Button
                  variant="btn btn-outline-warning"
                  title="cancel"
                  onClick={() => setEditing(false)}
                  disabled={saving}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (editing === "goal-value") {
      return (
        <div className="row g-2 mb-1">
          <div className="col-3">
            <div className="row">
              <div className="col-6 mt-1">
                <Form.Label>Set a goal value :</Form.Label>
              </div>
              <div className="col-6">
                <Form.Control
                  value={goalValue}
                  inputMode="numeric"
                  type="number"
                  style={{ border: 0, marginLeft: 0 }}
                  onChange={(e) => {
                    setGoalValue(e.target.value);
                    console.log(e.target.value);
                  }}
                  min="0"
                />
              </div>
            </div>
          </div>
          <div className="col-2">
            <div className="row">
              <div className="col-3">
                <Button
                  variant="btn btn-outline-primary"
                  onClick={handleSubmitGoalValue}
                  title="save"
                  disabled={saving}
                >
                  Save
                </Button>
              </div>
              <div className="col-3 ">
                <Button
                  variant="btn btn-outline-warning"
                  title="cancel"
                  onClick={() => setEditing(false)}
                  disabled={saving}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <>
      <Helmet title="Catalog Manager" />
      <Container fluid className="p-0" style={{ height: "100%" }}>
        <Row style={{ height: "100%" }}>
          <Col style={{ display: "flex", flexDirection: "column" }}>
            <div
              className="d-flex align-items-end"
              style={{
                marginTop: 18,
                marginLeft: 16,
                marginBottom: 12,
                paddingRight: 0,
              }}
            >
              <InputGroup className="input-group-catalog">
                <Form.Control
                  placeholder="Search..."
                  aria-label="Search"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  className="search-field-catalog"
                />
                {search ? (
                  <Button
                    onClick={() => {
                      setSearch("");
                    }}
                    className="search-field-adornment-catalog"
                  >
                    <X className="feather search-field-icon-catalog" />
                  </Button>
                ) : (
                  <Button className="search-field-adornment-catalog">
                    <Search className="feather search-field-icon-catalog" />
                  </Button>
                )}
              </InputGroup>
            </div>
            <div
              style={{
                background: "white",
                padding: 0,
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Row className="d-flex justify-content-between mb-1">
                <Col
                  className="d-flex align-items-end"
                  style={{ marginLeft: 0 }}
                >
                  {/*  <FilterComponent
                    gridRef={gridRef}
                    filterBy={filterBy}
                    defaultFilter={{}}
                    page="catalogManager"
                  /> */}
                  <button
                    className="filters-button"
                    onClick={() => {
                      setOpenFilters(!openFilters);
                    }}
                  >
                    {openFilters ? (
                      <span style={{ marginRight: 6 }}>Filters</span>
                    ) : null}
                    <FilterIcon style={{ marginRight: 6 }} />
                    {openFilters ? (
                      <FilterLeftArrowIcon />
                    ) : (
                      <FilterRightArrowIcon />
                    )}
                  </button>

                  <FilterChips filterBy={filterBy} setFilterBy={setFilterBy} />
                  <div style={{ flexGrow: 1 }} />
                  <ExportButton
                    onClick={() => {
                      exportToCSV(gridRef, "catalog.csv");
                    }}
                  />
                  <ColumnsButton
                    onClick={() => {
                      toggleColumnsPanelShow(gridRef);
                    }}
                  />
                  <GroupedToggle2 grouped={grouped} setGrouped={setGrouped} />
                </Col>
              </Row>
              <SelectedItems />
              <Row style={{ flexGrow: 1 }}>
                <Filters
                  columnsArray={rawColumns}
                  openFilters={openFilters}
                  setOpenFilters={setOpenFilters}
                  filterBy={filterBy}
                  setFilterBy={setFilterBy}
                />
                <Col>
                  <div className="ag-theme-alpine grid-wrapper">
                    {gridOnMemo}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
