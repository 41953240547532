import React from "react";
import { Outlet } from "react-router-dom";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/sidebar/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/navbar/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";
import Settings from "../components/Settings";

import getDashboardItems from "../components/sidebar/dashboardItems";
import AuthGuard from "../components/guards/AuthGuard";
import { useParams } from "react-router-dom";
import { ReactComponent as autoSVG } from "../assets/img/auto.svg";
import { ReactComponent as manualSVG } from "../assets/img/manual.svg";
import { ReactComponent as brandSVG } from "../assets/img/brand.svg";
import { ReactComponent as videoSVG } from "../assets/img/video.svg";
import { ReactComponent as displaySVG } from "../assets/img/display.svg";

import {
  AlignJustify,
  MousePointer,
  Video,
  Monitor,
  Briefcase,
  Search,
  Eye,
} from "react-feather";

import { SIDEBAR_NAV_SEPARATOR } from "../constants";

const Dashboard = ({ children, campaignsType }) => {
  const { profileId } = useParams();
  let dashboardItems = getDashboardItems([]);
  if (profileId) {
    const pages = [
      SIDEBAR_NAV_SEPARATOR,
        {
        href: `/profiles/${profileId}/campaigns`,
        icon: AlignJustify,
        title: "All Campaigns",
        type: "popup",
        children: [
          {
            title: "All Campaigns",
            href: `/profiles/${profileId}/campaigns`,
            type: "popup",
            children: [
              {
                title: "Campaigns",
                href: `/profiles/${profileId}/campaigns`,
              },
              {
                title: "Campaigns & Products",
                href: `/profiles/${profileId}/advertisedproducts`,
              },
            ],
          },
          { title: "All AdGroups", href: `/profiles/${profileId}/adgroups` },
          {
            title: "All Targeting",
            href: `/profiles/${profileId}/targeting`,
            type: "popup",
            children: [
              {
                title: "All Keywords",
                href: `/profiles/${profileId}/targeting`,
              },
              {
                title: "Products & Categories",
                href: `/profiles/${profileId}/targetingautopc`,
              },
              {
                title: "All Auto Targeting",
                href: `/profiles/${profileId}/targetingauto`,
              },
            ],
          },
          {
            title: "All Search Terms",
            href: `/profiles/${profileId}/searchterm`,
          },
          {
            title: "All Negatives",
            href: `/profiles/${profileId}/negativetargeting`,
          },
          {
            title: "Products Overview",
            href: `/profiles/${profileId}/advertisedproductsoverview`,
          },
        ],
      },
      {
        href: `/profiles/${profileId}/campaigns-auto`,
        icon: autoSVG,
        title: "Auto",
      },
      {
        href: `/profiles/${profileId}/campaigns-manual`,
        icon: manualSVG,
        title: "Manual",
      },
      {
        href: `/profiles/${profileId}/campaigns-brand`,
        icon: brandSVG,
        title: "Brand",
      },
      {
        href: `/profiles/${profileId}/campaigns-video`,
        icon: videoSVG,
        title: "Video",
      },
      {
        href: `/profiles/${profileId}/campaigns-display`,
        icon: displaySVG,
        title: "Display",
      },
    ];
    dashboardItems = getDashboardItems(pages);
  }
  return (
    <AuthGuard>
      <React.Fragment>
        <Wrapper>
          <Sidebar items={dashboardItems} campaignsType={campaignsType} />
          <Main>
            <Navbar />
            <Content style={{ padding: 0, overflow: "hidden" }}>
              {children}
              <Outlet />
            </Content>
            <Footer />
          </Main>
        </Wrapper>
      </React.Fragment>
    </AuthGuard>
  );
};

export default Dashboard;
