import { useEffect, useState, useRef } from "react";
import { useParams, useSearchParams, NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Modal,
  Nav,
  Tab,
  Table,
} from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import Filters from "components/Filters";
import FilterChips from "components/FilterChips";
import {
  common_fields,
  target_field,
  target_state_field,
  keyword_bid_field,
  campaign_name_field,
  ad_group_name_field,
  selection_box,
} from "pages/opasity/opasityApi/ag_grid_fields";

import {
  get_auto_targeting,
  get_auto_targeting_count,
  get_auto_targeting_summary,
} from "pages/opasity/opasityApi/adGroupKeywords";

import {
  updateAutoTarget,
  deleteKeyword,
  createKeywords,
} from "pages/opasity/opasityApi/updateKeyword";

import {
  getCachedCampaign,
  getAdgroup,
  getCampaign,
} from "pages/opasity/opasityApi/singleItem";
import { ReactComponent as FilterIcon } from "assets/img/icons/light-filter.svg";
import { ReactComponent as FilterLeftArrowIcon } from "assets/img/icons/filter-left-arrow.svg";
import { ReactComponent as FilterRightArrowIcon } from "assets/img/icons/filter-right-arrow.svg";
import {
  pickerToNorm,
  loadDate,
  saveToLocal,
  loadFromLocal,
} from "pages/opasity/opasityApi/utils";

import {
  agGridOptions,
  restoreColumnsState,
  restoreSortState,
  restoreFilters,
  onDragStarted,
  onDragStopped,
  onColumnMoved as onColumnMovedCommon,
  onSortChanged as onSortChangedCommon,
  onFilterChanged as onFilterChangedCommon,
  onColumnVisible as onColumnVisibleCommon,
  onRowDataUpdated as onRowDataUpdatedCommon,
  collapseFilterGroups,
  toggleColumnsPanelShow,
  exportToCSV,
  getTotals,
  DEFAULT_FILTER,
  AddKeywordGroupHeader,
  customizeToolPanel,
  filterObjToArray,
} from "utils/agGridCommon";

import DateModal from "components/opasity/DateModal";
import Pagination from "components/opasity/Pagination";
import FilterComponent from "components/opasity/FilterComponent";
import ExportButton from "components/opasity/ExportButton";
import ColumnsButton from "components/opasity/ColumnsButton";
import TabNav from "components/opasity/TabNav";
import TargetingNav from "components/opasity/TargetingNav";

import useNavbar from "hooks/useNavbar";
import useFooter from "hooks/useFooter";
import { useSearchDebounce } from "../utils";

const AdgroupTargetingAutoAll = () => {
  const { profileId, campaignId, adGroupId } = useParams();
  const [openFilters, setOpenFilters] = useState(false);
  const gridRef = useRef();
  const [gridReady, setGridReady] = useState(false);

  const keySuffix = `autotargeting${adGroupId ? "-adgroup" : ""}${
    campaignId ? "-campaign" : ""
  }`;
  const columnStateKey = `last-column-state-${keySuffix}`;
  const columnVisibleKey = `last-column-visible-${keySuffix}`;
  const sortStateKey = `last-sort-state-${keySuffix}`;
  const lastFilterKey = `last-filter-${keySuffix}`;
  const infoKey = `last-info-${keySuffix}`;

  const lastInfo = loadFromLocal(infoKey);

  const showAddKeyword = useRef(false);
  const onAddKeyword = useRef(null);

  const columnDefs = [
    {
      headerName: "Target",
      suppressColumnsToolPanel: true,
      children: [selection_box, target_state_field, target_field],
      headerGroupComponent: AddKeywordGroupHeader,
      headerGroupComponentParams: {
        showAddKeyword,
        onAddKeyword,
      },
    },
    {
      headerName: "Target Info",
      children: [
        keyword_bid_field,
        ...(!adGroupId ? [ad_group_name_field] : []),
        ...(!campaignId ? [campaign_name_field] : []),
      ],
    },
    ...common_fields,
  ];

  selection_box.pinned = "left";
  target_state_field.pinned = "left";
  target_field.pinned = "left";
  target_field.width = 180;
  ad_group_name_field.pinned = null;
  campaign_name_field.pinned = null;

  const [rawColumns, setRawColumns] = useState([]);
  const [parentInfo, setParentInfo] = useState([]);

  const [rawTableData, setRawTableData] = useState([{}]);
  const [summaryData, setSummaryData] = useState([]);

  const [dateState, setDateState] = useState(loadDate());

  const [selectedKeywords, setSelectedKeywords] = useState([]);

  const [pageSize, setPageSize] = useState(
    lastInfo?.pageSize ? lastInfo.pageSize : 50
  );
  const [page, setPage] = useState(1);
  const [noPages, setNoPages] = useState(0);
  const [count, setCount] = useState(0);

  const defaultFilter = campaignId ? {} : DEFAULT_FILTER;

  const [orderBy, setOrderBy] = useState([]);
  const [filterBy, setFilterBy] = useState(
    restoreFilters(lastFilterKey, defaultFilter) || {}
  );
  const [debFilter, setDebFilter] = useSearchDebounce(300);
  const { setBreadcrumb } = useNavbar();
  const { setPagination } = useFooter();

  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState("");

  const updateTable = () => {
    if (!gridRef.current.api || !gridReady) {
      return;
    }

    const { selection, compare } = dateState;

    const selStartDate = pickerToNorm(selection.startDate);
    const selEndDate = pickerToNorm(selection.endDate);
    const comStartDate = pickerToNorm(compare.startDate);
    const conEndDate = pickerToNorm(compare.endDate);

    gridRef.current.api.showLoadingOverlay();

    get_auto_targeting(
      profileId,
      Number(adGroupId),
      Number(campaignId),
      "TARGETING_EXPRESSION",
      selStartDate,
      selEndDate,
      comStartDate,
      conEndDate,
      pageSize,
      page,
      orderBy,
      filterObjToArray(filterBy),
      search
    )
      .then((res) => {
        setRawTableData(!res.data || res.data.length === 0 ? [{}] : res.data);

        get_auto_targeting_summary(
          profileId,
          Number(adGroupId),
          Number(campaignId),
          "TARGETING_EXPRESSION",
          selStartDate,
          selEndDate,
          comStartDate,
          conEndDate,
          filterObjToArray(filterBy),
          search
        ).then((res) => {
          setSummaryData(getTotals(res.data));
        });

        get_auto_targeting_count(
          profileId,
          Number(adGroupId),
          Number(campaignId),
          "TARGETING_EXPRESSION",
          selStartDate,
          selEndDate,
          comStartDate,
          conEndDate,
          pageSize,
          filterObjToArray(filterBy),
          search
        ).then((res) => {
          setNoPages(res.pages);
          setCount(res.count);
        });
      })
      .catch(() => {
        gridRef.current.api.hideOverlay();
      });
  };

  const onGridReady = () => {
    restoreSortState(gridRef, sortStateKey, setOrderBy);

    collapseFilterGroups(gridRef);

    setGridReady(true);
  };

  const onPageSizeChanged = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onSortChanged = (event) => {
    onSortChangedCommon(event, sortStateKey, orderBy, setOrderBy);
  };

  const onFilterChanged = (event) => {
    onFilterChangedCommon(event, lastFilterKey, filterBy, setFilterBy);
  };

  const onColumnMoved = (event) => {
    onColumnMovedCommon(event, parentInfo, columnDefs, columnStateKey);
  };

  const onColumnVisible = (event) => {
    onColumnVisibleCommon(event, columnVisibleKey);
  };

  const onRowDataUpdated = () => {
    onRowDataUpdatedCommon(gridRef, rawTableData);
  };

  const onSelectionChanged = (event) => {
    setSelectedKeywords(event.api.getSelectedNodes());
  };

  const isRowSelectable = (row) => {
    return row.data.targetState && row.data.targetState !== "archived";
  };

  const SelectedKeywords = ({ showAddKeyword, onAddKeyword }) => {
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [bidAdjustmentType, setBidAdjustmentType] = useState("setAmount");
    const [bidAdjustment, setBidAdjustment] = useState();
    const [error, setError] = useState("");
    function handleBidTypeChange(event) {
      setBidAdjustmentType(event.target.value);
    }
    async function handleBidChange(event) {
      const newBidAdjustment = parseFloat(event.target.value);
      setBidAdjustment(newBidAdjustment);
    }
    function getNewBid(oldBid) {
      if (bidAdjustmentType === "setAmount") {
        return bidAdjustment;
      }
      if (bidAdjustmentType === "increaseAmount") {
        return oldBid + bidAdjustment;
      }
      if (bidAdjustmentType === "increasePercent") {
        return oldBid + (oldBid * bidAdjustment) / 100;
      }
      if (bidAdjustmentType === "decreasePercent") {
        return oldBid - (oldBid * bidAdjustment) / 100;
      }
      if (bidAdjustmentType === "decreaseAmount") {
        return oldBid - bidAdjustment;
      }
    }
    function checkNewBid(newBid) {
      if (isNaN(newBid)) {
        setError("Value must be a number!");
        return {
          valid: false,
          error: "Value must be a number!",
        };
      }
      if (newBid < 0.02) {
        setError("Bid must be >= 0.02");
        return {
          valid: false,
          error: "Bid must be >= 0.02!",
        };
      }
      return {
        valid: true,
      };
    }
    function saveBid() {
      setSaving(true);
      const newBidAdjustment = bidAdjustment;
      setError("");
      const keywords = [];
      const nodes = gridRef.current.api.getSelectedNodes();
      for (let node_index in nodes) {
        let newBid = getNewBid(nodes[node_index].data.bid);
        const isValid = checkNewBid(newBid);
        if (!isValid.valid) {
          setError(
            isValid.error + " on keyword: " + nodes[node_index].data.keyword
          );
          return;
        }
        keywords.push({
          targetId: nodes[node_index].data.target_id,
          bid: newBid,
          oldBid: nodes[node_index].data.bid,
        });
      }
      updateAutoTarget(Number(profileId), keywords).then((res) => {
        setSaving(false);
        for (let i in res.success) {
          for (let j in nodes) {
            if (Number(res.success[i].targetId) == nodes[j].data.target_id) {
              nodes[j].updateData({
                ...nodes[j].data,
                bid: res.success[i].bid,
              });
              gridRef.current.api.flashCells({
                rowNodes: [nodes[i]],
                columns: ["bid"],
              });
            }
          }
        }
      });
    }
    function enableAutoTargets() {
      setSaving(true);
      const keywords = [];
      const nodes = gridRef.current.api.getSelectedNodes();
      for (let node_index in nodes) {
        keywords.push({
          targetId: nodes[node_index].data.target_id,
          state: "ENABLED",
        });
      }
      updateAutoTarget(Number(profileId), keywords).then((res) => {
        setSaving(false);
        for (let i in res.success) {
          for (let j in nodes) {
            if (Number(res.success[i].targetId) == nodes[j].data.target_id) {
              nodes[j].updateData({
                ...nodes[j].data,
                targetState: res.success[i].state,
              });
              gridRef.current.api.flashCells({
                rowNodes: [nodes[i]],
                columns: ["targetState"],
              });
            }
          }
        }
      });
    }
    function pauseAutoTarget() {
      setSaving(true);
      const keywords = [];
      const nodes = gridRef.current.api.getSelectedNodes();
      for (let node_index in nodes) {
        keywords.push({
          targetId: nodes[node_index].data.target_id,
          state: "PAUSED",
        });
      }
      updateAutoTarget(Number(profileId), keywords).then((res) => {
        setSaving(false);
        for (let i in res.success) {
          for (let j in nodes) {
            if (Number(res.success[i].targetId) == nodes[j].data.target_id) {
              nodes[j].updateData({
                ...nodes[j].data,
                targetState: res.success[i].state,
              });
              gridRef.current.api.flashCells({
                rowNodes: [nodes[i]],
                columns: ["targetState"],
              });
            }
          }
        }
      });
    }
    const bidAdjustmentTypeDetails = {
      setAmount: "$",
      increasePercent: "%+",
      increaseAmount: "$+",
      decreasePercent: "%-",
      decreaseAmount: "$-",
    };
    function addNewKeywords() {
      window.location.href = `/profiles/${profileId}/campaigns/${campaignId}/adgroups/${adGroupId}/addKeywords`;
    }

    useEffect(() => {
      showAddKeyword.current =
        selectedKeywords.length === 0 &&
        campaignId &&
        getCachedCampaign(campaignId) &&
        getCachedCampaign(campaignId).target_type === "manual";
    }, []);

    if (selectedKeywords.length === 0) {
      return campaignId &&
        getCachedCampaign(campaignId) &&
        getCachedCampaign(campaignId).target_type === "manual" ? (
        <AddKeywordsModal
          campaignId={campaignId}
          adGroupId={adGroupId}
          profileId={profileId}
          updateTable={updateTable}
          onAddKeyword={onAddKeyword}
        />
      ) : null;
    }

    if (editing) {
      return (
        <div className="d-flex align-items-start mb-1">
          <Form.Select
            id="bidAdjustmentSelect"
            name="bidAdjustmentSelect"
            onChange={handleBidTypeChange}
            style={{ marginRight: 12, maxWidth: 180 }}
          >
            <option value="setAmount">set bid to ($)</option>
            <option value="increasePercent">Increase bid by (%)</option>
            <option value="increaseAmount">Increase bid by ($)</option>
            <option value="decreasePercent">Decrease bid by (%)</option>
            <option value="decreaseAmount">Decrease bid by ($)</option>
          </Form.Select>
          <div style={{ marginRight: 36 }}>
            <InputGroup style={{ maxWidth: 150 }}>
              <InputGroup.Text>
                {bidAdjustmentTypeDetails[bidAdjustmentType]}
              </InputGroup.Text>
              <Form.Control onChange={handleBidChange} />
            </InputGroup>
            <span className="text-danger">{error}</span>
          </div>
          <Button
            variant="btn btn-outline-primary"
            title="save"
            onClick={() => saveBid()}
            disabled={saving}
            style={{ marginRight: 12 }}
          >
            Save
          </Button>
          <Button
            variant="btn btn-outline-warning"
            title="cancel"
            onClick={() => setEditing(false)}
            disabled={saving}
          >
            Cancel
          </Button>
        </div>
      );
    }
    if (selectedKeywords.length > 0) {
      return (
        <div className="mb-1">
          <Button
            style={{ marginRight: "6px" }}
            variant="btn btn-outline-primary"
            title="edit"
            onClick={() => setEditing(true)}
          >
            Adjust Bid ({selectedKeywords.length})
          </Button>
          <Button
            style={{ marginRight: "6px" }}
            variant="btn btn-outline-warning"
            title="edit"
            onClick={() => pauseAutoTarget()}
            disabled={saving}
          >
            Pause ({selectedKeywords.length})
          </Button>
          <Button
            style={{ marginRight: "6px" }}
            variant="btn btn-outline-success"
            title="edit"
            onClick={() => enableAutoTargets()}
            disabled={saving}
          >
            Enable ({selectedKeywords.length})
          </Button>
        </div>
      );
    }
  };

  useEffect(() => {
    updateTable();
  }, [gridReady, search, dateState, page, pageSize, orderBy, debFilter]);
  useEffect(() => {
    setDebFilter(filterBy);
  }, [filterBy]);
  useEffect(() => {
    restoreColumnsState(
      columnStateKey,
      columnVisibleKey,
      columnDefs,
      setParentInfo,
      setRawColumns
    );
  }, []);

  useEffect(() => {
    setSearch(searchParams.get("search"));
  }, [searchParams]);

  useEffect(() => {
    setBreadcrumb({ page: "Auto Targeting", profileId, campaignId, adGroupId });
  }, [profileId, campaignId, adGroupId]);

  useEffect(() => {
    setPagination({
      pageSize,
      onPageSizeChanged,
      setPage,
      noPages,
      page,
      count,
    });
  }, [pageSize, noPages, page, count]);

  useEffect(() => {
    let currentInfo = loadFromLocal(infoKey);

    if (!currentInfo) {
      currentInfo = {};
    }

    currentInfo.pageSize = pageSize;
    saveToLocal(infoKey, currentInfo);
  }, [pageSize]);

  useEffect(() => customizeToolPanel(gridRef), [gridRef]);
  useEffect(() => {
    onFilterChangedCommon(lastFilterKey, filterBy);
  }, [filterBy]);
  const [showTargetingNav, setShowTargetingNav] = useState(false);
  return (
    <>
      <Helmet title="Targeting" />
      <Container fluid className="p-0" style={{ height: "100%" }}>
        {/* <Row>
          <Col md="6">
            <BreadCrumb
              campaignId={campaignId}
              adGroupId={adGroupId}
              page="targeting"
              profileId={profileId}
            ></BreadCrumb>
          </Col>
          <Col className="text-end">
            {campaignId && !adGroupId && (
              <Button
                onClick={() =>
                  (window.location.href = `/profiles/${profileId}/campaigns/${campaignId}/settings`)
                }
              >
                Campaign Settings
              </Button>
            )}
            {adGroupId && (
              <Button
                onClick={() =>
                  (window.location.href = `/profiles/${profileId}/campaigns/${campaignId}/adgroups/${adGroupId}/settings`)
                }
              >
                AdGroup Settings
              </Button>
            )}
          </Col>
        </Row> */}
        <Row style={{ height: "100%" }}>
          <Col>
            <div
              className="tab"
              style={{
                height: "100%",
                marginBottom: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div
                  className="d-flex align-items-end"
                  style={{
                    marginTop: 18,
                    marginRight: 16,
                    paddingRight: 0,
                  }}
                >
                  <TabNav
                    profileId={profileId}
                    campaignId={campaignId}
                    adGroupId={adGroupId}
                    page="targeting"
                    setShowNav={setShowTargetingNav}
                    showNav={showTargetingNav}
                  />
                  <div style={{ flexGrow: 1 }} />
                  <div style={{ marginBottom: "0.5em" }}>
                    <DateModal
                      gridRef={gridRef}
                      dateState={dateState}
                      setDateState={setDateState}
                    />
                  </div>
                </div>
                <Tab.Content style={{ padding: 0, flexGrow: 1 }}>
                  <Tab.Pane
                    eventKey="first"
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        background: "white",
                        padding: 0,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="first"
                      >
                        <Tab.Content
                          style={{ padding: 0, boxShadow: "none", flexGrow: 1 }}
                        >
                          <Tab.Pane
                            eventKey="first"
                            style={{
                              height: "100%",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Row className="d-flex justify-content-end mb-1">
                              <Col
                                className="d-flex align-items-end"
                                style={{ marginLeft: 0 }}
                              >
                                {showTargetingNav ? (
                                  <TargetingNav
                                    profileId={profileId}
                                    campaignId={campaignId}
                                    adGroupId={adGroupId}
                                    type="targetingautopc"
                                    setShowNav={setShowTargetingNav}
                                    showNav={showTargetingNav}
                                  />
                                ) : selectedKeywords.length === 0 ? (
                                  <>
                                    <button
                                      className="filters-button"
                                      onClick={() => {
                                        setOpenFilters(!openFilters);
                                      }}
                                    >
                                      {openFilters ? (
                                        <span style={{ marginRight: 6 }}>
                                          Filters
                                        </span>
                                      ) : null}
                                      <FilterIcon style={{ marginRight: 6 }} />
                                      {openFilters ? (
                                        <FilterLeftArrowIcon />
                                      ) : (
                                        <FilterRightArrowIcon />
                                      )}
                                    </button>

                                    <FilterChips
                                      filterBy={filterBy}
                                      setFilterBy={setFilterBy}
                                    />
                                  </>
                                ) : (
                                  <SelectedKeywords
                                    showAddKeyword={showAddKeyword}
                                    onAddKeyword={onAddKeyword}
                                  />
                                )}
                                <div style={{ flexGrow: 1 }} />
                                <ExportButton
                                  onClick={() => {
                                    exportToCSV(gridRef, "targeting.csv");
                                  }}
                                />
                                <ColumnsButton
                                  onClick={() => {
                                    toggleColumnsPanelShow(gridRef);
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row style={{ flexGrow: 1 }}>
                              <Filters
                                columnsArray={rawColumns}
                                openFilters={openFilters}
                                setOpenFilters={setOpenFilters}
                                filterBy={filterBy}
                                setFilterBy={setFilterBy}
                              />
                              <Col>
                                <div className="ag-theme-alpine grid-wrapper">
                                  <AgGridReact
                                    ref={gridRef}
                                    onGridReady={onGridReady}
                                    columnDefs={rawColumns}
                                    rowData={rawTableData}
                                    gridOptions={agGridOptions}
                                    pinnedBottomRowData={summaryData}
                                    rowSelection="multiple"
                                    isRowSelectable={isRowSelectable}
                                    onSelectionChanged={onSelectionChanged}
                                    onDragStarted={onDragStarted}
                                    onDragStopped={onDragStopped}
                                    onColumnMoved={onColumnMoved}
                                    onSortChanged={onSortChanged}
                                    //onFilterChanged={onFilterChanged}
                                    onColumnVisible={onColumnVisible}
                                    onRowDataUpdated={onRowDataUpdated}
                                    rowModelType={"clientSide"}
                                    pivotMode={false}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdgroupTargetingAutoAll;

const AddKeywordsModal = ({
  profileId,
  campaignId,
  adGroupId,
  updateTable,
  onAddKeyword,
}) => {
  const colors = [
    {
      name: "Primary",
      value: "primary",
    },
  ];

  const [saving, setSaving] = useState(false);
  const [broad, setBroad] = useState(true);
  const [phrase, setPhrase] = useState(true);
  const [exact, setExact] = useState(true);
  const [bidType, setBidType] = useState("default");
  const [bid, setBid] = useState(0.66);
  const [keywords, setKeywords] = useState([]);
  const [error, setError] = useState({});
  const [keywordsText, setKeywordsText] = useState("");
  const [maxBid, setMaxBid] = useState(99);
  const initOpenModals = () => {
    let modals = {};

    colors.forEach((color, index) => {
      modals = Object.assign({}, modals, { [index]: false });
    });

    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());
  const toggle = (index) => {
    // Toggle selected element
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };

  useEffect(() => {
    onAddKeyword.current = () => {
      toggle(0);
    };
  }, []);

  useEffect(() => {
    adGroupId &&
      getAdgroup(profileId, adGroupId, true).then((res) => {
        setBid(res.defaultBid);
      });
  }, [profileId, adGroupId]);
  useEffect(() => {
    campaignId &&
      getCampaign(profileId, campaignId, true).then((res) => {
        setMaxBid(res.budget);
      });
  }, [profileId, campaignId]);
  function setKeywordBid(e) {
    const newBid = parseFloat(e.target.value);
    if (newBid > 0) {
      deleteError("bid");
      setBid(newBid);
    } else {
      createError("bid", "Some error with default bid...");
    }
  }
  function deleteError(key) {
    const newError = { ...error };
    if (Object.keys(newError).filter((e) => e == key).length > 0) {
      delete newError[key];
      setError(newError);
    }
  }
  function createError(key, val) {
    const newError = {
      ...error,
    };
    newError[key] = val;
    setError(newError);
  }
  function keywordAlreadyExists(keyword, match_type) {
    return (
      keywords.filter(
        (_keyword) =>
          _keyword.keyword == keyword && _keyword.match_type == match_type
      ).length > 0
    );
  }
  function addKeywords() {
    const splitted = keywordsText.split("\n");
    const keywordsSet = new Set(splitted);
    const kk = Array.from(keywordsSet);
    const newKeywords = [];
    deleteError("alpha_num");
    kk.map((keyword) => {
      if (!keyword.match(/^[0-9a-z ]+$/)) {
        createError(
          "alpha_num",
          "Keyword should consist of letters and number only"
        );
      } else {
        if (broad) {
          if (!keywordAlreadyExists(keyword, "BROAD")) {
            newKeywords.push({
              keyword,
              match_type: "BROAD",
              bid,
            });
          }
        }
        if (phrase) {
          if (!keywordAlreadyExists(keyword, "PHRASE")) {
            newKeywords.push({
              keyword,
              match_type: "PHRASE",
              bid,
            });
          }
        }
        if (exact) {
          if (!keywordAlreadyExists(keyword, "EXACT")) {
            newKeywords.push({
              keyword,
              match_type: "EXACT",
              bid,
            });
          }
        }
      }
    });
    setKeywords([...keywords, ...newKeywords]);
    setKeywordsText("");
  }
  function deleteAllKeywords() {
    setKeywords([]);
  }
  function setSpecificKeywordBid(keyword, match_type, e) {
    let newBid = bid;
    deleteError("bid_error");
    try {
      newBid = parseFloat(e.target.value);
      if (newBid < 0.03) {
        createError("bid_error", "Bid must be greater or equal to 0.02");
        return;
      }
      if (newBid > maxBid) {
        createError(
          "bid_error",
          "Bid must be less than campaign budget $" + maxBid
        );
        return;
      }
    } catch (e) {}
    const newKeywords = [...keywords];
    newKeywords.map((_keyword) => {
      if (_keyword.keyword == keyword && _keyword.match_type == match_type) {
        _keyword.bid = newBid;
      }
    });
    setKeywords(newKeywords);
  }
  function deleteSpecificKeyword(keyword, match_type) {
    const newKeywords = keywords.filter(
      (_keyword) =>
        !(_keyword.keyword == keyword && _keyword.match_type == match_type)
    );
    setKeywords(newKeywords);
  }
  function isDisabled() {
    return saving || keywords.length == 0;
  }
  function handleSubmit() {
    setSaving(true);
    createKeywords(profileId, campaignId, adGroupId, keywords).then((res) => {
      if (res.error) {
        createError("unexpected_error", res.error);
      } else {
        setKeywords([]);
        updateTable();
        toggle(0);
      }
    });
  }
  return (
    <>
      <Modal show={openModals[0]} onHide={() => toggle(0)}>
        <Modal.Header closeButton>Add Keywords</Modal.Header>
        <Modal.Body
          className="text-center"
          style={{
            background: "linear-gradient(90deg, #FFC0CB00 50%, #D8DDDD 50%)",
          }}
        >
          <Row>
            <Col md="6">
              <Form.Group className="mb-3">
                <Row>
                  <Col md="3">
                    <Form.Label className="mb-0 mt-2 h5">Bid</Form.Label>
                  </Col>
                  <Col md="9">
                    <Row>
                      <Col md="6">
                        <InputGroup className="p-1">
                          <InputGroup.Text>$</InputGroup.Text>
                          <Form.Control
                            value={bid}
                            onChange={(e) => setKeywordBid(e)}
                            type="number"
                            step="0.01"
                          />
                        </InputGroup>
                      </Col>
                      <Col md="12">
                        {error.bid && (
                          <h5 className="text-danger mt-2">{error.bid}</h5>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col md="3">
                    <Form.Label className="mb-0 mt-2 h5">Match Type</Form.Label>
                  </Col>
                  <Col md="2">
                    <Form.Group className="mb-2 mt-2">
                      <Form.Check
                        label="Broad"
                        type="checkbox"
                        name="broad"
                        value="broad"
                        onChange={(e) => setBroad(e.target.checked)}
                        checked={broad}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="2">
                    <Form.Group className="mb-2 mt-2">
                      <Form.Check
                        label="Phrase"
                        type="checkbox"
                        name="phrase"
                        value="phrase"
                        onChange={(e) => setPhrase(e.target.checked)}
                        checked={phrase}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="2">
                    <Form.Group className="mb-2 mt-2">
                      <Form.Check
                        label="Exact"
                        type="checkbox"
                        name="exact"
                        value="exact"
                        onChange={(e) => setExact(e.target.checked)}
                        checked={exact}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col md="12">
                    <Form.Control
                      as="textarea"
                      name="input"
                      placeholder="Add your Keywords here, one keyword per line"
                      rows={20}
                      value={keywordsText}
                      onChange={(e) => setKeywordsText(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Row>
                <Col md="4">
                  <Button
                    variant="primary"
                    onClick={() => addKeywords()}
                    className="me-1 mt-3"
                  >
                    add Keywords
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md="6">
              {Object.keys(error) &&
                Object.keys(error).map((e) => (
                  <Row>
                    <h5 className="text-danger mt-2">{error[e]}</h5>
                  </Row>
                ))}
              <Row>
                <Col md="6">
                  <span className="text-primary">
                    {keywords.length}{" "}
                    <span className="h5 text-primary">Added</span>
                  </span>
                </Col>
                <Col md="6">
                  <a
                    onClick={() => deleteAllKeywords()}
                    className="text-primary"
                  >
                    Remove all
                  </a>
                </Col>
              </Row>
              <Row>
                <Table>
                  <thead>
                    <tr>
                      <th style={{ width: "30%" }}>Keyword</th>
                      <th style={{ width: "25%" }}>Match Type</th>
                      <th style={{ width: "30%" }}>Bid</th>
                      <th style={{ width: "15%" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {keywords.map((keyword) => (
                      <tr>
                        <td>{keyword.keyword}</td>
                        <td>{keyword.match_type}</td>
                        <td>
                          <InputGroup className="p-1">
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control
                              value={keyword.bid}
                              onChange={(e) =>
                                setSpecificKeywordBid(
                                  keyword.keyword,
                                  keyword.match_type,
                                  e
                                )
                              }
                              type="number"
                              step="0.01"
                            />
                          </InputGroup>
                        </td>
                        <td>
                          <a
                            className="text-danger"
                            onClick={() =>
                              deleteSpecificKeyword(
                                keyword.keyword,
                                keyword.match_type
                              )
                            }
                          >
                            Delete
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => handleSubmit()}
            disabled={isDisabled()}
          >
            Save
          </Button>
          <Button variant="warning" onClick={() => toggle(0)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
