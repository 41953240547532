import { useEffect, useState, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Card,
  Tab,
} from "react-bootstrap";

import {
  getCampaign,
  getAndSaveCampaign,
  getAdgroup,
  getAndSaveAdGroup,
} from "pages/opasity/opasityApi/singleItem";

import { updateCampaign } from "pages/opasity/opasityApi/updateCampaign";
import { updateAdGroup } from "pages/opasity/opasityApi/updateAdGroup";

import TabNav from "components/opasity/TabNav";

import useNavbar from "hooks/useNavbar";

const AdGroupSettings = () => {
  const { profileId, adGroupId } = useParams();
  const [adGroupData, setAdGroupData] = useState({});
  const [initialData, setInitialData] = useState({});
  const [error, setError] = useState({});
  const [isSame, setIsSame] = useState(true);
  const [saving, setSaving] = useState(false);

  function getAdGroupState() {
    const result = {
      class: null,
      text: null,
    };
    if (adGroupData.state == "enabled") {
      result.class = "text-success";
      result.text = "Enabled";
    }
    if (adGroupData.state == "paused") {
      result.class = "text-warning";
      result.text = "Paused";
    }
    if (adGroupData.state == "archived") {
      result.class = "text-danger";
      result.text = "Archived";
    }
    return result;
  }

  useEffect(() => {
    getAdgroup(profileId, adGroupId, true).then((res) => {
      const newAdGroupData = { ...res };
      newAdGroupData.defaultBid = parseFloat(newAdGroupData.defaultBid);
      setInitialData(newAdGroupData);
      setAdGroupData(newAdGroupData);
    });
  }, [profileId, adGroupId]);
  useEffect(() => {
    setIsSame(true);
    Object.keys(adGroupData).map((key) => {
      if (adGroupData[key] != initialData[key]) {
        setIsSame(false);
      }
    });
  }, [adGroupData, initialData]);
  function isDisabled() {
    return adGroupData.state == "archived" || saving;
  }
  function deleteError(key) {
    if (key in error) {
      const newError = { ...error };
      delete newError[key];
      setError(newError);
    }
  }
  function createError(key, val) {
    const newError = {
      ...error,
    };
    newError[key] = val;
    setError(newError);
  }
  function setDefaultBid(e) {
    const newDefaultBid = parseFloat(e.target.value);
    if (newDefaultBid > 0) {
      deleteError("defaultBid");
      setAdGroupData({
        ...adGroupData,
        defaultBid: newDefaultBid,
      });
    } else {
      createError("defaultBid", "Some error with default bid...");
    }
  }

  function handleSubmit() {
    deleteError("unexpected_error");
    setSaving(true);
    const adgroups = [{ ...adGroupData, adGroupId }];
    updateAdGroup(Number(profileId), adgroups).then((res) => {
      setSaving(false);
      if (res.error.length > 0) {
        createError("unexpected_error", "Unexpected error occurred");
      }
      getAndSaveAdGroup(profileId, adGroupId).then((resc) => {
        const newAdGroupData = { ...resc };
        newAdGroupData.defaultBid = parseFloat(newAdGroupData.defaultBid);
        setInitialData(newAdGroupData);
        setAdGroupData(newAdGroupData);
      });
    });
    setSaving(false);
  }

  return (
    <>
      <Helmet title="Edit Campaign" />
      <Container fluid className="p-0">
        {/* <Row>
          <Col md="6">
            <BreadCrumb
              adGroupId={adGroupId}
              page="targeting"
              profileId={profileId}
            ></BreadCrumb>
          </Col>
        </Row> */}
        <Row>
          <Col>
            <div className={"tab "}>
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <TabNav profileId={profileId} adGroupId={adGroupId} />
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <Card className="shadow-none">
                      <Card.Body>
                        <Form>
                          <Form.Group className="mb-3">
                            <Row>
                              <Col md="2">
                                <Form.Label className="mb-0 mt-2 h5">
                                  Ad Group name
                                </Form.Label>
                              </Col>
                              <Col md="3">
                                <Form.Control
                                  type="text"
                                  name="adGroupName"
                                  placeholder="ad group name"
                                  value={adGroupData.name}
                                  onChange={(event) =>
                                    setAdGroupData({
                                      ...adGroupData,
                                      name: event.target.value,
                                    })
                                  }
                                  disabled={isDisabled()}
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Row>
                              <Col md="2">
                                <Form.Label className="mb-0 mt-2 h5">
                                  Active/Paused
                                </Form.Label>
                              </Col>
                              <Col md="4" className="flex">
                                <Form.Check
                                  type="switch"
                                  id="exampleCustomSwitch"
                                  name="customSwitch"
                                  className="form-switch-md"
                                  onChange={(event) =>
                                    setAdGroupData({
                                      ...adGroupData,
                                      state: event.target.checked
                                        ? "enabled"
                                        : "paused",
                                    })
                                  }
                                  onlabel="Active"
                                  offlabel="Paused"
                                  checked={
                                    adGroupData.state == "enabled"
                                      ? true
                                      : false
                                  }
                                  disabled={isDisabled()}
                                />
                                <div>
                                  <h5
                                    className={`mt-2 mx-2 ${
                                      getAdGroupState().class
                                    }`}
                                  >
                                    {getAdGroupState().text}
                                  </h5>
                                </div>
                              </Col>
                            </Row>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Row>
                              <Col md="2">
                                <Form.Label className="mb-0 mt-2 h5">
                                  Default Bid
                                </Form.Label>
                              </Col>
                              <Col md="10">
                                <Row>
                                  <Col md="2">
                                    <InputGroup className="p-1">
                                      <InputGroup.Text>$</InputGroup.Text>
                                      <Form.Control
                                        value={adGroupData.defaultBid}
                                        onChange={(e) => setDefaultBid(e)}
                                        type="number"
                                        step="0.01"
                                      />
                                    </InputGroup>
                                  </Col>
                                  <Col md="12">
                                    {error.defaultBid && (
                                      <h5 className="text-danger mt-2">
                                        {error.defaultBid}
                                      </h5>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Form.Group>
                          <Button
                            variant="primary"
                            className="btn-lg"
                            onClick={() => handleSubmit()}
                            disabled={isSame || saving}
                          >
                            Save
                          </Button>
                        </Form>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdGroupSettings;
