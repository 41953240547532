import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";

import useAuth from "../../hooks/useAuth";

function ConfirmPassword() {
    const navigate = useNavigate();
    const { confirmPassword } = useAuth();
    const [searchParams, setSearchParams] = useSearchParams()
    const email = searchParams.get('email')
    return (
        <Formik
            initialValues={{
                email,
                confirmationCode: "",
                password: "",
                confirmationPassword: "",
                submit: false,
            }}
            validationSchema={Yup.object().shape({
                password: Yup.string()
                    .min(6, "Must be at least 6 characters")
                    .max(255)
                    .required("Required"),
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    console.log("got ps", email)
                    if (values.password != values.confirmationPassword) {
                        setErrors({
                            confirmationPassword: 'Passwords not matching'
                        })
                        return
                    }
                    const cp = confirmPassword(email, values.confirmationCode, values.password);
                    cp.then(
                        (res) => window.location.href = "/auth/sign-in",
                        (err) => {
                            if (err.message) {
                                setErrors({ submit: err.message });
                            }
                        }
                    )
                } catch (error) {
                    const message = error.message || "Something went wrong";

                    setStatus({ success: false });
                    setErrors({ submit: message });
                    setSubmitting(false);
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
            }) => (
                <Form onSubmit={handleSubmit}>
                    {errors.submit && (
                        <Alert className="my-3" variant="danger">
                            {errors.submit}
                        </Alert>
                    )}
                    <Form.Group className="mb-3">
                        <Form.Label>Confirmation Code</Form.Label>
                        <Form.Control
                            type="text"
                            name="confirmationCode"
                            placeholder="Confirmation Code"
                            value={values.confirmationCode}
                            isInvalid={Boolean(touched.confirmationCode && errors.confirmationCode)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        {!!touched.confirmationCode && (
                            <Form.Control.Feedback type="invalid">
                                {errors.confirmationCode}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={values.password}
                            isInvalid={Boolean(touched.password && errors.password)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        {!!touched.password && (
                            <Form.Control.Feedback type="invalid">
                                {errors.password}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            type="password"
                            name="confirmationPassword"
                            placeholder="Confirm Password"
                            value={values.confirmationPassword}
                            isInvalid={Boolean(touched.confirmationPassword && errors.confirmationPassword)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        {!!touched.password && (
                            <Form.Control.Feedback type="invalid">
                                {errors.confirmationPassword}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <div className="text-center mt-3">
                        <Button
                            type="submit"
                            variant="primary"
                            size="lg"
                            disabled={isSubmitting}
                        >
                            Change Password
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default ConfirmPassword;
