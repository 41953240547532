import { useEffect, useState, useRef } from "react";

import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { ReactComponent as FilterIcon } from "assets/img/icons/light-filter.svg";
import { ReactComponent as FilterLeftArrowIcon } from "assets/img/icons/filter-left-arrow.svg";
import { ReactComponent as FilterRightArrowIcon } from "assets/img/icons/filter-right-arrow.svg";
import {
  common_fields,
  profile_name_field,
  profile_daily_budget,
  profile_channel,
} from "pages/opasity/opasityApi/ag_grid_fields";

import {
  get_profiles_data,
  get_profiles_data_summary,
  get_profiles_data_pages,
} from "pages/opasity/opasityApi/getUserProfiles";

import {
  pickerToNorm,
  loadDate,
  saveToLocal,
  loadFromLocal,
} from "pages/opasity/opasityApi/utils";

import {
  agGridOptions,
  restoreColumnsState,
  restoreSortState,
  restoreFilters,
  onDragStarted,
  onDragStopped,
  onColumnMoved as onColumnMovedCommon,
  onSortChanged as onSortChangedCommon,
  onFilterChanged as onFilterChangedCommon,
  onColumnVisible as onColumnVisibleCommon,
  onRowDataUpdated as onRowDataUpdatedCommon,
  collapseFilterGroups,
  toggleColumnsPanelShow,
  exportToCSV,
  getTotals,
  customizeToolPanel,
  filterObjToArray,
} from "utils/agGridCommon";

import DateModal from "components/opasity/DateModal";
import Pagination from "components/opasity/Pagination";
import FilterComponent from "components/opasity/FilterComponent";
import ExportButton from "components/opasity/ExportButton";
import ColumnsButton from "components/opasity/ColumnsButton";

import useNavbar from "hooks/useNavbar";
import useFooter from "hooks/useFooter";
import FilterChips from "components/FilterChips";
import Filters from "components/Filters";

const UserProfiles = () => {
  const gridRef = useRef();
  const [gridReady, setGridReady] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const keySuffix = "profiles";
  const columnStateKey = `last-column-state-${keySuffix}`;
  const columnVisibleKey = `last-column-visible-${keySuffix}`;
  const sortStateKey = `last-sort-state-${keySuffix}`;
  const lastFilterKey = `last-filter-${keySuffix}`;
  const infoKey = `last-info-${keySuffix}`;

  const lastInfo = loadFromLocal(infoKey);

  const columnDefs = [
    {
      headerName: "Profile Info",
      children: [profile_name_field, profile_daily_budget, profile_channel],
    },
    ...common_fields,
  ];

  const [rawColumns, setRawColumns] = useState([]);
  const [parentInfo, setParentInfo] = useState({});

  const [rawTableData, setRawTableData] = useState([{}]);
  const [summaryData, setSummaryData] = useState([]);

  const [hasAdsAccount, setHasAdsAccount] = useState(true);

  const [dateState, setDateState] = useState(loadDate());

  const [pageSize, setPageSize] = useState(
    lastInfo?.pageSize ? lastInfo.pageSize : 50
  );
  const [page, setPage] = useState(1);
  const [noPages, setNoPages] = useState(0);
  const [count, setCount] = useState(0);

  const [orderBy, setOrderBy] = useState([]);
  const [filterBy, setFilterBy] = useState(
    restoreFilters(lastFilterKey, {}) || {}
  );
  const { setBreadcrumb } = useNavbar();
  const { setPagination } = useFooter();

  const updateTable = () => {
    if (!gridRef.current.api || !gridReady) {
      return;
    }

    const { selection, compare } = dateState;

    const selStartDate = pickerToNorm(selection.startDate);
    const selEndDate = pickerToNorm(selection.endDate);
    const comStartDate = pickerToNorm(compare.startDate);
    const comEndDate = pickerToNorm(compare.endDate);

    gridRef.current.api.showLoadingOverlay();

    get_profiles_data(
      selStartDate,
      selEndDate,
      comStartDate,
      comEndDate,
      pageSize,
      page,
      orderBy,
      filterObjToArray(filterBy)
    )
      .then((res) => {
        if (res && res.error) {
          if (res.error == "NO_ADS_ACCOUNT") {
            setHasAdsAccount(false);
            return;
          }
        }

        setRawTableData(!res.data || res.data.length === 0 ? [{}] : res.data);

        get_profiles_data_summary(
          selStartDate,
          selEndDate,
          comStartDate,
          comEndDate,
          filterObjToArray(filterBy)
        ).then((res) => {
          setSummaryData(getTotals(res.data));
        });

        get_profiles_data_pages(
          selStartDate,
          selEndDate,
          comStartDate,
          comEndDate,
          pageSize,
          page,
          orderBy,
          filterObjToArray(filterBy)
        ).then((res) => {
          setNoPages(res.pages);
          setCount(res.count);
        });
      })
      .catch(() => {
        gridRef.current.api.hideOverlay();
      });
  };

  const onGridReady = () => {
    restoreSortState(gridRef, sortStateKey, setOrderBy);

    collapseFilterGroups(gridRef);

    setGridReady(true);
  };

  const onPageSizeChanged = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onSortChanged = (event) => {
    onSortChangedCommon(event, sortStateKey, orderBy, setOrderBy);
  };

  const onFilterChanged = (event) => {
    onFilterChangedCommon(lastFilterKey, filterBy);
  };

  const onColumnMoved = (event) => {
    onColumnMovedCommon(event, parentInfo, columnDefs, columnStateKey);
  };

  const onColumnVisible = (event) => {
    onColumnVisibleCommon(event, columnVisibleKey);
  };

  const onRowDataUpdated = () => {
    onRowDataUpdatedCommon(gridRef, rawTableData);
  };

  useEffect(() => {
    updateTable();
  }, [gridReady, dateState, pageSize, page, orderBy, filterBy]);
  useEffect(() => {
    onFilterChangedCommon(lastFilterKey, filterBy);
  }, [filterBy]);
  useEffect(() => {
    restoreColumnsState(
      columnStateKey,
      columnVisibleKey,
      columnDefs,
      setParentInfo,
      setRawColumns
    );
  }, []);

  useEffect(() => {
    setBreadcrumb(null);
  }, []);

  useEffect(() => {
    setPagination({
      pageSize,
      onPageSizeChanged,
      setPage,
      noPages,
      page,
      count,
    });
  }, [noPages, count]);

  useEffect(() => {
    let currentInfo = loadFromLocal(infoKey);

    if (!currentInfo) {
      currentInfo = {};
    }

    currentInfo.pageSize = pageSize;
    saveToLocal(infoKey, currentInfo);
  }, [pageSize]);

  useEffect(() => customizeToolPanel(gridRef), [gridRef]);

  return (
    <>
      <Helmet title="User Profiles" />
      <Container fluid className="p-0" style={{ height: "100%" }}>
        {hasAdsAccount ? null : (
          <Alert variant="danger">
            <div className="alert-message">
              No ads account could be found{" "}
              <a href="/connect">
                <strong>Click Here To Connect</strong>
              </a>
            </div>
          </Alert>
        )}
        <Row style={{ height: "100%" }}>
          <Col style={{ display: "flex", flexDirection: "column" }}>
            <div
              className="d-flex align-items-end"
              style={{
                marginTop: 18,
                marginRight: 16,
                paddingRight: 0,
              }}
            >
              <div style={{ flexGrow: 1 }} />
              <div style={{ marginBottom: "0.5em" }}>
                <DateModal
                  gridRef={gridRef}
                  dateState={dateState}
                  setDateState={setDateState}
                />
              </div>
            </div>
            <div
              style={{
                background: "white",
                padding: "20px 8px 16px 8px",
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Row className="d-flex justify-content-between mb-1">
                <Col
                  className="d-flex align-items-end"
                  style={{ marginLeft: 0 }}
                >
                  <>
                    <button
                      className="filters-button"
                      onClick={() => {
                        setOpenFilters(!openFilters);
                      }}
                    >
                      {openFilters ? (
                        <span style={{ marginRight: 6 }}>Filters</span>
                      ) : null}
                      <FilterIcon style={{ marginRight: 6 }} />
                      {openFilters ? (
                        <FilterLeftArrowIcon />
                      ) : (
                        <FilterRightArrowIcon />
                      )}
                    </button>

                    <FilterChips
                      filterBy={filterBy}
                      setFilterBy={setFilterBy}
                    />
                  </>
                  {/** 
                  <FilterComponent
                    gridRef={gridRef}
                    filterBy={filterBy}
                    page="profiles"
                  />
                  */}
                  <div style={{ flexGrow: 1 }} />
                  <ExportButton
                    onClick={() => {
                      exportToCSV(gridRef, "account.csv");
                    }}
                  />
                  <ColumnsButton
                    onClick={() => {
                      toggleColumnsPanelShow(gridRef);
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ flexGrow: 1 }}>
                <Filters
                  columnsArray={rawColumns}
                  openFilters={openFilters}
                  setOpenFilters={setOpenFilters}
                  filterBy={filterBy}
                  setFilterBy={setFilterBy}
                />
                <Col>
                  <div className="ag-theme-alpine grid-wrapper">
                    <AgGridReact
                      ref={gridRef}
                      onGridReady={onGridReady}
                      columnDefs={rawColumns}
                      rowData={rawTableData}
                      gridOptions={agGridOptions}
                      pinnedBottomRowData={summaryData}
                      onDragStarted={onDragStarted}
                      onDragStopped={onDragStopped}
                      onColumnMoved={onColumnMoved}
                      onSortChanged={onSortChanged}
                      //onFilterChanged={onFilterChanged}
                      onColumnVisible={onColumnVisible}
                      onRowDataUpdated={onRowDataUpdated}
                      pivotMode={false}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserProfiles;
