import api from "./Ax"


const lambdaUrl = "https://yusrr9y689.execute-api.us-east-1.amazonaws.com/default/filters";

api.defaults.headers.common = {
    ...api.defaults.headers.common,
    'x-api-key': 'N40ljcqjQR62nlfm34vsQ9uGPZm4MTyP6VDObjx4'
}

export const getFilters = async (page) => {
    const params = {
        'action': 'get_all_filters',
        'table_type': page
    };

    try {
        const response = await api.post(lambdaUrl, params);
        return response.data;
    }
    catch (e) {

    }
}

export const createFilter = async (name, page, filters, profile_id, tabs) => {

    const requestBody = {
        action: 'create_filter',
        table_name: page,
        name,
        json: JSON.stringify(filters),
        profile_id: profile_id,
        tabs: tabs
    };

    try {
        const response = await api.post(lambdaUrl, requestBody);
        return response.data;
    }
    catch (ex) {

    }
}

export const updateFilter = async (id, name, filters, profile_id, tabs) => {
    console.log(filters);

    const requestBody = {
        id: id,
        action: 'update_filter',
        name,
        json: JSON.stringify(filters),
        profile_id: profile_id,
        tabs: tabs
    };

    try {
        const response = await api.post(lambdaUrl, requestBody);
        return response.data;
    }
    catch (ex) {

    }
}

/**
 * 
 * @param {string} name The name of the filter
 * @param {*} page The page name
 * @param {*} filters {}
 */
export const saveFilter = async (name, page, filters) => {
    const requestBody = {
        action: 'create_or_update_filter',
        table_name: page,
        name,
        json: JSON.stringify(filters)
    };

    try {
        const response = await api.post(lambdaUrl, JSON.stringify(requestBody), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    }
    catch (e) {

    }
}

export const deleteFilter = async (id) => {
    const requestBody = {
        action: 'delete_filter',
        id: id
    };

    try {
        const response = await api.post(lambdaUrl, JSON.stringify(requestBody), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    }
    catch (e) {

    }

}