import React, { useState, useEffect } from "react";
import { getCampaign, getAdgroup } from "./opasityApi/singleItem";
import { Link } from "react-router-dom";
import { getProfile } from "../opasity/opasityApi/getUserProfiles";

const BreadCrumbs = ({ profileId, campaignId, adGroupId, page }) => {
  const [campaign, setCampaign] = useState("");
  const [adGroup, setAdgroup] = useState("");
  const [profile, setProfile] = useState("");
  useEffect(() => {
    setCampaign("");
    setAdgroup("");
    if (campaignId) {
      getCampaign(profileId, campaignId).then((res) => setCampaign(res.name));
    }
    if (adGroupId) {
      getAdgroup(profileId, adGroupId).then((res) => setAdgroup(res.name));
    }
    getProfile(profileId).then((res) => {
      setProfile(res[0].name);
    });
  }, [campaignId, adGroupId]);

  return (
    <h1 className="h3 mb-3">
      <Link to={`/profiles/${profileId}/campaigns/`}>{profile}</Link>
      {campaign ? (
        <React.Fragment>
          {" > "}
          <Link to={`/profiles/${profileId}/campaigns/${campaignId}/`}>
            {campaign}
          </Link>
        </React.Fragment>
      ) : (
        ""
      )}
      {adGroup ? (
        <React.Fragment>
          {" > "}
          <Link
            to={`/profiles/${profileId}/campaigns/${campaignId}/adgroups/${adGroupId}/targeting`}
          >
            {adGroup}
          </Link>
        </React.Fragment>
      ) : (
        ""
      )}
      {page ? ` > ${page}` : ""}
    </h1>
  );
};

export default BreadCrumbs;
