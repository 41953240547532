import api from "./Ax"


export async function get_campaigns_data(profileId, campaignId, start_date, end_date, vs_start_date, vs_end_date) {
    const query = {
        'action': 'get_campaign_placement',
        profileId: Number(profileId),
        campaign_id: Number(campaignId),
        start_date,
        end_date,
        vs_start_date,
        vs_end_date
    }
    const result = await api.post("https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data", query)
    return result.data
}