import { createContext, useState } from "react";

const initialState = {
  pagination: null,
};

const FooterContext = createContext(initialState);

function FooterProvider({ children }) {
  const [pagination, setPagination] = useState(null);

  return (
    <FooterContext.Provider
      value={{
        pagination,
        setPagination,
      }}
    >
      {children}
    </FooterContext.Provider>
  );
}

export { FooterProvider, FooterContext };
