import api from "./Ax";
import {
  saveToLocalSession,
  loadFromLocalSession,
  loadFromLocal,
  saveToLocal,
} from "./utils";

async function fetchAndSaveUserProfiles() {
  const query = {
    action: "get_user_profiles",
  };
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  return result.data;
}

export default async function getUserProfiles() {
  const fetchedUserProfiles = await fetchAndSaveUserProfiles();
  return fetchedUserProfiles;
}

export async function getProfile(profileId) {
  const profiles = await getUserProfiles();
  return profiles.filter((profile) => profile.profileId == profileId);
}

export function getMarketPlace(marketplaceId) {
  const marketplaces = {
    A2EUQ1WTGCTBG2: "Amazon.ca",
    ATVPDKIKX0DER: "Amazon.com",
    A1AM78C64UM0Y8: "Amazon.com.mx",
    A2Q3Y263D00KWC: "Amazon.com.br",
  };
  return marketplaces[marketplaceId];
}

export async function get_profiles_data(
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  pageSize,
  page,
  orderBy,
  filterBy
) {
  const query = JSON.stringify({
    action: "get_user_profiles_data",
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    pageSize,
    page,
    orderBy,
    filterBy,
  });
  fetchAndSaveUserProfiles();
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  return result.data;
}

export async function get_profiles_data_pages(
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  pageSize,
  page,
  orderBy,
  filterBy
) {
  const query = JSON.stringify({
    action: "get_user_profiles_data",
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    pageSize,
    page,
    orderBy,
    filterBy,
    getCount: true,
  });
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  return result.data;
}

export async function get_profiles_data_summary(
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  filterBy
) {
  const query = JSON.stringify({
    action: "get_user_profiles_data_summary",
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    filterBy,
  });
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  return result.data;
}
