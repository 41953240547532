import api from "./Ax"

export async function updateKeyword(profileId, keywords) {
    const payload = {
        action: "update_keywords",
        profile_id: profileId,
        keywords
    }
    console.log("payload is: ", payload)
    const result = await api.post('https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/manager', payload)
    console.log("upadted keywords: ", result)
    return result.data
}

export async function deleteKeyword(profileId, keywords) {
    const payload = {
        action: "delete_keywords",
        profile_id: profileId,
        keywords
    }
    console.log("payload is: ", payload)
    const result = await api.post('https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/manager', payload)
    console.log("upadted keywords: ", result)
    return result.data
}

export async function createKeywords(profileId, campaignId, adGroupId, keywords) {
    const payload = {
        action: 'create_keywords',
        profile_id: Number(profileId),
        campaign_id: Number(campaignId),
        ad_group_id: Number(adGroupId),
        keywords
    }
    console.log("payload is: ", payload)
    const result = await api.post('https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/manager', payload)
    console.log("upadted keywords: ", result)
    return result.data
}

export async function createNegativeKeywords(profileId, campaignId, adGroupId, keywords) {
    const payload = {
        action: 'create_negative_keywords',
        profile_id: Number(profileId),
        campaign_id: Number(campaignId),
        ad_group_id: Number(adGroupId),
        keywords
    }
    console.log("payload is: ", payload)
    const result = await api.post('https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/manager', payload)
    console.log("upadted keywords: ", result)
    return result.data
}

export async function updateAutoTarget(profile_id, targets) {
    const payload = {
        action: "update_targets",
        profile_id: profile_id,
        targets
    }
    console.log("payload is: ", payload)
    const result = await api.post('https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/manager', payload)
    console.log("upadted keywords: ", result)
    return result.data
}