import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, Navbar, Nav, Form, InputGroup } from "react-bootstrap";

import Select from "react-select";

import NavbarUser from "./NavbarUser";
import { useParams } from "react-router-dom";
import getUserProfiles, {
  getProfile,
  getMarketPlace,
} from "pages/opasity/opasityApi/getUserProfiles";

import BreadCrumbs from "components/opasity/CampaignsBreadCrumbs";

import useSidebar from "hooks/useSidebar";
import useNavbar from "hooks/useNavbar";

const NavbarComponent = () => {
  const { t } = useTranslation();
  const { isOpen, setIsOpen } = useSidebar();
  const { breadcrumb } = useNavbar();
  const { profileId } = useParams();

  const [profiles, setProfiles] = useState([]);
  const [currentProfile, setCurrentProfile] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (profileId && profiles.length) {
      setCurrentProfile(
        profiles.filter((profile) => profile.value == profileId)
      );
    } else {
      setCurrentProfile(null);
    }
  }, [profileId, profiles]);

  useEffect(async () => {
    const allProfiles = await getUserProfiles();

    if (!allProfiles) {
      return;
    }

    setProfiles(
      allProfiles.map((profile) => {
        return { value: profile.profileId, label: profile.name };
      })
    );
  }, []);

  return (
    <Navbar
      variant="light"
      expand
      className="navbar-bg"
      style={{ paddingTop: 0, paddingBottom: 0, height: 64 }}
    >
      <span
        className="sidebar-toggle d-flex me-0"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <i className="hamburger align-self-center" />
      </span>
      {profiles.length ? (
        <Select
          placeholder="Accounts"
          className="profile-select"
          options={profiles}
          value={currentProfile}
          onChange={(newProfile) => {
            setCurrentProfile(newProfile);
            navigate(`/profiles/${newProfile.value}/campaigns`);
          }}
          menuPortalTarget={document.body}
          components={{
            IndicatorSeparator: () => null,
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "var(--back-dark)",
              neutral0: "white",
              neutral5: "var(--back-dark)",
              neutral10: "var(--back-dark)",
              neutral20: "var(--back-dark)",
              neutral30: "var(--back-dark)",
              neutral40: "var(--back-dark)",
              neutral50: "var(--back-dark)",
              neutral60: "var(--back-dark)",
              neutral70: "var(--back-dark)",
              neutral80: "var(--back-dark)",
              neutral90: "var(--back-dark)",
            },
          })}
          styles={{
            control: (provided) => ({
              ...provided,
              minHeight: 20,
              boxShadow: "none",
              paddingLeft: 2,
              paddingRight: 2,
              border: "none",
              background: "transparent",
              fontWeight: "bold",
              width: 140,
            }),
            input: (provided) => ({
              ...provided,
              margin: "0px",
            }),
            indicatorsContainer: (provided) => ({
              ...provided,
              height: "30px",
            }),
            noOptionsMessage: (provided) => ({
              ...provided,
              color: "#999",
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />
      ) : null}
      {breadcrumb ? <BreadCrumbs {...breadcrumb} /> : null}
      <Navbar.Collapse style={{ height: "100%" }}>
        <Nav className="navbar-align" style={{ height: "100%" }}>
          <NavbarUser />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
