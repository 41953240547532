import axios from 'axios'
import { UserPool } from "../../../contexts/CognitoContext"
import { maskKeys } from "../../../config"
let retries = 0
const api = axios.create()
const red = {}

let maskDictionary = [];

api.interceptors.request.use(function (config) {
  // Do something before request is sent
  console.log("this is happening before the request is sent...")
  console.log("original config is: ", config)

    try {

        let maskMode = JSON.parse(localStorage.getItem('maskMode'));
        if (maskMode === true) {
            if (config.data) {
                if (config.data.action === 'get_advertised_products_data'
                    || config.data.action === 'get_advertised_products_data2') {

                    if (config.data.groupByDrillDown) {
                        for (var i = 0; i < config.data.groupByDrillDown.length; i++) {
                            var value = config.data.groupByDrillDown[i];
                            let dictionaryItem = maskDictionary.find(d => d.v === value);
                            if (dictionaryItem) {
                                config.data.groupByDrillDown[i] = dictionaryItem.k;
                            }
                        }
                    }

                }
            }
        }

    } catch (e) {
        console.error(e);
    }

  ttt()
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});
api.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data

    try {

        let maskMode = JSON.parse(localStorage.getItem('maskMode'));
        if (maskMode === true) {
            
            let data = response.data;

            let friendlyWords = ['apple', 'banana', 'cherry', 'date', 'elderberry', 'fig', 'grape', 'honeydew', 'kiwi', 'lemon', 'mango', 'nectarine', 'orange', 'peach', 'quince', 'raspberry', 'strawberry', 'tangerine', 'ugli fruit', 'vanilla bean', 'watermelon', 'xigua', 'yellow watermelon', 'zucchini', 'apricot', 'blackberry', 'coconut', 'dragonfruit', 'elderberry', 'fig', 'grapefruit', 'huckleberry', 'jackfruit', 'kiwano', 'lime', 'mulberry', 'nectarine', 'olive', 'papaya', 'quince', 'raspberry', 'starfruit', 'tangerine', 'ugli fruit', 'vanilla bean', 'watermelon', 'xigua', 'yellow watermelon', 'zucchini', 'almond', 'blueberry', 'cantaloupe', 'durian', 'elderberry', 'fig', 'grapefruit', 'honeyberry', 'ice plant', 'jujube', 'kumquat', 'lemon', 'mango', 'nectarine', 'orange', 'peach', 'quince', 'raspberry', 'strawberry', 'tangerine', 'ugli fruit', 'vanilla bean', 'watermelon', 'xigua', 'yellow watermelon', 'zucchini', 'apricot', 'blackberry', 'coconut', 'dragonfruit', 'elderberry', 'fig', 'grapefruit', 'huckleberry', 'jackfruit', 'kiwano', 'lime', 'mulberry', 'nectarine', 'olive', 'papaya', 'quince', 'raspberry', 'starfruit', 'tangerine', 'ugli fruit', 'vanilla bean', 'watermelon', 'xigua', 'yellow watermelon', 'zucchini'];

            const generateRandomValue = function (name, prefix, input) {
                if (typeof input === 'string') {

                    let dictionaryItem = maskDictionary.find(d => d.n == name && d.k === input);
                    if (dictionaryItem) {
                        return dictionaryItem.v;
                    }
                    else {
                        const words = input.split(' ');
                        let result = `${prefix}-`;
                        for (let i = 0; i < words.length; i++) {

                            const randomIndex = Math.floor(Math.random() * friendlyWords.length);
                            const randomWord = friendlyWords[randomIndex];
                            result += randomWord + ' ';
                        }
                        result = result.trim();

                        let tempResult = result;
                        let counter = 0;
                        while (maskDictionary.find(d => d.n == name && d.v === tempResult)) {
                            counter++;
                            tempResult = result + ' ' + counter.toString();
                        }
                        if (counter > 0) {
                            result = result + ' ' + counter.toString();
                        }

                        maskDictionary.push({n:name, k: input, v: result });
                        return result;
                    }
                }
                else if (typeof input === 'number') {
                    const length = input.toString().length;
                    const min = Math.pow(10, length - 1);
                    const max = Math.pow(10, length) - 1;
                    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
                    return randomNumber;
                }
                else {
                    return input;
                }
            }

            const visit = function (obj, key) {
                if (obj && key.name in obj) {
                    obj[key.name] = generateRandomValue(key.name, key.prefix, obj[key.name]);
                }
                for (const k in obj) {
                    if (typeof obj[k] === 'object') {
                        visit(obj[k], key);
                    }
                }
            }

            for (const key of maskKeys) {
                visit(data, key);
            }

            response.data = data;
        }

    } catch (e) {
        console.error(e);
    }

  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  console.log(error)
  console.log(error.response.status)
  const errorResponse = error.response
  if (errorResponse.data) {
    if (errorResponse.data.message) {
      if (errorResponse.data.message == "No user") {
        window.location.href = "/connect"
      }
    }
  } else {
    console.log("original config that returned error", error.config)
    //alert("Error fetching data, please reload the page")
    console.log("error code: ", error.response.status)
    error.config.headers['Authorization'] = getToken()
    api.defaults.headers.common = { 'Authorization': getToken() }
    retries += 1
    console.log("retries:; ", retries)
    if (retries > 5) {
      return Promise.reject(error);
    }
    return api.post(error.config.url, error.config.data)

  }
  return Promise.reject(error);
});

function getToken() {
  const key = "CognitoIdentityServiceProvider.487jmg0mua3ee7afo4gt4obp2v"
  const user = localStorage.getItem(key + ".LastAuthUser")
  const token = localStorage.getItem(key + "." + user + ".idToken")
  return token
}

function ttt() {
  console.log("running tttt")
  const user = UserPool.getCurrentUser();
  if (user) {
    user.getSession((err, session) => {
      if (err) {
        console.log("got error refreshing sessions: ", err)
      } else {
        const token = session?.getIdToken().getJwtToken();
        api.defaults.headers.common = { 'Authorization': token }
      }
    })
  }
}

let token = getToken()
api.defaults.headers.common = { 'Authorization': token }

export default api
