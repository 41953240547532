import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Helmet } from "react-helmet-async";
import { getMarketPlace } from "../opasityApi/getUserProfiles";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Alert,
  InputGroup,
} from "react-bootstrap";
import { getSpApiAccount } from "../opasityApi/spApiProfiles";
import getUserProfiles from "../opasityApi/getUserProfiles";
import { updateProfileSeller } from "../opasityApi/updateProfileSeller";

const UserSettings = () => {
  const [sellerAccountParticipations, setSellerAccountParticipations] =
    useState([]);
  const [profiles, setProfiles] = useState([]);
  const [sellerAccounts, setSellerAccounts] = useState([]);
  const [initialData, setInitialData] = useState({});
  const [formData, setFormData] = useState({});
  const [isSame, setIsSame] = useState(true);
  useEffect(() => {
    getSpApiAccount().then((res) => {
      if (res.participations) {
        setSellerAccountParticipations(res.participations);
        setSellerAccounts([
          {
            name: `main seller account (${res.email})`,
            email: res.email,
          },
        ]);
      }
    });
    getUserProfiles().then((res) => {
      if (res.error) {
      } else {
        setProfiles(res);
        const ini = {};
        res.map((profile) => {
          ini[profile.profileId] = profile.sellerAccount;
        });
        setInitialData(ini);
        setFormData(ini);
      }
    });
  }, []);
  useEffect(() => {
    setIsSame(true);
    Object.keys(formData).map((key) => {
      if (formData[key] != initialData[key]) {
        setIsSame(false);
      }
    });
  }, [formData]);
  function mapProfileSeller(e, profileId) {
    const val = e.target.value;
    const newFormData = { ...formData };
    newFormData[Number(profileId)] = val;
    setFormData(newFormData);
  }
  function handleSubmit() {
    const profiles = Object.keys(formData).map((_profileId) => ({
      profileId: Number(_profileId),
      sellerAccount: formData[_profileId] ? formData[_profileId] : "",
    }));
    updateProfileSeller(profiles).then((res) => {
      setInitialData({ ...formData });
      setIsSame(true);
    });
  }
  return (
    <React.Fragment>
      <Helmet title="Edit Campaign" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Account Settings </h1>
        <Row>
          <Card>
            <Card.Body>
              <Button
                onClick={() => (window.location.href = "/connectsp")}
                style={{ textAlign: "right", right: 0 }}
              >
                Connect Amazon Seller Account
              </Button>
              <h4 className="text-primary mb-3">
                Amazon Seller Account Participations
              </h4>
              {sellerAccountParticipations && (
                <div>
                  {sellerAccountParticipations.map((par) => (
                    <h5 className="mb-3">{getMarketPlace(par)}</h5>
                  ))}
                </div>
              )}
              <h4 className="text-primary mb-3">
                Amazon Ads Profiles and associated Seller Accounts
              </h4>
              <Form>
                {profiles &&
                  profiles.map((profile) => (
                    <Row>
                      <Col md="2">{profile.name}</Col>
                      <Col md="4">
                        <Form.Select
                          id="exampleCustomSelect"
                          name="customSelect"
                          className="mb-3"
                          onChange={(e) =>
                            mapProfileSeller(e, profile.profileId)
                          }
                        >
                          {sellerAccounts &&
                            sellerAccounts.map((account) => {
                              return (
                                <React.Fragment>
                                  <option
                                    value={account.email}
                                    selected={
                                      account.email == profile.sellerAccount
                                    }
                                  >
                                    {account.name}
                                  </option>
                                  <option
                                    value=""
                                    selected={!profile.sellerAccount}
                                  ></option>
                                </React.Fragment>
                              );
                            })}
                        </Form.Select>
                      </Col>
                    </Row>
                  ))}
                <Button
                  variant="primary"
                  className="btn-lg"
                  disabled={isSame}
                  onClick={() => handleSubmit()}
                >
                  Save
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default UserSettings;
