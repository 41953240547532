import api from "./Ax"

export async function updateAdGroup(profileId, adgroups) {
    console.log("CALLED UPDATE CAMPAIGN....")
    const payload = {
        action: "update_ad_groups",
        profile_id: profileId,
        adgroups
    }
    console.log("payload is: ", payload)
    const result = await api.post('https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/manager', payload)
    console.log("upadted adgroups: ", result)
    return result.data
}

export async function deleteAdGroup(profileId, adgroups) {
    console.log("CALLED UPDATE CAMPAIGN....")
    const payload = {
        action: "delete_ad_groups",
        profile_id: profileId,
        adgroups
    }
    console.log("payload is: ", payload)
    const result = await api.post('https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/manager', payload)
    console.log("upadted adgroups: ", result)
    return result.data
}