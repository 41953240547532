import { Badge, Button } from "react-bootstrap";

const FILTER_TYPES = {
  greaterThanOrEqual: ">=",
  lessThanOrEqual: "<=",
  equals: "=",
  greaterThan: ">",
  lessThan: "<",
  IS_IN: "one of",
};
export default function FilterChips({ filterBy, setFilterBy }) {
  const badgeStyle = {
    marginBottom: "2px",
    borderRadius: "10px",
    paddingTop: "4px",
    paddingBottom: "4px",
    paddingLeft: "10px",
    paddingRight: "10px",
    color: "#3F80EA",
    backgroundColor: "white",
    border: "0.1px solid #3F80EA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    caretColor: "transparent",
    marginLeft: 10,
    userSelect :"none"
  };

  const buttonStyle = {
    fontWeight: "bolder",
    cursor: "pointer",
  };
  const handleDeleteClick = (key) => {
    const newFilters = {};
    Object.keys(filterBy).map((fk) => {
      if (fk != key) {
        newFilters[fk] = filterBy[fk];
      }
    });
    setFilterBy(newFilters);
    console.log("deleteClicked");
  };
  function getFilterType(ft) {
    return FILTER_TYPES[ft] || ft;
  }
  function filterText(key) {
    const filterCrumbs = key
      .split("-")
      .filter((f) => f != "")
      .join("->");
    const filterType =
      filterBy[key]?.values?.constructor == Array &&
      filterBy[key]?.values?.length == 1
        ? "="
        : getFilterType(filterBy[key]?.filterType);
    const filterValue = filterBy[key]?.value;
    return {
      c: filterCrumbs,
      ft: filterType,
      fv:
        filterBy[key]?.values?.constructor == Array
          ? `(${filterBy[key]?.values?.join(", ")})`
          : filterValue,
    };
  }
  return Object.keys(filterBy).map((key) => (
    <>
      <div style={badgeStyle}>
        <div>
          {filterText(key).c} {filterText(key).ft} {filterText(key).fv}
        </div>
        <span
          style={buttonStyle}
          onClick={() => {
            handleDeleteClick(key);
          }}
        >
          x
        </span>
      </div>
    </>
  ));
}
