import { Row, Col, Button, Form } from "react-bootstrap";

import { ReactComponent as PrevIcon } from "assets/img/icons/prev.svg";
import { ReactComponent as NextIcon } from "assets/img/icons/next.svg";
import { ReactComponent as FirstIcon } from "assets/img/icons/first.svg";
import { ReactComponent as LastIcon } from "assets/img/icons/last.svg";

import { ReactComponent as PaginationDecorator } from "assets/img/drawings/pagination_decorator.svg";

const Pagination = ({
  pageSize,
  onPageSizeChanged,
  setPage,
  noPages,
  page,
  count,
}) => {
  const nextPage = () => {
    if (page < noPages) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const firstPage = () => {
    setPage(1);
  };

  const lastPage = () => {
    setPage(Math.max(noPages, 1));
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        backgroundColor: "white",
        height: 40,
        marginBottom: 12,
        padding: "0px 16px",
      }}
    >
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          borderRadius: "0px 0px 5px 5px",
          background: "#ECF0F5",
          height: 40,
          boxShadow: "0px 1.6px 5px 0px rgba(0, 0, 0, 0.12)",
          padding: "0px 16px",
        }}
      >
        <div style={{ flexGrow: 1 }} />
        <button
          className="pagination-btn pagination-btn-first"
          onClick={firstPage}
        >
          <FirstIcon />
        </button>
        <button
          className="pagination-btn pagination-btn-prev"
          onClick={prevPage}
        >
          <PrevIcon />
        </button>
        <span
          className="mx-2"
          style={{ color: "var(--back-dark)", fontSize: 13, fontWeight: 500 }}
        >
          Page {page} of {noPages} ({count} rows)
        </span>
        <button
          className="pagination-btn pagination-btn-next"
          onClick={nextPage}
        >
          <NextIcon />
        </button>
        <button
          className="pagination-btn pagination-btn-last"
          onClick={lastPage}
        >
          <LastIcon />
        </button>
      </div>
      <div
        className="form-group d-flex align-items-center ms-4"
        style={{ height: 40, marginBottom: 12, backgroundColor: "transparent" }}
      >
        <label
          className="col-md-auto col-form-label me-2"
          style={{ color: "var(--back-dark)", fontSize: 13, marginTop: "13px" }}
        >
          Show rows:
        </label>
        <div id="page-size-select-wrapper">
          <Form.Select
            name="customSelect"
            value={pageSize}
            onChange={onPageSizeChanged}
            style={{ color: "var(--back-dark)", marginTop: "13px" }}
            id="page-size-select"
          >
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
            <option value={300}>300</option>
          </Form.Select>
        </div>
      </div>
      <div style={{ flexGrow: 1 }} />
    </div>
  );
};

export default Pagination;
