import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { updateKeyword } from "../opasityApi/updateKeyword";
import { updateCampaign } from "../opasityApi/updateCampaign";
import { getMarketPlace } from "./getUserProfiles";
import {
  faPause,
  faPlay,
  faArchive,
  faDotCircle,
} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as AutoSVG } from "assets/img/auto.svg";
import { ReactComponent as ManualSVG } from "assets/img/manual.svg";
import { updateProfileAdmin, updateProfileReportAdmin } from "./adminAdpi";
import { updateCatalogItem } from "../opasityApi/catalogManager";

import { ReactComponent as Pencil } from "assets/img/icons/pencil.svg";
import { ReactComponent as Cross } from "assets/img/icons/cross.svg";
import { ReactComponent as Tick } from "assets/img/icons/tick.svg";

export function HeaderRenderer(props) {
  const [expandState, setExpandState] = useState(
    props.columnGroup.getProvidedColumnGroup().isExpanded()
      ? "expanded"
      : "collapsed"
  );

  const expandOrCollapse = () => {
    let currentState = props.columnGroup.getProvidedColumnGroup().isExpanded();
    props.setExpanded(!currentState);
  };

  const syncExpandButtons = () => {
    setExpandState(
      props.columnGroup.getProvidedColumnGroup().isExpanded()
        ? "expanded"
        : "collapsed"
    );
  };

  useEffect(() => {
    props.columnGroup
      .getProvidedColumnGroup()
      .addEventListener("expandedChanged", syncExpandButtons);

    return () => {
      props.columnGroup
        .getProvidedColumnGroup()
        .removeEventListener("expandedChanged", syncExpandButtons);
    };
  }, []);

  return (
    <div className="ag-header-group-cell-label" role="presentation">
      <span
        className="ag-header-group-text"
        role="presentation"
        onClick={() => expandOrCollapse()}
      >
        {props.displayName}
      </span>
      <span
        onClick={() => expandOrCollapse()}
        className={`ag-icon ag-icon-${
          expandState === "collapsed" ? "right" : "left"
        }`}
      />
    </div>
  );
}

function nodeDataGetter(node) {
  if ("aggData" in node) {
    return node.aggData;
  }
  return node.data;
}

function val_comparator(current, previous, fixed = 3) {
  if (isNaN(current) || !isFinite(current)) {
    return "-";
  }
  if (isNaN(previous) || !isFinite(previous)) {
    return "-";
  }
  return (current - previous).toFixed(fixed);
}
function comparator_string(
  current,
  previous,
  inverted_colors = false,
  sign = "",
  fixed = 3
) {
  if (isNaN(current) || !isFinite(current)) {
    return "-";
  }
  let val = sign + current.toFixed(fixed);
  if (!previous || !isFinite(previous)) {
    val += "<sup class='text-info'>no data</sup>";
    return val;
  }
  const green_class = "text-success";
  const red_class = "text-danger";
  let text_class = red_class;
  if (inverted_colors) {
    text_class = green_class;
  }
  const diff = current - previous;
  if (diff > 0) {
    text_class = green_class;
    if (inverted_colors) {
      text_class = red_class;
    }
  }
  let diff_percent = "";
  if (current > 0) {
    diff_percent = (diff * 100) / current;
    diff_percent = ` (${diff_percent.toFixed(2)}%)`;
  }
  val += `<sup class='${text_class}'>${
    sign + diff.toFixed(fixed)
  }${diff_percent}</sup>`;
  return val;
}

function dataGetter(params) {
  if ("data" in params && params.data) {
    return params.data;
  }
  return params.node.aggData;
}

function currencyFormatter(params) {
  if (params.value == null) {
    return "";
  }
  if (isNaN(params.value)) {
    return `$ ${params.value}`;
  }
  return `${new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(Number(params.value))}`;
}
function numberFormatter(params) {
  if (isNaN(params.value)) {
    return `$ ${params.value}`;
  }
  return `${new Intl.NumberFormat("en-US").format(Number(params.value))}`;
}
function percentFormatter(params) {
  if (params?.data === undefined || params?.value === undefined) {
    return "...";
  }
  if (isNaN(params.value)) {
    return `${params.value} %`;
  }
  return `${Number(params.value).toFixed(2)} %`;
}

function CampaignRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/profiles/${params?.data?.profileId}/campaigns/${params?.data?.campaignId}/`}
        style={{ textDecoration: "none" }}
      >
        {params?.data?.campaignName}
      </Link>
    </span>
  );
}
function CampaignTypeRenderer(params) {
  let tr = params?.data?.campaignType;
  if (params.value == "auto") {
    return (
      <a href="#">
        <AutoSVG
          style={{
            filter:
              "invert(46%) sepia(87%) saturate(7339%) hue-rotate(212deg) brightness(102%) contrast(105%)",
          }}
        />
      </a>
    );
  }
  if (params.value == "manual") {
    return (
      <a href="#">
        <ManualSVG
          style={{
            filter:
              "invert(46%) sepia(87%) saturate(7339%) hue-rotate(212deg) brightness(102%) contrast(105%)",
          }}
        />
      </a>
    );
  }
  return <span>{tr}</span>;
}
function AdGroupRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/profiles/${params?.data?.profileId}/campaigns/${params?.data?.campaignId}/adgroups/${params?.data?.adGroupId}/targeting/`}
        style={{ textDecoration: "none" }}
      >
        {params?.data?.adGroupName}
      </Link>
    </span>
  );
}
function ProfileRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/profiles/${params?.data?.profileId}/campaigns/`}
        style={{ textDecoration: "none" }}
      >
        {params?.data?.profileName}
      </Link>
    </span>
  );
}

function AdGroupStateRenderer(params) {
  const state = params?.data?.adGroupState;
  /* const valMapper = {
    enabled: <FontAwesomeIcon icon={faDotCircle} color="green" />,
    paused: <FontAwesomeIcon icon={faDotCircle} color="grey" />,
    archived: <FontAwesomeIcon icon={faDotCircle} color="grey" />,
  }; */
  return <span className={`state-toggle state-toggle-${state}`}></span>;
}

function CampaignStateRenderer(params) {
  const state = params?.data?.campaignState;
  /* const valMapper = {
    enabled: <FontAwesomeIcon icon={faDotCircle} color="green" />,
    paused: <FontAwesomeIcon icon={faDotCircle} color="grey" />,
    archived: <FontAwesomeIcon icon={faDotCircle} color="grey" />,
  }; */
  return <span className={`state-toggle state-toggle-${state}`}></span>;
}

function KeywordStateRenderer(params) {
  const state = params?.data?.keywordState || params?.data?.targetState;
  /* const valMapper = {
    enabled: <FontAwesomeIcon icon={faDotCircle} color="green" />,
    paused: <FontAwesomeIcon icon={faDotCircle} color="grey" />,
    archived: <FontAwesomeIcon icon={faDotCircle} color="grey" />,
  }; */
  return <span className={`state-toggle state-toggle-${state}`}></span>;
}
function NegativeKeywordStateRenderer(params) {
  const state = params?.data?.negativeKeywordState;
  /* const valMapper = {
    enabled: <FontAwesomeIcon icon={faDotCircle} color="green" />,
    paused: <FontAwesomeIcon icon={faDotCircle} color="grey" />,
    archived: <FontAwesomeIcon icon={faDotCircle} color="grey" />,
  }; */
  return <span className={`state-toggle state-toggle-${state}`}></span>;
}
export const keyword_id_field = {
  field: "keywordId",
  hidden: true,
};
export const profile_id_field = {
  field: "profileId",
  hidden: true,
};

export const keyword_field = {
  headerName: "keyword",
  field: "keyword",
  wrapText: true,
  autoHeight: true,
  width: 120,
  filter: "agTextColumnFilter",
  filterParams: {
    filterOptions: ["contains"],
    maxNumConditions: 1,
  },
};
export const target_field = {
  headerName: "Target",
  field: "verbose",
  wrapText: true,
  autoHeight: true,
  width: 180,
  filter: "agTextColumnFilter",
  filterParams: {
    filterOptions: ["contains"],
    maxNumConditions: 1,
  },
};

export const keyword_type_field = {
  headerName: "keyword Type",
  field: "keywordType",
  aggFunc: "first",
  enableRowGroup: true,
  width: 80,
};

export const match_type_field = {
  headerName: "Match Type",
  field: "match_type",
  width: 120,
  filter: "agTextColumnFilter",
  filterParams: {
    filterOptions: [
      "contains",
      "notContains",
      "startsWith",
      "endsWith",
      "equals",
    ],
    maxNumConditions: 1,
  },
};

export const selection_box = {
  field: "checkbox",
  headerCheckboxSelection: true,
  checkboxSelection: true,
  width: 35,
  resizable: false,
  filter: false,
};
export const keyword_state_field = {
  headerName: "Keyword State",
  field: "keywordState",
  cellRendererSelector: (params) => {
    return {
      component: KeywordStateRenderer,
    };
  },
  width: 36,
  resizable: false,
  filter: "agSetColumnFilter",
  filterParams: {
    values: ["enabled", "paused", "archived"],
  },
};
export const target_state_field = {
  headerName: "Target State",
  field: "targetState",
  cellRendererSelector: (params) => {
    return {
      component: KeywordStateRenderer,
    };
  },
  width: 36,
  resizable: false,
  filter: "agSetColumnFilter",
  filterParams: {
    values: ["enabled", "paused", "archived"],
  },
};

export const search_term_field = {
  headerName: "Search Term",
  field: "search_term",
  wrapText: true,
  autoHeight: true,
  filter: "agTextColumnFilter",
  filterParams: {
    filterOptions: [
      "contains",
      "notContains",
      "startsWith",
      "endsWith",
      "equals",
    ],
    maxNumConditions: 1,
  },
};

export const negative_keyword_state_field = {
  headerName: "Keyword State",
  field: "negativeKeywordState",
  cellRendererSelector: (params) => {
    return {
      component: NegativeKeywordStateRenderer,
    };
  },
  width: 36,
  resizable: false,
  filter: "agSetColumnFilter",
  filterParams: {
    values: ["enabled", "paused", "archived"],
  },
};
export const profile_channel = {
  headerName: "Channel",
  field: "marketplace",
  valueFormatter: (params) => getMarketPlace(params?.data?.marketplace),
  valueGetter: (params) => getMarketPlace(params?.data?.marketplace),
  filter: "agSetColumnFilter",
  filterParams: {
    values: ["Amazon.com", "Amazon.ca", "Amazon.com.mx", "Amazon.com.br"],
  },
};

function ProfileReportRefreshRate(params) {
  const [editing, setEditing] = useState(false);
  const [newRefreshRate, setNewRefreshRate] = useState(
    params?.data?.reportRefreshRate
  );
  const [saving, setSaving] = useState(false);
  function saveRefreshRate() {
    setSaving(true);
    if (isNaN(newRefreshRate)) {
      alert("Refresh rate must be a number");
      return;
    }
    if (newRefreshRate < 1) {
      alert("refresh rate must be >=1 hrs");
      return;
    }
    if (newRefreshRate === params?.data?.refreshRate) {
      setEditing(false);
    }
    updateProfileReportAdmin(params?.data?.profileId, newRefreshRate).then(
      (res) => {
        setSaving(false);
        if (res) {
          console.log("res is: ", res);
          console.log("refrate is: ", res.data.refreshRate);
          params.setValue(res.data.refreshRate);
        } else {
          if (res.errors.length == 1) {
            alert("ERROR UPDATING CAMPAIGN: " + JSON.stringify(res.errors));
          }
        }
        setEditing(false);
      }
    );
  }
  function handleRefreshRateChange(event) {
    const newRefreshVal = parseInt(event.target.value);
    setNewRefreshRate(newRefreshVal);
  }
  if (editing) {
    return (
      <div className="row">
        <div className="col-12">
          <InputGroup className="p-1">
            <InputGroup.Text>$</InputGroup.Text>
            <Form.Control
              onChange={handleRefreshRateChange}
              placeholder={params?.data?.reportRefreshRate}
              type="number"
            />
          </InputGroup>
        </div>
        <div className="col-12">
          <div className="row mb-2">
            <div className="col-6">
              <a
                className="text-primary"
                style={{ marginLeft: "20px" }}
                onClick={() => saveRefreshRate()}
                disabled={saving}
              >
                Save
              </a>
            </div>
            <div className="col-6">
              <a
                className="text-warning"
                style={{ marginRight: "20px" }}
                onClick={() => setEditing(false)}
                disabled={saving}
              >
                Cancel
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="row" onDoubleClick={() => setEditing(!editing)}>
      <div className="col-12">
        {params?.data?.reportRefreshRate != null ? (
          <InputGroup className="p-1">
            <InputGroup.Text>hrs</InputGroup.Text>
            <Form.Control
              value={params?.data?.reportRefreshRate}
              disabled={!editing}
            />
          </InputGroup>
        ) : (
          <span></span>
        )}
      </div>
    </div>
  );
}
function ProfileRefreshRate(params) {
  const [editing, setEditing] = useState(false);
  const [newRefreshRate, setNewRefreshRate] = useState(
    params?.data?.refreshRate
  );
  const [saving, setSaving] = useState(false);
  function saveRefreshRate() {
    setSaving(true);
    if (isNaN(newRefreshRate)) {
      alert("Refresh rate must be a number");
      return;
    }
    if (newRefreshRate < 1) {
      alert("refresh rate must be >=1 hrs");
      return;
    }
    if (newRefreshRate === params?.data?.refreshRate) {
      setEditing(false);
    }
    updateProfileAdmin(params?.data?.profileId, newRefreshRate).then((res) => {
      setSaving(false);
      if (res) {
        console.log("res is: ", res);
        console.log("refrate is: ", res.data.refreshRate);
        params.setValue(res.data.refreshRate);
      } else {
        if (res.errors.length == 1) {
          alert("ERROR UPDATING CAMPAIGN: " + JSON.stringify(res.errors));
        }
      }
      setEditing(false);
    });
  }
  function handleRefreshRateChange(event) {
    const newRefreshVal = parseInt(event.target.value);
    setNewRefreshRate(newRefreshVal);
  }
  if (editing) {
    return (
      <div className="row">
        <div className="col-12">
          <InputGroup className="p-1">
            <InputGroup.Text>$</InputGroup.Text>
            <Form.Control
              onChange={handleRefreshRateChange}
              placeholder={params?.data?.refreshRate}
              type="number"
            />
          </InputGroup>
        </div>
        <div className="col-12">
          <div className="row mb-2">
            <div className="col-6">
              <a
                className="text-primary"
                style={{ marginLeft: "20px" }}
                onClick={() => saveRefreshRate()}
                disabled={saving}
              >
                Save
              </a>
            </div>
            <div className="col-6">
              <a
                className="text-warning"
                style={{ marginRight: "20px" }}
                onClick={() => setEditing(false)}
                disabled={saving}
              >
                Cancel
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="row" onDoubleClick={() => setEditing(!editing)}>
      <div className="col-12">
        {params?.data?.refreshRate != null ? (
          <InputGroup className="p-1">
            <InputGroup.Text>hrs</InputGroup.Text>
            <Form.Control
              value={params?.data?.refreshRate}
              disabled={!editing}
            />
          </InputGroup>
        ) : (
          <span></span>
        )}
      </div>
    </div>
  );
}

const InlineFloatEditor = ({
  editing,
  setEditing,
  saving,
  value,
  newValue,
  saveValue,
  handleNewValueChange,
  editable,
  eGridCell,
}) => {
  useEffect(() => {
    if (editing) {
      eGridCell.classList.add("inline-cell-editing");
    } else {
      eGridCell.classList.remove("inline-cell-editing");
    }
  }, [editing]);

  return (
    <Container fluid className="p-0">
      <Row>
        <Col
          xs={12}
          className="d-flex align-items-center"
          style={{ paddingLeft: 20, paddingRight: 20 }}
        >
          {editing ? (
            <>
              <span style={{ marginRight: 2 }}>$</span>
              <input
                value={newValue}
                onChange={handleNewValueChange}
                className="opasity-grid-number-input"
                type="number"
                disabled={saving}
              />
              <button
                className="opasity-grid-circle-button opasity-grid-circle-button-cancel"
                onClick={() => setEditing(false)}
                disabled={saving}
              >
                <Cross />
              </button>
              <button
                className="opasity-grid-circle-button opasity-grid-circle-button-ok"
                onClick={() => saveValue()}
                disabled={saving}
              >
                <Tick />
              </button>
            </>
          ) : value ? (
            <>
              <span>{`$ ${value}`}</span>
              <div style={{ flexGrow: 1 }} />
              <Pencil
                style={{
                  cursor: editable ? "pointer" : "default",
                  filter: `opacity(${editable ? 1 : 0.3})`,
                }}
                onClick={() => {
                  if (editable) {
                    setEditing(true);
                  }
                }}
              />
            </>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

const CampaignDailyBudget = ({ data, setValue, eGridCell }) => {
  const [editing, setEditing] = useState(false);
  const [newBudget, setNewBudget] = useState(data.campaignDailyBudget);
  const [saving, setSaving] = useState(false);

  const editable = data.campaignState !== "archived";

  const saveBudget = () => {
    const budget = parseFloat(newBudget);

    if (budget < 0.02) {
      alert("Budget must be >=0.02");
      return;
    }

    if (budget == data.campaignDailyBudget) {
      setEditing(false);
      return;
    }

    setSaving(true);

    const campaigns = [
      {
        campaignId: data.campaignId,
        budget,
      },
    ];

    updateCampaign(data.profileId, campaigns)
      .then((res) => {
        if (res.success.length) {
          setValue(res.success[0].budget);
        } else if (res.errors.length) {
          alert(`ERROR UPDATING CAMPAIGN: ${JSON.stringify(res.errors)}`);
        }

        setSaving(false);
        setEditing(false);
      })
      .catch((err) => {
        alert(`ERROR UPDATING CAMPAIGN: ${err}`);
        setSaving(false);
      });
  };

  const handleBudgetChange = (event) => {
    setNewBudget(event.target.value);
  };

  return (
    <InlineFloatEditor
      editing={editing}
      setEditing={setEditing}
      saving={saving}
      value={data.campaignDailyBudget}
      newValue={newBudget}
      saveValue={saveBudget}
      handleNewValueChange={handleBudgetChange}
      editable={editable}
      eGridCell={eGridCell}
    />
  );
};

const KeywordBidCellRenderer = ({ data, setValue, eGridCell }) => {
  const [editing, setEditing] = useState(false);
  const [newBid, setNewBid] = useState(data.bid);
  const [saving, setSaving] = useState(false);

  const editable = data.keywordState !== "archived";

  const saveBid = () => {
    const bid = parseFloat(newBid);

    if (bid < 0.02) {
      alert("Bid must be >=0.02");
      return;
    }

    if (bid == data.bid) {
      setEditing(false);
      return;
    }

    setSaving(true);

    const keywords = [
      {
        keywordId: data.keywordId,
        bid,
      },
    ];

    updateKeyword(data.profileId, keywords)
      .then((res) => {
        if (res.success.length) {
          setValue(res.success[0].bid);
        } else if (res.errors.length) {
          alert(`ERROR UPDATING KEYWORD: ${JSON.stringify(res.errors)}`);
        }

        setSaving(false);
        setEditing(false);
      })
      .catch((err) => {
        alert(`ERROR UPDATING KEYWORD: ${err}`);
        setSaving(false);
      });
  };

  const handleBidChange = (event) => {
    setNewBid(event.target.value);
  };

  useEffect(() => {
    if (editing) {
      eGridCell.classList.add("inline-cell-editing");
    } else {
      eGridCell.classList.remove("inline-cell-editing");
    }
  }, [editing]);

  return (
    <InlineFloatEditor
      editing={editing}
      setEditing={setEditing}
      saving={saving}
      value={data.bid}
      newValue={newBid}
      saveValue={saveBid}
      handleNewValueChange={handleBidChange}
      editable={editable}
      eGridCell={eGridCell}
    />
  );
};
function GoalTargetRenderer(params) {
  const [val, setVal] = useState(params?.data?.goal_target);
  const [newVal, setNewVal] = useState(params?.data?.goal_target);
  const [isSaving, setIsSaving] = useState(false);
  function saveChange() {
    const asin = params?.data?.asin || params?.data?.advertised_asin;
    console.log("params data: ", params.data);
    setIsSaving(true);
    const payload = {
      profile_id: Number(params?.data?.profile_id),
      data: {
        goal_target: newVal,
      },
    };
    console.log(
      "grouped: ",
      params?.data?.grouped,
      " and advertised_asin: ",
      asin
    );
    if (params?.data?.grouped && !asin) {
      payload["parentAsin"] = params?.data?.parentAsin;
    } else {
      payload["asin"] = params?.data?.asin || params?.data?.advertised_asin;
    }
    updateCatalogItem(payload).then(
      (res) => {
        console.log("res is: ", res);
        setIsSaving(false);
        setVal(newVal);
      },
      (error) => {
        console.log("error: ", error);
        setIsSaving(false);
      }
    );
  }
  return params?.data?.advertised_sku ||
    params?.data?.sku ||
    params?.data?.campaignName ? (
    <Row></Row>
  ) : (
    <Row>
      <Col md={val != newVal ? "7" : "8"} style={{ paddingRight: 0 }}>
        <Form.Select onChange={(e) => setNewVal(e.target.value)}>
          <option value={null} selected={newVal == null}></option>
          <option value="clicks" selected={newVal == "clicks"}>
            Clicks
          </option>
          <option value="cost" selected={newVal == "cost"}>
            Ad Cost
          </option>
          <option value="orders" selected={newVal == "orders"}>
            Orders
          </option>
          <option value="sales" selected={newVal == "sales"}>
            Sales
          </option>
          <option value="acos" selected={newVal == "acos"}>
            ACOS
          </option>
          <option value="tacos" selected={newVal == "tacos"}>
            TACOS
          </option>
        </Form.Select>
      </Col>
      {val != newVal && (
        <Col md="2">
          <Button onClick={saveChange} disabled={isSaving}>
            Save
          </Button>
        </Col>
      )}
    </Row>
  );
}

const GoalValueRenderer = ({ data, eGridCell }) => {
  const [goalValue, setGoalValue] = useState(data?.goal_value || "");
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  const saveGoalValue = () => {
    setSaving(true);
    console.log("DATA IS: ", data);
    const asin = data.asin || data.advertised_asin;
    const payload = {
      profile_id: Number(data.profile_id),
      data: {
        goal_value: goalValue,
      },
    };
    if (data.grouped && !asin) {
      payload["parentAsin"] = data.parentAsin;
    } else {
      payload["asin"] = data.advertised_asin || data.asin;
    }
    updateCatalogItem(payload).then(
      (res) => {
        console.log("res is: ", res);
        setSaving(false);
        setEditing(false);
      },
      (error) => {
        console.log("error: ", error);
        setSaving(false);
      }
    );
  };

  return data.advertised_sku || data.sku || data.campaignName ? (
    <Row></Row>
  ) : (
    <Row>
      <Col
        xs={12}
        className="d-flex align-items-center"
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        {editing ? (
          <>
            <input
              value={goalValue}
              onChange={(e) => {
                console.log(
                  "cost tests: ",
                  e.target.value,
                  /^([1-9]\d*(\.|\,)\d*|0?(\.|\,)\d*[1-9]\d*|[1-9]\d*)$/.test(
                    e.target.value
                  )
                );
                if (
                  /^([1-9]\d*(\.|\,)\d*|0?(\.|\,)\d*[1-9]\d*|[1-9]\d*)$/.test(
                    e.target.value
                  )
                ) {
                  setGoalValue(e.target.value);
                }
              }}
              min="0"
              className="opasity-grid-number-input"
              disabled={!editing}
            />
            <button
              className="opasity-grid-circle-button opasity-grid-circle-button-cancel"
              onClick={() => {
                setGoalValue(data?.goal_value);
                setEditing(false);
              }}
              disabled={saving}
            >
              <Cross />
            </button>
            <button
              className="opasity-grid-circle-button opasity-grid-circle-button-ok"
              onClick={() => saveGoalValue()}
              disabled={saving}
            >
              <Tick />
            </button>
          </>
        ) : (
          <>
            <span>
              {data.goal_target === "cost" ? "$ " : ""}
              {goalValue ? `${goalValue}` : "N/A"}
              {data.goal_target === "acos" || data.goal_target === "tacos"
                ? " %"
                : ""}
            </span>
            <div style={{ flexGrow: 1 }} />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="17"
              viewBox="0 0 15 17"
              fill="none"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setEditing(true);
              }}
            >
              <path
                d="M14.3354 0.928057C13.9089 0.473858 13.3309 0.21875 12.7282 0.21875C12.1256 0.21875 11.5476 0.473858 11.1211 0.928057L0.918982 11.8106C0.626825 12.1205 0.395175 12.4891 0.237436 12.8951C0.079696 13.3011 -0.00100239 13.7364 9.3975e-06 14.1759V15.5496C9.3975e-06 15.7271 0.0660982 15.8973 0.183737 16.0228C0.301376 16.1482 0.460928 16.2187 0.627294 16.2187H1.91511C2.32709 16.22 2.7352 16.134 3.11583 15.9659C3.49646 15.7977 3.84204 15.5507 4.13256 15.2391L14.3354 4.35595C14.761 3.90108 15 3.28467 15 2.642C15 1.99934 14.761 1.38293 14.3354 0.928057ZM3.24558 14.293C2.89179 14.6679 2.41378 14.879 1.91511 14.8805H1.25458V14.1759C1.25394 13.9122 1.30235 13.651 1.397 13.4074C1.49164 13.1638 1.63065 12.9426 1.80596 12.7567L9.54854 4.4978L10.9913 6.03677L3.24558 14.293ZM13.4477 3.40982L11.8758 5.08729L10.433 3.55167L12.0056 1.87419C12.1003 1.77336 12.2128 1.69342 12.3365 1.63893C12.4602 1.58445 12.5927 1.55649 12.7265 1.55664C12.8604 1.5568 12.9928 1.58507 13.1164 1.63984C13.24 1.69461 13.3523 1.77481 13.4468 1.87586C13.5413 1.97691 13.6163 2.09683 13.6674 2.22878C13.7184 2.36072 13.7446 2.50211 13.7445 2.64486C13.7444 2.78761 13.7178 2.92893 13.6665 3.06076C13.6152 3.19258 13.54 3.31233 13.4452 3.41316L13.4477 3.40982Z"
                fill="#062238"
              />
            </svg>
          </>
        )}
      </Col>
    </Row>
  );
};

function CostRenderer(params) {
  const [cost, setCost] = useState(params?.data?.productCost || "");
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  function saveProductCost() {
    setIsSaving(true);
    console.log("DATA IS: ", params.data);
    updateCatalogItem(
      params?.data?.profile_id,
      params?.data?.asin,
      params?.data?.advertised_sku,
      { cost }
    ).then(
      (res) => {
        console.log("res is: ", res);
        setIsSaving(false);
        setIsEditing(false);
      },
      (error) => {
        console.log("error: ", error);
        setIsSaving(false);
      }
    );
  }
  return (
    <Row style={{ padding: 3 }}>
      {!isEditing && (
        <Col onDoubleClick={() => setIsEditing(true)}>
          <p onDoubleClick={() => setIsEditing(true)}>
            {cost ? `$ ${cost}` : "N/A"}
          </p>
        </Col>
      )}
      {isEditing && (
        <>
          <Col
            md={isEditing ? "12" : "10"}
            style={{ padding: 0 }}
            onClick={() => setIsEditing(true)}
          >
            <Form.Control
              value={cost}
              disabled={!isEditing}
              style={{ border: 0 }}
              onChange={(e) => {
                console.log(
                  "cost tests: ",
                  e.target.value,
                  /^([1-9]\d*(\.|\,)\d*|0?(\.|\,)\d*[1-9]\d*|[1-9]\d*)$/.test(
                    e.target.value
                  )
                );
                if (
                  /^([1-9]\d*(\.|\,)\d*|0?(\.|\,)\d*[1-9]\d*|[1-9]\d*)$/.test(
                    e.target.value
                  )
                ) {
                  setCost(e.target.value);
                }
              }}
              min="0"
            />
          </Col>
          <Col md="12" style={{ marginTop: 8 }}>
            <Button
              onClick={() => saveProductCost()}
              disabled={isSaving}
              variant="outline-primary"
              size="sm"
              style={{ paddingRight: 2, paddingLeft: 2, marginRight: 8 }}
            >
              Save
            </Button>
            <Button
              variant="outline-danger"
              style={{ paddingRight: 2, paddingLeft: 2 }}
              size="sm"
              onClick={() => {
                setCost(params?.data?.cost);
                setIsEditing(false);
              }}
            >
              Cancel
            </Button>
          </Col>
        </>
      )}
    </Row>
  );
}
export const keyword_bid_field = {
  headerName: "Bid",
  valueFormatter: currencyFormatter,
  field: "bid",
  aggFunc: "avg",
  cellRenderer: KeywordBidCellRenderer,
  autoHeight: true,
};

export const clicks_1_field = {
  field: "clicks_1",
  aggFunc: "sum",
  enableValue: true,
  hide: true,
  valueFormatter: numberFormatter,
};

export const impressions_1_field = {
  field: "impressions_1",
  aggFunc: "sum",
  enableValue: true,
  hide: true,
  valueFormatter: numberFormatter,
};

export const sales_1_field = {
  field: "sales1d_1",
  aggFunc: "sum",
  enableValue: true,
  hide: true,
  valueFormatter: numberFormatter,
};

export const attributedSalesSameSku1d_1_field = {
  field: "attributedSalesSameSku1d_1",
  valueFormatter: currencyFormatter,
  aggFunc: "sum",
  enableValue: true,
  hide: true,
};

export const purchases1d_1_field = {
  field: "purchases1d_1",
  aggFunc: "sum",
  enableValue: true,
  hide: true,
  valueFormatter: numberFormatter,
};

export const unitsSoldSameSku1d_1_field = {
  field: "unitsSoldSameSku1d_1",
  aggFunc: "sum",
  enableValue: true,
  hide: true,
  valueFormatter: numberFormatter,
};

export const purchasesSameSku1d_1_field = {
  field: "purchasesSameSku1d_1",
  aggFunc: "sum",
  enableValue: true,
  hide: true,
  valueFormatter: numberFormatter,
};

export const unitsSoldClicks1d_1_field = {
  field: "unitsSoldClicks1d_1",
  aggFunc: "sum",
  enableValue: true,
  hide: true,
  valueFormatter: numberFormatter,
};

export const campaign_type_field = {
  headerName: "Campaign Targeting Type",
  field: "campaignType",
  filter: null,
  width: 36,
  resizable: false,
  cellRendererSelector: (params) => {
    return {
      component: CampaignTypeRenderer,
    };
  },
};

export const campaign_state_field = {
  headerName: "Campaign State",
  field: "campaignState",
  width: 36,
  cellRendererSelector: (params) => {
    return {
      component: CampaignStateRenderer,
    };
  },
  resizable: false,
  filter: "agSetColumnFilter",
  filterParams: {
    // provide all days, even if days are missing in data!
    values: ["enabled", "paused", "archived"],
  },
};

export const campaign_daily_budget_field = {
  headerName: "Daily Budget",
  field: "campaignDailyBudget",
  aggFunc: "first",
  valueGetter: (params) => parseFloat(params?.data?.campaignDailyBudget),
  valueFormatter: currencyFormatter,
  cellRendererSelector: (params) => {
    return {
      component: CampaignDailyBudget,
    };
  },
  wrapText: true,
  autoHeight: true,
  suppressMovable: true,
};
export const profile_refresh_rate = {
  headerName: "Snapshot Refresh Rate",
  field: "refreshRate",
  aggFunc: "first",
  cellRendererSelector: (params) => {
    return {
      component: ProfileRefreshRate,
    };
  },
  wrapText: true,
  autoHeight: true,
};
export const profile_report_refresh_rate = {
  headerName: "Report Refresh Rate",
  field: "reportRefreshRate",
  aggFunc: "first",
  cellRendererSelector: (params) => {
    return {
      component: ProfileReportRefreshRate,
    };
  },
  wrapText: true,
  autoHeight: true,
};
export const profile_daily_budget = {
  headerName: "Daily Budget",
  field: "profileDailyBudget",
  valueFormatter: currencyFormatter,
};

export const campaign_premium_bid_adjustment_field = {
  headerName: "PBA",
  field: "premium_bid_adjustment",
  aggFunc: "first",
};
export const campaign_targeting_type_field = {
  headerName: "Targeting Type",
  field: "targeting_type",
  aggFunc: "first",
};
export const ad_group_state_field = {
  headerName: "Ad Group State",
  field: "adGroupState",
  aggFunc: "first",
  cellRendererSelector: (params) => {
    return {
      component: AdGroupStateRenderer,
    };
  },
  width: 36,
  resizable: false,
  filter: "agSetColumnFilter",
  filterParams: {
    values: ["enabled", "paused", "archived"],
  },
};

const cost_field = {
  headerName: "Spend",
  headerGroupComponent: HeaderRenderer,
  enableValue: true,
  children: [
    {
      hide: false,
      headerName: "current",
      field: "spent",
      valueFormatter: currencyFormatter,
      aggFunc: "sum",
      enableValue: true,
      width: 97,
    },
    {
      hide: false,
      headerName: "previous",
      field: "cost_1",
      aggFunc: "sum",
      enableValue: true,
      valueFormatter: currencyFormatter,
      columnGroupShow: "open",
      width: 97,
    },
    {
      hide: false,
      headerName: "difference",
      aggFunc: "sum",
      enableValue: true,
      columnGroupShow: "open",
      field: "cost_diff",
      valueFormatter: currencyFormatter,
      width: 97,
      aggFunc: "sum",
      enableValue: true,
      enableValue: true,
    },
    {
      hide: false,
      headerName: "difference %",
      aggFunc: "sum",
      enableValue: true,
      columnGroupShow: "open",
      valueFormatter: percentFormatter,
      field: "cost_diff_p",
      width: 97,
      aggFunc: "sum",
      enableValue: true,
      enableValue: true,
    },
  ],
};

const clicks_field = {
  headerGroupComponent: HeaderRenderer,
  enableValue: true,
  headerName: "Clicks",
  children: [
    {
      hide: false,
      headerName: "Current",
      field: "clicks",
      aggFunc: "sum",
      enableValue: true,
      valueFormatter: numberFormatter,
      width: 90,
    },
    {
      hide: false,
      headerName: "Previous",
      field: "clicks_1",
      aggFunc: "sum",
      enableValue: true,
      columnGroupShow: "open",
      valueFormatter: numberFormatter,
      width: 90,
    },
    {
      hide: false,
      headerName: "difference",
      field: "clicks_2",
      aggFunc: "sum",
      enableValue: true,
      columnGroupShow: "open",
      valueGetter: (params) => {
        const gotten_data = dataGetter(params);
        return gotten_data.clicks - gotten_data.clicks_1;
      },
      valueFormatter: numberFormatter,
      width: 90,
    },
    {
      hide: false,
      headerName: "difference %",
      field: "clicks_3",
      aggFunc: "sum",
      enableValue: true,
      columnGroupShow: "open",
      valueGetter: (params) => {
        const gotten_data = dataGetter(params);
        return gotten_data.clicks_diff_p;
      },
      valueFormatter: percentFormatter,
      width: 90,
    },
  ],
};

const impressions_field = {
  headerGroupComponent: HeaderRenderer,
  headerName: "Impressions",
  children: [
    {
      hide: false,
      headerName: "current",
      field: "impressions",
      aggFunc: "sum",
      enableValue: true,
      valueFormatter: numberFormatter,
      width: 120,
    },
    {
      hide: false,
      headerName: "previous",
      field: "impressions_1",
      aggFunc: "sum",
      enableValue: true,
      columnGroupShow: "open",
      valueFormatter: numberFormatter,
      width: 90,
    },
    {
      hide: false,
      headerName: "difference",
      aggFunc: "sum",
      enableValue: true,
      columnGroupShow: "open",
      valueFormatter: numberFormatter,
      field: "impressions_diff",
      width: 90,
    },
    {
      hide: false,
      headerName: "difference %",
      aggFunc: "sum",
      enableValue: true,
      columnGroupShow: "open",
      valueFormatter: percentFormatter,
      field: "impressions_diff_p",
      width: 90,
    },
  ],
};

const ctr_field_tt = {
  headerName: "current",
  hide: false,
  field: "ctr",
  valueFormatter: percentFormatter,
  valueGetter: (params) => params?.data?.ctr,
  width: 90,
  aggFunc: "sum",
  enableValue: true,
};

const ctr_field_tt_1 = {
  headerName: "previous",
  hide: false,
  columnGroupShow: "open",
  valueFormatter: percentFormatter,
  field: "ctr_1",
  valueGetter: (params) => params?.data?.ctr_1,
  width: 90,
  aggFunc: "sum",
  enableValue: true,
};
const ctr_field_tt_diff = {
  headerName: "difference",
  hide: false,
  columnGroupShow: "open",
  valueFormatter: percentFormatter,
  field: "ctr_diff",
  valueGetter: (params) => params?.data?.ctr_diff,
  width: 90,
  aggFunc: "sum",
  enableValue: true,
};
const ctr_field_tt_diff_p = {
  headerName: "difference %",
  hide: false,
  columnGroupShow: "open",
  valueFormatter: percentFormatter,
  field: "ctr_diff_p",
  valueGetter: (params) => params?.data?.ctr_diff_p,
  width: 90,
  aggFunc: "sum",
  enableValue: true,
};
const ctr_field = {
  headerName: "CTR",
  headerGroupComponent: HeaderRenderer,
  children: [
    ctr_field_tt,
    ctr_field_tt_1,
    ctr_field_tt_diff,
    ctr_field_tt_diff_p,
  ],
};
const cpc_field_tt = {
  headerName: "current",
  valueFormatter: currencyFormatter,
  hide: false,
  field: "cpc",
  valueGetter: (params) => params?.data?.cpc,
  width: 90,
  aggFunc: "sum",
  enableValue: true,
};

const cpc_field_tt_1 = {
  headerName: "previous",
  valueFormatter: currencyFormatter,
  hide: false,
  columnGroupShow: "open",
  field: "cpc_1",
  valueGetter: (params) => params?.data?.cpc_1,
  width: 90,
  aggFunc: "sum",
  enableValue: true,
};

const cpc_field_tt_diff = {
  headerName: "difference",
  valueFormatter: currencyFormatter,
  hide: false,
  columnGroupShow: "open",
  field: "cpc_diff",
  valueGetter: (params) => params?.data?.cpc_diff,
  width: 90,
  aggFunc: "sum",
  enableValue: true,
};
const cpc_field_tt_diff_p = {
  headerName: "difference %",
  valueFormatter: percentFormatter,
  hide: false,
  columnGroupShow: "open",
  field: "cpc_diff_p",
  valueGetter: (params) => params?.data?.cpc_diff_p,
  width: 90,
  aggFunc: "sum",
  enableValue: true,
};

const cpc_field = {
  headerName: "CPC",
  headerGroupComponent: HeaderRenderer,
  children: [
    cpc_field_tt,
    cpc_field_tt_1,
    cpc_field_tt_diff,
    cpc_field_tt_diff_p,
  ],
};

const cost_per_conv_tt = {
  headerName: "current",
  valueFormatter: currencyFormatter,
  hide: false,
  field: "so",
  valueGetter: (params) => params?.data?.so,
  width: 130,
  aggFunc: "sum",
  enableValue: true,
};

const cost_per_conv_tt_1 = {
  headerName: "previous",
  valueFormatter: currencyFormatter,
  hide: false,
  columnGroupShow: "open",
  field: "so_1",
  valueGetter: (params) => params?.data?.so_1,
  width: 90,
  aggFunc: "sum",
  enableValue: true,
};

const cost_per_conv_diff = {
  headerName: "difference",
  valueFormatter: currencyFormatter,
  hide: false,
  columnGroupShow: "open",
  field: "so_diff",
  valueGetter: (params) => params?.data?.so_diff,
  width: 90,
  aggFunc: "sum",
  enableValue: true,
};
const cost_per_conv_diff_p = {
  headerName: "difference %",
  valueFormatter: percentFormatter,
  hide: false,
  columnGroupShow: "open",
  field: "so_diff_p",
  valueGetter: (params) => params?.data?.so_diff_p,
  width: 90,
  aggFunc: "sum",
  enableValue: true,
};
const cost_per_conv = {
  headerName: "Spend/Orders",
  headerGroupComponent: HeaderRenderer,
  children: [
    cost_per_conv_tt,
    cost_per_conv_tt_1,
    cost_per_conv_diff,
    cost_per_conv_diff_p,
  ],
  hidden: true,
};

const roas_tt = {
  headerName: "current",
  hide: false,
  field: "roas",
  valueGetter: (params) => params?.data?.roas,
  width: 90,
  valueFormatter: percentFormatter,
  aggFunc: "sum",
  enableValue: true,
};

const roas_tt_1 = {
  headerName: "previous",
  hide: false,
  columnGroupShow: "open",
  field: "roas_1",
  valueGetter: (params) => params?.data?.roas_1,
  width: 90,
  valueFormatter: percentFormatter,
  aggFunc: "sum",
  enableValue: true,
};

const roas_tt_diff = {
  headerName: "difference",
  hide: false,
  columnGroupShow: "open",
  field: "roas_diff",
  valueGetter: (params) => params?.data?.roas_diff,
  width: 90,
  valueFormatter: percentFormatter,
  aggFunc: "sum",
  enableValue: true,
};

const roas_tt_diff_p = {
  headerName: "difference %",
  hide: false,
  columnGroupShow: "open",
  field: "roas_diff_p",
  valueGetter: (params) => params?.data?.roas_diff_p,
  width: 90,
  valueFormatter: percentFormatter,
  aggFunc: "sum",
  enableValue: true,
};

const roas = {
  headerName: "ROAS",
  headerGroupComponent: HeaderRenderer,
  children: [roas_tt, roas_tt_1, roas_tt_diff, roas_tt_diff_p],
  hidden: true,
};

const acos_tt = {
  headerName: "current",
  hide: false,
  field: "acos",
  valueGetter: (params) => params?.data?.acos,
  width: 90,
  valueFormatter: percentFormatter,
  aggFunc: "sum",
  enableValue: true,
};

const acos_tt_1 = {
  headerName: "previous",
  hide: false,
  columnGroupShow: "open",
  field: "acos_1",
  valueGetter: (params) => params?.data?.acos_1,
  width: 90,
  valueFormatter: percentFormatter,
  aggFunc: "sum",
  enableValue: true,
};

const acos_tt_diff = {
  headerName: "difference",
  hide: false,
  columnGroupShow: "open",
  field: "acos_diff",
  valueGetter: (params) => params?.data?.acos_diff,
  width: 90,
  valueFormatter: percentFormatter,
  aggFunc: "sum",
  enableValue: true,
};

const acos_tt_diff_p = {
  headerName: "difference %",
  hide: false,
  columnGroupShow: "open",
  field: "acos_diff_p",
  valueGetter: (params) => params?.data?.acos_diff_p,
  width: 90,
  valueFormatter: percentFormatter,
  aggFunc: "sum",
  enableValue: true,
};

const acos = {
  headerName: "ACOS",
  headerGroupComponent: HeaderRenderer,
  children: [acos_tt, acos_tt_1, acos_tt_diff, acos_tt_diff_p],
};

const conv_rate_tt = {
  headerName: "current",
  valueFormatter: percentFormatter,
  hide: false,
  field: "osr",
  valueGetter: (params) => params?.data?.osr,
  width: 120,
  aggFunc: "sum",
  enableValue: true,
};

const conv_rate_tt_1 = {
  headerName: "previous",
  valueFormatter: percentFormatter,
  hide: false,
  columnGroupShow: "open",
  field: "osr_1",
  valueGetter: (params) => params?.data?.osr_1,
  width: 90,
  aggFunc: "sum",
  enableValue: true,
};

const conv_rate_tt_diff = {
  headerName: "difference",
  valueFormatter: percentFormatter,
  hide: false,
  columnGroupShow: "open",
  field: "osr_diff",
  valueGetter: (params) => params?.data?.osr_diff,
  width: 90,
  aggFunc: "sum",
  enableValue: true,
};

const conv_rate_tt_diff_p = {
  headerName: "difference %",
  valueFormatter: percentFormatter,
  hide: false,
  columnGroupShow: "open",
  field: "osr_diff_p",
  valueGetter: (params) => params?.data?.osr_diff_p,
  width: 90,
  aggFunc: "sum",
  enableValue: true,
};

const conv_rate = {
  headerName: "Orders. Rate",
  headerGroupComponent: HeaderRenderer,
  children: [
    conv_rate_tt,
    conv_rate_tt_1,
    conv_rate_tt_diff,
    conv_rate_tt_diff_p,
  ],
};

const sales1d = {
  field: "sales1d",
  headerName: "current",
  valueFormatter: currencyFormatter,
  aggFunc: "sum",
  enableValue: true,
  width: 95,
};
const sales1d_1 = {
  field: "sales1d_1",
  headerName: "previous",
  columnGroupShow: "open",
  valueFormatter: currencyFormatter,
  aggFunc: "sum",
  enableValue: true,
  width: 95,
};
const sales1d_diff = {
  hide: false,
  headerName: "difference",
  valueFormatter: currencyFormatter,
  columnGroupShow: "open",
  field: "sales1d_diff",
  width: 95,
  aggFunc: "sum",
  enableValue: true,
};
const sales1d_diff_p = {
  hide: false,
  headerName: "difference %",
  valueFormatter: percentFormatter,
  columnGroupShow: "open",
  field: "sales1d_diff_p",
  width: 95,
  aggFunc: "sum",
  enableValue: true,
};
const sales1d_field = {
  headerName: "Sales",
  headerGroupComponent: HeaderRenderer,
  children: [sales1d, sales1d_1, sales1d_diff, sales1d_diff_p],
};

const organic_sales = {
    field: "organic_sales",
    headerName: "current",
    valueFormatter: currencyFormatter,
    aggFunc: "sum",
    enableValue: true,
    width: 130,
    valueGetter: (params) => params?.data?.organic_sales || "-",
};
const organic_sales_1 = {
    field: "organic_sales_1",
    headerName: "previous",
    columnGroupShow: "open",
    valueFormatter: currencyFormatter,
    aggFunc: "sum",
    enableValue: true,
    width: 95,
    valueGetter: (params) => params?.data?.organic_sales_1 || "-",
};
const organic_sales_diff = {
    hide: false,
    headerName: "difference",
    valueFormatter: currencyFormatter,
    columnGroupShow: "open",
    field: "organic_sales_diff",
    width: 95,
    aggFunc: "sum",
    enableValue: true,
    valueGetter: (params) => params?.data?.organic_sales_diff || "-",
};
const organic_sales_diff_p = {
    hide: false,
    headerName: "difference %",
    valueFormatter: percentFormatter,
    columnGroupShow: "open",
    field: "organic_sales_diff_p",
    width: 95,
    aggFunc: "sum",
    enableValue: true,
    valueGetter: (params) => params?.data?.organic_sales_diff_p || "-",
};
export const organic_sales_field = {
    headerName: "Organic Sales",
    headerGroupComponent: HeaderRenderer,
    children: [organic_sales, organic_sales_1, organic_sales_diff, organic_sales_diff_p],
};

const attributedSalesSameSku1d_tt = {
  field: "attributedSalesSameSku1d",
  headerName: "attributedSalesSameSku1d",
  valueFormatter: currencyFormatter,
  aggFunc: "sum",
  enableValue: true,
};
const attributedSalesSameSku1d_tt_1 = {
  field: "attributedSalesSameSku1d_1",
  headerName: "attributedSalesSameSku1d VS",
  valueFormatter: currencyFormatter,
  columnGroupShow: "open",
  aggFunc: "sum",
  enableValue: true,
};
const attributedSalesSameSku1d_tt_diff = {
  hide: false,
  headerName: "attributedSalesSameSku1d Diff",
  valueFormatter: currencyFormatter,
  columnGroupShow: "open",
  field: "attributedSalesSameSku1d_diff",
};
const attributedSalesSameSku1d_tt_diff_p = {
  hide: false,
  headerName: "Diff %",
  valueFormatter: percentFormatter,
  columnGroupShow: "open",
  field: "attributedSalesSameSku1d_diff_p",
};

export const attributedSalesSameSku1d_field = {
  headerName: "attributedSalesSameSku1d",
  headerGroupComponent: HeaderRenderer,
  children: [
    attributedSalesSameSku1d_tt,
    attributedSalesSameSku1d_tt_1,
    attributedSalesSameSku1d_tt_diff,
    attributedSalesSameSku1d_tt_diff_p,
  ],
};

const purchases1d = {
  field: "purchases1d",
  headerName: "current",
  aggFunc: "sum",
  enableValue: true,
  valueFormatter: numberFormatter,
  width: 90,
};
const purchases1d_1 = {
  field: "purchases1d_1",
  headerName: "previous",
  columnGroupShow: "open",
  aggFunc: "sum",
  enableValue: true,
  valueFormatter: numberFormatter,
  width: 90,
};
const purchases1d_diff = {
  hide: false,
  headerName: "difference",
  columnGroupShow: "open",
  valueFormatter: numberFormatter,
  field: "purchases1d_diff",
  width: 90,
  aggFunc: "sum",
  enableValue: true,
};
const purchases1d_diff_p = {
  hide: false,
  headerName: "difference %",
  columnGroupShow: "open",
  valueFormatter: percentFormatter,
  field: "purchases1d_diff_p",
  width: 90,
  aggFunc: "sum",
  enableValue: true,
};

const purchases1d_field = {
  headerName: "Orders",
  headerGroupComponent: HeaderRenderer,
  children: [purchases1d, purchases1d_1, purchases1d_diff, purchases1d_diff_p],
};

const unitsSoldSameSku1d_field_tt = {
  field: "unitsSoldSameSku1d",
  enableValue: true,
  aggFunc: "sum",
  enableValue: true,
  valueFormatter: numberFormatter,
};

const unitsSoldSameSku1d_field_tt_1 = {
  field: "unitsSoldSameSku1d_1",
  headerName: "unitsSoldSameSku1d VS",
  enableValue: true,
  aggFunc: "sum",
  enableValue: true,
  columnGroupShow: "open",
  valueFormatter: numberFormatter,
};

const unitsSoldSameSku1d_field_tt_diff = {
  hide: false,
  headerName: "unitsSoldSameSku1d Diff",
  columnGroupShow: "open",
  valueFormatter: numberFormatter,
  field: "unitsSoldSameSku1d_diff",
};

export const unitsSoldSameSku1d_field = {
  headerName: "unitsSoldSameSku1d",
  headerGroupComponent: HeaderRenderer,
  children: [
    unitsSoldSameSku1d_field_tt,
    unitsSoldSameSku1d_field_tt_1,
    unitsSoldSameSku1d_field_tt_diff,
  ],
};

const purchasesSameSku1d_field_tt = {
  field: "purchasesSameSku1d",
  enableValue: true,
  aggFunc: "sum",
  enableValue: true,
};

const purchasesSameSku1d_field_tt_1 = {
  field: "purchasesSameSku1d_1",
  enableValue: true,
  headerName: "purchasesSameSku1d VS",
  aggFunc: "sum",
  enableValue: true,
  columnGroupShow: "open",
};

const purchasesSameSku1d_field_tt_diff = {
  hide: false,
  headerName: "purchasesSameSku1d Diff",
  columnGroupShow: "open",
  field: "purchasesSameSku1d_diff",
};
export const purchasesSameSku1d_field = {
  headerName: "purchasesSameSku1d",
  headerGroupComponent: HeaderRenderer,
  children: [
    purchasesSameSku1d_field_tt,
    purchasesSameSku1d_field_tt_1,
    purchasesSameSku1d_field_tt_diff,
  ],
};

const unitsSoldClicks1d_field_tt = {
  field: "unitsSoldClicks1d",
  enableValue: true,
  aggFunc: "sum",
  enableValue: true,
  valueFormatter: numberFormatter,
};

const unitsSoldClicks1d_field_tt_1 = {
  field: "unitsSoldClicks1d_1",
  enableValue: true,
  headerName: "unitsSoldClicks1d VS",
  aggFunc: "sum",
  enableValue: true,
  columnGroupShow: "open",
  valueFormatter: numberFormatter,
};

const unitsSoldClicks1d_field_tt_diff = {
  hide: false,
  headerName: "unitsSoldClicks1d Diff",
  columnGroupShow: "open",
  valueFormatter: numberFormatter,
  field: "unitsSoldClicks1d_diff",
};

export const unitsSoldClicks1d_field = {
  headerName: "unitsSoldClicks1d",
  headerGroupComponent: HeaderRenderer,
  children: [
    unitsSoldClicks1d_field_tt,
    unitsSoldClicks1d_field_tt_1,
    unitsSoldClicks1d_field_tt_diff,
  ],
};

export const ad_group_name_field = {
  headerName: "AdGroup Name",
  field: "adGroupName",
  width: 140,
  valueGetter: (params) => {
    return `${params?.data?.adGroupName}`;
  },
  cellRendererSelector: (params) => {
    return {
      component: AdGroupRenderer,
    };
  },
  wrapText: true,
  autoHeight: true,
  filter: "agTextColumnFilter",
  filterParams: {
    filterOptions: [
      "contains",
      "notContains",
      "startsWith",
      "endsWith",
      "equals",
    ],
    maxNumConditions: 1,
  },
  aggFunc: "sum",
  enableValue: true,
};

export const campaign_name_field = {
  headerName: "Campaign Name",
  field: "campaignName",
  valueGetter: (params) => {
    return Number(params?.data?.campaignId);
  },
  cellRendererSelector: (params) => {
    return {
      component: CampaignRenderer,
    };
  },
  wrapText: true,
  autoHeight: true,
  filter: "agTextColumnFilter",
  filterParams: {
    filterOptions: [
      "contains",
      "notContains",
      "startsWith",
      "endsWith",
      "equals",
    ],
    maxNumConditions: 1,
  },
  enableValue: true,
};

export const profile_name_field = {
  headerName: "Profile",
  field: "profileName",
  valueGetter: (params) => {
    return `${params?.data?.profileName}`;
  },
  cellRendererSelector: (params) => {
    return {
      component: ProfileRenderer,
    };
  },
  filter: "agTextColumnFilter",
  filterParams: {
    filterOptions: [
      "contains",
      "notContains",
      "startsWith",
      "endsWith",
      "equals",
    ],
    maxNumConditions: 1,
  },
};

export const advertised_asin_field = {
  headerName: "Advertised Asin",
  field: "asin",
  width: 140,
  filter: "agTextColumnFilter",
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  aggFunc: "sum",
  enableValue: true,
};

export const advertised_sku_field = {
  headerName: "Advertised Sku",
  field: "sku",
  width: 150,
  filter: "agTextColumnFilter",
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  enableValue: true,
};

export const parent_asin_field = {
  headerName: "Parent Asin",
  field: "parentAsin",
  width: 150,
  filter: "agTextColumnFilter",
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  aggFunc: "sum",
  enableValue: true,
};

export const common_fields = [
  cost_field,
  clicks_field,
  impressions_field,
  ctr_field,
  cpc_field,
  purchases1d_field,
  conv_rate,
  sales1d_field,
  cost_per_conv,
  roas,
  acos,
];

const orders = {
  field: "qty",
  headerName: "current",
  aggFunc: "sum",
  enableValue: true,
  valueFormatter: numberFormatter,
  width: 90,
  valueGetter: (params) => params?.data?.qty || "-",
};
const orders_1 = {
  field: "qty_1",
  headerName: "previous",
  columnGroupShow: "open",
  aggFunc: "sum",
  enableValue: true,
  valueFormatter: numberFormatter,
  width: 90,
  valueGetter: (params) => params?.data?.qty_1 || "-",
};
const orders_diff = {
  hide: false,
  headerName: "difference",
  columnGroupShow: "open",
  valueFormatter: numberFormatter,
  field: "qty_diff",
  width: 90,
  valueGetter: (params) => params?.data?.qty_diff || "-",
  aggFunc: "sum",
  enableValue: true,
};
const orders_diff_p = {
  hide: false,
  headerName: "difference %",
  columnGroupShow: "open",
  valueFormatter: percentFormatter,
  field: "qty_diff_p",
  width: 90,
  valueGetter: (params) => params?.data?.qty_diff_p || "-",
  aggFunc: "sum",
  enableValue: true,
};

export const orders_field = {
  headerName: "Total Orders",
  headerGroupComponent: HeaderRenderer,
  children: [orders, orders_1, orders_diff, orders_diff_p],
};

const revenue = {
  field: "total_income",
  headerName: "current",
  valueFormatter: currencyFormatter,
  aggFunc: "sum",
  enableValue: true,
  width: 95,
  valueGetter: (params) => params?.data?.total_income || "-",
};
const revenue_1 = {
  field: "total_income_1",
  headerName: "previous",
  columnGroupShow: "open",
  valueFormatter: currencyFormatter,
  aggFunc: "sum",
  enableValue: true,
  width: 95,
  valueGetter: (params) => params?.data?.total_income_1 || "-",
};
const revenue_diff = {
  hide: false,
  headerName: "difference",
  valueFormatter: currencyFormatter,
  columnGroupShow: "open",
  field: "total_income_diff",
  width: 95,
  valueGetter: (params) => params?.data?.total_income_diff || "-",
  aggFunc: "sum",
  enableValue: true,
};
const revenue_diff_p = {
  hide: false,
  headerName: "difference %",
  valueFormatter: percentFormatter,
  columnGroupShow: "open",
  field: "total_income_diff_p",
  width: 95,
  valueGetter: (params) => params?.data?.total_income_diff_p || "-",
  aggFunc: "sum",
  enableValue: true,
};

export const revenue_field = {
  headerName: "Total Revenue",
  headerGroupComponent: HeaderRenderer,
  children: [revenue, revenue_1, revenue_diff, revenue_diff_p],
};

const tacos = {
  field: "tacos",
  headerName: "current",
  valueFormatter: percentFormatter,
  aggFunc: "sum",
  enableValue: true,
  width: 95,
  valueGetter: (params) => params?.data?.tacos || "-",
};
const tacos_1 = {
  field: "tacos_1",
  headerName: "previous",
  columnGroupShow: "open",
  valueFormatter: percentFormatter,
  aggFunc: "sum",
  enableValue: true,
  width: 95,
  valueGetter: (params) => params?.data?.tacos_1 || "-",
};
const tacos_diff = {
  hide: false,
  headerName: "difference",
  valueFormatter: percentFormatter,
  columnGroupShow: "open",
  field: "tacos_diff",
  width: 95,
  valueGetter: (params) => params?.data?.tacos_diff || "-",
  aggFunc: "sum",
  enableValue: true,
};
const tacos_diff_p = {
  hide: false,
  headerName: "difference %",
  valueFormatter: percentFormatter,
  columnGroupShow: "open",
  field: "tacos_diff_p",
  width: 95,
  valueGetter: (params) => params?.data?.tacos_diff_p || "-",
  aggFunc: "sum",
  enableValue: true,
};

export const tacos_field = {
  headerName: "TACoS",
  headerGroupComponent: HeaderRenderer,
  children: [tacos, tacos_1, tacos_diff, tacos_diff_p],
};

export const goal_vals_p = {
  headerName: "Goal Score %",
  headerGroupComponent: HeaderRenderer,
  children: [
    {
      headerName: "current",
      field: "goalReachPercent",
      valueGetter: (params) => minOneToBlank(params?.data?.goalReachPercent),
      valueFormatter: percentFormatter,
      width: 95,
      aggFunc: "sum",
      enableValue: true,
    },
    {
      headerName: "previous",
      field: "goalReachPercent_1",
      valueGetter: (params) => minOneToBlank(params?.data?.goalReachPercent_1),
      valueFormatter: percentFormatter,
      width: 95,
      columnGroupShow: "open",
      aggFunc: "sum",
      enableValue: true,
    },
    {
      headerName: "difference",
      field: "goalReachDiffPercent",
      valueGetter: (params) =>
        minOneToBlank(params?.data?.goalReachDiffPercent),
      valueFormatter: percentFormatter,
      width: 95,
      columnGroupShow: "open",
      aggFunc: "sum",
      enableValue: true,
    },
    {
      headerName: "difference %",
      field: "goalReachDiffPercentP",
      valueGetter: (params) =>
        minOneToBlank(params?.data?.goalReachDiffPercentP),
      valueFormatter: percentFormatter,
      width: 95,
      columnGroupShow: "open",
      aggFunc: "sum",
      enableValue: true,
    },
  ],
};
export const goal_vals = {
  headerName: "Goal Score",
  headerGroupComponent: HeaderRenderer,
  children: [
    {
      headerName: "current",
      field: "goalReach",
      valueGetter: (params) => minOneToBlank(params?.data?.goalReach),
      width: 95,
      aggFunc: "sum",
      enableValue: true,
    },
    {
      headerName: "previous",
      field: "goalReach_1",
      valueGetter: (params) => minOneToBlank(params?.data?.goalReach_1),
      width: 95,
      columnGroupShow: "open",
      aggFunc: "sum",
      enableValue: true,
    },

    {
      headerName: "difference",
      field: "goalReachDiff",
      valueGetter: (params) => minOneToBlank(params?.data?.goalReachDiff),
      width: 95,
      columnGroupShow: "open",
      aggFunc: "sum",
      enableValue: true,
    },
    {
      headerName: "difference %",
      field: "goalReachDiffP",
      valueGetter: (params) => minOneToBlank(params?.data?.goalReachDiffP),
      width: 95,
      columnGroupShow: "open",
      valueFormatter: percentFormatter,
      aggFunc: "sum",
      enableValue: true,
    },
  ],
};
function minOneToBlank(data) {
  if (!data || data == -1) {
    return "-";
  }
  return data.toFixed(2);
}

export const goal_type = {
  headerName: "Goal Type",
  valueGetter: (params) => params?.data?.goal_target,
  field: "goalTarget",
  minWidth: 120,
  cellRendererSelector: (params) => {
    return {
      component: GoalTargetRenderer,
    };
  },
  autoHeight: true,
  filterParams: {
    filterOptions: ["contains"],
    maxNumConditions: 1,
  },
  aggFunc: "sum",
  enableValue: true,
};
export const goal_value = {
  headerName: "Goal Value",
  valueGetter: (params) => params?.data?.goal_value,
  field: "goalValue",
  maxWidth: 180,
  cellRendererSelector: (params) => {
    return {
      component: GoalValueRenderer,
    };
  },
  aggFunc: "sum",
  enableValue: true,
};
