import { Container } from "react-bootstrap";

import Pagination from "components/opasity/Pagination";

import useFooter from "hooks/useFooter";

const Footer = () => {
  const { pagination } = useFooter();

  return (
    <footer className="footer d-flex align-items-center" style={{backgroundColor:"white !"}}>
      <ul
        className="list-inline d-flex align-items-center"
        style={{ paddingLeft: 24 }}
      >
        <li className="list-inline-item">
          <span className="text-muted " href="#">
            Support
          </span>
        </li>
        <li className="list-inline-item">
          <span className="text-muted" href="#">
            Help Center
          </span>
        </li>
        <li className="list-inline-item">
          <span className="text-muted" href="#">
            Privacy
          </span>
        </li>
        <li className="list-inline-item">
          <span className="text-muted" href="#">
            Terms of Service
          </span>
        </li>
      </ul>
      
      <div
        style={{ flexGrow: 10, borderTop: "1px solid #DEE6ED", height: 50 }}
      />
      {pagination ? (
        <div
          style={{
            flexGrow: 1,
            height: 50,
            borderTop: "1px solid #BABFC7",
            background: "white",
          }}
        >
          <Pagination {...pagination} />
        </div>
      ) : null}
      <div
        style={{ flexGrow: 10, borderTop: "1px solid #DEE6ED", height: 50 }}
      />
      <div
        className="d-flex flex-row-reverse align-items-center"
        style={{
          borderTop: "1px solid #DEE6ED",
          paddingRight: 24,
          height: 50,
        }}
      >
        <span href="/" className="text-muted">
          &copy; {`${new Date().getFullYear()} - Opasity`}
        </span>
        <span href="/" className="text-muted mx-2">
          |
        </span>
        <span href="/" className="text-muted">
          All Rights Reserved
        </span>
      </div>
    </footer>
  );
};

export default Footer;
