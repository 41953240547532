import { Link, useParams } from "react-router-dom";

import { Dropdown } from "react-bootstrap";
import { Settings } from "react-feather";

import avatar1 from "assets/img/avatars/avatar.jpg";
import useAuth from "hooks/useAuth";

import { useState } from 'react';

const NavbarUser = () => {
  const { user, signOut } = useAuth();
  const { profileId } = useParams();

    var initialMaskMode = false;
    if (localStorage.getItem('maskMode') !== null) {
        initialMaskMode = JSON.parse(localStorage.getItem('maskMode'));
    }

    const [maskMode, setMaskMode] = useState(initialMaskMode);
    const toggleMaskMode = () => {
        localStorage.setItem('maskMode', !maskMode)
        setMaskMode(!maskMode);
    };
    

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none" style={{ height: "100%" }}>
        <Dropdown.Toggle
          as="a"
          className="nav-link d-flex align-items-center"
          style={{ height: "100%" }}
        >
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block" style={{ height: "100%" }}>
        <Dropdown.Toggle
          as="a"
          className="nav-link d-flex align-items-center profile-dropdown"
          style={{ height: "100%", paddingRight: 24 }}
              >

            {maskMode ? <>
                <span className="me-3 badge bg-warning">
                    Mask Mode
                </span>
            </> : <></>}

          <img
            src={avatar1}
            className="avatar img-fluid rounded-circle me-1"
            alt="Chris Wood"
          />
          <span className="text-dark">{user.displayName}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu
        style={{
          border: "none",
          background: "#ffffff",
          borderRadius: "0px 0px 5px 5px",
        }}
      >
        {profileId ? (
          <Dropdown.Item
            as={Link}
            to={`/profiles/${profileId}/catalog`}
            style={{ color: "var(--back-dark)" }}
          >
            Catalog Manager
          </Dropdown.Item>
        ) : null}
        <Dropdown.Item
          as={Link}
          to="/settings"
          style={{ color: "var(--back-dark)" }}
        >
          Settings
        </Dropdown.Item>
        <Dropdown.Item
          onClick={toggleMaskMode}
          style={{ color: "var(--back-dark)" }}
        >
          {maskMode ? 'Disable Mask Mode' : 'Enable Mask Mode'}
        </Dropdown.Item>
        <Dropdown.Item onClick={signOut} style={{ color: "var(--back-dark)" }}>
          Sign out
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
