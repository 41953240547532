import api from "./Ax";

export async function updateCatalogItem(data) {
  const payload = {
    action: "update_catalog_item",
    ...data,
  };
  console.log("payload is: ", payload);
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/manager",
    payload
  );
  console.log("upadted cataloh item: ", result);
  return result.data;
}

export async function updateCatalogItems(profile_id, items) {
  const payload = {
    action: "update_catalog_items",
    profile_id,
    items,
  };
  console.log("payload is: ", payload);
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/manager/",
    payload
  );
  console.log("upadted cataloh item: ", result);
  return result.data;
}
