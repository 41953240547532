export const LoadingOverlay = ({ loadingMessage }) => {
  return (
    <div
      className="ag-overlay-loading-center"
      style={{ backgroundColor: "lightsteelblue", height: "9%" }}
    >
      <i className="fas fa-hourglass-half"> {loadingMessage} </i>
    </div>
  );
};
