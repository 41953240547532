import { number } from "yup";
import api from "./Ax";
import { saveToLocalSession, loadFromLocalSession } from "./utils";

export async function get_campaign_targeting(
  profileId,
  adGroupId,
  campaignId,
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  pageSize,
  page,
  orderBy,
  filterBy,
  search
) {
  console.log("got campaignid: ", campaignId);
  const query = {
    action: "get_keywords_data",
    profileId: Number(profileId),
    adgroup_id: Number(adGroupId),
    campaign_id: Number(campaignId),
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    pageSize,
    page,
    orderBy,
    filterBy,
    search,
  };
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  return result.data;
}
export async function get_campaign_targeting_count(
  profileId,
  adGroupId,
  campaignId,
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  pageSize,
  orderBy,
  filterBy,
  search
) {
  console.log("got campaignid: ", campaignId);
  const query = {
    action: "get_keywords_data",
    profileId: Number(profileId),
    adgroup_id: Number(adGroupId),
    campaign_id: Number(campaignId),
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    pageSize,
    orderBy,
    filterBy,
    getCount: true,
    search,
  };
  const cached = loadFromLocalSession(JSON.stringify(query));
  if (cached && cached.data) {
    return cached.data;
  }
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  saveToLocalSession(JSON.stringify(query), result);
  return result.data;
}

export async function get_campaign_targeting_summary(
  profileId,
  adGroupId,
  campaignId,
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  filterBy,
  search
) {
  const query = {
    action: "get_keywords_data_summary",
    profileId: Number(profileId),
    adgroup_id: Number(adGroupId),
    campaign_id: Number(campaignId),
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    filterBy,
    search,
  };
  const cached = loadFromLocalSession(JSON.stringify(query));
  if (cached && cached.data) {
    return cached.data;
  }
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  saveToLocalSession(JSON.stringify(query), result);
  return result.data;
}

export async function get_auto_targeting(
  profileId,
  adGroupId,
  campaignId,
  expression,
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  pageSize,
  page,
  orderBy,
  filterBy,
  search
) {
  console.log("got campaignid: ", campaignId);
  const query = {
    action: "get_auto_targeting_data",
    profileId: Number(profileId),
    adgroup_id: Number(adGroupId),
    campaign_id: Number(campaignId),
    expression,
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    pageSize,
    page,
    orderBy,
    filterBy,
    search,
  };
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  return result.data;
}
export async function get_auto_targeting_count(
  profileId,
  adGroupId,
  campaignId,
  expression,
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  pageSize,
  filterBy,
  search
) {
  console.log("got campaignid: ", campaignId);
  const query = {
    action: "get_auto_targeting_data",
    profileId: Number(profileId),
    adgroup_id: Number(adGroupId),
    campaign_id: Number(campaignId),
    expression,
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    pageSize,
    filterBy,
    getCount: true,
    search,
  };
  const cached = loadFromLocalSession(JSON.stringify(query));
  if (cached && cached.data) {
    return cached.data;
  }
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  saveToLocalSession(JSON.stringify(query), result);
  return result.data;
}
export async function get_auto_targeting_summary(
  profileId,
  adGroupId,
  campaignId,
  expression,
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  filterBy,
  search
) {
  console.log("got campaignid: ", campaignId);
  const query = {
    action: "get_auto_targeting_summary",
    profileId: Number(profileId),
    adgroup_id: Number(adGroupId),
    campaign_id: Number(campaignId),
    expression,
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    filterBy,
    search,
  };
  const cached = loadFromLocalSession(JSON.stringify(query));
  if (cached && cached.data) {
    return cached.data;
  }
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  saveToLocalSession(JSON.stringify(query), result);
  return result.data;
}
