import { useEffect, useState, useRef } from "react";
import { useParams, NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Tab,
} from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import { common_fields } from "pages/opasity/opasityApi/ag_grid_fields";
import { get_campaigns_data } from "pages/opasity/opasityApi/campaignPlacementData";

import { pickerToNorm, loadDate } from "pages/opasity/opasityApi/utils";

import {
  agGridOptions,
  restoreColumnsState,
  restoreSortState,
  onDragStarted,
  onDragStopped,
  onColumnMoved as onColumnMovedCommon,
  onSortChanged as onSortChangedCommon,
  onColumnVisible as onColumnVisibleCommon,
  onRowDataUpdated as onRowDataUpdatedCommon,
  toggleColumnsPanelShow,
  exportToCSV,
  customizeToolPanel,
} from "utils/agGridCommon";

import DateModal from "components/opasity/DateModal";
import ExportButton from "components/opasity/ExportButton";
import ColumnsButton from "components/opasity/ColumnsButton";
import TabNav from "components/opasity/TabNav";

import useNavbar from "hooks/useNavbar";
import useFooter from "hooks/useFooter";

const CampaignPlacement = () => {
  const { profileId, campaignId } = useParams();

  const gridRef = useRef();
  const [gridReady, setGridReady] = useState(false);

  const keySuffix = "placement";
  const columnStateKey = `last-column-state-${keySuffix}`;
  const columnVisibleKey = `last-column-visible-${keySuffix}`;
  const sortStateKey = `last-sort-state-${keySuffix}`;

  const columnDefs = [
    {
      field: "placement",
      aggFunc: "first",
    },
    ...common_fields,
  ];

  const [rawColumns, setRawColumns] = useState([]);
  const [parentInfo, setParentInfo] = useState({});

  const [rawTableData, setRawTableData] = useState([{}]);

  const [dateState, setDateState] = useState(loadDate());

  const defaultSort = [];
  const [orderBy, setOrderBy] = useState([]);

  const { setBreadcrumb } = useNavbar();
  const { setPagination } = useFooter();

  const updateTable = () => {
    if (!gridRef.current.api || !gridReady) {
      return;
    }

    const { selection, compare } = dateState;

    const start_date = pickerToNorm(selection.startDate);
    const end_date = pickerToNorm(selection.endDate);
    const vs_start_date = pickerToNorm(compare.startDate);
    const vs_end_date = pickerToNorm(compare.endDate);

    gridRef.current.api.showLoadingOverlay();

    get_campaigns_data(
      profileId,
      campaignId,
      start_date,
      end_date,
      vs_start_date,
      vs_end_date
    )
      .then((res) => {
        setRawTableData(!res || res.length === 0 ? [{}] : res);
      })
      .catch(() => {
        gridRef.current.api.hideOverlay();
      });
  };

  const onGridReady = () => {
    restoreSortState(gridRef, sortStateKey, setOrderBy, defaultSort);
    setGridReady(true);
  };

  const onSortChanged = (event) => {
    onSortChangedCommon(event, sortStateKey, orderBy, setOrderBy);
  };

  const onColumnMoved = (event) => {
    onColumnMovedCommon(event, parentInfo, columnDefs, columnStateKey);
  };

  const onColumnVisible = (event) => {
    onColumnVisibleCommon(event, columnVisibleKey);
  };

  const onRowDataUpdated = () => {
    onRowDataUpdatedCommon(gridRef, rawTableData);
  };

  useEffect(() => {
    updateTable();
  }, [gridReady, dateState]);

  useEffect(() => {
    restoreColumnsState(
      columnStateKey,
      columnVisibleKey,
      columnDefs,
      setParentInfo,
      setRawColumns
    );
  }, []);

  useEffect(() => {
    setBreadcrumb({ page: "Campaign Placement", profileId, campaignId });
  }, [profileId, campaignId]);

  useEffect(() => {
    setPagination(null);
  }, []);

  useEffect(() => customizeToolPanel(gridRef), [gridRef]);

  return (
    <>
      <Helmet title="Campaign Placement" />
      <Container fluid className="p-0" style={{ height: "100%" }}>
        {/* <BreadCrumb
          campaignId={campaignId}
          adGroupId={null}
          page="Campaign Placement"
          profileId={profileId}
        /> */}
        <Row style={{ height: "100%" }}>
          <Col>
            <div
              className="tab"
              style={{
                height: "100%",
                marginBottom: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div
                  className="d-flex align-items-end"
                  style={{
                    marginTop: 18,
                    marginRight: 16,
                    paddingRight: 0,
                  }}
                >
                  <TabNav
                    profileId={profileId}
                    campaignId={campaignId}
                    page="placement"
                  />
                  <div style={{ flexGrow: 1 }} />
                  <div style={{ marginBottom: "0.5em" }}>
                    <DateModal
                      gridRef={gridRef}
                      dateState={dateState}
                      setDateState={setDateState}
                    />
                  </div>
                </div>
                <Tab.Content style={{ padding: 0, flexGrow: 1 }}>
                  <Tab.Pane
                    eventKey="first"
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        background: "white",
                        padding: 0,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Row className="d-flex justify-content-between mb-1">
                        <Col className="d-flex" style={{ marginLeft: 8 }}>
                          <div style={{ flexGrow: 1 }} />
                          <ExportButton
                            onClick={() => {
                              exportToCSV(gridRef, "campaign_placement.csv");
                            }}
                          />
                          <ColumnsButton
                            onClick={() => {
                              toggleColumnsPanelShow(gridRef);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ flexGrow: 1 }}>
                        
                        <Col>
                          
                          <div className="ag-theme-alpine grid-wrapper">
                            <AgGridReact
                              ref={gridRef}
                              onGridReady={onGridReady}
                              columnDefs={rawColumns}
                              rowData={rawTableData}
                              gridOptions={agGridOptions}
                              onDragStarted={onDragStarted}
                              onDragStopped={onDragStopped}
                              onColumnMoved={onColumnMoved}
                              onSortChanged={onSortChanged}
                              onColumnVisible={onColumnVisible}
                              onRowDataUpdated={onRowDataUpdated}
                              rowModelType={"clientSide"}
                              pivotMode={false}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CampaignPlacement;
