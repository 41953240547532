import { useEffect, useState, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Button, Tab } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import {
  keyword_field,
  negative_keyword_state_field,
  campaign_name_field,
  ad_group_name_field,
  selection_box,
  match_type_field,
} from "pages/opasity/opasityApi/ag_grid_fields";

import { get_negative_keywords } from "pages/opasity/opasityApi/negativeKeywords";

import {
  updateNegativeKeywords,
  deleteNegativeKeywords,
} from "pages/opasity/opasityApi/updateNegativeKeywords";
import { ReactComponent as FilterIcon } from "assets/img/icons/light-filter.svg";
import { ReactComponent as FilterLeftArrowIcon } from "assets/img/icons/filter-left-arrow.svg";
import { ReactComponent as FilterRightArrowIcon } from "assets/img/icons/filter-right-arrow.svg";
import {
  agGridOptions,
  restoreSortState,
  restoreFilters,
  onSortChanged as onSortChangedCommon,
  onFilterChanged as onFilterChangedCommon,
  onColumnVisible as onColumnVisibleCommon,
  onRowDataUpdated as onRowDataUpdatedCommon,
  collapseFilterGroups,
  toggleColumnsPanelShow,
  exportToCSV,
  customizeToolPanel,
} from "utils/agGridCommon";

import { saveToLocal, loadFromLocal } from "pages/opasity/opasityApi/utils";

import Pagination from "components/opasity/Pagination";
import FilterComponent from "components/opasity/FilterComponent";
import ExportButton from "components/opasity/ExportButton";
import ColumnsButton from "components/opasity/ColumnsButton";
import TabNav from "components/opasity/TabNav";
import Filters from "components/Filters";
import FilterChips from "components/FilterChips";
import useNavbar from "hooks/useNavbar";
import useFooter from "hooks/useFooter";

const NegativeTargeting = () => {
  const { profileId, campaignId, adGroupId } = useParams();
  const [openFilters, setOpenFilters] = useState(false);
  const gridRef = useRef();
  const [gridReady, setGridReady] = useState(false);

  const keySuffix = `negative-targeting${adGroupId ? "-adgroup" : ""}${
    campaignId ? "-campaign" : ""
  }`;
  const columnStateKey = `last-column-state-${keySuffix}`;
  const columnVisibleKey = `last-column-visible-${keySuffix}`;
  const sortStateKey = `last-sort-state-${keySuffix}`;
  const lastFilterKey = `last-filter-${keySuffix}`;
  const infoKey = `last-info-${keySuffix}`;

  const lastInfo = loadFromLocal(infoKey);

  const columnDefs = [
    selection_box,
    negative_keyword_state_field,
    keyword_field,
    match_type_field,
    ...(adGroupId ? [] : [ad_group_name_field]),
    ...(campaignId ? [] : [campaign_name_field]),
  ];

  selection_box.pinned = null;
  negative_keyword_state_field.pinned = null;
  keyword_field.pinned = null;
  keyword_field.width = 300;
  ad_group_name_field.pinned = null;
  ad_group_name_field.width = 250;
  campaign_name_field.pinned = null;
  campaign_name_field.width = 250;

  const [rawColumns, setRawColumns] = useState([]);
  const [rawTableData, setRawTableData] = useState([{}]);

  const [selectedKeywords, setSelectedKeywords] = useState([]);

  const [pageSize, setPageSize] = useState(
    lastInfo?.pageSize ? lastInfo.pageSize : 50
  );
  const [page, setPage] = useState(1);
  const [noPages, setNoPages] = useState(0);
  const [count, setCount] = useState(0);

  const [orderBy, setOrderBy] = useState();
  const [filterBy, setFilterBy] = useState(
    restoreFilters(lastFilterKey, defaultFilter) || {}
  );
  const defaultFilter = {};
  const defaultSort = [];

  const { setBreadcrumb } = useNavbar();
  const { setPagination } = useFooter();

  const updateTable = () => {
    if (!gridRef.current.api || !gridReady) {
      return;
    }

    gridRef.current.api.showLoadingOverlay();

    get_negative_keywords(
      profileId,
      Number(campaignId),
      Number(adGroupId),
      pageSize,
      page,
      orderBy,
      filterBy
    )
      .then((res) => {
        setRawTableData(!res.data || res.data.length === 0 ? [{}] : res.data);
        setNoPages(res.pages);
        setCount(res.count);
      })
      .catch(() => {
        gridRef.current.api.hideOverlay();
      });
  };

  const onGridReady = () => {
    restoreSortState(gridRef, sortStateKey, setOrderBy, defaultSort);

    collapseFilterGroups(gridRef);

    setGridReady(true);
  };

  const onPageSizeChanged = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onDragStarted = (event) => {
    if (event.target.classList.contains("ag-header-cell")) {
      let dragHandler = document.getElementsByClassName("ag-dnd-ghost")[0];
      if (dragHandler) {
        dragHandler.style.visibility = "visible";
      }
    }
  };

  const onSortChanged = (event) => {
    onSortChangedCommon(event, sortStateKey, orderBy, setOrderBy);
  };

  const onFilterChanged = (event) => {
    onFilterChangedCommon(event, lastFilterKey, filterBy, setFilterBy);
  };

  const onColumnMoved = (event) => {
    const columnState = event.columnApi.getColumnState();
    saveToLocal(columnStateKey, columnState);
  };

  const onColumnVisible = (event) => {
    onColumnVisibleCommon(event, columnVisibleKey);
  };

  const onRowDataUpdated = () => {
    onRowDataUpdatedCommon(gridRef, rawTableData);
  };

  const onSelectionChanged = (event) => {
    setSelectedKeywords(event.api.getSelectedNodes());
  };

  const isRowSelectable = (row) => {
    return (
      row.data.negativeKeywordState &&
      row.data.negativeKeywordState !== "archived"
    );
  };

  function SelectedKeywords() {
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [bidAdjustmentType, setBidAdjustmentType] = useState("setAmount");
    const [bidAdjustment, setBidAdjustment] = useState();
    const [error, setError] = useState("");
    function pauseNegativeKeywords() {
      setSaving(true);
      console.log("gotta pause keywords...");
      const keywords = [];
      const nodes = gridRef.current.api.getSelectedNodes();
      for (let node_index in nodes) {
        keywords.push({
          keywordId: nodes[node_index].data.keywordId,
          state: "PAUSED",
        });
      }
      updateNegativeKeywords(Number(profileId), keywords).then((res) => {
        setSaving(false);
        console.log(res);
        for (let i in res.success) {
          for (let j in nodes) {
            if (Number(res.success[i].keywordId) == nodes[j].data.keywordId) {
              nodes[j].updateData({
                ...nodes[j].data,
                negativeKeywordState: res.success[i].state,
              });
              gridRef.current.api.flashCells({
                rowNodes: [nodes[i]],
                columns: ["negativeKeywordState"],
              });
            }
          }
        }
      });
    }
    function enableNegativeKeywords() {
      setSaving(true);
      console.log("gotta enable keywords...");
      const keywords = [];
      const nodes = gridRef.current.api.getSelectedNodes();
      for (let node_index in nodes) {
        keywords.push({
          keywordId: nodes[node_index].data.keywordId,
          state: "ENABLED",
        });
      }
      updateNegativeKeywords(Number(profileId), keywords).then((res) => {
        setSaving(false);
        console.log(res);
        for (let i in res.success) {
          for (let j in nodes) {
            if (Number(res.success[i].keywordId) == nodes[j].data.keywordId) {
              nodes[j].updateData({
                ...nodes[j].data,
                negativeKeywordState: res.success[i].state,
              });
              gridRef.current.api.flashCells({
                rowNodes: [nodes[i]],
                columns: ["negativeKeywordState"],
              });
            }
          }
        }
      });
    }
    function archiveNegativeKeywords() {
      setSaving(true);
      console.log("gotta enable keywords...");
      const keywords = [];
      const nodes = gridRef.current.api.getSelectedNodes();
      for (let node_index in nodes) {
        keywords.push({
          keywordId: nodes[node_index].data.keywordId,
        });
      }
      if (
        window.confirm(
          `You are about to delete ${keywords.length} negative keyword. Are you sure?`
        )
      ) {
        deleteNegativeKeywords(Number(profileId), keywords).then((res) => {
          setSaving(false);
          console.log(res);
          for (let i in res.success) {
            for (let j in nodes) {
              if (Number(res.success[i].keywordId) == nodes[j].data.keywordId) {
                nodes[j].updateData({
                  ...nodes[j].data,
                  keywordState: res.success[i].state,
                });
                gridRef.current.api.flashCells({
                  rowNodes: [nodes[i]],
                  columns: ["negativeKeywordState"],
                });
              }
            }
          }
        });
      } else {
        setSaving(false);
        return;
      }
    }

    if (selectedKeywords.length === 0) {
      return null;
    }

    if (selectedKeywords.length > 0) {
      return (
        <div className="mb-1">
          <Button
            style={{ marginRight: "6px" }}
            variant="btn btn-outline-warning"
            title="edit"
            onClick={() => pauseNegativeKeywords()}
            disabled={saving}
          >
            Pause ({selectedKeywords.length})
          </Button>
          <Button
            style={{ marginRight: "6px" }}
            variant="btn btn-outline-success"
            title="edit"
            onClick={() => enableNegativeKeywords()}
            disabled={saving}
          >
            Enable ({selectedKeywords.length})
          </Button>
          <Button
            variant="btn btn-outline-danger"
            title="edit"
            onClick={() => archiveNegativeKeywords()}
            disabled={saving}
          >
            Archive ({selectedKeywords.length})
          </Button>
        </div>
      );
    }
  }

  useEffect(() => {
    updateTable();
  }, [gridReady, page, pageSize, orderBy, filterBy]);

  useEffect(() => {
    let newColumnDefs = columnDefs;
    let info = {};

    columnDefs.forEach(({ field }, index) => {
      info[field] = index;
    });

    const columnState = loadFromLocal(columnStateKey);

    if (columnState && columnState.length > 0) {
      let lastColumnDefs = [];
      let groupArr = [];

      columnState.forEach(({ colId }) => {
        if (!groupArr.includes(info[colId]) && info[colId] !== undefined) {
          groupArr.push(info[colId]);
        }
      });

      columnDefs.forEach((_, index) => {
        if (!groupArr.includes(index)) {
          groupArr.push(index);
        }
      });

      groupArr.forEach((index) => {
        lastColumnDefs.push(columnDefs[index]);
      });

      newColumnDefs = lastColumnDefs;
    }

    const columnVisible = loadFromLocal(columnVisibleKey);

    if (columnVisible && columnVisible.length > 0) {
      let visibleInfo = {};

      columnVisible.forEach(({ colId, hide }) => (visibleInfo[colId] = hide));

      newColumnDefs.forEach(({ field }, index) => {
        newColumnDefs[index].hide = visibleInfo[field];
      });
    }

    setRawColumns(newColumnDefs);
  }, []);

  useEffect(() => {
    setBreadcrumb({
      page: "Negative Targeting",
      profileId,
      campaignId,
      adGroupId,
    });
  }, [profileId, campaignId, adGroupId]);

  useEffect(() => {
    setPagination({
      pageSize,
      onPageSizeChanged,
      setPage,
      noPages,
      page,
      count,
    });
  }, [pageSize, noPages, page, count]);

  useEffect(() => {
    let currentInfo = loadFromLocal(infoKey);

    if (!currentInfo) {
      currentInfo = {};
    }

    currentInfo.pageSize = pageSize;
    saveToLocal(infoKey, currentInfo);
  }, [pageSize]);

  useEffect(() => customizeToolPanel(gridRef), [gridRef]);
  useEffect(() => {
    onFilterChangedCommon(lastFilterKey, filterBy);
  }, [filterBy]);

 
  return (
    <>
      <Helmet title="Negative Keywords" />
      <Container fluid className="p-0" style={{ height: "100%" }}>
        {/* <BreadCrumb
          campaignId={campaignId}
          adGroupId={adGroupId}
          page="negativeKeywords"
          profileId={profileId}
        /> */}
        <Row style={{ height: "100%" }}>
          <Col>
            <div
              className="tab"
              style={{
                height: "100%",
                marginBottom: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div
                  className="d-flex align-items-end"
                  style={{
                    marginTop: 18,
                  }}
                >
                  <TabNav
                    profileId={profileId}
                    campaignId={campaignId}
                    adGroupId={adGroupId}
                    page="negativetargeting"
                  />
                </div>
                <Tab.Content style={{ padding: 0, flexGrow: 1 }}>
                  <Tab.Pane
                    eventKey="first"
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        background: "white",
                        padding: 0,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Row className="d-flex justify-content-end mb-1">
                        <Col
                          className="d-flex align-items-end"
                          style={{ marginLeft: 0 }}
                        >
                          {selectedKeywords.length === 0 ? (
                            // <FilterComponent
                            // gridRef={gridRef}
                            // filterBy={filterBy}
                            // defaultFilter={defaultFilter}
                            // page="negativeKeywords"
                            // />
                            <>
                              <button
                                className="filters-button"
                                onClick={() => {
                                  setOpenFilters(!openFilters);
                                }}
                              >
                                {openFilters ? (
                                  <span style={{ marginRight: 6 }}>
                                    Filters
                                  </span>
                                ) : null}
                                <FilterIcon style={{ marginRight: 6 }} />
                                {openFilters ? (
                                  <FilterLeftArrowIcon />
                                ) : (
                                  <FilterRightArrowIcon />
                                )}
                              </button>

                              <FilterChips
                                filterBy={filterBy}
                                setFilterBy={setFilterBy}
                              />
                            </>
                          ) : (
                            <SelectedKeywords />
                          )}
                          <div style={{ flexGrow: 1 }} />
                          <ExportButton
                            onClick={() => {
                              exportToCSV(gridRef, "negkeywords.csv");
                            }}
                          />
                          <ColumnsButton
                            onClick={() => {
                              toggleColumnsPanelShow(gridRef);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ flexGrow: 1 }}>
                        <Filters
                          style={{ margin: 0 }}
                          columnsArray={rawColumns}
                          openFilters={openFilters}
                          setOpenFilters={setOpenFilters}
                          filterBy={filterBy}
                          setFilterBy={setFilterBy}
                        />
                        <Col style={{ margin: 0 }}>
                          <div className="ag-theme-alpine grid-wrapper">
                            <AgGridReact
                              ref={gridRef}
                              onGridReady={onGridReady}
                              columnDefs={rawColumns}
                              rowData={rawTableData}
                              gridOptions={agGridOptions}
                              rowSelection="multiple"
                              isRowSelectable={isRowSelectable}
                              onSelectionChanged={onSelectionChanged}
                              onDragStarted={onDragStarted}
                              onColumnMoved={onColumnMoved}
                              onSortChanged={onSortChanged}
                              //onFilterChanged={onFilterChanged}
                              onColumnVisible={onColumnVisible}
                              onRowDataUpdated={onRowDataUpdated}
                              rowModelType={"clientSide"}
                              pivotMode={false}
                            />
                          </div>
                        </Col>
                      </Row>
                      {/* <Row className="mt-3">
                        <Col xs={12}>
                          <Pagination
                            pageSize={pageSize}
                            onPageSizeChanged={onPageSizeChanged}
                            setPage={setPage}
                            noPages={noPages}
                            page={page}
                            count={count}
                          />
                        </Col>
                      </Row> */}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NegativeTargeting;
