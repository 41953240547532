import { useState, useEffect } from "react";
import { getCampaign, getAdgroup } from "pages/opasity/opasityApi/singleItem";
import { Link } from "react-router-dom";

const BreadCrumbs = ({ profileId, campaignId, adGroupId, page }) => {
  const [campaign, setCampaign] = useState("");
  const [adGroup, setAdgroup] = useState("");

  useEffect(() => {
    setCampaign("");
    setAdgroup("");
    if (campaignId) {
      getCampaign(profileId, campaignId).then((res) => setCampaign(res.name));
    }
    if (adGroupId) {
      getAdgroup(profileId, adGroupId).then((res) => setAdgroup(res.name));
    }
  }, [campaignId, adGroupId]);

  return (
    <span style={{ color: "#BABFC7", fontSize: 12 }} className="ms-2">
      {campaign ? (
        <>
          <Link
            to={`/profiles/${profileId}/campaigns/${campaignId}/`}
            style={{ color: "#BABFC7" }}
          >
            {campaign}
          </Link>
          {" > "}
        </>
      ) : null}
      {adGroup ? (
        <>
          <Link
            to={`/profiles/${profileId}/campaigns/${campaignId}/adgroups/${adGroupId}/targeting`}
            style={{ color: "#BABFC7" }}
          >
            {adGroup}
          </Link>
          {" > "}
        </>
      ) : null}
      {page ? <span style={{ fontWeight: "bold" }}>{page}</span> : null}
    </span>
  );
};

export default BreadCrumbs;
