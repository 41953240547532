import PerfectScrollbar from "react-perfect-scrollbar";
// import "react-perfect-scrollbar/dist/css/styles.css";

import SidebarFooter from "./SidebarFooter";
import SidebarNav from "./SidebarNav";

import useSidebar from "hooks/useSidebar";

import { ReactComponent as Logo } from "assets/img/opasity_logo.svg";
import { useEffect } from "react";

import $ from "jquery";

const Sidebar = ({ items, campaignsType }) => {
  const { isOpen } = useSidebar();

  useEffect(() => {
    $(".sidebar-popup").removeClass("show");
  });

  return (
    <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
      {/* <div className="sidebar-content">
        <PerfectScrollbar> */}
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <a className="sidebar-brand" style={{ width: "180px" }} href="/">
          <Logo style={{ width: "100%", height: "80%" }} />
        </a>
        <SidebarNav items={items} campaignsType={campaignsType} />
      </div>
      {/* </PerfectScrollbar>
      </div> */}
    </nav>
  );
};

export default Sidebar;
