import api from "./Ax";
import { saveToLocal, loadFromLocal } from "./utils";

export async function getAndSaveCampaign(profileId, campaignId) {
  const query = {
    profileId: Number(profileId),
    action: "get_campaign",
    campaign_id: Number(campaignId),
  };
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  saveToLocal(`campaign-${campaignId}`, result.data);
  return result.data;
}
export async function getCampaign(profileId, campaignId, live = false) {
  if (live) {
    const campaign = await getAndSaveCampaign(profileId, campaignId);
    return campaign;
  }
  const savedCampaign = loadFromLocal(`campaign-${campaignId}`);
  if (savedCampaign) {
    return savedCampaign;
  }
  const campaign = await getAndSaveCampaign(profileId, campaignId);
  return campaign;
}
export function getCachedCampaign(campaignId) {
  return loadFromLocal(`campaign-${campaignId}`);
}
export async function getAndSaveAdGroup(profileId, adGroupId) {
  const query = {
    profileId: Number(profileId),
    action: "get_adgroup",
    adgroup_id: Number(adGroupId),
  };
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  saveToLocal(`adGroup-${adGroupId}`, result.data);
  return result.data;
}
export async function getAdgroup(profileId, adGroupId, live = false) {
  if (live) {
    const adGroup = await getAndSaveAdGroup(profileId, adGroupId);
    return adGroup;
  }
  const savedAdGroup = loadFromLocal(`adGroup-${adGroupId}`);
  if (savedAdGroup) {
    return savedAdGroup;
  }
  const adGroup = await getAndSaveAdGroup(profileId, adGroupId);
  return adGroup;
}
