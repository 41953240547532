import { getCampaigns } from "./campaignsAdGroups";
import api from "./Ax";
import * as dfd from "danfojs";
import {
  saveToLocal,
  loadFromLocal,
  loadFromLocalSession,
  saveToLocalSession,
} from "./utils";

export async function get_campaigns_data(
  profileId,
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  pageSize,
  page,
  orderBy,
  filterBy,
  search
) {
  const query = JSON.stringify({
    action: "get_campaigns_data",
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    profileId: Number(profileId),
    pageSize,
    page,
    orderBy,
    filterBy,
    search,
  });
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  return result.data;
}
export async function get_campaigns_summary_data(
  profileId,
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  filterBy,
  search
) {
  const query = JSON.stringify({
    action: "get_campaigns_data_summary",
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    profileId: Number(profileId),
    filterBy,
    search,
  });
  const cached = loadFromLocalSession(query);
  if (cached && cached.data) {
    return cached.data;
  }
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  saveToLocalSession(query, result);
  return result.data;
}
export async function get_campaigns_data_pages(
  profileId,
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  pageSize,
  page,
  orderBy,
  filterBy,
  search
) {
  const query = JSON.stringify({
    action: "get_campaigns_data",
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    profileId: Number(profileId),
    getCount: true,
    search,
    pageSize,
    page,
    orderBy,
    filterBy,
  });
  console.log("getting pages count...", query);
  const cached = loadFromLocalSession(query);
  if (cached && cached.data) {
    console.log("loaded pages from cache");
    return cached.data;
  }
  console.log("loaging pages from request");
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  saveToLocalSession(query, result);
  return result.data;
}
