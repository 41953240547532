import { ReactComponent as Export } from "assets/img/icons/export.svg";
import { DateRange } from "react-date-range";
import { useState, useEffect, forwardRef, createRef, useRef } from "react";
import { saveDates } from "pages/opasity/opasityApi/utils";
import { Row, Col, Form, InputGroup, Dropdown, Button } from "react-bootstrap";
import { ReactComponent as Column } from "assets/img/icons/column.svg";

export const GroupedToggle2 = ({ grouped, setGrouped }) => {
  return (
    <Row>
      <button
        style={{
          background: "transparent",
          border: "none",
          fontSize: 12,
          color: "var(--back-dark)",
          minWidth: 72,
          outline: "none",
        }}
      >
        <Col md="12">
          <Form.Check
            type="switch"
            id="exampleCustomSwitch"
            name="customSwitch"
            className="form-switch-sm"
            onChange={(e) => setGrouped(e.target.checked)}
            checked={grouped}
            style={{
              "& input": {
                height: "2px !important",
              },
            }}
          />
        </Col>
        <Col md="12">
          <div>{grouped ? `Grouped` : "Ungrouped"}</div>
        </Col>
      </button>
    </Row>
  );
};
const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <button
    href=""
    ref={ref}
    onClick={(e) => {
      console.log("clicked...");
      e.preventDefault();
      onClick(e);
    }}
    style={{
      background: "transparent",
      border: "none",
      fontSize: 12,
      color: "var(--back-dark)",
      minWidth: 72,
      outline: "none",
    }}
  >
    {children}
  </button>
));
function GroupedToggle({ grouped, setGrouped, page, groupingOptions }) {
  function setGrouping() {}
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  return (
    <Row>
      <Dropdown autoClose={false} show={show}>
        <Dropdown.Toggle
          id="dropdown-autoclose-false"
          style={{
            background: "transparent",
            border: "none",
            fontSize: 12,
            color: "var(--back-dark)",
            minWidth: 72,
            outline: "none",
          }}
          as={CustomToggle}
          onClick={() => setShow(!show)}
        >
          <Row>
            <Col md="12">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-bar-chart-steps"
                viewBox="0 0 16 16"
              >
                <path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0zM2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1z" />
              </svg>
            </Col>
            <Col md="12">Grouping</Col>
          </Row>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div className="px-2">
            {groupingOptions.map((option) => (
              <Form.Check
                type={"checkbox"}
                label={`${option.name}`}
                id={`check-${option.value}`}
                onChange={(e) => {
                  if (
                    e.target.checked &&
                    !grouped.map((item) => item.value).includes(option.value)
                  ) {
                    setGrouped([...grouped, option]);
                  }
                  if (!e.target.checked) {
                    setGrouped(
                      grouped.filter((item) => item.value != option.value)
                    );
                  }
                }}
                checked={grouped
                  .map((item) => item.value)
                  .includes(option.value)}
              />
            ))}
          </div>
          <div style={{ textAlign: "right" }}>
            <Button
              onClick={() => setShow(false)}
              variant="outline-warning"
              className="mx-2"
              style={{
                maxWidth: 80,
                paddingRight: 5,
                paddingLeft: 5,
                paddingTop: 2,
                paddingBottom: 2,
              }}
            >
              Close
            </Button>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </Row>
  );
}
export default GroupedToggle;
