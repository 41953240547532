import { createContext, useCallback, useEffect, useReducer } from "react";

import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
} from "amazon-cognito-identity-js";

import axios from "../utils/axios";
import { cognitoConfig } from "../config";
import { da } from "date-fns/locale";

const INITIALIZE = "INITIALIZE";
const SIGN_OUT = "SIGN_OUT";

export const UserPool = new CognitoUserPool({
  UserPoolId: cognitoConfig.userPoolId || "us-east-1_M56iH33IC",
  ClientId: cognitoConfig.clientId || "487jmg0mua3ee7afo4gt4obp2v",
});

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user, token } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      token
    };
  }
  if (action.type === SIGN_OUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getUserAttributes = useCallback(
    (currentUser) =>
      new Promise((resolve, reject) => {
        currentUser.getUserAttributes((err, attributes) => {
          if (err) {
            reject(err);
          } else {
            const results = {};

            attributes?.forEach((attribute) => {
              results[attribute.Name] = attribute.Value;
            });
            resolve(results);
          }
        });
      }),
    []
  );

  const getSession = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser();
        if (user) {
          user.getSession(async (err, session) => {
            if (err) {
              reject(err);
            } else {
              const attributes = await getUserAttributes(user);
              const token = session?.getIdToken().getJwtToken();

              axios.defaults.headers.common.Authorization = token;

              dispatch({
                type: INITIALIZE,
                payload: { isAuthenticated: true, user: attributes, token: token },
              });

              resolve({
                user,
                session,
                headers: { Authorization: token },
              });
            }
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }),
    [getUserAttributes]
  );

  const initialize = useCallback(async () => {
    try {
      await getSession();
    } catch {
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [getSession]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const confirmUser = (email, confirmationCode) =>
    new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool: UserPool,
      });
      user.confirmRegistration(
        confirmationCode,
        false,
        async (err) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(undefined);
          // Set destination URL here
          window.location.href = "/auth/sign-in";
        }
      )
    })
  const resendConfirmationCode = (email) => new Promise(
    (resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool: UserPool,
      });
      user.resendConfirmationCode(
        async (err) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(undefined);
        }
      )
    }
  )

  const signIn = useCallback(
    (email, password) =>
      new Promise((resolve, reject) => {
        const user = new CognitoUser({
          Username: email,
          Pool: UserPool,
        });

        const authDetails = new AuthenticationDetails({
          Username: email,
          Password: password,
        });

        user.authenticateUser(authDetails, {
          onSuccess: (data) => {
            getSession();
            resolve(data);
            window.location.href = "/profiles";
          },
          onFailure: (err) => {
            reject(err);
          },
          newPasswordRequired: () => {
            resolve({ message: "New password required" });
          },
        });
      }),
    [getSession]
  );

  const signOut = () => {
    const user = UserPool.getCurrentUser();
    if (user) {
      user.signOut();
      dispatch({ type: SIGN_OUT });
    }
  };

  const signUp = (email, password, firstName, lastName) =>
    new Promise((resolve, reject) => {
      UserPool.signUp(
        email,
        password,
        [
          new CognitoUserAttribute({ Name: "email", Value: email }),
          new CognitoUserAttribute({
            Name: "name",
            Value: `${firstName} ${lastName}`,
          }),
          new CognitoUserAttribute({ Name: "given_name", Value: firstName }),
          new CognitoUserAttribute({ Name: "family_name", Value: lastName }),
        ],
        [],
        async (err) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(undefined);
          // Set destination URL here
          window.location.href = `/auth/validateEmail?email=${email}`
        }
      );
    });

  const resetPassword = (email) => new Promise(
    (resolve, reject) => {
      const user = new CognitoUser(
        {
          Username: email,
          Pool: UserPool,
        }
      )
      user.forgotPassword({
        onSuccess: (data) => resolve(data),
        onFailure: (err) => reject(err)
      }
      )
    }
  )

  const confirmPassword = (email, confirmationCode, newPassword) => new Promise(
    (resolve, reject) => {
      const user = new CognitoUser(
        {
          Username: email,
          Pool: UserPool,
        }
      )
      user.confirmPassword(
        confirmationCode,
        newPassword,
        {
          onSuccess: (data) => resolve(data),
          onFailure: (err) => reject(err)
        }
      )
    }
  )

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "cognito",
        user: {
          displayName: state?.user?.name || "Undefined",
          role: "user",
          ...state.user,
        },
        signIn,
        signUp,
        signOut,
        resetPassword,
        confirmUser,
        resendConfirmationCode,
        confirmPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
