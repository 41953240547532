import api from "./Ax";

export async function getSpApiAccount() {
    console.log("setting seller data")
    const query = {
        action: 'get_seller_accounts'
    }
    const result = await api.post("https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data", query)
    console.log("got seller data...", result)
    return result.data
}