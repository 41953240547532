export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

export const maskKeys = [
    { name: "campaignName", prefix: "C" },
    { name: "keyword", prefix: "K" },
    { name: "search_term", prefix: "ST" },
    { name: "asin", prefix: "A" },
    { name: "advertised_asin", prefix: "AA" },
    { name: "parentAsin", prefix: "PA" },
    { name: "advertised_sku", prefix: "AS" },
    { name: "sku", prefix: "S" },
    { name: "name", prefix: "N" },
    { name: "adGroupName", prefix: "G" },
    { name: "productName", prefix: "P" }
];