import api from "./Ax";
import { saveToLocalSession, loadFromLocalSession } from "./utils";

export async function get_advertised_products(
  profileId,
  adGroupId,
  campaignId,
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  pageSize,
  page,
  orderBy,
  filterBy,
  search,
  groupBy,
  groupByDrillDown
) {
  const query = {
    action: "get_advertised_products_data",
    profileId: Number(profileId),
    adgroup_id: Number(adGroupId),
    campaign_id: Number(campaignId),
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    pageSize,
    page,
    orderBy,
    filterBy,
    search,
    groupBy,
    groupByDrillDown,
  };
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  return result.data;
}
export async function get_advertised_products_count(
  profileId,
  adGroupId,
  campaignId,
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  pageSize,
  filterBy,
  search
) {
  const query = {
    action: "get_advertised_products_data",
    profileId: Number(profileId),
    adgroup_id: Number(adGroupId),
    campaign_id: Number(campaignId),
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    pageSize,
    filterBy,
    getCount: true,
    search,
  };
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  return result.data;
}

export async function get_advertised_products2(
  profileId,
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  pageSize,
  page,
  orderBy,
  filterBy,
  search,
  groupBy,
  groupByDrillDown
) {
  const query = {
    action: "get_advertised_products_data2",
    profileId: Number(profileId),
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    pageSize,
    page,
    orderBy,
    filterBy,
    search,
    groupBy,
    groupByDrillDown,
  };
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  return result.data;
}
export async function get_advertised_products2_count(
  profileId,
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  pageSize,
  orderBy,
  filterBy,
  search
) {
  const query = {
    action: "get_advertised_products_data2",
    profileId: Number(profileId),
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    pageSize,
    orderBy,
    filterBy,
    getCount: true,
    search,
  };
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  return result.data;
}

export async function get_advertised_products_summary(
  profileId,
  adGroupId,
  campaignId,
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  filterBy,
  search
) {
  const query = {
    action: "get_advertised_products_data_summary",
    profileId: Number(profileId),
    adgroup_id: Number(adGroupId),
    campaign_id: Number(campaignId),
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    filterBy,
    search,
  };
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  return result.data;
}

export async function get_advertised_products2_summary(
  profileId,
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  filterBy,
  search
) {
  const query = {
    action: "get_advertised_products_data2_summary",
    profileId: Number(profileId),
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    filterBy,
    search,
  };
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  return result.data;
}
