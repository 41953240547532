import { useEffect, useState, useRef, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Tab,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { ReactComponent as FilterIcon } from "assets/img/icons/light-filter.svg";
import { ReactComponent as FilterLeftArrowIcon } from "assets/img/icons/filter-left-arrow.svg";
import { ReactComponent as FilterRightArrowIcon } from "assets/img/icons/filter-right-arrow.svg";

import {
  common_fields,
  orders_field,
  revenue_field,
  tacos_field,
  goal_vals,
  goal_vals_p,
  goal_type,
  goal_value,
  organic_sales_field
} from "pages/opasity/opasityApi/ag_grid_fields";

import {
  get_advertised_products2,
  get_advertised_products2_summary,
  get_advertised_products2_count,
} from "pages/opasity/opasityApi/advertisedproducts";

import {
  pickerToNorm,
  loadDate,
  saveToLocal,
  loadFromLocal,
} from "pages/opasity/opasityApi/utils";

import {
  agGridOptions,
  restoreColumnsState,
  restoreSortState,
  restoreFilters,
  onDragStarted,
  onDragStopped,
  onColumnMoved as onColumnMovedCommon,
  onSortChanged as onSortChangedCommon,
  onFilterChanged as onFilterChangedCommon,
  onColumnVisible as onColumnVisibleCommon,
  onRowDataUpdated as onRowDataUpdatedCommon,
  collapseFilterGroups,
  toggleColumnsPanelShow,
  exportToCSV,
  getTotals,
  agOrderToNorm,
  customizeToolPanel,
  parseFilter,
  filterObjToArray,
} from "utils/agGridCommon";
import GroupedToggle from "components/opasity/GroupedToggle";

import DateModal from "components/opasity/DateModal";
import Pagination from "components/opasity/Pagination";
import FilterComponent from "components/opasity/FilterComponent";
import ExportButton from "components/opasity/ExportButton";
import ColumnsButton from "components/opasity/ColumnsButton";
import TabNav from "components/opasity/TabNav";

import useNavbar from "hooks/useNavbar";
import useFooter from "hooks/useFooter";
import Filters from "components/Filters";
import FilterChips from "components/FilterChips";

function getCommonFields() {
  const commonFields = [];
  common_fields.map((cf) => {
    if (cf.field) {
      commonFields.push(cf.field);
    }
    if (cf.children) {
      cf.children.map((ccf) => {
        if (ccf.field) {
          commonFields.push(ccf.field);
        }
      });
    }
  });
  return commonFields;
}
function getAdditionalFilter(incGoals = true) {
  const result = [];
  const additionalFilters = incGoals
    ? [
        orders_field,
        revenue_field,
        organic_sales_field,
        tacos_field,
        goal_type,
        goal_value,
        goal_vals,
        goal_vals_p,
      ]
    : [orders_field, revenue_field, tacos_field, organic_sales_field];
  additionalFilters.map((cf) => {
    if (cf.field) {
      result.push(cf.field);
    }
    if (cf.children) {
      cf.children.map((ccf) => {
        if (ccf.field) {
          result.push(ccf.field);
        }
      });
    }
  });
  return result;
}
const COMMON_FILTERS = getCommonFields();
const SKU_FILTERS = ["sku", ...COMMON_FILTERS, ...getAdditionalFilter(false)];
const ASIN_FILTERS = ["asin", ...COMMON_FILTERS, ...getAdditionalFilter()];
const PARENT_ASIN_FILTERS = [
  "parentAsin",
  ...COMMON_FILTERS,
  ...getAdditionalFilter(),
];
const GROUP_FIELDS = {
  sku: SKU_FILTERS,
  asin: ASIN_FILTERS,
  parentAsin: PARENT_ASIN_FILTERS,
};
const ProductNameRenderer = (params) => {
    let value = params.value;
    //let value = "Testing product name, Testing product name, Testing product name, Testing product name, Testing product name, Testing product name, Testing product name, Testing product name";
    return value ? (
        <p className="pt-1">
            <span className={'line-clamp-4'}>
                <OverlayTrigger
                    placement={'top'}
                    trigger={'click'}
                    overlay={
                        <Tooltip>
                            {value}
                        </Tooltip>
                    }
                >
                    <span>{value}</span>
                </OverlayTrigger>
            </span>
        </p>
    ) : (
        "-"
    );
};

const ProductImageRenderer = (params) => {
  return params.value ? (
    <img
      src={params.value}
      width="45px"
      height="45px"
      style={{ paddingTop: "5px", paddingBottom: "5px" }}
    />
  ) : (
    "-"
  );
};

const AsinSkuRenderer = (params) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div>
      <a
        onClick={() => setSearchParams({ search: params.data.advertised_sku })}
      >
        {params.data.advertised_sku}
      </a>
      {params.data.asin ? (
        <div>
          <span color="red">/</span>
          <a onClick={() => setSearchParams({ search: params.data.asin })}>
            {params.data.asin}
          </a>
        </div>
      ) : null}
    </div>
  );
};

const asinSkuGetter = (params) => {
  if (params.data.asin && params.data.asin.length > 0) {
    return `${params.data.advertised_sku}/${params.data.asin}`;
  }
  return params.data.asin;
};

const AdvertisedProducts2 = () => {
  const [openFilters, setOpenFilters] = useState(false);
  const { profileId } = useParams();
  const MEMO = useRef({});
  const gridRef = useRef();
  const [gridReady, setGridReady] = useState(false);

  const keySuffix = "adproductsoverview";
  const columnStateKey = `last-column-state-${keySuffix}`;
  const columnVisibleKey = `last-column-visible-${keySuffix}`;
  const sortStateKey = `last-sort-state-${keySuffix}`;
  const lastFilterKey = `last-filter-${keySuffix}`;
  const infoKey = `last-info-${keySuffix}`;
  const lastGrouping = `last-grouping-${keySuffix}`;
  const lastInfo = loadFromLocal(infoKey);
  const [grouped, setGrouped] = useState(loadFromLocal(lastGrouping) || []);
  const columnDefs = [
    {
      headerName: "Advertised Products Info",
      suppressColumnsToolPanel: true,
      children: [
        {
          headerName: "Parent Asin",
          field: "parentAsin",
          pinned: "left",
          width: 150,
          filter: false,
          sort: false,
          sortable: false,
          autoHeight: true,
          wrapText: true,
          enableValue: true,
          aggFunc: "sum",
        },
        {
          headerName: "Sku",
          field: "sku",
          pinned: "left",
          width: 150,
          filter: "agTextColumnFilter",
          rowGroup: true,
          rowGroupOpened: false,
          filterParams: {
            filterOptions: [
              "contains",
              "notContains",
              "startsWith",
              "endsWith",
              "equals",
            ],
            suppressAndOrCondition: true,
          },
          valueGetter: (params) => params?.data?.sku || "-",
          //hide: true,
          enableValue: true,
          aggFunc: "sum",
        },
        {
          headerName: "Asin",
          field: "asin",
          width: 120,
          wrapText: true,
          autoHeight: true,
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: [
              "contains",
              "notContains",
              "startsWith",
              "endsWith",
              "equals",
            ],
            suppressAndOrCondition: true,
          },
          enableValue: true,
          aggFunc: "sum",
          //hide: true,
        },
        /*
        {
          headerName: "Parent Asin",
          field: "asin",
          pinned: "left",
          width: 150,
          wrapText: true,
          autoHeight: true,
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: [
              "contains",
              "notContains",
              "startsWith",
              "endsWith",
              "equals",
            ],
            suppressAndOrCondition: true,
          },
          //hide: true,
        },*/
        {
          headerName: "Image",
          field: "image",
          //pinned: "left",
          filter: null,
          autoHeight: true,
          cellRendererSelector: (params) => {
            return {
              component: ProductImageRenderer,
            };
          },
          sortable: false,
          enableValue: true,
          aggFunc: "sum",
        },
        {
          headerName: "Product Name",
          field: "productName",
          width: 200,
          wrapText: true,
          autoHeight: true,
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: [
              "contains",
              "notContains",
              "startsWith",
              "endsWith",
              "equals",
            ],
            suppressAndOrCondition: true,
          },
          cellRendererSelector: (params) => {
            return {
              component: ProductNameRenderer,
            };
          },
          enableValue: true,
          aggFunc: "sum",
        },
        {
          headerName: "Cost",
          field: "productCost",
          width: 100,
          enableValue: true,
          aggFunc: "sum",
        },
      ],
    },

    ...common_fields,
    orders_field,
    organic_sales_field,
    revenue_field,
    tacos_field,
    goal_type,
    goal_value,
    goal_vals,
    goal_vals_p,
  ];

  const rawColumns = columnDefs;
  function setRawColumns() {}
  const [parentInfo, setParentInfo] = useState({});

  const [rawTableData, setRawTableData] = useState([{}]);
  const [summaryData, setSummaryData] = useState([]);

  const [dateState, setDateState] = useState(loadDate());

  const [pageSize, setPageSize] = useState(
    lastInfo?.pageSize ? lastInfo.pageSize : 50
  );
  const [page, setPage] = useState(1);
  const [noPages, setNoPages] = useState(0);
  const [count, setCount] = useState(0);

  const [orderBy, setOrderBy] = useState([]);
  const [filterBy, setFilterBy] = useState(
    restoreFilters(lastFilterKey, {}) || {}
  );
  const [debouncedFilterBy, setDebouncedFilterBy] = useState({});
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedFilterBy(filterBy);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [filterBy, 500]);
  const { setBreadcrumb } = useNavbar();
  const { setPagination } = useFooter();

  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState("");
  useMemo(() => {
    const { selection, compare } = dateState;
    const selStartDate = pickerToNorm(selection.startDate);
    const selEndDate = pickerToNorm(selection.endDate);
    const comStartDate = pickerToNorm(compare.startDate);
    const comEndDate = pickerToNorm(compare.endDate);
    get_advertised_products2_summary(
      profileId,
      selStartDate,
      selEndDate,
      comStartDate,
      comEndDate,
      debouncedFilterBy,
      search
    ).then((res) => {
      setTimeout(
        gridRef?.current?.api?.setPinnedBottomRowData(getTotals(res.data)),
        0
      );
    });
  }, [profileId, dateState, debouncedFilterBy, search]);
  function getGroupKeys(groupKeys) {
    if (groupKeys.length > 0) {
      return [
        ...groupKeys,
        {
          id: "ad_id",
          displayName: "Ad",
          field: "ad_id",
        },
      ];
    }
    return groupKeys;
  }
  function getFilters(groupKeys, group) {
    const groups = groupKeys.map((gk) => gk.id);
    console.log("grouping2 group keys: ", groups);
    console.log("grouping2 groups: ", group);
    console.log("grouping2 filtering by: ", groups[0]);
    group.map((a) => groups.shift());
    let splittedFilters = {};
    Object.keys(debouncedFilterBy).map((fk) => {
      const splittedKey = fk.split("-");
      const gg = groups[0].replace("campaignName", "campaign");
      if (splittedKey[0] == gg) {
        splittedFilters[splittedKey[0]] = debouncedFilterBy[fk];
      }
    });
    console.log("grouping2 filter by is:  ", debouncedFilterBy);
    console.log("grouping2 filter by is splitted: ", splittedFilters);
    return filterObjToArray(splittedFilters);
    //Object.keys()
  }
  const datasource = {
    getRows(params) {
      console.log("grid making request");
      //console.log(JSON.stringify(params.request, null, 1));
      const { selection, compare } = dateState;
      const selStartDate = pickerToNorm(selection.startDate);
      const selEndDate = pickerToNorm(selection.endDate);
      const comStartDate = pickerToNorm(compare.startDate);
      const comEndDate = pickerToNorm(compare.endDate);
      const queryParams = [
        profileId,
        selStartDate,
        selEndDate,
        comStartDate,
        comEndDate,
        pageSize,
        page,
        agOrderToNorm(params.request.sortModel),
        getFilters(
          getGroupKeys(params.request.rowGroupCols),
          params.request.groupKeys
        ),
        search,
        params.request.rowGroupCols,
        params.request.groupKeys,
      ];
      const cache_key = JSON.stringify(queryParams);

      if (MEMO.current[cache_key]) {
        console.log("grid making request cache", cache_key);
        setTimeout(
          params.successCallback(
            MEMO.current[cache_key].map((d) => {
              return {
                ...d,
                profile_id: profileId,
                grouped,
                profileId,
              };
            })
          ),
          0
        );
        return;
      } else {
        console.log("grid making request cache key not in memo", {
          cache_key,
          MEMO,
        });
        //setFilterBy(params.request.filterModel);
        console.log("grid making request live, ", queryParams);
        get_advertised_products2(...queryParams)
          .then((res) => {
            //setRawTableData(!res.data || res.data.length === 0 ? [{}] : res.data);
            MEMO.current = {
              ...MEMO.current,
              [cache_key]: res.data,
            };
            //setNoPages(res.pages);
            //setCount(res.count);
            if (params.request.groupKeys.length == 0) {
              setNoPages(res.pages);
              setCount(res.count);
            }

            setTimeout(
              params.successCallback(
                res.data.map((d) => {
                  return {
                    ...d,
                    profile_id: profileId,
                    grouped,
                    profileId,
                  };
                })
              ),
              0
            );
          })
          .catch((error) => {
            console.error(error);
            //gridRef.current.api.hideOverlay();
            params.failCallback();
          });
      }
    },
  };

  const onGridReady = () => {
    restoreSortState(gridRef, sortStateKey, setOrderBy);
    collapseFilterGroups(gridRef);
    setGridReady(true);
  };
  function onPageSizeChanged(event) {
    setPageSize(Number(event.target.value));
    setPage(1);
  }
  /*
  const onPageSizeChanged = (event) => {
    setPageSize(Number(event.target.value));
  };
*/
  const onSortChanged = (event) => {
    onSortChangedCommon(event, sortStateKey, orderBy, () => {});
  };

  const onFilterChanged = (event) => {
    onFilterChangedCommon(event, lastFilterKey, filterBy, () => {});
  };

  const onColumnMoved = (event) => {
    onColumnMovedCommon(event, parentInfo, columnDefs, columnStateKey);
  };

  const onColumnVisible = (event) => {
    onColumnVisibleCommon(event, columnVisibleKey);
  };

  const onRowDataUpdated = () => {
    onRowDataUpdatedCommon(gridRef, rawTableData);
  };

  useEffect(() => {
    // updateTable()
    console.log("gotta update");
  }, [gridReady, search, dateState, page, pageSize, orderBy, filterBy]);

  useEffect(() => {
    restoreColumnsState(
      columnStateKey,
      columnVisibleKey,
      columnDefs,
      setParentInfo,
      setRawColumns
    );
  }, []);

  useEffect(() => {
    setSearch(searchParams.get("search"));
  }, [searchParams]);

  useEffect(() => {
    setBreadcrumb({ page: "Advertised Products Overview", profileId });
  }, [profileId]);

  useEffect(() => {
    setPagination({
      pageSize,
      onPageSizeChanged,
      setPage,
      noPages,
      page,
      count,
    });
  }, [pageSize, noPages, page, count]);

  useEffect(() => {
    let currentInfo = loadFromLocal(infoKey);

    if (!currentInfo) {
      currentInfo = {};
    }

    currentInfo.pageSize = pageSize;
    saveToLocal(infoKey, currentInfo);
  }, [pageSize]);

  useEffect(() => customizeToolPanel(gridRef), [gridRef]);
  const groupedExclude = [];
  const groupFields = ["parentAsin", "asin"];
  function updateColDef2(rawColumns, child = false) {
    const newCols = rawColumns.map((col) => {
      const newCol = { ...col };
      if (grouped) {
        if (groupedExclude.includes(newCol.field)) {
          newCol.hide = true;
        }
        if (groupFields.includes(newCol.field)) {
          newCol.hide = true;
          newCol.rowGroup = true;
        }
      } else {
        if (groupedExclude.includes(newCol.field)) {
          newCol.hide = false;
        }
        if (groupFields.includes(newCol.field)) {
          newCol.hide = false;
          newCol.rowGroup = false;
        }
      }
      if (newCol.children) {
        newCol.children = updateColDef(newCol.children, true);
      }
      return newCol;
    });
    if (gridRef && gridRef.current && gridRef.current.api && !child) {
      setTimeout(gridRef.current.api.setColumnDefs(newCols), 0);
    }
    return newCols;
  }
  function compare(a, b) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }
  function updateColDef(rawColumns, child = false) {
    const groupedExclude = ["asin", "sku", "parentAsin"];
    const groupFields = grouped.sort(compare).map((group) => group.value);
    console.log("grouping original is: ", grouped);
    console.log("grouping fiels are: ", groupFields);
    const newCols = rawColumns.map((col) => {
      const newCol = { ...col };
      if (newCol.field == "sku") {
        newCol.rowGroup = true;
        newCol.rowGroupOpened = false;
        newCol.hide = true;
        newCol.rowGroupIndex = 5;
      } else {
        if (groupFields.includes(newCol.field)) {
          newCol.hide = true;
          newCol.rowGroup = true;
          newCol.rowGroupIndex = groupFields.indexOf(newCol.field);
        } else {
          newCol.hide = false;
          newCol.rowGroup = false;
        }
      }
      if (groupedExclude.includes(newCol.field) && groupFields.length > 0) {
        newCol.hide = true;
        newCol.aggFunc = null;
      }
      if (newCol.children) {
        newCol.children = updateColDef(newCol.children, true);
      }
      return newCol;
    });
    console.log("grouping new cols: ", newCols);
    if (gridRef && gridRef.current && gridRef.current.api && !child) {
      console.log("is child not, updating...");
      setTimeout(gridRef.current.api.setColumnDefs(newCols), 0);
    }
    return newCols;
  }
  console.log("grouping by is: ", filterBy);
  console.log("TABLE COLS IS: ", tableCols);
  //alert("reloaded");
  const groupingOptions = [
    {
      name: "ASIN",
      value: "asin",
      exclude: [],
      order: 2,
    },
    {
      name: "Parent",
      value: "parentAsin",
      exclude: [],
      order: 1,
    },
  ];
  const tableCols = useMemo(() => {
    saveToLocal(lastGrouping, grouped);
    return updateColDef(rawColumns, false);
  }, [grouped]);
  useEffect(() => {
    console.log("filters2; filters changed saving...");
    onFilterChangedCommon(lastFilterKey, debouncedFilterBy);
    gridRef?.current?.api?.setServerSideDatasource(datasource);
  }, [debouncedFilterBy, dateState, search]);
  const TABLE = useMemo(
    () => (
      <AgGridReact
        ref={gridRef}
        onGridReady={onGridReady}
        columnDefs={tableCols}
        gridOptions={{
          ...agGridOptions,
          onRowGroupOpened: function (params) {
            // Prevent expanding or collapsing for the specific column
            if (params.node.field === "sku") {
              params.node.setExpanded(false); // Always keep it collapsed
            }
          },
        }}
        onDragStarted={onDragStarted}
        onDragStopped={onDragStopped}
        onColumnMoved={onColumnMoved}
        onSortChanged={onSortChanged}
        //onFilterChanged={onFilterChanged}
        onColumnVisible={onColumnVisible}
        //onRowDataUpdated={onRowDataUpdated}
        rowModelType="serverSide"
        serverSideDatasource={datasource}
        groupDisplayType="singleColumn"
        pivotMode={true}
      />
    ),
    [tableCols, page, pageSize, filterBy]
  );
  return (
    <>
      <Helmet title="Advertised Products" />
      <Container fluid className="p-0" style={{ height: "100%" }}>
        {/* <BreadCrumb page="Advertised Products Overview" profileId={profileId} /> */}
        <Row style={{ height: "100%" }}>
          <Col>
            <div
              className="tab"
              style={{
                height: "100%",
                marginBottom: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div
                  className="d-flex align-items-end"
                  style={{
                    marginTop: 18,
                    marginRight: 16,
                    paddingRight: 0,
                  }}
                >
                  <TabNav
                    profileId={profileId}
                    page="advertisedproductsoverview"
                  />
                  <div style={{ flexGrow: 1 }} />
                  <div style={{ marginBottom: "0.5em" }}>
                    <DateModal
                      gridRef={gridRef}
                      dateState={dateState}
                      setDateState={setDateState}
                    />
                  </div>
                </div>
                <Tab.Content style={{ padding: 0, flexGrow: 1 }}>
                  <Tab.Pane
                    eventKey="first"
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        background: "white",
                        padding: 0,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Row className="d-flex justify-content-between align-items-center mb-2">
                        <Col
                          className="d-flex align-items-end"
                          style={{ marginLeft: 0 }}
                        >
                          <button
                            className="filters-button"
                            onClick={() => {
                              setOpenFilters(!openFilters);
                            }}
                          >
                            {openFilters ? (
                              <span style={{ marginRight: 6 }}>Filters</span>
                            ) : null}
                            <FilterIcon style={{ marginRight: 6 }} />
                            {openFilters ? (
                              <FilterLeftArrowIcon />
                            ) : (
                              <FilterRightArrowIcon />
                            )}
                          </button>

                          <FilterChips
                            filterBy={filterBy}
                            setFilterBy={setFilterBy}
                          />
                          {/* <FilterComponent
                            gridRef={gridRef}
                            filterBy={filterBy}
                            page="advertisedProductsOverview"
                          /> */}
                          <div style={{ flexGrow: 1 }} />
                          <GroupedToggle
                            grouped={grouped}
                            setGrouped={setGrouped}
                            groupingOptions={groupingOptions}
                          />
                          <ExportButton
                            onClick={() => {
                              exportToCSV(gridRef, "adgroups.csv");
                            }}
                          />
                          <ColumnsButton
                            onClick={() => {
                              toggleColumnsPanelShow(gridRef);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ flexGrow: 1 }}>
                        <Filters
                          columnsArray={tableCols}
                          openFilters={openFilters}
                          setOpenFilters={setOpenFilters}
                          filterBy={filterBy}
                          setFilterBy={setFilterBy}
                          grouped={
                            grouped.length > 0
                              ? grouped
                                  .sort(compare)
                                  .map((group) => group.value)
                                  .concat(["sku"])
                              : [""]
                          }
                          supportedFilters={GROUP_FIELDS}
                        />
                        <Col>
                          <div className="ag-theme-alpine grid-wrapper">
                            {TABLE}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdvertisedProducts2;
