import { createContext, useState } from "react";

const initialState = {
  breadcrumb: null,
};

const NavbarContext = createContext(initialState);

function NavbarProvider({ children }) {
  const [breadcrumb, setBreadcrumb] = useState(null);

  return (
    <NavbarContext.Provider
      value={{
        breadcrumb,
        setBreadcrumb,
      }}
    >
      {children}
    </NavbarContext.Provider>
  );
}

export { NavbarProvider, NavbarContext };
