import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";

import ConfirmPassword from "../../components/auth/ConfirmPassword";
import avatar from "../../assets/img/opasity_logo_1.svg";

const ConfirmPasswordPage = () => (
    <React.Fragment>
        <Helmet title="Sign In" />
        <div className="text-center mt-4">
            <p className="lead">Enter your new password and Verification Code</p>
        </div>

        <Card>
            <Card.Body>
                <div className="m-sm-4">
                    <div className="text-center">
                        <img
                            src={avatar}
                            alt="Chris Wood"
                            className="img-fluid"
                            width="132"
                            height="132"
                        />
                    </div>
                    <ConfirmPassword />
                </div>
            </Card.Body>
        </Card>
    </React.Fragment>
);

export default ConfirmPasswordPage;
