import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Button } from "react-bootstrap";
import AuthGuard from "../../components/guards/AuthGuardSimple";

const generateState = () => {
    const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let array = new Uint8Array(40);
    window.crypto.getRandomValues(array);
    array = array.map((x) => validChars.codePointAt(x % validChars.length));
    const randomState = String.fromCharCode.apply(null, array);
    return randomState;
};

function connectUrl() {
    const state = generateState();
    sessionStorage.setItem("oauth_state", state);
    const url = "https://www.amazon.com/ap/oa?"
    const clientId = "client_id=amzn1.application-oa2-client.5e073b48d9c94c09ae6b79a0b491476d"
    const responseType = "&response_type=code"
    const redirectUrl = "&redirect_uri=https%3A%2F%2Fapp.opasity.com%2Foauth%2Fcallback"
    const appDetails = `&scope=advertising%3A%3Acampaign_management`
    const stateURL = `&state=${state}`
    return `${url}${clientId}${responseType}${redirectUrl}${appDetails}${stateURL}`
}
const ConnectAMZ = () => (
    <AuthGuard>
        <React.Fragment>
            <Helmet title="Connect Amazon account" />
            <div className="text-center">
                <p className="h1">No Amazon account found</p>
                <p className="h2 fw-normal mt-3 mb-4">
                    Looks like you haven't connected an Amazon account yet, get started by clicking the button below.
                </p>
                <Button variant="warning" size="lg" onClick={() => window.location.href = connectUrl()}>
                    Connect to Amazon
                </Button>
            </div>
        </React.Fragment>
    </AuthGuard>
);

export default ConnectAMZ;
