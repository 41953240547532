import api from "./Ax"

export async function updateNegativeKeywords(profileId, negativeKeywords) {
    const payload = {
        action: "update_negative_keywords",
        profile_id: profileId,
        negativeKeywords
    }
    console.log("payload is: ", payload)
    const result = await api.post('https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/manager', payload)
    console.log("upadted keywords: ", result)
    return result.data
}

export async function deleteNegativeKeywords(profileId, negativeKeywords) {
    const payload = {
        action: "delete_negative_keywords",
        profile_id: profileId,
        negativeKeywords
    }
    console.log("payload is: ", payload)
    const result = await api.post('https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/manager', payload)
    console.log("upadted keywords: ", result)
    return result.data
}