import React from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { store } from "./redux/store";

import "./i18n";
import routes from "./routes";

import { ThemeProvider } from "contexts/ThemeContext";
import { SidebarProvider } from "contexts/SidebarContext";
import { NavbarProvider } from "contexts/NavbarContext";
import { FooterProvider } from "contexts/FooterContext";
import { LayoutProvider } from "contexts/LayoutContext";
import ChartJsDefaults from "utils/ChartJsDefaults";

// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
import { AuthProvider } from "contexts/CognitoContext";
import TagManager from "react-gtm-module";

import "assets/css/opasity/index.css";

const tagManagerArgs = {
  gtmId: "GTM-5NGWN9W",
};

TagManager.initialize(tagManagerArgs);

const App = () => {
  const content = useRoutes(routes);
  window.dataLayer.push({
    event: "pageview",
    url: window.location,
  });
  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | Opasity - Unclouded visibility. Total control"
        defaultTitle="Opasity - Unclouded visibility. Total control"
      />
      <Provider store={store}>
        <ThemeProvider>
          <SidebarProvider>
            <NavbarProvider>
              <FooterProvider>
                <LayoutProvider>
                  <ChartJsDefaults />
                  <AuthProvider>{content}</AuthProvider>
                </LayoutProvider>
              </FooterProvider>
            </NavbarProvider>
          </SidebarProvider>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
