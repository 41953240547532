import { useEffect, useState, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Tab,
} from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { ReactComponent as FilterIcon } from "assets/img/icons/light-filter.svg";
import { ReactComponent as FilterLeftArrowIcon } from "assets/img/icons/filter-left-arrow.svg";
import { ReactComponent as FilterRightArrowIcon } from "assets/img/icons/filter-right-arrow.svg";
import {
  selection_box,
  common_fields,
  campaign_name_field,
  campaign_state_field,
  campaign_daily_budget_field,
  campaign_type_field,
} from "pages/opasity/opasityApi/ag_grid_fields";

import {
  get_campaigns_data,
  get_campaigns_summary_data,
  get_campaigns_data_pages,
} from "pages/opasity/opasityApi/campaignsData";

import {
  updateCampaign,
  deleteCampaign,
} from "pages/opasity/opasityApi/updateCampaign";

import {
  pickerToNorm,
  loadDate,
  saveToLocal,
  loadFromLocal,
} from "pages/opasity/opasityApi/utils";

import {
  agGridOptions,
  restoreColumnsState,
  restoreSortState,
  restoreFilters,
  onDragStarted,
  onDragStopped,
  onColumnMoved as onColumnMovedCommon,
  onSortChanged as onSortChangedCommon,
  onFilterChanged as onFilterChangedCommon,
  onColumnVisible as onColumnVisibleCommon,
  onRowDataUpdated as onRowDataUpdatedCommon,
  collapseFilterGroups,
  toggleColumnsPanelShow,
  exportToCSV,
  getTotals,
  customizeToolPanel,
  filterObjToArray,
} from "utils/agGridCommon";

import DateModal from "components/opasity/DateModal";
import Pagination from "components/opasity/Pagination";
import FilterComponent from "components/opasity/FilterComponent";
import ExportButton from "components/opasity/ExportButton";
import ColumnsButton from "components/opasity/ColumnsButton";
import TabNav from "components/opasity/TabNav";

import useNavbar from "hooks/useNavbar";
import useFooter from "hooks/useFooter";
import CampaignNav from "components/opasity/CampaignNav";
import Filters from "../../../components/Filters";
import FilterChips from "../../../components/FilterChips";
import { useSearchDebounce } from "../utils";

const AllCampaigns = ({ type }) => {
  const typeFilter = type
    ? {
        column: "campaignType",
        filterType: "equals",
        value: type,
      }
    : null;

  const { profileId } = useParams();

  const gridRef = useRef();
  const [gridReady, setGridReady] = useState(false);

  const keySuffix = "campaigns";
  const columnStateKey = `last-column-state-${keySuffix}`;
  const columnVisibleKey = `last-column-visible-${keySuffix}`;
  const sortStateKey = `last-sort-state-${keySuffix}`;
  const lastFilterKey = `last-filter-${keySuffix}`;
  const infoKey = `last-info-${keySuffix}`;

  const lastInfo = loadFromLocal(infoKey);

  const columnDefs = [
    {
      headerName: "Campaign Info",
      suppressColumnsToolPanel: true,
      children: [
        selection_box,
        campaign_type_field,
        campaign_state_field,
        campaign_name_field,
        campaign_daily_budget_field,
      ],
    },
    ...common_fields,
  ];

  selection_box.pinned = "left";
  campaign_state_field.pinned = "left";
  campaign_name_field.pinned = "left";
  campaign_daily_budget_field.pinned = null;
  campaign_type_field.pinned = "left";
  const [openFilters, setOpenFilters] = useState(false);
  const [rawColumns, setRawColumns] = useState([]);
  const [parentInfo, setParentInfo] = useState({});

  const [rawTableData, setRawTableData] = useState([{}]);
  const [summaryData, setSummaryData] = useState([]);

  const [dateState, setDateState] = useState(loadDate());

  const [selectedCampaigns, setSelectedCampaigns] = useState([]);

  const [pageSize, setPageSize] = useState(
    lastInfo?.pageSize ? lastInfo.pageSize : 50
  );
  const [page, setPage] = useState(1);
  const [noPages, setNoPages] = useState(0);
  const [count, setCount] = useState(0);

  const [orderBy, setOrderBy] = useState([]);
  const [filterBy, setFilterBy] = useState(
    restoreFilters(lastFilterKey, {}) || {}
  );
  const [debFilter, setDebFilter] = useSearchDebounce(300);
  const { setBreadcrumb } = useNavbar();
  const { setPagination } = useFooter();

  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState("");

  const updateTable = () => {
    if (!gridRef.current.api || !gridReady) {
      return;
    }

    const { selection, compare } = dateState;

    const selStartDate = pickerToNorm(selection.startDate);
    const selEndDate = pickerToNorm(selection.endDate);
    const comStartDate = pickerToNorm(compare.startDate);
    const comEndDate = pickerToNorm(compare.endDate);

    gridRef.current.api.showLoadingOverlay();

    let queryFilter = filterObjToArray(filterBy);
    if (typeFilter) {
      queryFilter = filterBy.concat(typeFilter);
    }

    get_campaigns_data(
      profileId,
      selStartDate,
      selEndDate,
      comStartDate,
      comEndDate,
      pageSize,
      page,
      orderBy,
      queryFilter,
      search
    )
      .then((res) => {
        setRawTableData(!res.data || res.data.length === 0 ? [{}] : res.data);

        get_campaigns_summary_data(
          profileId,
          selStartDate,
          selEndDate,
          comStartDate,
          comEndDate,
          queryFilter,
          search
        ).then((res) => {
          setSummaryData(getTotals(res.data));
        });

        get_campaigns_data_pages(
          profileId,
          selStartDate,
          selEndDate,
          comStartDate,
          comEndDate,
          pageSize,
          page,
          orderBy,
          queryFilter,
          search
        ).then((res) => {
          setNoPages(res.pages);
          setCount(res.count);
        });
      })
      .catch(() => {
        gridRef.current.api.hideOverlay();
      });
  };

  const onGridReady = () => {
    restoreSortState(gridRef, sortStateKey, setOrderBy);

    collapseFilterGroups(gridRef);

    setGridReady(true);
  };

  const onPageSizeChanged = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onSortChanged = (event) => {
    onSortChangedCommon(event, sortStateKey, orderBy, setOrderBy);
  };

  const onFilterChanged = (event) => {
    onFilterChangedCommon(lastFilterKey, filterBy);
  };

  const onColumnMoved = (event) => {
    onColumnMovedCommon(event, parentInfo, columnDefs, columnStateKey);
  };

  const onColumnVisible = (event) => {
    onColumnVisibleCommon(event, columnVisibleKey);
  };

  const onRowDataUpdated = () => {
    onRowDataUpdatedCommon(gridRef, rawTableData);
  };

  const onSelectionChanged = (event) => {
    setSelectedCampaigns(event.api.getSelectedNodes());
  };

  const isRowSelectable = (row) => {
    return row.data.campaignState && row.data.campaignState !== "archived";
  };

  const SelectedCampaigns = () => {
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [budgetAdjustmentType, setBudgetAdjustmentType] =
      useState("setAmount");
    const [budgetAdjustment, setBudgetAdjustment] = useState();
    const [error, setError] = useState("");

    const handleBudgetTypeChange = (event) => {
      setBudgetAdjustmentType(event.target.value);
    };

    const handleBudgetChange = (event) => {
      setBudgetAdjustment(parseFloat(event.target.value));
    };

    const getNewBudget = (oldBudget) => {
      if (budgetAdjustmentType === "setAmount") {
        return budgetAdjustment;
      }
      if (budgetAdjustmentType === "increaseAmount") {
        return parseFloat(oldBudget) + budgetAdjustment;
      }
      if (budgetAdjustmentType === "decreaseAmount") {
        return parseFloat(oldBudget) - budgetAdjustment;
      }
      if (budgetAdjustmentType === "increasePercent") {
        return parseFloat(oldBudget) + (oldBudget * budgetAdjustment) / 100;
      }
      if (budgetAdjustmentType === "decreasePercent") {
        return parseFloat(oldBudget) - (oldBudget * budgetAdjustment) / 100;
      }
    };

    const checkNewBudget = (newBudget) => {
      if (isNaN(newBudget)) {
        setError("Value must be a number!");
        return {
          valid: false,
          error: "Value must be a number!",
        };
      }

      if (newBudget < 0.02) {
        setError("Budget must be >= 0.02");
        return {
          valid: false,
          error: "Budget must be >= 0.02!",
        };
      }

      return { valid: true };
    };

    const pauseCampaigns = () => {
      setSaving(true);

      const campaigns = [];
      const nodes = gridRef.current.api.getSelectedNodes();

      for (let node_index in nodes) {
        campaigns.push({
          campaignId: nodes[node_index].data.campaignId,
          state: "PAUSED",
        });
      }

      updateCampaign(Number(profileId), campaigns)
        .then((res) => {
          setSaving(false);

          for (let i in res.success) {
            for (let j in nodes) {
              if (
                Number(res.success[i].campaignId) == nodes[j].data.campaignId
              ) {
                nodes[j].updateData({
                  ...nodes[j].data,
                  campaignState: res.success[i].state,
                });
                gridRef.current.api.flashCells({
                  rowNodes: [nodes[i]],
                  columns: ["campaignState"],
                });
              }
            }
          }
        })
        .catch(() => {
          setSaving(false);
        });
    };

    const enableCampaigns = () => {
      setSaving(true);

      const campaigns = [];
      const nodes = gridRef.current.api.getSelectedNodes();

      for (let node_index in nodes) {
        campaigns.push({
          campaignId: nodes[node_index].data.campaignId,
          state: "ENABLED",
        });
      }

      updateCampaign(Number(profileId), campaigns)
        .then((res) => {
          setSaving(false);

          for (let i in res.success) {
            for (let j in nodes) {
              if (
                Number(res.success[i].campaignId) == nodes[j].data.campaignId
              ) {
                nodes[j].updateData({
                  ...nodes[j].data,
                  campaignState: res.success[i].state,
                });
                gridRef.current.api.flashCells({
                  rowNodes: [nodes[i]],
                  columns: ["campaignState"],
                });
              }
            }
          }
        })
        .catch(() => {
          setSaving(false);
        });
    };

    const archiveCampaigns = () => {
      setSaving(true);

      const campaigns = [];
      const nodes = gridRef.current.api.getSelectedNodes();

      for (let node_index in nodes) {
        campaigns.push({
          campaignId: nodes[node_index].data.campaignId,
        });
      }

      if (
        window.confirm(
          `You are about to delete ${campaigns.length} campaigns. Are you sure?`
        )
      ) {
        deleteCampaign(Number(profileId), campaigns)
          .then((res) => {
            setSaving(false);

            for (let i in res.success) {
              for (let j in nodes) {
                if (
                  Number(res.success[i].campaignId) == nodes[j].data.campaignId
                ) {
                  nodes[j].updateData({
                    ...nodes[j].data,
                    campaignState: res.success[i].state,
                  });
                  gridRef.current.api.flashCells({
                    rowNodes: [nodes[i]],
                    columns: ["campaignState"],
                  });
                }
              }
            }
          })
          .catch(() => {
            setSaving(false);
          });
      } else {
        setSaving(false);
        return;
      }
    };

    const saveBudget = () => {
      setSaving(true);

      const newBudgetAdjustment = budgetAdjustment;
      setError("");
      const campaigns = [];
      const nodes = gridRef.current.api.getSelectedNodes();

      for (let node_index in nodes) {
        let newBudget = getNewBudget(
          nodes[node_index].data.campaignDailyBudget
        );

        const isValid = checkNewBudget(newBudget);
        if (!isValid.valid) {
          setError(
            isValid.error +
              " on campaign: " +
              nodes[node_index].data.campaignName
          );
          return;
        }
        campaigns.push({
          campaignId: nodes[node_index].data.campaignId,
          budget: newBudget,
          oldBudget: nodes[node_index].data.campaignDailyBudget,
        });
      }

      updateCampaign(Number(profileId), campaigns)
        .then((res) => {
          setSaving(false);

          for (let i in res.success) {
            for (let j in nodes) {
              if (
                Number(res.success[i].campaignId) == nodes[j].data.campaignId
              ) {
                nodes[j].updateData({
                  ...nodes[j].data,
                  campaignDailyBudget: res.success[i].budget,
                });
                gridRef.current.api.flashCells({
                  rowNodes: [nodes[i]],
                  columns: ["campaignDailyBudget"],
                });
              }
            }
          }
        })
        .catch(() => {
          setSaving(false);
        });
    };

    const campaignAdjustmentTypeDetails = {
      setAmount: "$",
      increasePercent: "%+",
      increaseAmount: "$+",
      decreasePercent: "%-",
      decreaseAmount: "$-",
    };

    if (selectedCampaigns.length === 0) {
      return null;
    }

    if (editing) {
      return (
        <div className="d-flex align-items-start mb-1">
          <Form.Select
            id="bidAdjustmentSelect"
            name="bidAdjustmentSelect"
            onChange={handleBudgetTypeChange}
            style={{ marginRight: 12, maxWidth: 200 }}
          >
            <option value="setAmount">set budget to ($)</option>
            <option value="increasePercent">Increase budget by (%)</option>
            <option value="increaseAmount">Increase budget by ($)</option>
            <option value="decreasePercent">Decrease budget by (%)</option>
            <option value="decreaseAmount">Decrease budget by ($)</option>
          </Form.Select>
          <div style={{ marginRight: 36 }}>
            <InputGroup style={{ maxWidth: 150 }}>
              <InputGroup.Text>
                {campaignAdjustmentTypeDetails[budgetAdjustmentType]}
              </InputGroup.Text>
              <Form.Control onChange={handleBudgetChange} />
            </InputGroup>
            <span className="text-danger">{error}</span>
          </div>
          <Button
            variant="btn btn-outline-primary"
            title="save"
            onClick={() => saveBudget()}
            disabled={saving}
            style={{ marginRight: 12 }}
          >
            Save
          </Button>
          <Button
            variant="btn btn-outline-warning"
            title="cancel"
            onClick={() => setEditing(false)}
            disabled={saving}
          >
            Cancel
          </Button>
        </div>
      );
    }
    if (selectedCampaigns.length > 0) {
      return (
        <div className="mb-1">
          <Button
            style={{ marginRight: "6px" }}
            variant="btn btn-outline-primary"
            title="edit"
            onClick={() => setEditing(true)}
            disabled={saving}
          >
            Adjust Budget ({selectedCampaigns.length})
          </Button>
          <Button
            style={{ marginRight: "6px" }}
            variant="btn btn-outline-warning"
            title="edit"
            onClick={() => pauseCampaigns()}
            disabled={saving}
          >
            Pause Campaigns ({selectedCampaigns.length})
          </Button>
          <Button
            style={{ marginRight: "6px" }}
            variant="btn btn-outline-success"
            title="edit"
            onClick={() => enableCampaigns()}
            disabled={saving}
          >
            Enable Campaigns ({selectedCampaigns.length})
          </Button>
          <Button
            style={{ marginRight: "6px" }}
            variant="btn btn-outline-danger"
            title="edit"
            onClick={() => archiveCampaigns()}
            disabled={saving}
          >
            Archive Campaigns ({selectedCampaigns.length})
          </Button>
        </div>
      );
    }
  };

  useEffect(() => {
    updateTable();
    onFilterChanged();
  }, [
    gridReady,
    search,
    profileId,
    dateState,
    page,
    pageSize,
    orderBy,
    debFilter,
    type,
  ]);
  useEffect(() => {
    setDebFilter(filterBy);
  }, [filterBy]);
  useEffect(() => {
    restoreColumnsState(
      columnStateKey,
      columnVisibleKey,
      columnDefs,
      setParentInfo,
      setRawColumns
    );
  }, []);

  useEffect(() => {
    setSearch(searchParams.get("search"));
  }, [searchParams]);

  useEffect(() => {
    setBreadcrumb({ profileId });
  }, [profileId]);

  useEffect(() => {
    setPagination({
      pageSize,
      onPageSizeChanged,
      setPage,
      noPages,
      page,
      count,
    });
  }, [pageSize, noPages, page, count]);

  useEffect(() => {
    let currentInfo = loadFromLocal(infoKey);

    if (!currentInfo) {
      currentInfo = {};
    }

    currentInfo.pageSize = pageSize;
    saveToLocal(infoKey, currentInfo);
  }, [pageSize]);

  useEffect(() => customizeToolPanel(gridRef), [gridRef]);
  console.log("COLUMNS ARE", rawColumns);
  useEffect(() => {
    onFilterChangedCommon(lastFilterKey, filterBy);
  }, [filterBy]);

  const [showCampaignNav, setShowCampaignNav] = useState(false);
  return (
    <>
      <Helmet title="All campaigns" />
      <Container fluid className="p-0" style={{ height: "100%" }}>
        <Row style={{ height: "100%" }}>
          <Col>
            <div
              className="tab"
              style={{
                height: "100%",
                marginBottom: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div
                  className="d-flex align-items-end"
                  style={{
                    marginTop: 18,
                    marginRight: 16,
                    paddingRight: 0,
                  }}
                >
                  <TabNav
                    profileId={profileId}
                    page="campaigns"
                    setShowNav={setShowCampaignNav}
                    showNav={showCampaignNav}
                  />
                  <div style={{ flexGrow: 1 }} />
                  <div style={{ marginBottom: "0.5em" }}>
                    <DateModal
                      gridRef={gridRef}
                      dateState={dateState}
                      setDateState={setDateState}
                    />
                  </div>
                </div>
                <Tab.Content style={{ padding: 0, flexGrow: 1 }}>
                  <Tab.Pane
                    eventKey="first"
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        background: "white",
                        padding: 0,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Row className="d-flex justify-content-between mb-1">
                        <Col
                          className="d-flex align-items-end"
                          style={{ marginLeft: 0 }}
                        >
                          {showCampaignNav ? (
                            <CampaignNav
                              page="campaigns"
                              profileId={profileId}
                              campaignId={null}
                              adGroupId={null}
                              type="campaign"
                              setShowNav={setShowCampaignNav}
                              showNav={showCampaignNav}
                            />
                          ) : selectedCampaigns.length === 0 ? (
                            <>
                              <button
                                className="filters-button"
                                onClick={() => {
                                  setOpenFilters(!openFilters);
                                }}
                              >
                                {openFilters ? (
                                  <span style={{ marginRight: 6 }}>
                                    Filters
                                  </span>
                                ) : null}
                                <FilterIcon style={{ marginRight: 6 }} />
                                {openFilters ? (
                                  <FilterLeftArrowIcon />
                                ) : (
                                  <FilterRightArrowIcon />
                                )}
                              </button>

                              <FilterChips
                                filterBy={filterBy}
                                setFilterBy={setFilterBy}
                              />
                            </>
                          ) : (
                            <SelectedCampaigns />
                          )}
                          <div style={{ flexGrow: 1 }} />
                          <ExportButton
                            onClick={() => {
                              exportToCSV(gridRef, "campaigns.csv");
                            }}
                          />
                          <ColumnsButton
                            onClick={() => {
                              toggleColumnsPanelShow(gridRef);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ flexGrow: 1 }}>
                        <Filters
                          columnsArray={rawColumns}
                          openFilters={openFilters}
                          setOpenFilters={setOpenFilters}
                          filterBy={filterBy}
                          setFilterBy={setFilterBy}
                        />
                        <Col>
                          <div className="ag-theme-alpine grid-wrapper">
                            <AgGridReact
                              ref={gridRef}
                              onGridReady={onGridReady}
                              columnDefs={rawColumns}
                              rowData={rawTableData}
                              gridOptions={agGridOptions}
                              pinnedBottomRowData={summaryData}
                              rowSelection="multiple"
                              isRowSelectable={isRowSelectable}
                              onSelectionChanged={onSelectionChanged}
                              onDragStarted={onDragStarted}
                              onDragStopped={onDragStopped}
                              onColumnMoved={onColumnMoved}
                              onSortChanged={onSortChanged}
                              //onFilterChanged={onFilterChanged}
                              onColumnVisible={onColumnVisible}
                              onRowDataUpdated={onRowDataUpdated}
                              rowModelType={"clientSide"}
                              pivotMode={false}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AllCampaigns;
