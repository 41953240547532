import { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Nav } from "react-bootstrap";

const CampaignNav = ({
  profileId,
  campaignId,
  adGroupId,
  type,
  setShowNav,
  page,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState("");

  useEffect(() => {
    setSearch(searchParams.get("search"));
  }, [searchParams]);

  const navs = [
    {
      title: "Campaigns",
      type: "campaign",
      href: `/profiles/${profileId}/campaigns${
        search ? "?search=" + search : ""
      }`,
    },
    {
      title: "Campaigns & Products",
      type: "campaigns-products",
      href: `/profiles/${profileId}/advertisedproducts${
        search ? "?search=" + search : ""
      }`,
    },
  ];
  return (
    <Nav style={{ marginLeft: "30px", marginTop: "10px" }}>
      {navs.map(({ title, href, type: navType }, index) => (
        <Nav.Item key={index}>
          <Nav.Link
            as={Link}
            to={href}
            active={type === navType}
            onMouseEnter={() => {
              {
                setShowNav(true);
              }
            }}
            onMouseLeave={() => {
              {
                setShowNav(false);
              }
            }}
            style={
              type === navType
                ? {
                    border: "1px solid #1659c7",
                    backgroundColor: "#04223a",
                    color: "white",
                    padding: "6px",
                    borderTopLeftRadius: index === 0 ? "20px" : "0",
                    borderBottomLeftRadius: index === 0 ? "20px" : "0",
                    borderTopRightRadius:
                      index === navs.length - 1 ? "20px" : "0",
                    borderBottomRightRadius:
                      index === navs.length - 1 ? "20px" : "0",
                  } // active
                : {
                    border: "1px solid #1659c7",
                    padding: "6px",
                    color: "#062238",
                    borderTopLeftRadius: index === 0 ? "20px" : "0",
                    borderBottomLeftRadius: index === 0 ? "20px" : "0",
                    borderTopRightRadius:
                      index === navs.length - 1 ? "20px" : "0",
                    borderBottomRightRadius:
                      index === navs.length - 1 ? "20px" : "0",
                  }
            }
          >
            {title}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );
};

export default CampaignNav;
