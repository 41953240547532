import { useEffect, useState, useRef, useMemo } from "react";
import { useParams, useSearchParams, NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Button, Tab } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { ReactComponent as FilterIcon } from "assets/img/icons/light-filter.svg";
import { ReactComponent as FilterLeftArrowIcon } from "assets/img/icons/filter-left-arrow.svg";
import { ReactComponent as FilterRightArrowIcon } from "assets/img/icons/filter-right-arrow.svg";
import {
  common_fields,
  campaign_name_field,
  ad_group_name_field,
  advertised_asin_field,
  advertised_sku_field,
  orders_field,
  revenue_field,
  tacos_field,
  goal_vals,
  goal_vals_p,
  goal_type,
  goal_value,
  parent_asin_field,
  campaign_state_field,
  organic_sales_field
} from "pages/opasity/opasityApi/ag_grid_fields";

import {
  get_advertised_products,
  get_advertised_products_summary,
  get_advertised_products_count,
} from "pages/opasity/opasityApi/advertisedproducts";

import {
  pickerToNorm,
  loadDate,
  saveToLocal,
  loadFromLocal,
} from "pages/opasity/opasityApi/utils";

import {
  agGridOptions,
  restoreColumnsState,
  restoreSortState,
  restoreFilters,
  onDragStarted,
  onDragStopped,
  onColumnMoved as onColumnMovedCommon,
  onSortChanged as onSortChangedCommon,
  onFilterChanged as onFilterChangedCommon,
  onColumnVisible as onColumnVisibleCommon,
  onRowDataUpdated as onRowDataUpdatedCommon,
  collapseFilterGroups,
  toggleColumnsPanelShow,
  exportToCSV,
  getTotals,
  DEFAULT_FILTER,
  customizeToolPanel,
  agOrderToNorm,
  parseFilter,
  filterObjToArray,
} from "utils/agGridCommon";

import DateModal from "components/opasity/DateModal";
import Pagination from "components/opasity/Pagination";
import FilterComponent from "components/opasity/FilterComponent";
import ExportButton from "components/opasity/ExportButton";
import ColumnsButton from "components/opasity/ColumnsButton";
import TabNav from "components/opasity/TabNav";
import useNavbar from "hooks/useNavbar";
import useFooter from "hooks/useFooter";
import GroupedToggle from "components/opasity/GroupedToggle";
import CampaignNav from "components/opasity/CampaignNav";
import { tableColumns } from "pages/tables/data";
import Filters from "components/Filters";
import FilterChips from "components/FilterChips";

function getCommonFields() {
  const commonFields = [];
  common_fields.map((cf) => {
    if (cf.field) {
      commonFields.push(cf.field);
    }
    if (cf.children) {
      cf.children.map((ccf) => {
        if (ccf.field) {
          commonFields.push(ccf.field);
        }
      });
    }
  });
  return commonFields;
}
function getAdditionalFilter(incGoals = true) {
  const result = [];
  const additionalFilters = incGoals
    ? [
        orders_field,
        revenue_field,
        organic_sales_field,
        tacos_field,
        goal_type,
        goal_value,
        goal_vals,
        goal_vals_p,
      ]
    : [orders_field, revenue_field, tacos_field, organic_sales_field];
  additionalFilters.map((cf) => {
    if (cf.field) {
      result.push(cf.field);
    }
    if (cf.children) {
      cf.children.map((ccf) => {
        if (ccf.field) {
          result.push(ccf.field);
        }
      });
    }
  });
  return result;
}
const COMMON_FILTERS = getCommonFields();
const SKU_FILTERS = ["sku", ...COMMON_FILTERS, ...getAdditionalFilter(false)];
const ASIN_FILTERS = ["asin", ...COMMON_FILTERS, ...getAdditionalFilter()];
const PARENT_ASIN_FILTERS = [
  "parentAsin",
  ...COMMON_FILTERS,
  ...getAdditionalFilter(),
];
const GROUP_FIELDS = {
  sku: SKU_FILTERS,
  asin: ASIN_FILTERS,
  parentAsin: PARENT_ASIN_FILTERS,
  campaign: [...COMMON_FILTERS, campaign_name_field.field],
};
const AdvertisedProducts = () => {
  const [openFilters, setOpenFilters] = useState(false);
  const { profileId, campaignId, adGroupId } = useParams();
  const MEMO = useRef({});
  const gridRef = useRef();
  const [gridReady, setGridReady] = useState(false);

  const keySuffix = `adproducts${adGroupId ? "-adgroup" : ""}${
      campaignId ? "-campaign" : ""
  }`;
  const columnStateKey = `last-column-state-${keySuffix}`;
  const columnVisibleKey = `last-column-visible-${keySuffix}`;
  const sortStateKey = `last-sort-state-${keySuffix}`;
  const lastFilterKey = `last-filter-${keySuffix}`;
  const infoKey = `last-info-${keySuffix}`;
  const lastGrouping = `last-grouping-${keySuffix}`;
  const lastInfo = loadFromLocal(infoKey);

  const columnDefs = [
    {
      headerName: "Advertised Products Info",
      suppressColumnsToolPanel: true,
      children: [
        advertised_asin_field,
        advertised_sku_field,
        parent_asin_field,
        ...(campaignId ? [] : [campaign_name_field]),
      ],
    },
    ...common_fields,
    organic_sales_field,
    orders_field,
    revenue_field,
    tacos_field,
    goal_type,
    goal_value,
    goal_vals,
    goal_vals_p,
  ];

  advertised_asin_field.pinned = "left";
  advertised_sku_field.pinned = "left";
  ad_group_name_field.pinned = "left";
  campaign_name_field.pinned = "left";
  parent_asin_field.pinned = "left";
  campaign_name_field.enableRowGroup = true;
  advertised_asin_field.enableRowGroup = true;
  const [rawColumns, setRawColumns] = useState([]);
  const [parentInfo, setParentInfo] = useState({});

  const [rawTableData, setRawTableData] = useState([{}]);
  const [summaryData, setSummaryData] = useState([]);

  const [dateState, setDateState] = useState(loadDate());

  const [pageSize, setPageSize] = useState(
      lastInfo?.pageSize ? lastInfo.pageSize : 50
  );
  const [page, setPage] = useState(1);
  const [noPages, setNoPages] = useState(0);
  const [count, setCount] = useState(0);

  const defaultFilter = campaignId ? {} : DEFAULT_FILTER;

  const [orderBy, setOrderBy] = useState([]);
  const [filterBy, setFilterBy] = useState(
      restoreFilters(lastFilterKey, defaultFilter) || {}
  );
  const [debouncedFilterBy, setDebouncedFilterBy] = useState({});
  useEffect(() => {
    console.log("filtering2 changed to: ", filterBy);
    const timeoutId = setTimeout(() => {
      setDebouncedFilterBy(filterBy);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [filterBy, 500]);
  const { setBreadcrumb } = useNavbar();
  const { setPagination } = useFooter();

  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState("");

  const updateTable = () => {
    return;
    if (!gridRef.current.api || !gridReady) {
      return;
    }
    const { selection, compare } = dateState;
    const selStartDate = pickerToNorm(selection.startDate);
    const selEndDate = pickerToNorm(selection.endDate);
    const comStartDate = pickerToNorm(compare.startDate);
    const comEndDate = pickerToNorm(compare.endDate);
    get_advertised_products_summary(
        profileId,
        Number(adGroupId),
        Number(campaignId),
        selStartDate,
        selEndDate,
        comStartDate,
        comEndDate,
        filterBy,
        search
    ).then((res) => {
      setTimeout(
          gridRef.current.api.setPinnedBottomRowData(getTotals(res.data)),
          0
      );
    });
  };

  const onGridReady = () => {
    console.log("grid ready...");
    restoreSortState(gridRef, sortStateKey, setOrderBy);
    collapseFilterGroups(gridRef);
    setGridReady(true);
  };

  const onPageSizeChanged = (event) => {
    setPageSize(Number(event.target.value));
    setPage(1);
  };

  const onSortChanged = (event) => {
    console.log(
        "sort changed, current order by is: ",
        orderBy,
        "and event is: ",
        event
    );
    onSortChangedCommon(event, sortStateKey, orderBy || [], () => {});
    setPage(1);
  };

  const onFilterChanged = (event) => {
    onFilterChangedCommon(event, lastFilterKey, filterBy, () => {});
  };

  const onColumnMoved = (event) => {
    onColumnMovedCommon(event, parentInfo, columnDefs, columnStateKey);
  };

  const onColumnVisible = (event) => {
    onColumnVisibleCommon(event, columnVisibleKey);
  };

  useEffect(() => {
    updateTable();
  }, [gridReady, search, dateState]);

  useEffect(() => {
    restoreColumnsState(
        columnStateKey,
        columnVisibleKey,
        columnDefs,
        setParentInfo,
        setRawColumns
    );
  }, []);

  useEffect(() => {
    setSearch(searchParams.get("search"));
  }, [searchParams]);

  useEffect(() => {
    setBreadcrumb({
      page: "Advertised Products",
      profileId,
      campaignId,
      adGroupId,
    });
  }, [profileId, campaignId, adGroupId]);

  useEffect(() => {
    setPagination({
      pageSize,
      onPageSizeChanged,
      setPage,
      noPages,
      page,
      count,
    });
  }, [pageSize, noPages, page, count]);

  useEffect(() => {
    let currentInfo = loadFromLocal(infoKey);
    if (!currentInfo) {
      currentInfo = {};
    }
    currentInfo.pageSize = pageSize;
    saveToLocal(infoKey, currentInfo);
  }, [pageSize]);

  useEffect(() => customizeToolPanel(gridRef), [gridRef]);
  function getGroupKeys(groupKeys) {
    if (groupKeys.length > 0) {
      return [
        ...groupKeys,
        {
          id: "ad_id",
          displayName: "Ad",
          field: "ad_id",
        },
      ];
    }
    return groupKeys;
  }
  function getFilters(groupKeys, group) {
    const groups = groupKeys.map((gk) => gk.id);
    console.log("grouping2 group keys: ", groups);
    console.log("grouping2 groups: ", group);
    console.log("grouping2 filtering by: ", groups[0]);
    group.map((a) => groups.shift());
    let splittedFilters = {};
    Object.keys(debouncedFilterBy).map((fk) => {
      console.log("filtering2 fk: ", fk);
      const splittedKey = fk.split("-");
      console.log("filtering2 splitted key: ", splittedKey);
      console.log("filtering2 compare: ", { s: splittedKey[0], g: groups[0] });
      const gg = groups[0].replace("campaignName", "campaign");
      if (splittedKey[0] == gg) {
        splittedFilters[splittedKey[1]] = debouncedFilterBy[fk];
      }
    });
    console.log("filtering2 filter by is:  ", debouncedFilterBy);
    console.log("filtering2 filter by is splitted: ", splittedFilters);
    const res = filterObjToArray(splittedFilters);
    console.log("filtering2----> filtering 2: ", res);
    return res;
    //Object.keys()
  }
  const datasource = {
    getRows(params) {
      console.log(
          "grid making request, sort model: ",
          params.request.sortModel
      );
      getFilters(
          getGroupKeys(params.request.rowGroupCols),
          params.request.groupKeys
      );
      const { selection, compare } = dateState;
      const selStartDate = pickerToNorm(selection.startDate);
      const selEndDate = pickerToNorm(selection.endDate);
      const comStartDate = pickerToNorm(compare.startDate);
      const comEndDate = pickerToNorm(compare.endDate);
      const queryParams = [
        profileId,
        Number(adGroupId),
        Number(campaignId),
        selStartDate,
        selEndDate,
        comStartDate,
        comEndDate,
        pageSize,
        page,
        agOrderToNorm(params.request.sortModel),
        getFilters(
            getGroupKeys(params.request.rowGroupCols),
            params.request.groupKeys
        ),
        search,
        getGroupKeys(params.request.rowGroupCols),
        params.request.groupKeys,
      ];
      const cache_key = JSON.stringify(queryParams);

      if (MEMO.current[cache_key]) {
        console.log("grid making request cache", cache_key);
        setTimeout(
            params.successCallback(
                MEMO.current[cache_key].map((row) => {
                  return { ...row, profile_id: profileId, profileId };
                })
            ),
            0
        );
        return;
      } else {
        console.log("grid making request cache key not in memo", {
          cache_key,
          MEMO,
        });
        //setFilterBy(params.request.filterModel);
        console.log("grid making request live, ", queryParams);
        get_advertised_products(...queryParams)
            .then((res) => {
              //setRawTableData(!res.data || res.data.length === 0 ? [{}] : res.data);
              MEMO.current = { ...MEMO.current, [cache_key]: res.data };
              if (params.request.groupKeys.length == 0) {
                setNoPages(res.pages);
                setCount(res.count);
              }
              setTimeout(
                  params.successCallback(
                      res.data.map((row) => {
                        return { ...row, profile_id: profileId, profileId };
                      })
                  ),
                  0
              );
            })
            .catch((error) => {
              console.error(error);
              //gridRef.current.api.hideOverlay();
              params.failCallback();
            });
      }
    },
  };
  const [grouped, setGrouped] = useState(loadFromLocal(lastGrouping) || []);
  const groupedExclude = ["advertised_sku", "adGroupName"];
  const groupFields = ["advertised_asin"];

  function getGrouped(){
    if(campaignId || adGroupId){
      return []
    }
    return grouped
  }
  function compare(a, b) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }
  const groupingOptions = [
    {
      name: "ASIN",
      value: "asin",
      exclude: ["sku", "parentAsin", "adGroupName"],
      order: 2,
    },
    {
      name: "Parent",
      value: "parentAsin",
      exclude: ["sku", "asin", "adGroupName"],
      order: 1,
    },
    {
      value: "sku",
      name: "SKU",
      exclude: ["asin", "parentAsin", "adGroupName"],
      order: 3,
    },
  ];

  function updateColDef(rawColumns, child = false) {
    const groupedExclude = ["asin", "sku", "parentAsin"];
    const groupFields = getGrouped().sort(compare).map((group) => group.value);
    const contextExlude = campaignId||adGroupId?[
        'tacos','tacos_1','tacos_diff','tacos_diff_p',
        'total_income','total_income_1','total_income_diff','total_income_diff_p',
        'qty','qty_1','qty_diff','qty_diff_p',
        'goalTarget', 'goalValue',
        'goalReach', 'goalReach_1', 'goalReachDiff', 'goalReachDiffP',
        'goalReachPercent', 'goalReachPercent_1', 'goalReachDiffPercent', 'goalReachDiffPercentP'
    ]:[]

    console.log("grouping original is: ", getGrouped());
    console.log("grouping fiels are: ", groupFields);
    const newCols = rawColumns.map((col) => {
      const newCol = { ...col };
      if (newCol.field == "campaignName" && groupFields.length > 0) {
        newCol.hide = true;
        newCol.rowGroup = true;
        newCol.rowGroupIndex = 5;
        newCol.aggFunc = null;
      }
      if (groupFields.includes(newCol.field)) {
        newCol.hide = true;
        newCol.rowGroup = true;
        newCol.rowGroupIndex = groupFields.indexOf(newCol.field);
        newCol.aggFunc = "sum";
      } else {
        if (
            groupingOptions.map((option) => option.value).includes(newCol.field)
        ) {
          newCol.hide = false;
          newCol.rowGroup = false;
          newCol.aggFunc = "sum";
        }
      }
      if (groupedExclude.includes(newCol.field) && groupFields.length > 0) {
        newCol.hide = true;
        newCol.aggFunc = null;
      }
      if(contextExlude.includes(newCol.field)){
        newCol.hide = true;
        newCol.aggFunc = null;
      }
      if (newCol.children) {
        newCol.children = updateColDef(newCol.children, true);
      }
      return newCol;
    });
    if (gridRef && gridRef.current && gridRef.current.api && !child) {
      setTimeout(gridRef.current.api.setColumnDefs(newCols), 0);
    }
    return newCols;
  }
  const tableCols = useMemo(() => {
    saveToLocal(lastGrouping, grouped);
    return updateColDef(rawColumns);
  }, [rawColumns, grouped]);

  //alert("reloaded");
  useEffect(() => {
    onFilterChangedCommon(lastFilterKey, debouncedFilterBy);
    gridRef?.current?.api?.setServerSideDatasource(datasource);
  }, [debouncedFilterBy, dateState, search]);

  const TABLE = useMemo(
      () => (
          <AgGridReact
              ref={gridRef}
              onGridReady={onGridReady}
              columnDefs={tableCols}
              gridOptions={agGridOptions}
              onDragStarted={(e) => setTimeout(onDragStarted(e), 0)}
              onDragStopped={(e) => setTimeout(onDragStopped(e), 0)}
              onColumnMoved={(e) => setTimeout(onColumnMoved(e), 0)}
              onSortChanged={(e) => {
                setTimeout(onSortChanged(e), 0);
              }}
              onColumnVisible={(e) => setTimeout(onColumnVisible(e), 0)}
              //onRowDataUpdated={onRowDataUpdated}
              rowModelType="serverSide"
              serverSideDatasource={datasource}
              groupDisplayType="singleColumn"
              pivotMode={true}
          />
      ),
      [tableCols]
  );

  const [showCampaignNav, setShowCampaignNav] = useState(false);
  return (
      <>
        <Helmet title="Advertised Products" />
        <Container fluid className="p-0" style={{ height: "100%" }}>
          <Row style={{ height: "100%" }}>
            <Col>
              <div
                  className="tab"
                  style={{
                    height: "100%",
                    marginBottom: 0,
                    display: "flex",
                    flexDirection: "column",
                  }}
              >
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <div
                      className="d-flex align-items-end"
                      style={{
                        marginTop: 18,
                        marginRight: 16,
                        paddingRight: 0,
                      }}
                  >
                    <TabNav
                        profileId={profileId}
                        campaignId={campaignId}
                        adGroupId={adGroupId}
                        page={campaignId ? "advertisedproducts" : "campaigns"}
                        setShowNav={setShowCampaignNav}
                        showNav={showCampaignNav}
                    />
                    <div style={{ flexGrow: 1 }} />
                    <div style={{ marginBottom: "0.5em" }}>
                      <DateModal
                          gridRef={gridRef}
                          dateState={dateState}
                          setDateState={setDateState}
                      />
                    </div>
                  </div>
                  <Tab.Content style={{ padding: 0, flexGrow: 1 }}>
                    <Tab.Pane
                        eventKey="first"
                        style={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                    >
                      <div
                          style={{
                            background: "white",
                            padding: 0,
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                      >
                        <Row className="d-flex justify-content-between mb-1">
                          <Col
                              className="d-flex align-items-end"
                              style={{ marginLeft: 0 }}
                          >
                            {showCampaignNav && !campaignId && !adGroupId ? (
                                <CampaignNav
                                    page="campaigns"
                                    profileId={profileId}
                                    campaignId={null}
                                    adGroupId={null}
                                    type="campaigns-products"
                                    setShowNav={setShowCampaignNav}
                                    showNav={showCampaignNav}
                                />
                            ) : (
                                <>
                                  <button
                                      className="filters-button"
                                      onClick={() => {
                                        setOpenFilters(!openFilters);
                                      }}
                                  >
                                    {openFilters ? (
                                        <span style={{ marginRight: 6 }}>
                                    Filters
                                  </span>
                                    ) : null}
                                    <FilterIcon style={{ marginRight: 6 }} />
                                    {openFilters ? (
                                        <FilterLeftArrowIcon />
                                    ) : (
                                        <FilterRightArrowIcon />
                                    )}
                                  </button>

                                  <FilterChips
                                      filterBy={filterBy}
                                      setFilterBy={setFilterBy}
                                  />
                                </>
                            )}
                            {/* <FilterComponent
                            gridRef={gridRef}
                            filterBy={filterBy}
                            defaultFilter={defaultFilter}
                            page="advertisedProducts"
                          /> */}
                            <div style={{ flexGrow: 1 }} />
                            {!campaignId && !adGroupId && <GroupedToggle
                                grouped={grouped}
                                setGrouped={setGrouped}
                                groupingOptions={groupingOptions}
                            />}

                            <ExportButton
                                onClick={() => {
                                  exportToCSV(gridRef, "adproducts.csv");
                                }}
                            />
                            <ColumnsButton
                                onClick={() => {
                                  toggleColumnsPanelShow(gridRef);
                                }}
                            />
                          </Col>
                        </Row>

                        <Row style={{ flexGrow: 1 }}>
                          <Filters
                              columnsArray={tableCols}
                              openFilters={openFilters}
                              setOpenFilters={setOpenFilters}
                              filterBy={filterBy}
                              setFilterBy={setFilterBy}
                              grouped={
                                getGrouped().length > 0
                                    ? getGrouped()
                                        .sort(compare)
                                        .map((group) => group.value)
                                        .concat(["campaign"])
                                    : [""]
                              }
                              supportedFilters={GROUP_FIELDS}
                          />
                          <Col>
                            <div className="ag-theme-alpine grid-wrapper">
                              {TABLE}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Col>
          </Row>
        </Container>
      </>
  );
};

export default AdvertisedProducts;

function ServerSideFilter({ filterBy }) {}
