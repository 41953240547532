import { useLocation } from "react-router-dom";

import reduceChildRoutes from "./reduceChildRoutes";

const SidebarNavList = ({ pages, depth }) => {
  const { pathname } = useLocation();

  const childRoutes = pages.reduce(
    (items, page, index, arr) =>
      reduceChildRoutes({
        items,
        page,
        pathname,
        depth,
        index,
        length: arr.length,
      }),
    []
  );

  return <>{childRoutes}</>;
};

export default SidebarNavList;
