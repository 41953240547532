import { useState, useEffect } from "react";
import { FormControl, Form, Row, Button, Col } from "react-bootstrap";
import { updateCatalogItem } from "../opasityApi/catalogManager";

import { ReactComponent as Pencil } from "assets/img/icons/pencil.svg";
import { ReactComponent as Cross } from "assets/img/icons/cross.svg";
import { ReactComponent as Tick } from "assets/img/icons/tick.svg";

function ProductImageRenderer(params) {
  return params.value ? (
    <img
      src={params.value}
      width="80px"
      height="80px"
      style={{ paddingTop: "5px", paddingBottom: "5px" }}
    />
  ) : (
    ""
  );
}
function GoalTargetRenderer(params) {
  const [val, setVal] = useState(params?.data?.goal_target);
  const [newVal, setNewVal] = useState(params?.data?.goal_target);
  const [isSaving, setIsSaving] = useState(false);
  function saveChange() {
    setIsSaving(true);
    const payload = {
      profile_id: Number(params.data.profile_id),
      data: {
        goal_target: newVal,
      },
    };
    console.log("PARAMS IS : ", params);
    if (params.data.grouped && !params.data.asin) {
      payload["parentAsin"] = params.data.parent_asin;
    } else {
      payload["asin"] = params.data.asin;
    }
    updateCatalogItem(payload).then(
      (res) => {
        console.log("res is: ", res);
        setIsSaving(false);
        setVal(newVal);
      },
      (error) => {
        console.log("error: ", error);
        setIsSaving(false);
      }
    );
  }
  return params.data.asin && params.data.sku ? (
    "-"
  ) : (
    <Row>
      <Col md={val != newVal ? "7" : "8"} style={{ paddingRight: 0 }}>
        <Form.Select onChange={(e) => setNewVal(e.target.value)}>
          <option value={null} selected={newVal == null}></option>
          <option value="clicks" selected={newVal == "clicks"}>
            Clicks
          </option>
          <option value="cost" selected={newVal == "cost"}>
            Ad Cost
          </option>
          <option value="orders" selected={newVal == "orders"}>
            Orders
          </option>
          <option value="sales" selected={newVal == "sales"}>
            Sales
          </option>
          <option value="acos" selected={newVal == "acos"}>
            ACOS
          </option>
          <option value="tacos" selected={newVal == "tacos"}>
            TACOS
          </option>
        </Form.Select>
      </Col>
      {val != newVal && (
        <Col md="2">
          <Button onClick={saveChange} disabled={isSaving}>
            Save
          </Button>
        </Col>
      )}
    </Row>
  );
}

const GoalValueRenderer = ({ data, eGridCell }) => {
  const [goalValue, setGoalValue] = useState(data?.goal_value || "");
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (editing) {
      eGridCell.classList.add("inline-cell-editing");
    } else {
      eGridCell.classList.remove("inline-cell-editing");
    }
  }, [editing]);

  const payload = {
    profile_id: Number(data.profile_id),
    data: {
      goal_value: goalValue,
    },
  };

  if (data.grouped && !data.asin) {
    payload["parentAsin"] = data.parent_asin;
  } else {
    payload["asin"] = data.asin;
  }
  const saveGoalValue = () => {
    setSaving(true);

    updateCatalogItem(payload).then(
      () => {
        setSaving(false);
        setEditing(false);
      },
      () => {
        setSaving(false);
      }
    );
  };

  return (
    <Row>
      <Col
        xs={12}
        className="d-flex align-items-center"
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        {editing ? (
          <>
            <input
              value={goalValue}
              onChange={(e) => {
                if (
                  /^([1-9]\d*(\.|\,)\d*|0?(\.|\,)\d*[1-9]\d*|[1-9]\d*)$/.test(
                    e.target.value
                  )
                ) {
                  setGoalValue(e.target.value);
                }
              }}
              min="0"
              className="opasity-grid-number-input"
              disabled={!editing}
            />
            <button
              className="opasity-grid-circle-button opasity-grid-circle-button-cancel"
              onClick={() => {
                setGoalValue(data?.goal_value);
                setEditing(false);
              }}
              disabled={saving}
            >
              <Cross />
            </button>
            <button
              className="opasity-grid-circle-button opasity-grid-circle-button-ok"
              onClick={() => saveGoalValue()}
              disabled={saving}
            >
              <Tick />
            </button>
          </>
        ) : (
          <>
            <span>{goalValue ? `$ ${goalValue}` : "N/A"}</span>
            <div style={{ flexGrow: 1 }} />
            <Pencil
              style={{ cursor: "pointer" }}
              onClick={() => {
                setEditing(true);
              }}
            />
          </>
        )}
      </Col>
    </Row>
  );
};

const CostRenderer = ({ data, eGridCell }) => {
  const [cost, setCost] = useState(data?.cost || "");
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (editing) {
      eGridCell.classList.add("inline-cell-editing");
    } else {
      eGridCell.classList.remove("inline-cell-editing");
    }
  }, [editing]);

  const payload = {
    profile_id: Number(data.profile_id),
    data: {
      cost: parseFloat(cost),
    },
  };

  if (data.grouped && !data.asin) {
    payload["parentAsin"] = data.parent_asin;
  } else {
    payload["asin"] = data.asin;
  }

  const saveProductCost = () => {
    setSaving(true);
    
    updateCatalogItem(payload).then(
      () => {
        setSaving(false);
        setEditing(false);
      },
      () => {
        setSaving(false);
      }
    );
  };

  return (
    <Row>
      <Col
        xs={12}
        className="d-flex align-items-center"
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        {editing ? (
          <>
            <input
              value={cost}
              onChange={(e) => {
                if (
                  /^([1-9]\d*(\.|\,)\d*|0?(\.|\,)\d*[1-9]\d*|[1-9]\d*)$/.test(
                    e.target.value
                  )
                ) {
                  setCost(e.target.value);
                }
              }}
              min="0"
              className="opasity-grid-number-input"
              disabled={!editing}
            />
            <button
              className="opasity-grid-circle-button opasity-grid-circle-button-cancel"
              onClick={() => {
                setCost(data?.cost);
                setEditing(false);
              }}
              disabled={saving}
            >
              <Cross />
            </button>
            <button
              className="opasity-grid-circle-button opasity-grid-circle-button-ok"
              onClick={() => saveProductCost()}
              disabled={saving}
            >
              <Tick />
            </button>
          </>
        ) : (
          <>
            <span>{cost ? `$ ${cost}` : "N/A"}</span>
            <div style={{ flexGrow: 1 }} />
            <Pencil
              style={{ cursor: "pointer" }}
              onClick={() => {
                setEditing(true);
              }}
            />
          </>
        )}
      </Col>
    </Row>
  );
};

function ProductNameRenderer(params) {
  const [name, setName] = useState(params?.data?.name || "");
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  function saveProductName() {
    setIsSaving(true);
    console.log("DATA IS: ", params.data);
    updateCatalogItem(params.data.profile_id, params.data.asin, { name }).then(
      (res) => {
        console.log("res is: ", res);
        setIsSaving(false);
        setIsEditing(false);
      },
      (error) => {
        console.log("error: ", error);
        setIsSaving(false);
      }
    );
  }
  return (
    <>
      {!isEditing && (
        <div
          className="ag-cell-auto-height ag-cell-wrap-text"
          onDoubleClick={() => setIsEditing(true)}
        >
          <div className="ag-cell-wrapper">{name}</div>
        </div>
      )}
      {isEditing && (
        <Row style={{ padding: 8 }}>
          <Col
            md={12}
            style={{ padding: 0 }}
            onClick={() => setIsEditing(true)}
          >
            <Form.Control
              onClick={() => {
                setIsEditing(true);
                console.log("clicked...");
              }}
              value={name}
              disabled={!isEditing}
              style={{ border: 0 }}
              as="textarea"
              rows="4"
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
          <Col style={{ textAlign: "right", marginTop: 8 }} md={12}>
            <Row>
              <Col md="12">
                <Button
                  size="sm"
                  variant="success"
                  style={{ marginRight: 3 }}
                  onClick={saveProductName}
                >
                  Save
                </Button>
                <Button
                  size="sm"
                  variant="danger"
                  onClick={() => setIsEditing(false)}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
}

const asin = {
  headerName: "Asin",
  field: "asin",
  minWidth: 150,
  filter: "agTextColumnFilter",
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  // pinned: "left",
  
};

const sku = {
  headerName: "Sku",
  valueGetter: (params) => params?.data?.sku,
  field: "sku",
  maxWidth: 120,
  pinned: "left",
  filter: "agTextColumnFilter",
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
};
const product_name = {
  headerName: "Product Name",
  field: "name",
  autoHeight: true,
  filter: "agTextColumnFilter",
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  cellRendererSelector: (params) => {
    return {
      component: ProductNameRenderer,
    };
  },
  minWidth: 400,
  maxWidth: 500,
};

const parent_asin = {
  headerName: "Parent asin",
  valueGetter: (params) => params?.data?.parent_asin,
  filter: "agTextColumnFilter",
  field: "parent_asin",
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },

};

const categories = {
  headerName: "Categories",
  valueGetter: (params) => params?.data?.categories,
  filter: "agTextColumnFilter",
  field: "categories",
  autoHeight: true,
  wrapText: true,
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
};

const image = {
  headerName: "Image",
  field: "image",
  filter: null,
  autoHeight: true,
  cellRendererSelector: (params) => {
    return {
      component: ProductImageRenderer,
    };
  },
  sortable: false,
  width: 120,
};

const cost = {
  headerName: "Cost",
  valueGetter: (params) => params?.data?.cost,
  field: "cost",
  maxWidth: 180,
  cellRendererSelector: (params) => {
    return {
      component: CostRenderer,
    };
  },
  autoHeight: true,
};
const goal_target = {
  headerName: "Goal Target",
  valueGetter: (params) => params?.data?.goal_target,
  field: "goal_target",
  minWidth: 120,
  cellRendererSelector: (params) => {
    return {
      component: GoalTargetRenderer,
    };
  },
  autoHeight: true,
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
};
const goal_value = {
  headerName: "Goal Value",
  valueGetter: (params) => params?.data?.goal_value,
  field: "goal_value",
  maxWidth: 180,
  cellRendererSelector: (params) => {
    return {
      component: GoalValueRenderer,
    };
  },
};

export const selection_box = {
  field: "checkbox",
  headerName:"",
  headerCheckboxSelection: false,
  checkboxSelection: true,
  width: 35,
  sortable:false,
  resizable: false,
  filter: false,
  pinned: "left",
};

export const columns = [
  //sku,
  asin,
  parent_asin,
  image,
  product_name,
  categories,
  goal_target,
  goal_value,
  cost,
];
