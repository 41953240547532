import api from "./Ax";

export async function list_catalog(
  profileId,
  pageSize,
  page,
  orderBy,
  filterBy,
  search,
  grouped,
  grouped_drill
) {
  const query = JSON.stringify({
    action: "list_catalog",
    profileId: Number(profileId),
    pageSize,
    page,
    orderBy,
    filterBy,
    search,
    grouped,
    grouped_drill,
  });
  const result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  return result.data;
}
