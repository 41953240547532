import * as dfd from "danfojs";
import api from "./Ax";
import {
  saveToLocal,
  loadFromLocal,
  saveToLocalSession,
  loadFromLocalSession,
} from "./utils";

export async function get_adgroups_data(
  profileId,
  campaignId,
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  pageSize,
  page,
  orderBy,
  filterBy,
  search
) {
  const query = JSON.stringify({
    action: "get_adgroups_data",
    profileId: Number(profileId),
    campaign_id: Number(campaignId),
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    pageSize,
    page,
    orderBy,
    filterBy,
    search,
  });
  const request_result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  return request_result.data;
}

export async function get_adgroups_data_summary(
  profileId,
  campaignId,
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  filterBy,
  search
) {
  const query = JSON.stringify({
    action: "get_adgroups_data_summary",
    profileId: Number(profileId),
    campaign_id: Number(campaignId),
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    filterBy,
    search,
  });
  const cached = loadFromLocalSession(query);
  if (cached && cached.data) {
    return cached.data;
  }
  const request_result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  saveToLocalSession(query, request_result);
  return request_result.data;
}

export async function get_adgroups_data_pages(
  profileId,
  campaignId,
  start_date,
  end_date,
  vs_start_date,
  vs_end_date,
  pageSize,
  page,
  orderBy,
  filterBy,
  search
) {
  const query = JSON.stringify({
    action: "get_adgroups_data",
    profileId: Number(profileId),
    campaign_id: Number(campaignId),
    start_date,
    end_date,
    vs_start_date,
    vs_end_date,
    pageSize,
    page,
    orderBy,
    filterBy,
    getCount: true,
    search,
  });
  const request_result = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  return request_result.data;
}
