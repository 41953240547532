import * as React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import { loadFromLocal } from "../../pages/opasity/opasityApi/utils";
// For routes that can only be accessed by authenticated users

function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized, user } = useAuth();
  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  }
  const dates = loadFromLocal("dates");
  const startDate = dates
    ? dates.selection
      ? dates.selection.startDate
        ? dates.selection.startDate
        : ""
      : ""
    : "";
  const endDate = dates
    ? dates.selection
      ? dates.selection.endDate
        ? dates.selection.endDate
        : ""
      : ""
    : "";
  const vsStartDate = dates
    ? dates.compare
      ? dates.compare.startDate
        ? dates.compare.startDate
        : ""
      : ""
    : "";
  const vsEndDate = dates
    ? dates.compare
      ? dates.compare.endDate
        ? dates.compare.endDate
        : ""
      : ""
    : "";
  window.dataLayer.push({
    event: "authenticatedpageview",
    url: window.location,
    user: user.email,
    startDate: startDate,
    endDate: endDate,
    vsStartDate: vsStartDate,
    vsEndDate: vsEndDate,
  });
  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
