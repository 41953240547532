import { Link, useLocation } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { ReactComponent as CarotIcon } from "assets/img/icons/nav-carot.svg";
import { ReactComponent as CarotIconActive } from "assets/img/icons/nav-carot-active.svg";

const TabNav = ({
  profileId,
  campaignId,
  adGroupId,
  page,
  setShowNav,
  showNav,
}) => {
  const { search } = useLocation();

  const adGroupNavs = [
    {
      title: "Targeting",
      page: "targeting",
      href: `/profiles/${profileId}/campaigns/${campaignId}/adgroups/${adGroupId}/targeting/${search}`,
    },
    {
      title: "Search Term",
      page: "searchterm",
      href: `/profiles/${profileId}/campaigns/${campaignId}/adgroups/${adGroupId}/searchterm/${search}`,
    },
    {
      title: "Advertised Products",
      page: "advertisedproducts",
      href: `/profiles/${profileId}/campaigns/${campaignId}/adgroups/${adGroupId}/advertisedproducts/${search}`,
    },
    {
      title: "Negative Targeting",
      page: "negativetargeting",
      href: `/profiles/${profileId}/campaigns/${campaignId}/adgroups/${adGroupId}/negativetargeting/${search}`,
    },
  ];

  const campaignNavs = [
    {
      title: "AdGroups",
      page: "adgroups",
      href: `/profiles/${profileId}/campaigns/${campaignId}/${search}`,
    },
    {
      title: "Placement",
      page: "placement",
      href: `/profiles/${profileId}/campaigns/${campaignId}/placement/${search}`,
    },
    {
      title: "Targeting",
      page: "targeting",
      href: `/profiles/${profileId}/campaigns/${campaignId}/targeting/${search}`,
    },
    {
      title: "Search Term",
      page: "searchterm",
      href: `/profiles/${profileId}/campaigns/${campaignId}/searchterm/${search}`,
    },
    {
      title: "Advertised Products",
      page: "advertisedproducts",
      href: `/profiles/${profileId}/campaigns/${campaignId}/advertisedproducts/${search}`,
    },
    {
      title: "Negative Targeting",
      page: "negativetargeting",
      href: `/profiles/${profileId}/campaigns/${campaignId}/negativetargeting/${search}`,
    },
  ];

  const mainNavs = [
    {
      title: "All Campaigns",
      page: "campaigns",
      href: `/profiles/${profileId}/campaigns/${search}`,
      hasChild: true,
    },
    {
      title: "All AdGroups",
      page: "adgroups",
      href: `/profiles/${profileId}/adgroups/${search}`,
    },
    {
      title: "All Targeting",
      page: "targeting",
      href: `/profiles/${profileId}/targeting/${search}`,
      hasChild: true,
    },
    {
      title: "All Search Term",
      page: "searchterm",
      href: `/profiles/${profileId}/searchterm/${search}`,
    },

    {
      title: "All Negative Targeting",
      page: "negativetargeting",
      href: `/profiles/${profileId}/negativetargeting/${search}`,
    },
    {
      title: "Advertised Products Overview",
      page: "advertisedproductsoverview",
      href: `/profiles/${profileId}/advertisedproductsoverview/${search}`,
    },
  ];

  const navs = adGroupId ? adGroupNavs : campaignId ? campaignNavs : mainNavs;

  return (
    <Nav variant="tabs" className="page-tabs">
      {navs.map(({ title, href, page: pageName, hasChild }, index) => (
        <Nav.Item key={index}>
          <Nav.Link
            style={{ fontSize: "14px" }}
            as={Link}
            to={href}
            active={page === pageName}
            onMouseEnter={() => {
              if (page === pageName) {
                setShowNav(true);

              }
            }}
            
          >
            {title}{" "}
            {hasChild ? (
              page === pageName ? (
                <CarotIconActive
                  style={{ marginLeft: 2, transform: "scale(0.5)" }}
                />
              ) : (
                <CarotIcon style={{ marginLeft: 2, transform: "scale(0.5)" }} />
              )
            ) : null}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );
};

export default TabNav;
