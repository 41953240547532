import { DateRange } from "react-date-range";
import { useState, useEffect } from "react";
import { saveDates } from "pages/opasity/opasityApi/utils";
import { Row, Col, Form, InputGroup, Dropdown } from "react-bootstrap";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "assets/css/opasity/date-modal.css";

import $ from "jquery";

$(document).on("keyup", ".rdrDateDisplayItem > input", (event) => {
  if (event.keyCode == 13) {
    $(event.target).trigger("blur");
  }
});

const DateModal = ({ gridRef, dateState, setDateState }) => {
  const [daysToToday, setDaysToToday] = useState(30);
  const [daysToYesterday, setDaysToYesterday] = useState(30);
  const [showCompare, setShowCompare] = useState(true);
  const [show, setShow] = useState(false);

  function onTog(val, event) {
    if (event && event.source == "rootClose") {
      setShow(val);
    }
  }

  function updateDateState(newDateState) {
    setDateState(newDateState);
    saveDates(newDateState);
  }

  const getNDaysAgo = (nDays) => {
    let yesterday = new Date();

    yesterday.setDate(yesterday.getDate() - nDays);
    yesterday.setHours(0);
    yesterday.setMinutes(0);
    yesterday.setSeconds(0);

    return yesterday;
  };

  const getToday = () => getNDaysAgo(0);

  const getYesterday = () => getNDaysAgo(1);

  const getMonday = (date, prev = 0) => {
    date = new Date(date);
    var day = date.getDay(),
      diff = date.getDate() - day + (day == 0 ? -6 : 1) - prev * 7; // adjust when day is sunday
    return new Date(date.setDate(diff));
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1);
  };

  const setLastNDays = (nDays) => {
    const stSel = getNDaysAgo(nDays);
    const edSel = getNDaysAgo(1);
    const stCom = getNDaysAgo(2 * nDays);
    const edCom = getNDaysAgo(nDays + 1);

    updateDateState({
      ...dateState,
      selection: { startDate: stSel, endDate: edSel, key: "selection" },
      compare: { startDate: stCom, endDate: edCom, key: "compare" },
    });
  };

  const setYesterday = () => setLastNDays(1);

  const setToday = () => {
    const today = getToday();
    const yesterday = getYesterday();

    updateDateState({
      ...dateState,
      selection: { startDate: today, endDate: today, key: "selection" },
      compare: { startDate: yesterday, endDate: yesterday, key: "compare" },
    });
  };

  const setThisWeek = () => {
    const today = getToday();
    const thisMonday = getMonday(today);
    const lastMonday = getMonday(today, 1);
    const sunday = getMonday(today);

    sunday.setDate(sunday.getDate() - 1);

    updateDateState({
      ...dateState,
      selection: { startDate: thisMonday, endDate: today, key: "selection" },
      compare: { startDate: lastMonday, endDate: sunday, key: "compare" },
    });
  };

  const setLastWeek = () => {
    const today = getToday();
    const lastMonday = getMonday(today, 1);
    const sunday = getMonday(today);
    sunday.setDate(sunday.getDate() - 1);
    const lastLastMonday = getMonday(today, 2);
    const lastLastSunday = getMonday(today, 1);
    lastLastSunday.setDate(lastLastSunday.getDate() - 1);

    updateDateState({
      ...dateState,
      selection: { startDate: lastMonday, endDate: sunday, key: "selection" },
      compare: {
        startDate: lastLastMonday,
        endDate: lastLastSunday,
        key: "compare",
      },
    });
  };

  const setThisMonth = () => {
    const today = getToday();
    const firstDayThisMonth = getFirstDayOfMonth(
      today.getFullYear(),
      today.getMonth()
    );
    const firstDayPrevMonth = getFirstDayOfMonth(
      today.getFullYear(),
      today.getMonth() - 1
    );
    const lastDayPrevMonth = getFirstDayOfMonth(
      today.getFullYear(),
      today.getMonth()
    );
    lastDayPrevMonth.setDate(lastDayPrevMonth.getDate() - 1);

    updateDateState({
      ...dateState,
      selection: {
        startDate: firstDayThisMonth,
        endDate: today,
        key: "selection",
      },
      compare: {
        startDate: firstDayPrevMonth,
        endDate: lastDayPrevMonth,
        key: "compare",
      },
    });
  };

  const setLastMonth = () => {
    const date = getToday();
    const firstDayLastMonth = getFirstDayOfMonth(
      date.getFullYear(),
      date.getMonth() - 1
    );
    const lastDayLastMonth = getFirstDayOfMonth(
      date.getFullYear(),
      date.getMonth()
    );
    lastDayLastMonth.setDate(lastDayLastMonth.getDate() - 1);

    const firstDayPrevMonth = getFirstDayOfMonth(
      date.getFullYear(),
      date.getMonth() - 2
    );
    const lastDayPrevMonth = getFirstDayOfMonth(
      date.getFullYear(),
      date.getMonth() - 1
    );
    lastDayPrevMonth.setDate(lastDayPrevMonth.getDate() - 1);

    updateDateState({
      ...dateState,
      selection: {
        startDate: firstDayLastMonth,
        endDate: lastDayLastMonth,
        key: "selection",
      },
      compare: {
        startDate: firstDayPrevMonth,
        endDate: lastDayPrevMonth,
        key: "compare",
      },
    });
  };

  const handleDaysToToday = (event) => {
    const days = parseInt(event.target.value);
    setDaysToToday(days);

    if (days < 1 || isNaN(days)) {
      return;
    }

    const stSel = getNDaysAgo(days - 1);
    const edSel = getToday();
    const stCom = getNDaysAgo(2 * days - 1);
    const edCom = getNDaysAgo(days);

    updateDateState({
      ...dateState,
      selection: { startDate: stSel, endDate: edSel, key: "selection" },
      compare: { startDate: stCom, endDate: edCom, key: "compare" },
    });
  };

  const handleDaysToYesterday = (event) => {
    const days = parseInt(event.target.value);
    setDaysToYesterday(days);

    if (days < 1 || isNaN(days)) {
      return;
    }

    setLastNDays(days);
  };

  function pickerToText(date) {
    var d = new Date(date);
    return d.toLocaleDateString("en-us", {
      day: "numeric",
      year: "2-digit",
      month: "short",
    });
  }

  const changeHelperVisibility = (hide) => {
    const columnDefs = gridRef.current.api.getColumnDefs();

    const newColumnDefs = columnDefs.map((column) => {
      if (column.field) {
        if (column.field.includes("_1") || column.field.includes("diff")) {
          column["hide"] = !hide;
        }
      }
      if (column.children) {
        column.children = column.children.map((child) => {
          if (child.headerName) {
            if (
              child.headerName === "difference" ||
              child.headerName === "previous" ||
              child.headerName === "difference %"
            ) {
              child["hide"] = !hide;
            }
          }
          return child;
        });
      }
      return column;
    });

    setTimeout(gridRef.current.api.setColumnDefs(newColumnDefs), 0);
  };

  const handleShowCompare = (event) => {
    setShowCompare(event.target.checked);
    changeHelperVisibility(event.target.checked);
  };

  useEffect(() => {
    if (
      document.getElementsByClassName("rdrDateDisplay") &&
      document.getElementsByClassName("rdrDateDisplay").length > 1
    ) {
      document
        .getElementsByClassName("rdrDateDisplay")[0]
        .setAttribute("id", "selection");
      document
        .getElementsByClassName("rdrDateDisplay")[1]
        .setAttribute("id", "compare");
    }
  }, [show]);

  return (
    <Dropdown autoClose="outside" show={show} onToggle={onTog}>
      <Dropdown.Toggle
        variant="primary"
        id="selection-dropdown"
        onClick={() => {
          setShow(!show);
        }}
      >
        <span style={{ marginRight: 8 }}>
          {`${
            pickerToText(dateState.selection.startDate).split(",")[0]
          } - ${pickerToText(dateState.selection.endDate)}`}
        </span>
      </Dropdown.Toggle>
      <div className="d-flex align-items center">
        <div style={{ flexGrow: 1 }} />
        <Form.Check
          type="switch"
          id="exampleCustomSwitch"
          name="customSwitch"
          className="form-switch-md small-switch"
          onChange={(e) => handleShowCompare(e)}
          checked={showCompare}
          style={{ height: "1.6em", paddingLeft: 0 }}
        />
        <span
          style={{
            fontSize: 10,
            color: "#6C757D",
            lineHeight: "24px",
            marginLeft: 4,
            whiteSpace: "nowrap",
          }}
        >
          {showCompare
            ? `Compared to ${
                pickerToText(dateState.compare.startDate).split(",")[0]
              } - ${pickerToText(dateState.compare.endDate)}`
            : "Compare Hidden"}
        </span>
      </div>
      <Dropdown.Menu
        className="py-0"
        style={{ border: "none", borderRadius: "none" }}
        id="date-range-modal"
      >
        <div style={{ minWidth: "40rem", padding: 0 }}>
          <Row
            style={{
              margin: 0,
              overflow: "hidden",
              borderRadius: "3.2px",
              border: "0.5px solid rgba(0, 104, 255, 0.47)",
              boxShadow: "-2px 5px 15px 1px #D0DCED",
            }}
          >
            <Col
              md="5"
              style={{
                padding: 0,
                background: "#f7f9fc",
              }}
            >
              <Row className="static-range-row" onClick={() => setToday()}>
                <button className="staticRange">
                  <span>Today</span>
                </button>
              </Row>
              <Row className="static-range-row" onClick={() => setYesterday()}>
                <button className="staticRange">
                  <span>Yesterday</span>
                </button>
              </Row>
              <Row className="static-range-row" onClick={() => setLastNDays(7)}>
                <button className="staticRange">
                  <span>Last 7 days</span>
                </button>
              </Row>
              <Row
                className="static-range-row"
                onClick={() => setLastNDays(14)}
              >
                <button className="staticRange">
                  <span>Last 14 days</span>
                </button>
              </Row>
              <Row className="static-range-row" onClick={() => setThisWeek()}>
                <button className="staticRange">
                  <span>This Week</span>
                </button>
              </Row>
              <Row className="static-range-row" onClick={() => setLastWeek()}>
                <button className="staticRange">
                  <span>Last Week</span>
                </button>
              </Row>
              <Row className="static-range-row" onClick={() => setThisMonth()}>
                <button className="staticRange">
                  <span>This Month</span>
                </button>
              </Row>
              <Row className="static-range-row" onClick={() => setLastMonth()}>
                <button className="staticRange">
                  <span>Last Month</span>
                </button>
              </Row>
              <Row style={{ margin: 0, marginTop: 10, marginBottom: 4 }}>
                <Col md="5">
                  <InputGroup className="p-1">
                    <Form.Control
                      onChange={(e) => handleDaysToToday(e)}
                      value={daysToToday}
                      type="number"
                    />
                  </InputGroup>
                </Col>
                <Col md="7" className="pt-2 px-0">
                  <span style={{ color: "#000000ea" }}>Days up to today</span>
                </Col>
              </Row>
              <Row style={{ margin: 0, marginBottom: 10, marginTop: 4 }}>
                <Col md="5">
                  <InputGroup className="p-1">
                    <Form.Control
                      onChange={(e) => handleDaysToYesterday(e)}
                      value={daysToYesterday}
                      type="number"
                    />
                  </InputGroup>
                </Col>
                <Col md="7" className="pt-2 px-0">
                  <span style={{ color: "#000000ea" }}>
                    Days up to yesterday
                  </span>
                </Col>
              </Row>
            </Col>
            <Col
              md="7"
              style={{
                padding: 0,
                background: "#f7f9fc",
                display: "flex",
                flexDirection: "column",
                borderLeft: "1px solid #babfc7",
              }}
            >
              <DateRange
                editableDateInputs={true}
                startDatePlaceholder="Start Date"
                endDatePlaceholder="End Date"
                dateDisplayFormat="M/d/yyyy"
                retainEndDateOnFirstSelection={true}
                onChange={(change) => {
                  setDateState({ ...dateState, ...change });
                  saveDates({ ...dateState, ...change });
                }}
                months={1}
                maxDate={getToday()}
                direction="vertical"
                ranges={[dateState.selection, dateState.compare]}
                rangeColors={[
                  "var(--major-blue)",
                  "var(--major-orange)",
                  "#eeeeee",
                ]}
                ariaLabels={{
                  dateInput: {
                    selection: { startDate: "start date", endDate: "end date" },
                    compare: {
                      startDate: "compare start date",
                      endDate: "compare end date",
                    },
                  },
                  monthPicker: "month picker",
                  yearPicker: "year picker",
                  prevButton: "previous month button",
                  nextButton: "next month button",
                }}
                className="col-md-12"
              />
              <div style={{ flexGrow: 1 }} />
              <Row style={{ margin: 0 }}>
                <Col className="text-center" style={{ padding: "10px 0px" }}>
                  <button
                    style={{
                      background: "#ECF0F5",
                      border: "1px solid var(--major-blue)",
                      color: "var(--major-blue)",
                      borderRadius: 50,
                      padding: "3px 20px",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    Apply
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DateModal;
