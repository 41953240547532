import React from "react";

import SidebarNavList from "./SidebarNavList";

const SidebarNavSection = ({ title, pages, className, ...rest }) => {
  return (
    <React.Fragment {...rest}>
      {/* {title ? <li className="sidebar-header">{title}</li> : null} */}
      <SidebarNavList pages={pages} depth={0} />
    </React.Fragment>
  );
};

export default SidebarNavSection;
