import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Helmet } from "react-helmet-async";

import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Alert,
  InputGroup,
} from "react-bootstrap";
import { getCampaign, getAndSaveCampaign } from "../opasityApi/singleItem";
import { ObjectSchema } from "yup";
import { updateCampaign } from "../opasityApi/updateCampaign";
import TabNav from "../../../components/opasity/TabNav";
import { Tab } from "react-bootstrap";
import BreadCrumb from "../CampaignsBreadCrumbs";

const CampaignSettings = () => {
  const { profileId, campaignId } = useParams();
  const [campaignData, setCampaignData] = useState({});
  const [initialData, setInitialData] = useState({});
  const [error, setError] = useState({});
  const [isSame, setIsSame] = useState(true);
  const [saving, setSaving] = useState(false);
  function getDeliveringStatus() {
    let deliveryStatus = null;
    console.log("state: ", campaignData.state);
    switch (campaignData.state) {
      case "enabled":
        deliveryStatus = "Delivering";
        break;
      case "paused":
        deliveryStatus = "Paused";
        break;
      case "archived":
        deliveryStatus = "Archived";
        break;
    }
    return deliveryStatus;
  }
  function getCampaignState() {
    const result = {
      class: null,
      text: null,
    };
    if (campaignData.state == "enabled") {
      result.class = "text-success";
      result.text = "Enabled";
    }
    if (campaignData.state == "paused") {
      result.class = "text-warning";
      result.text = "Paused";
    }
    if (campaignData.state == "archived") {
      result.class = "text-danger";
      result.text = "Archived";
    }
    return result;
  }
  function handleBiddingStrategyChange(e) {
    setCampaignData({
      ...campaignData,
      bidding_strategy: e.target.value,
    });
  }
  function handleCampaignEndDate(e) {
    if (Date.parse(e.target.value) <= Date.now()) {
      createError("end_date", "Campaign end date should be in the future");
    } else {
      deleteError("end_date");
      setCampaignData({
        ...campaignData,
        end_date: e.target.value,
      });
    }
  }
  useEffect(() => {
    getCampaign(profileId, campaignId, true).then((res) => {
      const newCampaignData = { ...res };
      newCampaignData.budget = parseFloat(newCampaignData.budget);
      setInitialData(newCampaignData);
      setCampaignData(newCampaignData);
    });
  }, [profileId, campaignId]);
  useEffect(() => {
    console.log(campaignData);
    setIsSame(true);
    Object.keys(campaignData).map((key) => {
      if (campaignData[key] != initialData[key]) {
        console.log(
          "campaign: ",
          campaignData[key],
          "is different than initial data",
          initialData[key]
        );
        setIsSame(false);
      }
    });
  }, [campaignData, initialData]);
  function isDisabled() {
    return campaignData.state == "archived";
  }
  function deleteError(key) {
    if (key in error) {
      const newError = { ...error };
      delete newError[key];
      setError(newError);
    }
  }
  function createError(key, val) {
    const newError = {
      ...error,
    };
    newError[key] = val;
    setError(newError);
  }
  function setDailyBudget(e) {
    const newDailyBudget = parseFloat(e.target.value);
    if (newDailyBudget >= 1) {
      deleteError("budget");
      setCampaignData({
        ...campaignData,
        budget: newDailyBudget,
      });
    } else {
      createError("budget", "Daily budget must be >= 1");
    }
  }
  function setAdjustment(key, val) {
    const newData = { ...campaignData };
    const newAdjustment = parseInt(val);
    if (newAdjustment < 0) {
      const adj =
        key == "top_adjustment"
          ? "Top of search (first page) value"
          : "Product pages adjust value";
      createError(key, `${adj} must be > 0`);
    } else {
      deleteError(key);
      newData[key] = newAdjustment;
      setCampaignData(newData);
    }
  }
  function handleSubmit() {
    deleteError("unexpected_error");
    setSaving(true);
    console.log("submitting: ", campaignData);
    const campaigns = [{ ...campaignData, campaignId }];
    updateCampaign(Number(profileId), campaigns).then((res) => {
      console.log("res is...", res);
      if (res.error.length > 0) {
        createError("unexpected_error", "Unexpected error occurred");
      }
      getAndSaveCampaign(profileId, campaignId, true).then((resc) => {
        const newCampaignData = { ...resc };
        newCampaignData.budget = parseFloat(newCampaignData.budget);
        setInitialData(newCampaignData);
        setCampaignData(newCampaignData);
      });
    });
    setSaving(false);
  }
  function normalizeData(val) {
    const vals = {
      LEGACY_FOR_SALES: "legacyForSales",
      AUTO_FOR_SALES: "autoForSales",
      MANUAL: "manual",
      RULE_BASED: "ruleBased",
    };
    if (val in vals) {
      console.log("found in vals...");
      return vals[val];
    }
    console.log(val, "not in vals");
    return val;
  }

  return (
    <React.Fragment>
      <Helmet title="Edit Campaign" />
      <Container fluid className="p-0">
        <Row>
          <Col md="6">
            <BreadCrumb
              campaignId={campaignId}
              profileId={profileId}
            ></BreadCrumb>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={"tab "}>
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <TabNav profileId={profileId} campaignId={campaignId} />
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <Card className="shadow-none">
                      <Card.Body>
                        <Form>
                          <Form.Group className="mb-3">
                            <Row>
                              <Col md="2">
                                <Form.Label className="mb-0 mt-2 h5">
                                  Campaign name
                                </Form.Label>
                              </Col>
                              <Col md="3">
                                <Form.Control
                                  type="text"
                                  name="campaignName"
                                  placeholder="campaign name"
                                  value={campaignData.name}
                                  onChange={(event) =>
                                    setCampaignData({
                                      ...campaignData,
                                      name: event.target.value,
                                    })
                                  }
                                  disabled={isDisabled()}
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Row>
                              <Col md="2">
                                <Form.Label className="mb-0 mt-2 h5">
                                  Type
                                </Form.Label>
                              </Col>
                              <Col md="8">
                                <h5 className="mt-2">Sponsored Products</h5>
                              </Col>
                            </Row>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Row>
                              <Col md="2">
                                <Form.Label className="mb-0 mt-2 h5">
                                  Active/Paused
                                </Form.Label>
                              </Col>
                              <Col md="4" className="flex">
                                <Form.Check
                                  type="switch"
                                  id="exampleCustomSwitch"
                                  name="customSwitch"
                                  className="form-switch-md"
                                  onChange={(event) =>
                                    setCampaignData({
                                      ...campaignData,
                                      state: event.target.checked
                                        ? "enabled"
                                        : "paused",
                                    })
                                  }
                                  onlabel="Active"
                                  offlabel="Paused"
                                  checked={
                                    campaignData.state == "enabled"
                                      ? true
                                      : false
                                  }
                                  disabled={isDisabled()}
                                />
                                <div>
                                  <h5
                                    className={`mt-2 mx-2 ${
                                      getCampaignState().class
                                    }`}
                                  >
                                    {getCampaignState().text}
                                  </h5>
                                </div>
                              </Col>
                            </Row>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Row>
                              <Col md="2">
                                <Form.Label className="mb-0 mt-2 h5">
                                  Status
                                </Form.Label>
                              </Col>
                              <Col md="8">
                                <h5 className="mt-2">
                                  {getDeliveringStatus()}
                                </h5>
                              </Col>
                            </Row>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Row>
                              <Col md="2">
                                <Form.Label className="mb-0 mt-2 h5">
                                  Schedule
                                </Form.Label>
                              </Col>
                              <Col md="3">
                                <Row className="mb-2">
                                  <Col md="4">
                                    <h5 className="mt-2">Start Date</h5>
                                  </Col>
                                  <Col md="8">
                                    <Form.Control
                                      type="date"
                                      name="startDate"
                                      value={campaignData.start_date}
                                      disabled={true}
                                    />
                                  </Col>
                                </Row>
                                <Row className="mt-2">
                                  <Col md="4">
                                    <h5 className="mt-2">End Date</h5>
                                  </Col>
                                  <Col md="8">
                                    <Form.Control
                                      type="date"
                                      name="endDate"
                                      placeholder="End Date"
                                      value={campaignData.end_date}
                                      onChange={(e) => handleCampaignEndDate(e)}
                                      disabled={isDisabled()}
                                    />
                                  </Col>
                                  <Col>
                                    {error.end_date && (
                                      <h5 className="text-danger mt-2">
                                        {error.end_date}
                                      </h5>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Row>
                              <Col md="2">
                                <Form.Label className="mb-0 mt-2 h5">
                                  Budget
                                </Form.Label>
                              </Col>
                              <Col md="10">
                                <Row>
                                  <Col md="2">
                                    <InputGroup className="p-1">
                                      <InputGroup.Text>$</InputGroup.Text>
                                      <Form.Control
                                        value={campaignData.budget}
                                        onChange={(e) => setDailyBudget(e)}
                                        type="number"
                                        step="0.01"
                                      />
                                    </InputGroup>
                                  </Col>
                                  <Col md="12">
                                    {error.budget && (
                                      <h5 className="text-danger mt-2">
                                        {error.budget}
                                      </h5>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Row>
                              <Col md="2">
                                <Form.Label className="mb-0 mt-2 h5">
                                  Campaign Targeting
                                </Form.Label>
                              </Col>
                              <Col md="8">
                                <h5 className="mt-2">
                                  {campaignData.target_type == "auto"
                                    ? "Automatic Targeting"
                                    : "Manual Targeting"}{" "}
                                </h5>
                              </Col>
                            </Row>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Row>
                              <Col md="2">
                                <Form.Label className="mb-0 mt-2 h5">
                                  Campaign Bidding Strategy
                                </Form.Label>
                              </Col>
                              <Col md="8">
                                <Form.Group className="mb-2 mt-2">
                                  <Form.Check
                                    label="Dynamic bids - down only"
                                    type="radio"
                                    name="biddingStrategy"
                                    value="legacyForSales"
                                    onChange={(e) =>
                                      handleBiddingStrategyChange(e)
                                    }
                                    disabled={isDisabled()}
                                    checked={
                                      normalizeData(
                                        campaignData.bidding_strategy
                                      ) == "legacyForSales"
                                    }
                                  />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                  <Form.Check
                                    label="Dynamic bids - up and down"
                                    type="radio"
                                    name="biddingStrategy"
                                    value="autoForSales"
                                    onChange={(e) =>
                                      handleBiddingStrategyChange(e)
                                    }
                                    disabled={isDisabled()}
                                    checked={
                                      normalizeData(
                                        campaignData.bidding_strategy
                                      ) == "autoForSales"
                                    }
                                  />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                  <Form.Check
                                    label="Fixed bids"
                                    type="radio"
                                    disabled={isDisabled()}
                                    name="biddingStrategy"
                                    value="manual"
                                    onChange={(e) =>
                                      handleBiddingStrategyChange(e)
                                    }
                                    checked={
                                      normalizeData(
                                        campaignData.bidding_strategy
                                      ) == "manual"
                                    }
                                  />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                  <Form.Check
                                    label="Rule-based bidding"
                                    type="radio"
                                    name="biddingStrategy"
                                    onChange={(e) =>
                                      handleBiddingStrategyChange(e)
                                    }
                                    disabled
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Row>
                              <Col md="2">
                                <Form.Label className="mb-0 mt-2 h5">
                                  Adjust bids by placement (replaces Bid+)
                                </Form.Label>
                              </Col>
                              <Col md="3">
                                <Row>
                                  <Form.Label className="mb-0 mt-2">
                                    In addition to your bidding strategy, you
                                    can increase bids by up to 900%
                                  </Form.Label>
                                </Row>
                                <Row>
                                  <Col md="12" className="mt-2">
                                    <Row>
                                      <Col md="7">
                                        <Form.Label className="mb-0 mt-2">
                                          Top of search (first page)
                                        </Form.Label>
                                      </Col>
                                      <Col md="5">
                                        <InputGroup className="p-1">
                                          <Form.Control
                                            value={campaignData.top_adjustment}
                                            onChange={(e) =>
                                              setAdjustment(
                                                "top_adjustment",
                                                e.target.value
                                              )
                                            }
                                            type="number"
                                          />
                                          <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup>
                                      </Col>
                                      <Col md="12">
                                        {error.top_adjustment && (
                                          <h5 className="text-danger mt-2">
                                            {error.top_adjustment}
                                          </h5>
                                        )}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="7">
                                        <Form.Label className="mb-0 mt-2">
                                          Product pages
                                        </Form.Label>
                                      </Col>
                                      <Col md="5">
                                        <InputGroup className="p-1">
                                          <Form.Control
                                            value={
                                              campaignData.product_page_adjustment
                                            }
                                            onChange={(e) =>
                                              setAdjustment(
                                                "product_page_adjustment",
                                                e.target.value
                                              )
                                            }
                                            type="number"
                                          />
                                          <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup>
                                      </Col>
                                      <Col md="12">
                                        {error.product_page_adjustment && (
                                          <h5 className="text-danger mt-2">
                                            {error.product_page_adjustment}
                                          </h5>
                                        )}
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Form.Group>
                          <Button
                            variant="primary"
                            className="btn-lg"
                            onClick={() => handleSubmit()}
                            disabled={isSame || saving}
                          >
                            Save
                          </Button>
                        </Form>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default CampaignSettings;
