import api from "./Ax"

export async function get_negative_keywords(profileId, campaignId, adGroupId, pageSize, page, orderBy, filterBy) {

    const query = JSON.stringify({
        action: 'get_negative_targeting',
        "profileId": Number(profileId),
        campaign_id: campaignId,
        adgroup_id: adGroupId,
        pageSize,
        page,
        orderBy,
        filterBy
    })
    const result = await api.post("https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data", query)
    return result.data
}