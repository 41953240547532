import { useState } from "react";
import { Row, Col, Form, InputGroup, Dropdown, Button } from "react-bootstrap";
// Styles

const componentStyle = {
  marginBottom: "0",
  overflow: "scroll",
  padding: "0px 10px 20px 20px",
  border: "1px solid #babfc7",
  width: "250px",
  marginLeft: "10px",
  marginRight: "-30px",
};
const closeButtonStyle = {
  textAlign: "right",
  cursor: "pointer",
  caretColor: "transparent",
  userSelect: "none",
  fontSize: "15px",
};
const searchStyle = {
  marginTop: "10px",
  marginBottom: "10px",
  border: "1px solid #ccc",
  borderRadius: "4px",
  boxSizing: "border-box",
  fontSize: "11px",
};

const columnStyle = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
};
const grandparentButtonStyle = {
  width: "100%",
  color: "black",
  backgroundColor: "transparent",
  border: 0,
  padding: "5px",
  cursor: "pointer",
  caretColor: "transparent",
  userSelect: "none",
  fontWeight: "bolder",
};
const parentButtonStyle = {
  width: "100%",
  color: "black",
  backgroundColor: "transparent",
  border: 0,
  paddingLeft: "15px",
  cursor: "pointer",
  caretColor: "transparent",
  userSelect: "none",
  fontWeight: "bolder",
};

const childrenButtonStyle = {
  width: "100%",
  paddingLeft: "25px",
  color: "black",
  border: 0,
  backgroundColor: "transparent",
  cursor: "pointer",
  caretColor: "transparent",
  userSelect: "none",
};

const selectStyle = {
  width: "70%",
  marginLeft: "30px",
  marginBottom: "2px",
  border: "1px solid #ccc",
  borderRadius: "4px",
  boxSizing: "border-box",
  fontSize: "11px",
  backgroundColor: "white",
};
const inputStyle = {
  width: "70%",
  marginLeft: "30px",
  marginBottom: "10px",
  border: "1px solid #ccc",
  borderRadius: "4px",
  boxSizing: "border-box",
  fontSize: "11px",
};

export default function Filters({
  columnsArray,
  setOpenFilters,
  openFilters,
  filterBy,
  setFilterBy,
  grouped = [""],
  supportedFilters = {},
}) {
  const [appliedFilters, setAppliedFilters] = useState([]);
  // UTILS
  const filterSelect = (item, group) => {
    // TEXT
    if (item.filter && item.filter === "agTextColumnFilter") {
      return (
        <TextFilter
          item={item}
          filterBy={filterBy}
          setFilterBy={setFilterBy}
          group={group}
        />
      );
    }
    // NUMBERS
    if (
      (item.aggFunc === "sum" ||
        item.aggFunc === "avg" ||
        item.aggFunc === "first" ||
        item.valueGetter) &&
      !item?.filterParams?.values
    ) {
      return (
        <NumberFilter
          item={item}
          filterBy={filterBy}
          setFilterBy={setFilterBy}
          group={group}
        />
      );
    }
  };

  function getValues(item) {
    let valuesArray = [];
    item.filterParams &&
      item.filterParams.values &&
      item.filterParams.values.map((value) => valuesArray.push(value));
    return valuesArray;
  }

  // STATES

  const [openAccordions, setOpenAccordions] = useState([]);
  const handleButtonClick = (selectedAccordion) => {
    if (openAccordions.includes(selectedAccordion)) {
      setOpenAccordions((prevAccordion) => {
        return prevAccordion.filter((item) => item !== selectedAccordion);
      });
    } else {
      setOpenAccordions((prevAccordion) => {
        return [selectedAccordion, ...prevAccordion];
      });
    }
  };
  const [groupAccodrion, setGroupAccordion] = useState(grouped[0]);
  function getFilterableFields(col, group) {
    if (col.children) {
      return col.children.filter((col) => {
        if (col.field) {
          if (Object.keys(supportedFilters).length > 0) {
            if (supportedFilters[group]) {
              if (!supportedFilters[group].includes(col.field)) {
                return false;
              }
            }
          }
        }
        return true;
      });
    }
    return [];
  }
  return (
    openFilters && (
      <div style={componentStyle}>
        <div style={closeButtonStyle} onClick={() => setOpenFilters(false)}>
          ⛌
        </div>
        <input placeholder="Search..." style={searchStyle} />
        <div style={columnStyle}>
          {(grouped || [""]).map((group) => (
            <>
              {group != "" && (
                <div
                  onClick={() => {
                    if (groupAccodrion == group) {
                      setGroupAccordion("");
                    } else {
                      setGroupAccordion(group);
                    }
                  }}
                  style={grandparentButtonStyle}
                >
                  <span style={{ fontSize: "13px" }}>
                    {groupAccodrion == group ? "↓" : "→"}
                  </span>{" "}
                  {group}
                </div>
              )}

              <div>
                {groupAccodrion == group && (
                  <div>
                    {columnsArray
                      .filter((col) => {
                        if (
                          col.children &&
                          getFilterableFields(col, group).length == 0
                        ) {
                          return false;
                        }
                        return true;
                      })
                      .map((col) => {
                        // WHEN THERE ARE NO CHILDREN
                        if (col.headerName && col.field && col.filter) {
                          return (
                            <>
                              <div
                                onClick={() => handleButtonClick(col.field)}
                                style={
                                  group == ""
                                    ? grandparentButtonStyle
                                    : parentButtonStyle
                                }
                              >
                                <span style={{ fontSize: "13px" }}>
                                  {openAccordions.includes(col.field)
                                    ? "↓"
                                    : "→"}
                                </span>{" "}
                                {col.headerName}
                              </div>
                              <div>
                                {openAccordions.includes(col.field) && (
                                  <div>
                                    {col.filterParams &&
                                      col.filterParams.values && (
                                        <select style={selectStyle}>
                                          <option value=""></option>
                                          {col.filterParams.values.map(
                                            (value) => {
                                              return (
                                                <option value={value}>
                                                  {value}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                      )}
                                    {filterSelect(col, group)}
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        }
                        // WHEN YOU HAVE CHILDREN
                        else if (col.children) {
                          return (
                            <div style={columnStyle}>
                              <div
                                onClick={() =>
                                  handleButtonClick(col.headerName)
                                }
                                style={
                                  group == ""
                                    ? grandparentButtonStyle
                                    : parentButtonStyle
                                }
                              >
                                <span style={{ fontSize: "13px" }}>
                                  {openAccordions.includes(col.headerName)
                                    ? "↓"
                                    : "→"}
                                </span>{" "}
                                {col.headerName}
                              </div>

                              {openAccordions.includes(col.headerName) && (
                                <>
                                  {getFilterableFields(col, group).map(
                                    (subCol) =>
                                      subCol.headerName &&
                                      subCol.filter !== null && (
                                        <>
                                          <div
                                            onClick={() =>
                                              handleButtonClick(subCol.field)
                                            }
                                            style={{ ...childrenButtonStyle }}
                                          >
                                            <span style={{ fontSize: "13px" }}>
                                              {openAccordions.includes(
                                                subCol.field
                                              )
                                                ? "↓"
                                                : "→"}
                                            </span>{" "}
                                            {subCol.headerName}
                                          </div>
                                          <div>
                                            {openAccordions.includes(
                                              subCol.field
                                            ) && (
                                              <div>
                                                <SelectFilter
                                                  subCol={subCol}
                                                  group={group}
                                                  filterBy={filterBy}
                                                  setFilterBy={setFilterBy}
                                                />
                                                {filterSelect(subCol, group)}
                                              </div>
                                            )}
                                          </div>
                                        </>
                                      )
                                  )}
                                </>
                              )}
                            </div>
                          );
                        }
                      })}
                  </div>
                )}
              </div>
            </>
          ))}
        </div>
      </div>
    )
  );
}

function NumberFilter({ item, filterBy, setFilterBy, group }) {
  const filterKey = `${group}-${item.field}`;
  function setFilter(value, ftype) {
    const nf = {
      ...filterBy,
      [filterKey]: {
        value: value,
        filterType:
          ftype || filterBy[filterKey]?.filterType || "greaterThanOrEqual",
        column: item.field,
      },
    };
    const cleanNf = {};
    Object.keys(nf).map((nfk) => {
      console.log("last nfk: ", nf[nfk]);
      if (nf[nfk].value == null || nf[nfk].value == "") {
        return;
      }
      cleanNf[nfk] = nf[nfk];
    });
    console.log("setting new filter by to: ", nf);
    setFilterBy(cleanNf);
  }
  return (
    <div>
      <select
        style={selectStyle}
        id="comparison"
        onChange={(e) => setFilter(filterBy[filterKey]?.value, e.target.value)}
      >
        <option
          value="greaterThanOrEqual"
          selected={
            filterBy[filterKey]?.filterType == "greaterThanOrEqual" ||
            !filterBy[filterKey]?.filterType
          }
        >
          Greater than or equals
        </option>
        <option
          value="greaterThan"
          selected={filterBy[filterKey]?.filterType == "greaterThan"}
        >
          Greater than
        </option>
        <option
          value="lessThanOrEqual"
          selected={filterBy[filterKey]?.filterType == "lessThanOrEqual"}
        >
          Lesser than or equals
        </option>
        <option
          value="lessThan"
          selected={filterBy[filterKey]?.filterType == "lessThan"}
        >
          Lesser than
        </option>
        <option
          value="equals"
          selected={filterBy[filterKey]?.filterType == "equals"}
        >
          Equals
        </option>
      </select>
      <input
        placeholder="Filter..."
        style={inputStyle}
        value={filterBy[filterKey]?.value}
        onChange={(e) => {
          setFilter(e.target.value);
        }}
      />
    </div>
  );
}

function TextFilter({ item, filterBy, setFilterBy, group }) {
  const filterKey = `${group}-${item.field}`;
  function setFilter(value, ftype) {
    const nf = {
      ...filterBy,
      [filterKey]: {
        value: value,
        filterType: ftype || filterBy[filterKey]?.filterType,
        column: item.field,
      },
    };
    const cleanNf = {};
    Object.keys(nf).map((nfk) => {
      console.log("nfk: ", nf[nfk]);
      if (nf[nfk].value == null || nf[nfk].value == "") {
        return;
      }
      cleanNf[nfk] = nf[nfk];
    });
    console.log("setting new filter by to: ", nf);
    setFilterBy(cleanNf);
  }
  return (
    <div>
      <input
        placeholder="Filter..."
        style={inputStyle}
        value={filterBy[filterKey]?.value}
        onChange={(e) => setFilter(e.target.value, "contains")}
      />
    </div>
  );
}

function listOrEmpty(val) {
  if (!val) {
    return [];
  }
  return val;
}
function SelectFilter({ subCol, group, filterBy, setFilterBy }) {
  const [selected, setSelected] = useState([]);
  const filterKey = `${group}-${subCol.field}`;
  function setFilter(values) {
    const nf = {
      ...filterBy,
      [filterKey]: {
        values: values,
        filterType: "IS_IN",
        column: subCol.field,
      },
    };
    const cleanNf = {};
    Object.keys(nf).map((nfk) => {
      console.log("nfk: ", nf[nfk]);
      if (!nf[nfk].values || nf[nfk].values?.length == 0) {
        return;
      }
      cleanNf[nfk] = nf[nfk];
    });
    console.log("filtering2: setting new filter by to: ", cleanNf);
    setFilterBy(cleanNf);
  }
  return (
    <>
      {subCol.filterParams && subCol.filterParams.values && (
        <div style={{ marginLeft: 30 }}>
          {subCol.filterParams.values.map((value) => {
            return (
              <Form.Check
                type={"checkbox"}
                label={`${value}`}
                id={`check-${value}`}
                checked={listOrEmpty(filterBy[filterKey]?.values).includes(
                  value
                )}
                onChange={(e) => {
                  if (
                    e.target.checked &&
                    !listOrEmpty(filterBy[filterKey]?.values).includes(value)
                  ) {
                    setFilter(
                      listOrEmpty(filterBy[filterKey]?.values).concat([value])
                    );
                  }
                  if (
                    !e.target.checked &&
                    listOrEmpty(filterBy[filterKey]?.values).includes(value)
                  ) {
                    setFilter(
                      listOrEmpty(filterBy[filterKey]?.values).filter(
                        (val) => val != value
                      )
                    );
                  }
                }}
              />
            );
          })}
        </div>
      )}
    </>
  );
}
