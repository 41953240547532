import api from "./Ax"

export async function updateCampaign(profileId, campaigns) {
    console.log("CALLED UPDATE CAMPAIGN....")
    const payload = {
        action: "update_campaigns",
        profile_id: profileId,
        campaigns
    }
    console.log("payload is: ", payload)
    const result = await api.post('https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/manager', payload)
    console.log("upadted campaigns: ", result)
    return result.data
}

export async function deleteCampaign(profileId, campaigns) {
    console.log("CALLED UPDATE CAMPAIGN....")
    const payload = {
        action: "delete_campaigns",
        profile_id: profileId,
        campaigns
    }
    console.log("payload is: ", payload)
    const result = await api.post('https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/manager', payload)
    console.log("upadted campaigns: ", result)
    return result.data
}