import { useState, Component } from "react";
import { saveToLocal, loadFromLocal } from "pages/opasity/opasityApi/utils";
import { LoadingOverlay } from "components/opasity/LoadingOverlay";
import { Button } from "react-bootstrap";

import $ from "jquery";

import _ from "lodash";

export function agOrderToNorm(sortModel) {
  return sortModel.map((model) => {
    return {
      field: model.colId,
      sort: model.sort,
    };
  });
}
export const CustomFilterToolPanel = (props) => {
  return (
    <div style={{ textAlign: "center" }}>
      <span></span>
    </div>
  );
};

const sideBarOptions = {
  toolPanels: [
    {
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
      minWidth: 180,
      maxWidth: 400,
      width: 250,
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        contractColumnSelection: true,
      },
    },
    {
      id: "filters",
      labelDefault: "Filters",
      labelKey: "filters",
      iconKey: "filter",
      toolPanel: "agFiltersToolPanel",
      minWidth: 180,
      maxWidth: 400,
      width: 250,
    },
  ],
};
function renameGroupingKey(k) {
  const keys = {
    parentAsin: "parent asin",
    campaignName: "campaign",
  };
  return keys[k] || k;
}
export const agGridOptions = {
  defaultColDef: {
    resizable: true,
    sortable: true,
    suppressMenu: true,
    cellStyle: { alignContent: "center", display: "grid" },
    filter: "agNumberColumnFilter",
    filterParams: {
      filterOptions: [
        "greaterThanOrEqual",
        "greaterThan",
        "lessThanOrEqual",
        "lessThan",
        "equals",
        "notEqual",
      ],
      maxNumConditions: 1,
    },
    pinnedRowCellRenderer: "customPinnedRowRenderer",
    pinnedRowCellRendererParams: {
      style: { color: "yellow", fontWeight: "bold" },
    },
  },
  defaultColGroupDef: {
    marryChildren: true,
  },
  suppressAggFuncInHeader: true,
  suppressDragLeaveHidesColumns: true,
  suppressMakeColumnVisibleAfterUnGroup: true,
  //suppressRowGroupHidesColumns: true,
  loadingOverlayComponent: LoadingOverlay,
  loadingOverlayComponentParams: {
    loadingMessage: "One moment please...",
  },
  autoGroupColumnDef: {
    pinned: "left",
    headerValueGetter: (params) => {
      const gkeys = Object.keys(params.api.columnModel.groupDisplayColumnsMap);
      return `${gkeys.map((key) => renameGroupingKey(key)).join("->")}`;
    },
    minWidth: 220,
    autoHeight: true,
    wrapText: true,
    cellRendererParams: {
      suppressCount: true,
    },
  },
  sideBar: sideBarOptions,
  suppressRowClickSelection: true,
  components: {
    customPinnedRowRenderer: CustomPinnedRowRenderer,
  },
  getRowClass: (row) => {
    return Object.keys(row.data || []).length === 0 ? "ag-hidden" : "";
  },
  getRowHeight: () => 32,
};

export function StatusBarComp(props) {
  const [vtotals, setVtoltals] = useState({
    cost: 0,
    sales1d: 0,
  });

  function updateStatusBar() {
    const totals = {};
    props.api.forEachNode((node) => {
      Object.keys(node.data).map((key) => {
        if (!totals[key]) {
          totals[key] = 0;
        }
        if (isNaN(node.data[key])) {
          totals[key] += 1;
        } else {
          totals[key] += node.data[key];
        }
      });
    });
    setVtoltals(totals);
  }

  //props.api.addEventListener("rowDataUpdated", updateStatusBar);
  //props.api.addEventListener('modelUpdated', updateStatusBar);
  const ctr = (
    vtotals.impressions ? (vtotals.clicks / vtotals.impressions) * 100 : 0
  ).toFixed(2);
  const cpc = (vtotals.clicks ? vtotals.cost / vtotals.clicks : 0).toFixed(2);
  const osr = (
    vtotals.clicks ? (vtotals.purchases1d / vtotals.clicks) * 100 : 0
  ).toFixed(2);
  const so = (
    vtotals.purchases1d ? vtotals.cost / vtotals.purchases1d : 0
  ).toFixed(2);
  return (
    <div>
      <h4 className="mt-2">
        <strong>Summary</strong>
        <span className=""> | spend: ${vtotals.cost.toFixed(2)}</span>
        <span className=""> | clicks: {vtotals.clicks}</span>
        <span> | impressions: {vtotals.impressions}</span>
        <span> | orders: {vtotals.purchases1d}</span>
        <span> | sales: ${vtotals.sales1d.toFixed(2)}</span>
      </h4>
      <h4>
        <span className="pt-1">
          {" "}
          ctr: {(vtotals.ctr / Object.keys(vtotals).length).toFixed(2)}%
        </span>
        <span>
          {" "}
          | cpc: ${(vtotals.cpc / Object.keys(vtotals).length).toFixed(2)}
        </span>
        <span>
          {" "}
          | orders rate:{" "}
          {(vtotals.osr / Object.keys(vtotals).length).toFixed(2)}%
        </span>
        <span>
          {" "}
          | spent/orders: $
          {(vtotals.so / Object.keys(vtotals).length).toFixed(2)}
        </span>
        <span>
          {" "}
          | roas: {(vtotals.roas / Object.keys(vtotals).length).toFixed(2)}
        </span>
        <span>
          {" "}
          | acos: {(vtotals.acos / Object.keys(vtotals).length).toFixed(2)}
        </span>
      </h4>
    </div>
  );
}

export function statusBar() {
  return {
    statusPanels: [
      {
        statusPanel: StatusBarComp,
        align: "left",
      },
    ],
  };
}

export function getTotals(data) {
  return data.map((row) => {
    row.cost_diff_p = row.cost > 0 ? (row.cost_diff / row.cost) * 100 : 0;
    row.clicks_diff_p =
      row.clicks > 0 ? (row.clicks_diff * 100) / row.clicks : 0;
    row.impressions_diff_p =
      row.impressions > 0 ? (row.impressions_diff * 100) / row.impressions : 0;
    row.ctr = row.impressions > 0 ? (row.clicks * 100) / row.impressions : 0;
    row.ctr_1 =
      row.impressions_1 > 0 > 0 ? (row.clicks_1 * 100) / row.impressions_1 : 0;
    row.ctr_diff = row.ctr - row.ctr_1;
    row.ctr_diff_p = row.ctr ? (row.ctr_diff / row.ctr) * 100 : 0;
    row.cpc = row.clicks > 0 ? row.cost / row.clicks : 0;
    row.cpc_1 = row.clicks_1 > 0 ? row.cost_1 / row.clicks_1 : 0;
    row.cpc_diff = row.cpc - row.cpc_1;
    row.cpc_diff_p = row.cpc > 0 ? (row.cpc_diff * 100) / row.cpc : 0;
    row.purchases1d_diff_p = row.purchases1d
      ? (row.purchases1d_diff * 100) / row.purchases1d
      : 0;
    row.osr = row.clicks > 0 ? (row.purchases1d * 100) / row.clicks : 0;
    row.osr_1 = row.clicks_1 > 0 ? (row.purchases1d_1 * 100) / row.clicks_1 : 0;
    row.osr_diff = row.osr - row.osr_1;
    row.osr_diff_p = row.osr > 0 ? (row.osr_diff * 100) / row.osr : 0;
    row.sales1d_diff_p =
      row.sales1d > 0 ? (row.sales1d_diff * 100) / row.sales1d : 0;
    row.so_diff_p = row.so > 0 ? (row.so_diff * 100) / row.so : 0;
    row.roas = row.cost > 0 ? (row.sales1d * 100) / row.cost : 0;
    row.roas_1 = row.cost_1 > 0 ? (row.sales1d_1 * 100) / row.cost_1 : 0;
    row.roas_diff = row.roas - row.roas_1;
    row.roas_diff_p = row.roas > 0 ? (row.roas_diff * 100) / row.roas : 0;
    row.acos = row.sales1d > 0 ? (row.cost * 100) / row.sales1d : 0;
    row.acos_1 = row.sales1d_1 ? (row.cost_1 * 100) / row.sales1d_1 : 0;
    row.acos_diff = row.acos - row.acos_1;
    row.acos_diff_p = row.acos ? (row.acos_diff * 100) / row.acos : 0;
    row.orders_diff_p = row.orders ? (row.orders_diff * 100) / row.orders : 0;
    row.total_income_diff_p = row.total_income
      ? (row.total_income_diff * 100) / row.total_income
      : 0;
    row.tacos = row.total_income ? (row.cost * 100) / row.total_income : 0;
    row.tacos_1 = row.total_income_1
      ? (row.cost_1 * 100) / row.total_income_1
      : 0;
    row.tacos_diff = row.tacos - row.tacos_1;
    row.tacos_diff_p = row.tacos ? (row.tacos_diff * 100) / row.tacos : 0;
    return row;
  });
}

export class CustomPinnedRowRenderer extends Component {
  render() {
    return <span style={this.props.style}>{this.props.value}</span>;
  }
}

const togglePanelShow = (gridRef, panel, position) => {
  const { api } = gridRef.current;

  if (api.getOpenedToolPanel() === panel) {
    setTimeout(api.closeToolPanel(), 0);
  } else {
    setTimeout(api.setSideBarPosition(position), 0);
    setTimeout(api.openToolPanel(panel), 0);
  }
};

export const toggleColumnsPanelShow = (gridRef) => {
  togglePanelShow(gridRef, "columns", "right");
};

export const toggleFiltersPanelShow = (gridRef) => {
  togglePanelShow(gridRef, "filters", "left");
};

export const exportToCSV = (gridRef, fileName) => {
  gridRef.current.api.exportDataAsCsv({
    fileName,
    allColumns: true,
  });
};

export const restoreColumnsState = (
  stateKey,
  visibleKey,
  columnDefs,
  setParentInfo,
  setRawColumns
) => {
  let newColumnDefs = columnDefs;
  let info = {};

  columnDefs.forEach((col, index) => {
    if (col.children) {
      col.children.forEach(({ field }) => {
        info[field] = index;
      });
    } else {
      info[col.field] = index;
    }
  });

  setParentInfo(info);

  const columnState = loadFromLocal(stateKey);

  if (columnState && columnState.length > 0) {
    let lastColumnDefs = [];
    let groupArr = [];

    columnState.forEach(({ colId }) => {
      if (!groupArr.includes(info[colId]) && info[colId] !== undefined) {
        groupArr.push(info[colId]);
      }
    });

    columnDefs.forEach((_, index) => {
      if (!groupArr.includes(index)) {
        groupArr.push(index);
      }
    });

    groupArr.forEach((index) => {
      lastColumnDefs.push(columnDefs[index]);
    });

    newColumnDefs = lastColumnDefs;
  }

  const columnVisible = loadFromLocal(visibleKey);

  if (columnVisible && columnVisible.length > 0) {
    let visibleInfo = {};

    columnVisible.forEach(({ colId, hide }) => (visibleInfo[colId] = hide));

    newColumnDefs.forEach((col, index) => {
      if (col.children) {
        col.children.forEach(({ field }, chIndex) => {
          col.children[chIndex].hide = visibleInfo[field];
        });
      } else {
        newColumnDefs[index].hide = visibleInfo[col.field];
      }
    });
  }

  setRawColumns(newColumnDefs);
};

export const restoreSortState = (
  gridRef,
  key,
  setOrderBy,
  defaultSort = DEFAULT_SORT
) => {
  let sortState = loadFromLocal(key);
  if (!sortState || sortState.length === 0) {
    sortState = defaultSort;
  }

  setOrderBy(parseSortState(sortState));
  setTimeout(
    gridRef.current.columnApi.applyColumnState({ state: sortState }),
    0
  );
};

function parsedFilterTwo(filters) {
  console.log("restore filters: parsing: ", filters);
  const res = {};
  Object.keys(filters).map((fk) => {
    if (
      filters[fk].column &&
      (filters[fk].value ||
        !isNaN(parseInt(filters[fk].value)) ||
        filters[fk].values) &&
      filters[fk].filterType
    ) {
      res[fk] = filters[fk];
    }
  });
  console.log("restore filters, parsed: ", res);
  return res;
}
export const restoreFilters = (key, defaultFilter = DEFAULT_FILTER) => {
  console.log("filters2: restore filters: about to respore filters fo: ", key);
  let lastFilter = loadFromLocal(key);
  if (!lastFilter || Object.keys(lastFilter).length === 0) {
    lastFilter = defaultFilter;
  }
  return parsedFilterTwo(lastFilter);
  //setTimeout(gridRef.current.api.setFilterModel(lastFilter), 0);
};

export const onDragStarted = (event) => {
  if (event.target.classList.contains("ag-header-cell")) {
    let columnDefs = event.api.getColumnDefs();
    columnDefs.forEach((col) => {
      if (col.children) {
        col.children.forEach((child) => {
          child.suppressMovable = true;
        });
      }
    });
    event.api.setColumnDefs(columnDefs);
  }
  if (event.target.classList.contains("ag-header-group-cell")) {
    let dragHandler = document.getElementsByClassName("ag-dnd-ghost")[0];
    if (dragHandler) {
      dragHandler.style.visibility = "visible";
    }
  }
};

export const onDragStopped = (event) => {
  if (event.target.classList.contains("ag-header-cell")) {
    let columnDefs = event.api.getColumnDefs();
    columnDefs.forEach((col) => {
      if (col.children) {
        col.children.forEach((child) => {
          child.suppressMovable = false;
        });
      }
    });
    event.api.setColumnDefs(columnDefs);
  }
};

export const onColumnMoved = (event, parentInfo, columnDefs, key) => {
  const columnState = event.columnApi.getColumnState();

  let groupArr = [];
  let newColumnState = [];
  let colStateInfo = {};

  columnState.forEach(({ colId }, index) => {
    colStateInfo[colId] = index;
    if (!groupArr.includes(parentInfo[colId])) {
      groupArr.push(parentInfo[colId]);
    }
  });

  groupArr.forEach((group) => {
    if (columnDefs[group].children) {
      columnDefs[group].children.forEach(({ field }) => {
        newColumnState.push(columnState[colStateInfo[field]]);
      });
    } else {
      newColumnState.push(columnState[colStateInfo[columnDefs[group].field]]);
    }
  });

  event.columnApi.applyColumnState({
    state: newColumnState,
    applyOrder: true,
  });

  saveToLocal(key, newColumnState);
};

const parseSortState = (sortState) => {
  return sortState.map((column) => {
    return { field: column.colId, sort: column.sort };
  });
};

export const onSortChanged = (event, key, orderBy, setOrderBy) => {
  const sortState = event.columnApi
    .getColumnState()
    .filter((column) => column.sort != null)
    .map((column) => {
      return {
        colId: column.colId,
        sort: column.sort,
        sortIndex: column.sortIndex,
      };
    })
    .sort((l, r) => l.sortIndex - r.sortIndex);

  const parsedSortState = parseSortState(sortState);

  if (!_.isEqual(parsedSortState, orderBy)) {
    setOrderBy(parsedSortState);
  }

  saveToLocal(key, sortState);
};

export const parseFilter = (filters) => {
  return Object.entries(filters).map(([col, filter]) => {
    if (filter.filterType === "set") {
      return {
        column: col,
        filterType: "IS_IN",
        values: filter.values,
      };
    }
    if (filter.type === "inRange") {
      return {
        column: col,
        filterType: filter.type,
        start: filter.filter,
        end: filter.filterTo,
      };
    }
    return {
      column: col,
      filterType: filter.type,
      value: filter.filter,
    };
  });
};

export const onFilterChanged = (key, filterBy) => {
  console.log("filters2, saving filter vaslue to filter key; ", {
    filterBy,
    key,
  });
  saveToLocal(key, filterBy);
};

export const onColumnVisible = (event, key) => {
  const columnVisible = event.columnApi
    .getColumnState()
    .map(({ colId, hide }) => {
      return { colId, hide };
    });

  saveToLocal(key, columnVisible);
};

export const onRowDataUpdated = (gridRef, rowData) => {
  if (rowData.length > 0 && Object.keys(rowData[0]).length === 0) {
    setTimeout(gridRef.current.api.showNoRowsOverlay(), 0);
  }
};

export const collapseFilterGroups = (gridRef) => {
  setTimeout(
    gridRef.current.api.getToolPanelInstance("filters").collapseFilterGroups(),
    0
  );
};

export const DEFAULT_FILTER = {
  impressions: { type: "greaterThan", filter: 0 },
};

export const DEFAULT_SORT = [{ colId: "cost", sort: "desc", sortIndex: 0 }];

export const AddKeywordGroupHeader = ({
  displayName,
  showAddKeyword,
  onAddKeyword,
}) => {
  return (
    <div className="ag-header-group-cell-label d-flex align-items-center">
      <div className="customHeaderLabel">{displayName}</div>
      {showAddKeyword.current ? (
        <>
          <div style={{ flexGrow: 1 }} />
          <Button
            onClick={() => {
              if (onAddKeyword.current) {
                onAddKeyword.current();
              }
            }}
            id="targeting-add-keyword"
          >
            Add Keyword
          </Button>
        </>
      ) : null}
    </div>
  );
};

export const customizeToolPanel = (gridRef) => {
  $(() => {
    if ($("#filter-panel-close").length === 0) {
      $(".ag-filter-toolpanel").prepend(`
      <div class="d-flex align-items-center">
        <div style="flex-grow: 1;"></div>
        <button id="filter-panel-close">×</button>
      </div>
    `);
    }

    if ($("#column-panel-close").length === 0) {
      $(".ag-column-select").prepend(`
      <div class="d-flex align-items-center">
        <div style="flex-grow: 1;"></div>
        <button id="column-panel-close">×</button>
      </div>
    `);
    }

    const closeToolPanel = () => {
      setTimeout(gridRef.current.api.closeToolPanel(), 0);
    };

    $("#filter-panel-close").on("click", closeToolPanel);
    $("#column-panel-close").on("click", closeToolPanel);
  });
};

export function filterObjToArray(filterObj) {
  return Object.keys(filterObj).map((fk) => {
    return {
      column: filterObj[fk].column,
      filterType: filterObj[fk].filterType,
      value: filterObj[fk].value,
      values: filterObj[fk].values,
    };
  });
}
