import api from "./Ax";
import * as dfd from "danfojs";

import { ReactComponent as autoSVG } from "assets/img/auto.svg";
import { ReactComponent as manualSVG } from "assets/img/manual.svg";
import { ReactComponent as brandSVG } from "assets/img/brand.svg";
import { ReactComponent as videoSVG } from "assets/img/video.svg";
import { ReactComponent as displaySVG } from "assets/img/display.svg";

import { Grid } from "react-feather";

const iconMap = {
  auto: autoSVG,
  manual: manualSVG,
  brand: brandSVG,
  video: videoSVG,
  display: displaySVG,
};

export default async function campaignsAdGroups(
  profileId,
  campaignsType,
  pageSize,
  page,
  search = ""
) {
  // get campaigns and adgroups from API
  const query = JSON.stringify({
    action: "get_campaigns_ad_groups",
    profileId: Number(profileId),
    campaignsType,
    pageSize,
    page,
    search,
  });
  const request_result_original = await api.post(
    "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data",
    query
  );
  const request_result = request_result_original.data;
  console.log("requested", request_result_original);
  const result = [];
  if (request_result.data.length == 0) {
    result.push({
      title: "no result found",
      href: "#",
    });
  }
  for (let i = 0; i < request_result.data.length; i++) {
    const campaign = request_result.data[i];
    const row = {
      icon: iconMap[campaign.type] ? iconMap[campaign.type] : Grid,
      title: campaign.name,
      id: campaign.id,
      href: `/profiles/${profileId}/campaigns/${campaign.id}/`,
      budget: parseFloat(campaign.budget),
      state: campaign.state,
      type: "dropdown",
      children: [],
    };
    const adgroups = campaign.adgroups;
    for (let adgroup in adgroups) {
      row.children.push({
        id: adgroups[adgroup].id,
        title: adgroups[adgroup].name,
        href: `/profiles/${profileId}/campaigns/${row.id}/adgroups/${adgroups[adgroup].id}/targeting`,
        bid: parseFloat(adgroups[adgroup].bid),
        state: adgroups[adgroup].state,
      });
    }
    result.push(row);
  }
  return {
    data: result,
    pages: request_result_original.data.pages,
  };
}
