import api from "./Ax"

export async function updateProfileSeller(profiles) {
    const payload = {
        action: "assign_profile_seller",
        profiles: profiles
    }
    console.log("payload is: ", payload)
    const result = await api.post('https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/manager', payload)
    console.log("upadted keywords: ", result)
    return result.data
}