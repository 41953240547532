import { ReactComponent as Export } from "assets/img/icons/export.svg";

const ExportButton = ({ onClick }) => {
  return (
    <button
      style={{
        background: "transparent",
        border: "none",
        fontSize: 12,
        color: "var(--back-dark)",
        minWidth: 64,
        outline: "none",
      }}
      onClick={onClick}
    >
      <Export />
      <div>Export</div>
    </button>
  );
};

export default ExportButton;
