import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Button } from "react-bootstrap";
import AuthGuard from "../../components/guards/AuthGuardSimple";
import axios from "axios";
import useAuth from "../../hooks/useAuth";

const checkState = (receivedState) => {
    const state = sessionStorage.getItem("oauth_state");
    return state === receivedState;
};
const queryToObject = (query) => {
    const parameters = new URLSearchParams(query);
    return Object.fromEntries(parameters.entries());
};

async function getToken(code, email) {
    try {
        const result = await axios.post(
            "https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/users",
            JSON.stringify(
                {
                    action: "get_token",
                    code,
                    email
                }
            )
        )
        return {
            ok: true,
            result: result.data.message
        }
    } catch (error) {
        return {
            ok: false,
            result: "An unexpected error occurred"
        }
    }
}
function sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}
const CallbackAmz = () => {
    const [error, setError] = React.useState()
    const [isFinished, setIsFinished] = React.useState(false)
    const { user } = useAuth()
    React.useEffect(() => {
        if (!user.email) {
            return
        }
        const payload = queryToObject(window.location.search.split('?')[1]);
        const state = payload && payload.state;
        const error = payload && payload.error;
        const code = payload && payload.code;
        if (!code) {
            setError('Something went wrong...')
            setIsFinished(true)
        }
        if (error) {
            setError(decodeURI(error) || 'OAuth error: An error has occured.')
            setIsFinished(true)
        } else if (state && checkState(state)) {
            getToken(code, user.email).then(res => {
                if (!res.ok) {
                    setError(res.result)
                    setIsFinished(true)
                }
                else {
                    setIsFinished(true)
                    sleep(2500).then(() => {
                        // Do something after the sleep!
                        window.location.href = "/profiles"
                    });
                }
            })
        } else {
            setError('OAuth error: State mismatch.')
            setIsFinished(true)
        }
    }, [user]);

    function getTitle(isFinished, error) {
        if (!isFinished) {
            return "Finishing up"
        } else {
            if (error) {
                return "Error connecting Amazon account"
            }
            return "Connected!"
        }
    }
    function getMessage(isFinished, error) {
        if (!isFinished) {
            return "Authorization initiation went well, please wait a few seconds while we finish things up."
        } else {
            if (error) {
                return error
            }
            return "All set, your data should appear in a few hours, you will be redirected to your dashboard in seconds."
        }
    }
    return (<AuthGuard>
        <React.Fragment>
            <Helmet title="Connect Amazon account" />
            <div className="text-center">
                <p className="h1">{getTitle(isFinished, error)}</p>
                <p className="h2 fw-normal mt-3 mb-4">
                    {getMessage(isFinished, error)}
                </p>
            </div>
        </React.Fragment>
    </AuthGuard>)
}

export default CallbackAmz;
