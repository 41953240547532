import api from "./Ax"

export async function profilesAdmin(pageSize, page, orderBy, filterBy) {
    const query = {
        'action': 'admin_profiles',
        pageSize,
        page,
        orderBy,
        filterBy
    }
    const result = await api.post("https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/data", query)
    return result.data
}

export async function updateProfileAdmin(profileId, newRefreshRate) {
    console.log("to update; ", { profileId, newRefreshRate })
    const payload = {
        action: "update_refresh_rate",
        profile_id: profileId,
        new_refresh_rate: newRefreshRate
    }
    console.log("payload is: ", payload)
    const result = await api.post('https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/manager', payload)
    console.log("upadted campaigns: ", result)
    return result.data
}

export async function updateProfileReportAdmin(profileId, newRefreshRate) {
    console.log("to update; ", { profileId, newRefreshRate })
    const payload = {
        action: "update_report_refresh_rate",
        profile_id: profileId,
        new_refresh_rate: newRefreshRate
    }
    console.log("payload is: ", payload)
    const result = await api.post('https://hzi493syzg.execute-api.us-east-1.amazonaws.com/v0/manager', payload)
    console.log("upadted campaigns: ", result)
    return result.data
}