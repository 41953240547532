import { date } from "yup";

export function renameKeys(obj, newKeys) {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

export function to_rename(agg) {
  const result = {};
  for (const key in agg) {
    result[`${key}_${agg[key]}`] = key;
  }
  return result;
}

export function get_cols_agg(cols) {
  const agg = {};
  const agg_client = [];
  let _cols = cols.map((item) => ("children" in item ? item.children : [item]));
  cols = _cols.flat(1);
  for (let i = 0; i < cols.length; i++) {
    if (!("field" in cols[i])) {
      continue;
    }
    if (cols[i].field.includes("_1")) {
      continue;
    }
    if (typeof cols[i].aggFunc === "string") {
      agg[cols[i].field] = cols[i].aggFunc;
      if (cols[i].aggFunc === "first") {
        agg_client.push(cols[i].field);
      }
      if (cols[i].aggFunc === "avg") {
        agg[cols[i].field] = "mean";
      }
    }
  }
  return { agg, agg_client };
}

export const gridOptions = {
  pagination: true,
  paginationPageSize: 20,
  defaultColDef: {
    sortable: true,
  },
};

export function saveToLocal(key, value, expiry = 86400000) {
  try {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + expiry,
    };
    localStorage.setItem(key, JSON.stringify(item));
  } catch (e) {}
}

export function loadFromLocal(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export function pickerToNorm(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function saveDates(dates) {
  saveToLocal("dates", dates);
}

export function loadDate() {
  const today = new Date();
  const past7 = new Date();
  const past14 = new Date();
  past7.setDate(past7.getDate() - 7);
  past14.setDate(past14.getDate() - 14);
  const defDates = {
    selection: {
      startDate: past7,
      endDate: today,
      key: "selection",
    },
    compare: {
      startDate: past14,
      endDate: past7,
      key: "compare",
    },
  };
  const dates = loadFromLocal("dates");
  if (dates) {
    if (dates.selection) {
      dates.selection.startDate = new Date(dates.selection.startDate);
      dates.selection.endDate = new Date(dates.selection.endDate);
    } else {
      return defDates;
    }
    if (dates.compare) {
      dates.compare.startDate = new Date(dates.compare.startDate);
      dates.compare.endDate = new Date(dates.compare.endDate);
    } else {
      return defDates;
    }
  }
  return dates || defDates;
}

export const saveToLocalSession = (key, value, expiry = 86400000) => {
  try {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + expiry,
    };
    sessionStorage.setItem(key, JSON.stringify(item));
  } catch (e) {}
};

export const loadFromLocalSession = (key) => {
  const itemStr = sessionStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    sessionStorage.removeItem(key);
    return null;
  }
  return item.value;
};
