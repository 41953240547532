import { useEffect, useState, useRef } from "react";

import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import {
  profile_refresh_rate,
  profile_report_refresh_rate,
} from "pages/opasity/opasityApi/ag_grid_fields";

import { profilesAdmin } from "pages/opasity/opasityApi/adminAdpi";

import {
  agGridOptions,
  restoreSortState,
  restoreFilters,
  onSortChanged as onSortChangedCommon,
  onFilterChanged as onFilterChangedCommon,
  onColumnVisible as onColumnVisibleCommon,
  onRowDataUpdated as onRowDataUpdatedCommon,
  collapseFilterGroups,
  toggleColumnsPanelShow,
  exportToCSV,
  customizeToolPanel,
} from "utils/agGridCommon";

import { saveToLocal, loadFromLocal } from "pages/opasity/opasityApi/utils";

import Pagination from "components/opasity/Pagination";
import FilterComponent from "components/opasity/FilterComponent";
import ExportButton from "components/opasity/ExportButton";
import ColumnsButton from "components/opasity/ColumnsButton";

import useNavbar from "hooks/useNavbar";
import useFooter from "hooks/useFooter";

const ProfilesAdmin = () => {
  const gridRef = useRef();
  const [gridReady, setGridReady] = useState(false);

  const keySuffix = "admin";
  const columnStateKey = `last-column-state-${keySuffix}`;
  const columnVisibleKey = `last-column-visible-${keySuffix}`;
  const sortStateKey = `last-sort-state-${keySuffix}`;
  const lastFilterKey = `last-filter-${keySuffix}`;
  const infoKey = `last-info-${keySuffix}`;

  const lastInfo = loadFromLocal(infoKey);

  const columnDefs = [
    {
      headerName: "Profile",
      field: "profileName",
      flex: 4,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
      },
      floatingFilter: true,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Keyword Count",
      field: "KeywordCount",
    },
    {
      headerName: "ASIN Count",
      field: "asinCount",
    },
    {
      headerName: "Currency",
      field: "currency",
      flex: 2,
    },
    {
      headerName: "Active",
      field: "profileIsActive",
      flex: 2,
    },
    profile_refresh_rate,
    {
      headerName: "Snapshot Last Refresh",
      field: "lastRefresh",
      flex: 2,
    },
    profile_report_refresh_rate,
    {
      headerName: "Report Last Refresh",
      field: "lastReportRefresh",
      flex: 2,
    },
  ];

  const [rawColumns, setRawColumns] = useState([]);

  const [rawTableData, setRawTableData] = useState([{}]);

  const [pageSize, setPageSize] = useState(
    lastInfo?.pageSize ? lastInfo.pageSize : 50
  );
  const [page, setPage] = useState(1);
  const [noPages, setNoPages] = useState(0);
  const [count, setCount] = useState(0);

  const defaultFilter = {};
  const defaultSort = [];

  const [orderBy, setOrderBy] = useState([]);
  const [filterBy, setFilterBy] = useState(
    restoreFilters(lastFilterKey, defaultFilter) || {}
  );
  const { setBreadcrumb } = useNavbar();
  const { setPagination } = useFooter();

  const updateTable = () => {
    if (!gridRef.current.api || !gridReady) {
      return;
    }

    gridRef.current.api.showLoadingOverlay();

    profilesAdmin(pageSize, page, orderBy, filterBy)
      .then((res) => {
        setRawTableData(!res.data || res.data.length === 0 ? [{}] : res.data);
        setNoPages(res.pages);
        setCount(res.count);
      })
      .catch(() => {
        gridRef.current.api.hideOverlay();
      });
  };

  const onGridReady = () => {
    restoreSortState(gridRef, sortStateKey, setOrderBy, defaultSort);

    collapseFilterGroups(gridRef);

    setGridReady(true);
  };

  const onPageSizeChanged = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onDragStarted = (event) => {
    if (event.target.classList.contains("ag-header-cell")) {
      let dragHandler = document.getElementsByClassName("ag-dnd-ghost")[0];
      if (dragHandler) {
        dragHandler.style.visibility = "visible";
      }
    }
  };

  const onSortChanged = (event) => {
    onSortChangedCommon(event, sortStateKey, orderBy, setOrderBy);
  };

  const onFilterChanged = (event) => {
    onFilterChangedCommon(event, lastFilterKey, filterBy, setFilterBy);
  };

  const onColumnMoved = (event) => {
    const columnState = event.columnApi.getColumnState();
    saveToLocal(columnStateKey, columnState);
  };

  const onColumnVisible = (event) => {
    onColumnVisibleCommon(event, columnVisibleKey);
  };

  const onRowDataUpdated = () => {
    onRowDataUpdatedCommon(gridRef, rawTableData);
  };

  useEffect(() => {
    updateTable();
  }, [gridReady, page, pageSize, orderBy, filterBy]);

  useEffect(() => {
    let newColumnDefs = columnDefs;
    let info = {};

    columnDefs.forEach(({ field }, index) => {
      info[field] = index;
    });

    const columnState = loadFromLocal(columnStateKey);

    if (columnState && columnState.length > 0) {
      let lastColumnDefs = [];
      let groupArr = [];

      columnState.forEach(({ colId }) => {
        if (!groupArr.includes(info[colId]) && info[colId] !== undefined) {
          groupArr.push(info[colId]);
        }
      });

      columnDefs.forEach((_, index) => {
        if (!groupArr.includes(index)) {
          groupArr.push(index);
        }
      });

      groupArr.forEach((index) => {
        lastColumnDefs.push(columnDefs[index]);
      });

      newColumnDefs = lastColumnDefs;
    }

    const columnVisible = loadFromLocal(columnVisibleKey);

    if (columnVisible && columnVisible.length > 0) {
      let visibleInfo = {};

      columnVisible.forEach(({ colId, hide }) => (visibleInfo[colId] = hide));

      newColumnDefs.forEach(({ field }, index) => {
        newColumnDefs[index].hide = visibleInfo[field];
      });
    }

    setRawColumns(newColumnDefs);
  }, []);

  useEffect(() => {
    setBreadcrumb(null);
  }, []);

  useEffect(() => {
    setPagination({
      pageSize,
      onPageSizeChanged,
      setPage,
      noPages,
      page,
      count,
    });
  }, [pageSize, noPages, page, count]);

  useEffect(() => {
    let currentInfo = loadFromLocal(infoKey);

    if (!currentInfo) {
      currentInfo = {};
    }

    currentInfo.pageSize = pageSize;
    saveToLocal(infoKey, currentInfo);
  }, [pageSize]);

  useEffect(() => customizeToolPanel(gridRef), [gridRef]);
  useEffect(() => {
    onFilterChangedCommon(lastFilterKey, filterBy);
  }, [filterBy]);
  return (
    <>
      <Helmet title="All Profiles" />
      <Container fluid className="p-0" style={{ height: "100%" }}>
        <Row style={{ height: "100%" }}>
          <Col style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                background: "white",
                padding: "20px 8px 16px 8px",
                marginTop: 0,
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Row className="d-flex justify-content-between mb-1">
                <Col
                  className="d-flex align-items-end"
                  style={{ marginLeft: 8 }}
                >
                  <div style={{ flexGrow: 1 }} />
                  <ExportButton
                    onClick={() => {
                      exportToCSV(gridRef, "admin.csv");
                    }}
                  />
                  <ColumnsButton
                    onClick={() => {
                      toggleColumnsPanelShow(gridRef);
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ flexGrow: 1 }}>
                <Col>
                  <div className="ag-theme-alpine grid-wrapper">
                    <AgGridReact
                      ref={gridRef}
                      onGridReady={onGridReady}
                      columnDefs={rawColumns}
                      rowData={rawTableData}
                      gridOptions={agGridOptions}
                      onDragStarted={onDragStarted}
                      onColumnMoved={onColumnMoved}
                      onSortChanged={onSortChanged}
                      //onFilterChanged={onFilterChanged}
                      onColumnVisible={onColumnVisible}
                      onRowDataUpdated={onRowDataUpdated}
                      rowModelType={"clientSide"}
                      pivotMode={false}
                    />
                  </div>
                </Col>
              </Row>
              {/* <Row className="mt-3">
                <Col xs={12}>
                  <Pagination
                    pageSize={pageSize}
                    onPageSizeChanged={onPageSizeChanged}
                    setPage={setPage}
                    noPages={noPages}
                    page={page}
                    count={count}
                  />
                </Col>
              </Row> */}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProfilesAdmin;
