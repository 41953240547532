import { useEffect, useState, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Tab,
  Alert,
  Modal,
  Table,
} from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { ReactComponent as FilterIcon } from "assets/img/icons/light-filter.svg";
import { ReactComponent as FilterLeftArrowIcon } from "assets/img/icons/filter-left-arrow.svg";
import { ReactComponent as FilterRightArrowIcon } from "assets/img/icons/filter-right-arrow.svg";
import {
  common_fields,
  ad_group_name_field,
  campaign_name_field,
  selection_box,
  search_term_field,
} from "pages/opasity/opasityApi/ag_grid_fields";

import {
  get_ad_group_search_term,
  get_ad_group_search_term_summary,
  get_ad_group_search_term_count,
} from "pages/opasity/opasityApi/adGroupSearchTerm";

import { getAdgroup, getCampaign } from "pages/opasity/opasityApi/singleItem";

import {
  createNegativeKeywords,
  createKeywords,
} from "pages/opasity/opasityApi/updateKeyword";

import campaignsAdGroups from "pages/opasity/opasityApi/campaignsAdGroups";

import {
  pickerToNorm,
  loadDate,
  saveToLocal,
  loadFromLocal,
} from "pages/opasity/opasityApi/utils";

import {
  agGridOptions,
  restoreColumnsState,
  restoreSortState,
  restoreFilters,
  onDragStarted,
  onDragStopped,
  onColumnMoved as onColumnMovedCommon,
  onSortChanged as onSortChangedCommon,
  onFilterChanged as onFilterChangedCommon,
  onColumnVisible as onColumnVisibleCommon,
  onRowDataUpdated as onRowDataUpdatedCommon,
  collapseFilterGroups,
  toggleColumnsPanelShow,
  exportToCSV,
  getTotals,
  DEFAULT_FILTER,
  customizeToolPanel,
  filterObjToArray,
} from "utils/agGridCommon";
import Filters from "components/Filters";
import FilterChips from "components/FilterChips";
import DateModal from "components/opasity/DateModal";
import Pagination from "components/opasity/Pagination";
import FilterComponent from "components/opasity/FilterComponent";
import ExportButton from "components/opasity/ExportButton";
import ColumnsButton from "components/opasity/ColumnsButton";
import TabNav from "components/opasity/TabNav";

import useNavbar from "hooks/useNavbar";
import useFooter from "hooks/useFooter";
import { useSearchDebounce } from "../utils";

const AdGroupSearchTerm = () => {
  const { profileId, campaignId, adGroupId } = useParams();
  const [openFilters, setOpenFilters] = useState(false);
  const gridRef = useRef();
  const [gridReady, setGridReady] = useState(false);

  const keySuffix = `searchterm${adGroupId ? "-adgroup" : ""}${
    campaignId ? "-campaign" : ""
  }`;
  const columnStateKey = `last-column-state-${keySuffix}`;
  const columnVisibleKey = `last-column-visible-${keySuffix}`;
  const sortStateKey = `last-sort-state-${keySuffix}`;
  const lastFilterKey = `last-filter-${keySuffix}`;
  const infoKey = `last-info-${keySuffix}`;

  const lastInfo = loadFromLocal(infoKey);

  const columnDefs = [
    {
      headerName: "Search Term Info",
      suppressColumnsToolPanel: true,
      children: [
        selection_box,
        search_term_field,
        ...(adGroupId ? [] : [ad_group_name_field]),
        ...(campaignId ? [] : [campaign_name_field]),
      ],
    },
    ...common_fields,
  ];

  search_term_field.pinned = "left";
  ad_group_name_field.pinned = "left";
  campaign_name_field.pinned = "left";
  selection_box.pinned = "left";

  const [rawColumns, setRawColumns] = useState([]);
  const [parentInfo, setParentInfo] = useState({});

  const [rawTableData, setRawTableData] = useState([{}]);
  const [summaryData, setSummaryData] = useState([]);

  const [dateState, setDateState] = useState(loadDate());

  const [selectedSearchTerm, setSelectedSearchTerm] = useState([]);

  const [pageSize, setPageSize] = useState(
    lastInfo?.pageSize ? lastInfo.pageSize : 50
  );
  const [page, setPage] = useState(1);
  const [noPages, setNoPages] = useState(0);
  const [count, setCount] = useState(0);

  const [successMessages, setSuccessMessages] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);

  const defaultFilter = campaignId ? {} : DEFAULT_FILTER;

  const [orderBy, setOrderBy] = useState([]);
  const [filterBy, setFilterBy] = useState(
    restoreFilters(lastFilterKey, defaultFilter) || {}
  );
  const [debFilter, setDebFilter] = useSearchDebounce(300);
  const { setBreadcrumb } = useNavbar();
  const { setPagination } = useFooter();

  const updateTable = () => {
    if (!gridRef.current.api || !gridReady) {
      return;
    }

    const { selection, compare } = dateState;

    const selStartDate = pickerToNorm(selection.startDate);
    const selEndDate = pickerToNorm(selection.endDate);
    const comStartDate = pickerToNorm(compare.startDate);
    const comEndDate = pickerToNorm(compare.endDate);

    gridRef.current.api.showLoadingOverlay();

    get_ad_group_search_term(
      profileId,
      Number(adGroupId),
      Number(campaignId),
      selStartDate,
      selEndDate,
      comStartDate,
      comEndDate,
      pageSize,
      page,
      orderBy,
      filterObjToArray(filterBy)
    )
      .then((res) => {
        setRawTableData(!res.data || res.data.length === 0 ? [{}] : res.data);

        get_ad_group_search_term_summary(
          profileId,
          Number(adGroupId),
          Number(campaignId),
          selStartDate,
          selEndDate,
          comStartDate,
          comEndDate,
          filterObjToArray(filterBy)
        ).then((res) => {
          setSummaryData(getTotals(res.data));
        });

        get_ad_group_search_term_count(
          profileId,
          Number(adGroupId),
          Number(campaignId),
          selStartDate,
          selEndDate,
          comStartDate,
          comEndDate,
          filterObjToArray(filterBy)
        ).then((res) => {
          setNoPages(res.pages);
          setCount(res.count);
        });
      })
      .catch(() => {
        gridRef.current.api.hideOverlay();
      });
  };

  const onGridReady = () => {
    restoreSortState(gridRef, sortStateKey, setOrderBy);

    collapseFilterGroups(gridRef);

    setGridReady(true);
  };

  const onPageSizeChanged = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onSortChanged = (event) => {
    onSortChangedCommon(event, sortStateKey, orderBy, setOrderBy);
  };

  const onFilterChanged = (event) => {
    onFilterChangedCommon(event, lastFilterKey, filterBy, setFilterBy);
  };

  const onColumnMoved = (event) => {
    onColumnMovedCommon(event, parentInfo, columnDefs, columnStateKey);
  };

  const onColumnVisible = (event) => {
    onColumnVisibleCommon(event, columnVisibleKey);
  };

  const onRowDataUpdated = () => {
    onRowDataUpdatedCommon(gridRef, rawTableData);
  };

  const onSelectionChanged = (event) => {
    setSelectedSearchTerm(event.api.getSelectedNodes());
  };

  const isRowSelectable = (row) => {
    return Object.keys(row.data).length !== 0;
  };

  useEffect(() => {
    updateTable();
  }, [gridReady, dateState, page, pageSize, orderBy, debFilter]);
  useEffect(() => {
    setDebFilter(filterBy);
  }, [filterBy]);
  useEffect(() => {
    restoreColumnsState(
      columnStateKey,
      columnVisibleKey,
      columnDefs,
      setParentInfo,
      setRawColumns
    );
  }, []);

  useEffect(() => {
    setBreadcrumb({ page: "Search Term", profileId, campaignId, adGroupId });
  }, [profileId, campaignId, adGroupId]);

  useEffect(() => {
    setPagination({
      pageSize,
      onPageSizeChanged,
      setPage,
      noPages,
      page,
      count,
    });
  }, [pageSize, noPages, page, count]);

  useEffect(() => {
    let currentInfo = loadFromLocal(infoKey);

    if (!currentInfo) {
      currentInfo = {};
    }

    currentInfo.pageSize = pageSize;
    saveToLocal(infoKey, currentInfo);
  }, [pageSize]);

  useEffect(() => customizeToolPanel(gridRef), [gridRef]);
  useEffect(() => {
    onFilterChangedCommon(lastFilterKey, filterBy);
  }, [filterBy]);
  return (
    <>
      <Helmet title="Search Term" />
      <Container fluid className="p-0" style={{ height: "100%" }}>
        {/* <Row>
          <Col>
            <BreadCrumb
              campaignId={campaignId}
              adGroupId={adGroupId}
              page="Search Term"
              profileId={profileId}
            ></BreadCrumb>
          </Col>
          <Col className="text-end">
            {campaignId && !adGroupId && (
              <Button
                onClick={() =>
                  (window.location.href = `/profiles/${profileId}/campaigns/${campaignId}/settings`)
                }
              >
                Campaign Settings
              </Button>
            )}
            {adGroupId && (
              <Button
                onClick={() =>
                  (window.location.href = `/profiles/${profileId}/campaigns/${campaignId}/adgroups/${adGroupId}/settings`)
                }
              >
                AdGroup Settings
              </Button>
            )}
          </Col>
        </Row> */}
        <Row style={{ height: "100%" }}>
          <Col>
            <div
              className="tab"
              style={{
                height: "100%",
                marginBottom: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div
                  className="d-flex align-items-end"
                  style={{
                    marginTop: 18,
                    marginRight: 16,
                    paddingRight: 0,
                  }}
                >
                  <TabNav
                    profileId={profileId}
                    campaignId={campaignId}
                    adGroupId={adGroupId}
                    page="searchterm"
                  />
                  <div style={{ flexGrow: 1 }} />
                  <div style={{ marginBottom: "0.5em" }}>
                    <DateModal
                      gridRef={gridRef}
                      dateState={dateState}
                      setDateState={setDateState}
                    />
                  </div>
                </div>
                <Tab.Content style={{ padding: 0, flexGrow: 1 }}>
                  <Tab.Pane
                    eventKey="first"
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        background: "white",
                        padding: 0,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Row>
                        {successMessages &&
                          successMessages.map((msg) => (
                            <Alert
                              variant="success"
                              onClose={() => setSuccessMessages([])}
                              dismissible
                            >
                              <div className="alert-message">{msg}</div>
                            </Alert>
                          ))}
                      </Row>
                      <Row>
                        {errorMessages &&
                          errorMessages.map((msg) => (
                            <Alert
                              variant="danger"
                              onClose={() => setErrorMessages([])}
                              dismissible
                            >
                              <div className="alert-message">{msg}</div>
                            </Alert>
                          ))}
                      </Row>
                      <Row className="d-flex justify-content-between mb-1">
                        <Col
                          className="d-flex align-items-end"
                          style={{ marginLeft: 0 }}
                        >
                          {selectedSearchTerm.length === 0 ? (
                            //  <FilterComponent
                            //   gridRef={gridRef}
                            //  filterBy={filterBy}
                            //   defaultFilter={defaultFilter}
                            // page="searchTerm"
                            // />
                            <>
                              <button
                                className="filters-button"
                                onClick={() => {
                                  setOpenFilters(!openFilters);
                                }}
                              >
                                {openFilters ? (
                                  <span style={{ marginRight: 6 }}>
                                    Filters
                                  </span>
                                ) : null}
                                <FilterIcon style={{ marginRight: 6 }} />
                                {openFilters ? (
                                  <FilterLeftArrowIcon />
                                ) : (
                                  <FilterRightArrowIcon />
                                )}
                              </button>

                              <FilterChips
                                filterBy={filterBy}
                                setFilterBy={setFilterBy}
                              />
                            </>
                          ) : (
                            <div style={{ marginLeft: "5px", display: "flex" }}>
                              <AddKeywordsModal
                                profileId={profileId}
                                updateTable={updateTable}
                                campaignIdDefault={campaignId}
                                adGroupIdDefault={adGroupId}
                                keywordsTextInp={selectedSearchTerm
                                  .map((node) => node.data.search_term)
                                  .join("\n")}
                              />
                              <AddNegativeKeywordsModal
                                profileId={profileId}
                                updateTable={updateTable}
                                campaignIdDefault={campaignId}
                                adGroupIdDefault={adGroupId}
                                keywordsTextInp={selectedSearchTerm
                                  .map((node) => node.data.search_term)
                                  .join("\n")}
                                setErrorMessages={setErrorMessages}
                                setSuccessMessages={setSuccessMessages}
                              />
                            </div>
                          )}
                          <div style={{ flexGrow: 1 }} />
                          <ExportButton
                            onClick={() => {
                              exportToCSV(gridRef, "searchterm.csv");
                            }}
                          />
                          <ColumnsButton
                            onClick={() => {
                              toggleColumnsPanelShow(gridRef);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ flexGrow: 1 }}>
                        <Filters
                          columnsArray={rawColumns}
                          openFilters={openFilters}
                          setOpenFilters={setOpenFilters}
                          filterBy={filterBy}
                          setFilterBy={setFilterBy}
                        />
                        <Col>
                          <div className="ag-theme-alpine grid-wrapper">
                            <AgGridReact
                              ref={gridRef}
                              onGridReady={onGridReady}
                              columnDefs={rawColumns}
                              rowData={rawTableData}
                              gridOptions={agGridOptions}
                              pinnedBottomRowData={summaryData}
                              rowSelection="multiple"
                              isRowSelectable={isRowSelectable}
                              onSelectionChanged={onSelectionChanged}
                              onDragStarted={onDragStarted}
                              onDragStopped={onDragStopped}
                              onColumnMoved={onColumnMoved}
                              onSortChanged={onSortChanged}
                              //onFilterChanged={onFilterChanged}
                              onColumnVisible={onColumnVisible}
                              onRowDataUpdated={onRowDataUpdated}
                              pivotMode={false}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdGroupSearchTerm;

const colors = [
  {
    name: "Primary",
    value: "primary",
  },
];

const AddKeywordsModal = ({
  profileId,
  updateTable,
  campaignIdDefault = null,
  adGroupIdDefault = null,
  keywordsTextInp = "",
}) => {
  const [saving, setSaving] = useState(false);
  const [broad, setBroad] = useState(true);
  const [phrase, setPhrase] = useState(true);
  const [exact, setExact] = useState(true);
  const [bidType, setBidType] = useState("default");
  const [bid, setBid] = useState(0.66);
  const [keywords, setKeywords] = useState([]);
  const [error, setError] = useState({});
  const [keywordsText, setKeywordsText] = useState(keywordsTextInp);
  const [maxBid, setMaxBid] = useState(99);
  const [campaigns, setCampaigns] = useState([]);
  const [campaignId, setCampaignId] = useState(campaignIdDefault);
  const [adGroupId, setAdGroupId] = useState(adGroupIdDefault);

  const initOpenModals = () => {
    let modals = {};

    colors.forEach((color, index) => {
      modals = Object.assign({}, modals, { [index]: false });
    });

    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());
  const toggle = (index) => {
    // Toggle selected element
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };

  useEffect(() => {
    setKeywordsText(keywordsTextInp);
  }, [keywordsTextInp]);
  useEffect(() => {
    console.log("getting adgroup, ", profileId, adGroupId);
    const campaign = campaigns.filter((campaign) => campaign.id == campaignId);
    console.log("campaign is: ", campaign);
    if (campaign.length > 0) {
      const adgroup = campaign[0].children.filter(
        (_adgroup) => _adgroup.id == adGroupId
      );
      console.log("adgroup us: ", adgroup[0]);

      if (adgroup.length > 0) {
        setBid(adgroup[0].bid);
      }
    }
  }, [profileId, adGroupId]);
  useEffect(() => {
    getCampaign(profileId, campaignId, true).then((res) => {
      console.log("campaign is: ", res);
      setMaxBid(res.budget);
      const campaign = campaigns.filter(
        (campaign) => campaign.id == campaignId
      );
      const adGroupIds = campaign[0].children.map((adgroup) => adgroup.id);
      if (!adGroupId || !(adGroupId in adGroupIds)) {
        setAdGroupId(adGroupIds[0]);
      }
    });
  }, [profileId, campaignId]);
  useEffect(() => {
    campaignsAdGroups(profileId, "manual").then((res) => {
      console.log("campaigns adgroups: ", res);
      const nonarchived = res.filter(
        (campaign) => campaign.state != "archived"
      );
      console.log("non archived campaigns: ", nonarchived);
      setCampaigns(nonarchived);
      if (!campaignId) {
        setCampaignId(nonarchived[0].id);
        setAdGroupId(nonarchived[0].children[0].id);
        setBid(nonarchived[0].children[0].bid);
      }
    });
  }, [profileId]);
  function setKeywordBid(e) {
    const newBid = parseFloat(e.target.value);
    if (newBid > maxBid) {
      createError(
        "bid",
        "bid must be less than campaign budget of: $" + maxBid
      );
    }
    if (newBid > 0) {
      deleteError("bid");
      setBid(newBid);
    } else {
      createError("bid", "Some error with default bid...");
    }
  }
  function deleteError(key) {
    const newError = { ...error };
    if (Object.keys(newError).filter((e) => e == key).length > 0) {
      delete newError[key];
      setError(newError);
    }
  }
  function createError(key, val) {
    const newError = {
      ...error,
    };
    newError[key] = val;
    setError(newError);
    console.log("error is now;: ", error);
  }
  function keywordAlreadyExists(keyword, match_type) {
    return (
      keywords.filter(
        (_keyword) =>
          _keyword.keyword == keyword && _keyword.match_type == match_type
      ).length > 0
    );
  }
  function addKeywords() {
    console.log("add keyword event: ", keywordsText);
    const splitted = keywordsText.split("\n");
    const keywordsSet = new Set(splitted);
    const kk = Array.from(keywordsSet);
    const newKeywords = [];
    deleteError("alpha_num");
    kk.map((keyword) => {
      if (!keyword.match(/^[0-9a-z ]+$/)) {
        createError(
          "alpha_num",
          "Keyword should consist of letters and number only"
        );
      } else {
        if (broad) {
          if (!keywordAlreadyExists(keyword, "BROAD")) {
            newKeywords.push({
              keyword,
              match_type: "BROAD",
              bid,
            });
          }
        }
        if (phrase) {
          if (!keywordAlreadyExists(keyword, "PHRASE")) {
            newKeywords.push({
              keyword,
              match_type: "PHRASE",
              bid,
            });
          }
        }
        if (exact) {
          if (!keywordAlreadyExists(keyword, "EXACT")) {
            newKeywords.push({
              keyword,
              match_type: "EXACT",
              bid,
            });
          }
        }
      }
    });
    setKeywords([...keywords, ...newKeywords]);
    setKeywordsText("");
  }
  function deleteAllKeywords() {
    setKeywords([]);
  }
  function setSpecificKeywordBid(keyword, match_type, e) {
    let newBid = bid;
    deleteError("bid_error");
    try {
      newBid = parseFloat(e.target.value);
      if (newBid < 0.03) {
        createError("bid_error", "Bid must be greater or equal to 0.02");
        console.log("created an error ...");
        console.log(error);
        return;
      }
      if (newBid > maxBid) {
        createError(
          "bid_error",
          "Bid must be less than campaign budget $" + maxBid
        );
        return;
      }
    } catch (e) {}
    const newKeywords = [...keywords];
    newKeywords.map((_keyword) => {
      if (_keyword.keyword == keyword && _keyword.match_type == match_type) {
        _keyword.bid = newBid;
      }
    });
    setKeywords(newKeywords);
  }
  function deleteSpecificKeyword(keyword, match_type) {
    const newKeywords = keywords.filter(
      (_keyword) =>
        !(_keyword.keyword == keyword && _keyword.match_type == match_type)
    );
    setKeywords(newKeywords);
  }
  function isDisabled() {
    return saving || keywords.length == 0;
  }
  function handleSubmit() {
    setSaving(true);
    createKeywords(profileId, campaignId, adGroupId, keywords).then((res) => {
      console.log("keywords creation result: ", res);
      if (res.error) {
        createError("unexpected_error", res.error);
      } else {
        setKeywords([]);
        updateTable();
        toggle(0);
      }
    });
  }
  return (
    <div className="mb-1">
      <Button
        variant="primary"
        onClick={() => toggle(0)}
        className="me-2"
        style={{ background: "var(--major-blue)" }}
      >
        Create Keywords ({keywordsText.split("\n").length})
      </Button>
      <Modal show={openModals[0]} onHide={() => toggle(0)}>
        <Modal.Header closeButton>Add as Keywords</Modal.Header>
        <Modal.Body
          className="text-center"
          style={{
            background: "linear-gradient(90deg, #FFC0CB00 50%, #D8DDDD 50%)",
          }}
        >
          <Row>
            <Col md="6">
              <Row>
                <Col md="3">
                  <Form.Label className="mb-0 mt-2 h5">Campaign</Form.Label>
                </Col>
                <Col md="9">
                  <Form.Select
                    id="exampleCustomSelect"
                    name="customSelect"
                    className="mb-3"
                    onChange={(e) => setCampaignId(e.target.value)}
                  >
                    {campaigns &&
                      campaigns.map((campaign) => {
                        return (
                          <>
                            <option
                              value={campaign.id}
                              selected={campaign.id == campaignId}
                            >
                              {campaign.title} ({campaign.state})
                            </option>
                          </>
                        );
                      })}
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <Form.Label className="mb-0 mt-2 h5">Ad Group</Form.Label>
                </Col>
                <Col md="9">
                  <Form.Select
                    id="exampleCustomSelect"
                    name="customSelect"
                    className="mb-3"
                    onChange={(e) => setAdGroupId(e.target.value)}
                  >
                    {campaigns.filter((campaign) => campaign.id == campaignId)
                      .length > 0 &&
                      campaigns
                        .filter((campaign) => campaign.id == campaignId)[0]
                        .children.map((adgroup) => {
                          return (
                            <>
                              <option
                                value={adgroup.id}
                                selected={adgroup.id == adGroupId}
                              >
                                {adgroup.title} ({adgroup.state})
                              </option>
                            </>
                          );
                        })}
                  </Form.Select>
                </Col>
              </Row>
              <Form.Group className="mb-3">
                <Row>
                  <Col md="3">
                    <Form.Label className="mb-0 mt-2 h5">Bid</Form.Label>
                  </Col>
                  <Col md="9">
                    <Row>
                      <Col md="6">
                        <InputGroup className="p-1">
                          <InputGroup.Text>$</InputGroup.Text>
                          <Form.Control
                            value={bid}
                            onChange={(e) => setKeywordBid(e)}
                            type="number"
                            step="0.01"
                          />
                        </InputGroup>
                        (max bid: {maxBid})
                      </Col>
                      <Col md="12">
                        {error.bid && (
                          <h5 className="text-danger mt-2">{error.bid}</h5>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col md="3">
                    <Form.Label className="mb-0 mt-2 h5">Match Type</Form.Label>
                  </Col>
                  <Col md="2">
                    <Form.Group className="mb-2 mt-2">
                      <Form.Check
                        label="Broad"
                        type="checkbox"
                        name="broad"
                        value="broad"
                        onChange={(e) => setBroad(e.target.checked)}
                        checked={broad}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="2">
                    <Form.Group className="mb-2 mt-2">
                      <Form.Check
                        label="Phrase"
                        type="checkbox"
                        name="phrase"
                        value="phrase"
                        onChange={(e) => setPhrase(e.target.checked)}
                        checked={phrase}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="2">
                    <Form.Group className="mb-2 mt-2">
                      <Form.Check
                        label="Exact"
                        type="checkbox"
                        name="exact"
                        value="exact"
                        onChange={(e) => setExact(e.target.checked)}
                        checked={exact}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col md="12">
                    <Form.Control
                      as="textarea"
                      name="input"
                      placeholder="Add your Keywords here, one keyword per line"
                      rows={20}
                      value={keywordsText}
                      onChange={(e) => setKeywordsText(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Row>
                <Col md="4">
                  <Button
                    variant="primary"
                    onClick={() => addKeywords()}
                    className="me-1 mt-3"
                  >
                    add Keywords
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md="6">
              {Object.keys(error) &&
                Object.keys(error).map((e) => (
                  <Row>
                    <h5 className="text-danger mt-2">{error[e]}</h5>
                  </Row>
                ))}
              <Row>
                <Col md="6">
                  <span className="text-primary">
                    {keywords.length}{" "}
                    <span className="h5 text-primary">Added</span>
                  </span>
                </Col>
                <Col md="6">
                  <a
                    onClick={() => deleteAllKeywords()}
                    className="text-primary"
                  >
                    Remove all
                  </a>
                </Col>
              </Row>
              <Row>
                <Table>
                  <thead>
                    <tr>
                      <th style={{ width: "30%" }}>Keyword</th>
                      <th style={{ width: "25%" }}>Match Type</th>
                      <th style={{ width: "30%" }}>Bid</th>
                      <th style={{ width: "15%" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {keywords.map((keyword) => (
                      <tr>
                        <td>{keyword.keyword}</td>
                        <td>{keyword.match_type}</td>
                        <td>
                          <InputGroup className="p-1">
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control
                              value={keyword.bid}
                              onChange={(e) =>
                                setSpecificKeywordBid(
                                  keyword.keyword,
                                  keyword.match_type,
                                  e
                                )
                              }
                              type="number"
                              step="0.01"
                            />
                          </InputGroup>
                        </td>
                        <td>
                          <a
                            className="text-danger"
                            onClick={() =>
                              deleteSpecificKeyword(
                                keyword.keyword,
                                keyword.match_type
                              )
                            }
                          >
                            Delete
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => handleSubmit()}
            disabled={isDisabled()}
          >
            Save
          </Button>
          <Button variant="warning" onClick={() => toggle(0)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
function AddNegativeKeywordsModal({
  profileId,
  updateTable,
  campaignIdDefault = null,
  adGroupIdDefault = null,
  keywordsTextInp = "",
  setErrorMessages,
  setSuccessMessages,
}) {
  const [saving, setSaving] = useState(false);
  const [phrase, setPhrase] = useState(true);
  const [exact, setExact] = useState(true);
  const [negativeKeywords, setNegativeKeywords] = useState([]);
  const [error, setError] = useState({});
  const [negativeKeywordsText, setNegativeKeywordsText] =
    useState(keywordsTextInp);
  const [campaigns, setCampaigns] = useState([]);
  const [campaignId, setCampaignId] = useState(campaignIdDefault);
  const [adGroupId, setAdGroupId] = useState(adGroupIdDefault);

  const initOpenModals = () => {
    let modals = {};

    colors.forEach((color, index) => {
      modals = Object.assign({}, modals, { [index]: false });
    });

    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());
  const toggle = (index) => {
    // Toggle selected element
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };

  useEffect(() => {
    setNegativeKeywordsText(keywordsTextInp);
  }, [keywordsTextInp]);
  useEffect(() => {
    getCampaign(profileId, campaignId, true).then((res) => {
      console.log("campaign is: ", res);
      const campaign = campaigns.filter(
        (campaign) => campaign.id == campaignId
      );
      const adGroupIds = campaign[0].children.map((adgroup) => adgroup.id);
      if (!adGroupId || !(adGroupId in adGroupIds)) {
        setAdGroupId(adGroupIds[0]);
      }
    });
  }, [profileId, campaignId]);
  useEffect(() => {
    campaignsAdGroups(profileId, "manual").then((res) => {
      console.log("campaigns adgroups: ", res);
      const nonarchived = res.filter(
        (campaign) => campaign.state != "archived"
      );
      console.log("non archived campaigns: ", nonarchived);
      setCampaigns(nonarchived);
      if (!campaignId) {
        setCampaignId(nonarchived[0].id);
        setAdGroupId(nonarchived[0].children[0].id);
      }
    });
  }, [profileId]);

  function deleteError(key) {
    const newError = { ...error };
    if (Object.keys(newError).filter((e) => e == key).length > 0) {
      delete newError[key];
      setError(newError);
    }
  }
  function createError(key, val) {
    const newError = {
      ...error,
    };
    newError[key] = val;
    setError(newError);
    console.log("error is now;: ", error);
  }
  function keywordAlreadyExists(keyword, match_type) {
    return (
      negativeKeywords.filter(
        (_keyword) =>
          _keyword.keyword == keyword && _keyword.match_type == match_type
      ).length > 0
    );
  }
  function addNegativeKeywords() {
    console.log("add keyword event: ", negativeKeywords);
    const splitted = negativeKeywordsText.split("\n");
    const keywordsSet = new Set(splitted);
    const kk = Array.from(keywordsSet);
    const newKeywords = [];
    deleteError("alpha_num");
    kk.map((keyword) => {
      if (!keyword.match(/^[0-9a-z ]+$/)) {
        createError(
          "alpha_num",
          "Keyword should consist of letters and number only"
        );
      } else {
        if (phrase) {
          if (!keywordAlreadyExists(keyword, "PHRASE")) {
            newKeywords.push({
              keyword,
              match_type: "NEGATIVE_PHRASE",
            });
          }
        }
        if (exact) {
          if (!keywordAlreadyExists(keyword, "EXACT")) {
            newKeywords.push({
              keyword,
              match_type: "NEGATIVE_EXACT",
            });
          }
        }
      }
    });
    setNegativeKeywords([...negativeKeywords, ...newKeywords]);
    setNegativeKeywordsText("");
  }
  function deleteAllNegativeKeywords() {
    setNegativeKeywords([]);
  }

  function deleteSpecificNegativeKeyword(keyword, match_type) {
    const newKeywords = negativeKeywords.filter(
      (_keyword) =>
        !(_keyword.keyword == keyword && _keyword.match_type == match_type)
    );
    setNegativeKeywords(newKeywords);
  }
  function isDisabled() {
    return saving || negativeKeywords.length == 0;
  }
  function handleSubmit() {
    setSaving(true);
    createNegativeKeywords(
      profileId,
      campaignId,
      adGroupId,
      negativeKeywords
    ).then((res) => {
      console.log("keywords creation result: ", res);
      const errs = [];
      if (res.errors.length > 0) {
        console.log("errors from request: ", res.errors);
        res.errors.map((error) => {
          console.log("error from map;: ", error);
          let msg = "";
          error.errors.map((err) => {
            console.log("err is: ", err);
            console.log("error value before keyws: ", err.errorValue);
            return Object.keys(err.errorValue).map((errval) => {
              console.log("errval: ", err.errorValue[errval]);
              msg += err.errorValue[errval].message;
            });
          });
          errs.push(
            <span>
              Error creating negative keyword:{" "}
              <strong>{negativeKeywords[error.index].keyword}</strong>(
              <strong>{negativeKeywords[error.index].match_type}</strong>)
              reason <strong>{msg}</strong>
            </span>
          );
        });
      }
      setErrorMessages(errs);
      setSuccessMessages([
        "Created " + res.success.length + " negative keywords",
      ]);
      setNegativeKeywords([]);
      updateTable();
      toggle(0);
      setSaving(false);
    });
  }
  return (
    <div className="mb-1">
      <Button
        variant="primary"
        onClick={() => toggle(0)}
        style={{ background: "var(--major-blue)" }}
      >
        Create Negative Keywords ({negativeKeywordsText.split("\n").length})
      </Button>
      <Modal show={openModals[0]} onHide={() => toggle(0)}>
        <Modal.Header closeButton>Add as Keywords</Modal.Header>
        <Modal.Body
          className="text-center"
          style={{
            background: "linear-gradient(90deg, #FFC0CB00 50%, #D8DDDD 50%)",
          }}
        >
          <Row>
            <Col md="6">
              <Row>
                <Col md="3">
                  <Form.Label className="mb-0 mt-2 h5">Campaign</Form.Label>
                </Col>
                <Col md="9">
                  <Form.Select
                    id="exampleCustomSelect"
                    name="customSelect"
                    className="mb-3"
                    onChange={(e) => setCampaignId(e.target.value)}
                  >
                    {campaigns &&
                      campaigns.map((campaign) => {
                        return (
                          <>
                            <option
                              value={campaign.id}
                              selected={campaign.id == campaignId}
                            >
                              {campaign.title} ({campaign.state})
                            </option>
                          </>
                        );
                      })}
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <Form.Label className="mb-0 mt-2 h5">Ad Group</Form.Label>
                </Col>
                <Col md="9">
                  <Form.Select
                    id="exampleCustomSelect"
                    name="customSelect"
                    className="mb-3"
                    onChange={(e) => setAdGroupId(e.target.value)}
                  >
                    <>
                      <option value="763" selected={adGroupId == 763}>
                        ENTIRE CAMPAIGN
                      </option>
                      {campaigns.filter((campaign) => campaign.id == campaignId)
                        .length > 0 &&
                        campaigns
                          .filter((campaign) => campaign.id == campaignId)[0]
                          .children.map((adgroup) => {
                            return (
                              <option
                                value={adgroup.id}
                                selected={adgroup.id == adGroupId}
                              >
                                {adgroup.title} ({adgroup.state})
                              </option>
                            );
                          })}
                    </>
                  </Form.Select>
                </Col>
              </Row>
              <Form.Group className="mb-3">
                <Row>
                  <Col md="6">
                    <Form.Group className="mb-2 mt-2">
                      <Form.Check
                        label="Negative Phrase"
                        type="checkbox"
                        name="phrase"
                        value="phrase"
                        onChange={(e) => setPhrase(e.target.checked)}
                        checked={phrase}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="mb-2 mt-2">
                      <Form.Check
                        label="Negative Exact"
                        type="checkbox"
                        name="exact"
                        value="exact"
                        onChange={(e) => setExact(e.target.checked)}
                        checked={exact}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col md="12">
                    <Form.Control
                      as="textarea"
                      name="input"
                      placeholder="Add your Keywords here, one keyword per line"
                      rows={20}
                      value={negativeKeywordsText}
                      onChange={(e) => setNegativeKeywordsText(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Row>
                <Col md="8">
                  <Button
                    variant="primary"
                    onClick={() => addNegativeKeywords()}
                    className="me-1 mt-3"
                  >
                    add Negative Keywords
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md="6">
              {Object.keys(error) &&
                Object.keys(error).map((e) => (
                  <Row>
                    <h5 className="text-danger mt-2">{error[e]}</h5>
                  </Row>
                ))}
              <Row>
                <Col md="6">
                  <span className="text-primary">
                    {negativeKeywords.length}{" "}
                    <span className="h5 text-primary">Added</span>
                  </span>
                </Col>
                <Col md="6">
                  <a
                    onClick={() => deleteAllNegativeKeywords()}
                    className="text-primary"
                  >
                    Remove all
                  </a>
                </Col>
              </Row>
              <Row>
                <Table>
                  <thead>
                    <tr>
                      <th style={{ width: "50%" }}>Keyword</th>
                      <th style={{ width: "35%" }}>Match Type</th>
                      <th style={{ width: "15%" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {negativeKeywords.map((keyword) => (
                      <tr>
                        <td>{keyword.keyword}</td>
                        <td>{keyword.match_type}</td>
                        <td>
                          <a
                            className="text-danger"
                            onClick={() =>
                              deleteSpecificNegativeKeyword(
                                keyword.keyword,
                                keyword.match_type
                              )
                            }
                          >
                            Delete
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => handleSubmit()}
            disabled={isDisabled()}
          >
            Save
          </Button>
          <Button variant="warning" onClick={() => toggle(0)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
