import { useParams, useSearchParams } from "react-router-dom";

import campaignsAdGroups from "pages/opasity/opasityApi/campaignsAdGroups";
import { useState, useEffect } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { Search, X } from "react-feather";

import PerfectScrollbar from "react-perfect-scrollbar";
// import "react-perfect-scrollbar/dist/css/styles.css";

import useAuth from "hooks/useAuth";
import Spinner from "react-spinner-material";
import SidebarNavSection from "./SidebarNavSection";
import SidebarNavSeparator from "./SidebarNavSeparator";

const CampaignsNav = ({ campaignsType }) => {
  const { profileId } = useParams();
  const { token } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const pageSize = 10;

  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(0);

  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);

  const [searchWord, setSearchWord] = useState("");

  const filterCampaigns = (event) => {
    setSearchWord(event.target.value);
    setSearchParams({ search: event.target.value });
  };

  const updateSideBarCampaigns = () => {
    setLoading(true);

    campaignsAdGroups(profileId, campaignsType, pageSize, page, searchWord)
      .then((res) => {
        setCampaigns(page === 1 ? res.data : [...campaigns, ...res.data]);
        setNumPages(res.pages);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (token && profileId) {
      updateSideBarCampaigns();
    }
  }, [token, profileId, campaignsType, page]);

  useEffect(() => {
    setCampaigns([]);
    if (page === 1) {
      updateSideBarCampaigns();
    }
    setPage(1);
  }, [searchWord]);

  useEffect(() => {
    const newSearchWord = searchParams.get("search");

    if (newSearchWord !== null) {
      setSearchWord(newSearchWord);
    }
  }, [searchParams]);

  return profileId ? (
    <>
      <SidebarNavSeparator />
      <Form
        inline="true"
        className="my-3"
        onSubmit={(event) => {
          event.preventDefault();
        }}
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <InputGroup className="input-group-navbar">
          <Form.Control
            placeholder="Search..."
            aria-label="Search"
            onChange={filterCampaigns}
            value={searchWord}
            className="search-field-navbar"
          />
          {searchWord ? (
            <Button
              onClick={() => {
                setSearchWord("");
                setSearchParams({ search: "" });
              }}
              className="search-field-adornment-navbar"
            >
              <X className="feather search-field-icon-navbar" />
            </Button>
          ) : (
            <Button className="search-field-adornment-navbar">
              <Search className="feather search-field-icon-navbar" />
            </Button>
          )}
        </InputGroup>
      </Form>
      <div style={{ flexGrow: 1 }} className="sidebar-campaign-items-container">
        <PerfectScrollbar>
          <div style={{ height: 0 }}>
            <SidebarNavSection key="Campaigns" pages={campaigns} title="" />
            {page < numPages ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "12px 0px",
                }}
              >
                {loading ? (
                  <Spinner radius={36} color="#E9ECEF" stroke={2} />
                ) : (
                  <Button
                    style={{
                      background: "var(--major-blue)",
                      border: "none",
                    }}
                    onClick={() => {
                      setPage((page) => page + 1);
                    }}
                  >
                    Load more ...
                  </Button>
                )}
              </div>
            ) : null}
          </div>
        </PerfectScrollbar>
      </div>
    </>
  ) : null;
};

const SidebarNav = ({ items, campaignsType }) => {
  console.log("ITEEEMS ARE", items);
  return (
    <ul
      className="sidebar-nav"
      style={{
        flexGrow: 1,
        marginBottom: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {items &&
        items.map((item) => (
          <SidebarNavSection
            key={item.title}
            pages={item.pages}
            title={item.title}
          />
        ))}
      <CampaignsNav campaignsType={campaignsType} />
    </ul>
  );
};

export default SidebarNav;
