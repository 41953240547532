import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";

import useAuth from "../../hooks/useAuth";

function SignIn() {
    const navigate = useNavigate();
    const { confirmUser, resendConfirmationCode } = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();
    const email = searchParams.get("email")
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    async function resendCode() {
        try {
            setSuccessMessage("Resending verification code...")
            const res = await resendConfirmationCode(email)
            setSuccessMessage("Verification code resent")
        } catch (error) {
            const message = error.message || "Couldn't resend verification code"
            setErrorMessage(message)
        }

    }

    return (

        <Formik
            initialValues={{
                email: email,
                confirmationCode: "",
                submit: false,
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .email("Must be a valid email")
                    .max(255)
                    .required("Email is required"),
                confirmationCode: Yup.string().max(255).required("Password is required"),
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    setSuccessMessage("Checking the code...")
                    const res = await confirmUser(values.email, values.confirmationCode);
                    setSuccessMessage("Code validated")
                } catch (error) {
                    const message = error.message || errorMessage || "Something went wrong";
                    setStatus({ success: false });
                    setErrors({ submit: message });
                    setSubmitting(false);
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
            }) => (
                <Form onSubmit={handleSubmit}>
                    {(errors.submit || errorMessage) && (
                        <Alert className="my-3" variant="danger">
                            <div className="alert-message">{errors.submit || errorMessage}</div>
                        </Alert>
                    )}
                    {successMessage && (
                        <Alert className="my-3" variant="success">
                            <div className="alert-message">{successMessage}</div>
                        </Alert>
                    )}
                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            size="lg"
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            value={values.email}
                            isInvalid={Boolean(touched.email && errors.email)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            disabled={true}
                        />
                        {!!touched.email && (
                            <Form.Control.Feedback type="invalid">
                                {errors.email}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Confirmation code</Form.Label>
                        <Form.Control
                            size="lg"
                            type="text"
                            name="confirmationCode"
                            placeholder="Enter your confirmation code"
                            value={values.confirmationCode}
                            isInvalid={Boolean(touched.confirmationCode && errors.confirmationCode)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        {!!touched.confirmationCode && (
                            <Form.Control.Feedback type="invalid">
                                {errors.confirmationCode}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>

                    <div className="text-center mt-3">
                        <Button
                            type="submit"
                            variant="primary"
                            size="lg"
                            disabled={isSubmitting}
                            className="m-3"
                        >
                            Confirm Email
                        </Button>
                        <Button
                            type="button"
                            variant="secondary"
                            size="lg"
                            disabled={isSubmitting}
                            onClick={resendCode}
                            className="m-3"
                        >
                            Resend Code
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default SignIn;
