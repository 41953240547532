import { Briefcase } from "react-feather";

const pagesSection = [
  {
    href: "/profiles",
    icon: Briefcase,
    title: "Accounts",
  },
];

export default function getDashboardItems(add = []) {
  const pages = [...pagesSection, ...add];
  return [
    {
      title: "Pages",
      pages: pages,
    },
  ];
}
