import { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Nav } from "react-bootstrap";

const TabNav = ({ profileId, campaignId, adGroupId, type, setShowNav, showNav }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState("");

  useEffect(() => {
    setSearch(searchParams.get("search"));
  }, [searchParams]);

  const adGroupNavs = [
    {
      title: "All Keywords",
      type: "targeting",
      href: `/profiles/${profileId}/campaigns/${campaignId}/adgroups/${adGroupId}/targeting${
        search ? "?search=" + search : ""
      }`,
    },
    {
      title: "Products & Categories",
      type: "targetingautopc",
      href: `/profiles/${profileId}/campaigns/${campaignId}/adgroups/${adGroupId}/targetingautopc${
        search ? "?search=" + search : ""
      }`,
    },
    {
      title: "All Auto Targeting",
      type: "targetingauto",
      href: `/profiles/${profileId}/campaigns/${campaignId}/adgroups/${adGroupId}/targetingauto${
        search ? "?search=" + search : ""
      }`,
    },
  ];

  const campaignNavs = [
    {
      title: "All Keywords",
      type: "targeting",
      href: `/profiles/${profileId}/campaigns/${campaignId}/targeting/${
        search ? "?search=" + search : ""
      }`,
    },
    {
      title: "Products & Categories",
      type: "targetingautopc",
      href: `/profiles/${profileId}/campaigns/${campaignId}/targetingautopc/${
        search ? "?search=" + search : ""
      }`,
    },
    {
      title: "All Auto Targeting",
      type: "targetingauto",
      href: `/profiles/${profileId}/campaigns/${campaignId}/targetingauto/${
        search ? "?search=" + search : ""
      }`,
    },
  ];

  const mainNavs = [
    {
      title: "All Keywords",
      type: "targeting",
      href: `/profiles/${profileId}/targeting/${
        search ? "?search=" + search : ""
      }`,
    },
    {
      title: "Products & Categories",
      type: "targetingautopc",
      href: `/profiles/${profileId}/targetingautopc/${
        search ? "?search=" + search : ""
      }`,
    },
    {
      title: "All Auto Targeting",
      type: "targetingauto",
      href: `/profiles/${profileId}/targetingauto/${
        search ? "?search=" + search : ""
      }`,
    },
  ];

  const navs = adGroupId ? adGroupNavs : campaignId ? campaignNavs : mainNavs;

  return (
    <Nav style={{ marginLeft: "30px", marginTop: "10px" }}>
      {navs.map(({ title, href, type: navType }, index) => (
        <Nav.Item key={index}>
          <Nav.Link
            as={Link}
            to={href}
            active={type === navType}
            onMouseEnter={() => {
              {
                setShowNav(true);
              }
            }}
            onMouseLeave={() => {
              {
                setShowNav(false);
              }
            }}
            style={
              type === navType
                ? {
                    border: "1px solid #1659c7",
                    backgroundColor: "#04223a",
                    color: "white",
                    padding: "8px",
                    borderTopLeftRadius: index === 0 ? "20px" : "0",
                    borderBottomLeftRadius: index === 0 ? "20px" : "0",
                    borderTopRightRadius:
                      index === navs.length - 1 ? "20px" : "0",
                    borderBottomRightRadius:
                      index === navs.length - 1 ? "20px" : "0",
                  } // active
                : {
                    border: "1px solid #1659c7",
                    padding: "8px",
                    color: "#062238",
                    borderTopLeftRadius: index === 0 ? "20px" : "0",
                    borderBottomLeftRadius: index === 0 ? "20px" : "0",
                    borderTopRightRadius:
                      index === navs.length - 1 ? "20px" : "0",
                    borderBottomRightRadius:
                      index === navs.length - 1 ? "20px" : "0",
                  }
            }
          >
            {title}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );
};

export default TabNav;
