import { ReactComponent as Column } from "assets/img/icons/column.svg";

const ColumnsButton = ({ onClick }) => {
  return (
    <button
      style={{
        background: "transparent",
        border: "none",
        fontSize: 12,
        color: "var(--back-dark)",
        minWidth: 72,
        outline: "none",
      }}
      onClick={onClick}
    >
      <Column />
      <div>Columns</div>
    </button>
  );
};

export default ColumnsButton;
